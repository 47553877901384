import React, {useState, useEffect} from 'react'
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker, {utils} from 'react-modern-calendar-datepicker';
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {AiOutlineInfoCircle} from 'react-icons/ai'
import {FiHelpCircle} from 'react-icons/fi'


export const InputFormGroup = ({type, placeholder, error, setError, errorMessage, label, value, setValue, help}) => {
    return (
        <div className="form-group">
            <label className="col-md-4">{label}</label>
            {help && <OverlayTrigger overlay = {<Tooltip>{help}</Tooltip>}>
                <FiHelpCircle className = 'ml-2'/>
            </OverlayTrigger>}
            <input
                type={type}
                name={type}
                className={`col-md-6 inline-block ${error ? 'form-control is-invalid' : 'form-control'}`}
                placeholder={placeholder}
                value = {value}
                onChange = {(e) => {setError(false); setValue(e.target.value)}}
            />
            <div className = 'invalid-feedback ml-1rem'>{"*"}{errorMessage}</div>
      </div>
    )
}


export const DateFormInput = ({placeholder, error, setError, errorMessage, label, setValue, value, minimumDate, disabled}) => {

    const [dateText, setDateText] = useState(value || '');

    useEffect(() => {
      setDateText(value || '')
    }, [value])

    const handleChange = (value) => {
        if(!value) return;
        setError(false);
        let day = value.day, month = value.month;
        if(value.day < 9){
            day = '0' + value.day
        }
        if(value.month < 9){
            month = '0' + value.month
        }
        setDateText(day + '/' + month + '/' + value.year)
        setValue(day + '/' + month + '/' + value.year)
    }

    return(
        <div className="form-group">
            <label className="col-md-4" >{label}</label>
            <div className = {`customDatePickerWidth col-md-6 inline-block p-0 ${ disabled ? 'card-disabled' : ''}`} style = {{position: 'relative'}}>
            <DatePicker  
                onChange = {handleChange} 
                value = {dateText ? {day: parseInt(dateText.split('/')[0]), month: parseInt(dateText.split('/')[1]), year: parseInt(dateText.split('/')[2])} : null}
                minimumDate={minimumDate ? utils().getToday() : null}
                shouldHighlightWeekends
                className = 'w-100'
                popperClassName="calendar-popout"
                inputClassName = 'w-100'
                popperPlacement="top-end"
                popperModifiers={{
                  offset: { enabled: true, offset: '5px, 10px' },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: 'viewport'
                  }
                }}
                renderInput = {
                    ({ref}) => 
                    <div className = 'input-group input-group-merge w-100'>
                      <input 
                          placeholder = {placeholder}
                          value = {dateText} 
                          ref = {ref} 
                          className = {error ? 'form-control form-control-appended is-invalid' : 'form-control form-control-appended'}
                          onBlur = {() => {
                            if(dateText === '') setError(true)
                          }}
                      />
                      <div className="input-group-append"> 
                        <span className="input-group-text">
                        {/* <ion-icon name="calendar-outline"></ion-icon> */}
                        </span>
                      </div>
                    </div>
                }
            />
            </div>
            <div className = 'invalid-feedback' style = {error ? {display: 'block'} : {}}>{errorMessage}</div>
        </div>
    )
}


export const DateFormInput1 = ({placeholder, error, setError, errorMessage, label, setValue, value, minimumDate}) => {
  const [dateText, setDateText] = useState(value || '');

  const handleChange = (value) => {
      if(!value) return;
      setError(false);
      let day = value.day, month = value.month;
      if(value.day < 9){
          day = '0' + value.day
      }
      if(value.month < 9){
          month = '0' + value.month
      }
      setDateText(day + '/' + month + '/' + value.year)
      setValue(day + '/' + month + '/' + value.year)
  }

  useEffect(() => {
    setDateText(value)
  }, [value])

  return(
      <div className="form-group mb-0">
          <label>{label}</label>
          <div className = 'customDatePickerWidth' style = {{position: 'relative'}}>
          <DatePicker  
              onChange = {handleChange} 
              value = {dateText ? {day: parseInt(dateText.split('/')[0]), month: parseInt(dateText.split('/')[1]), year: parseInt(dateText.split('/')[2])} : null}
              minimumDate={minimumDate ? utils().getToday() : null}
              shouldHighlightWeekends
              className = 'w-100'
              popperClassName="calendar-popout"
              inputClassName = 'w-100'
              popperPlacement="top-end"
              popperModifiers={{
                offset: { enabled: true, offset: '5px, 10px' },
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: 'viewport'
                }
              }}
              renderInput = {
                  ({ref}) => 
                  <div className = 'input-group input-group-merge w-100'>
                    <input 
                        placeholder = {placeholder}
                        value = {dateText} 
                        ref = {ref} 
                        className = {error ? 'form-control form-control-appended is-invalid' : 'form-control form-control-appended'}
                        onBlur = {() => {
                          if(dateText === '') setError(true)
                        }}
                    />
                    <div className="input-group-append"> 
                      <span className="input-group-text"><ion-icon name="calendar-outline"></ion-icon></span>
                    </div>
                  </div>
              }
          />
          </div>
          <div className = 'invalid-feedback' style = {error ? {display: 'block'} : {}}>{errorMessage}</div>
      </div>
  )
}

export const InputTextAreaGroup = ({label, description, value, setValue, placeholder, error, setError, errorMessage }) => {
    return(
        <div className="form-group">
            <label className="mb-1">{label}</label>
            <small className="form-text text-muted">{description}</small>
            <textarea 
                className = {error ? 'form-control is-invalid' : 'form-control'}
                placeholder = {placeholder} 
                value = {value}  
                onChange = {(e) => {setError(false); setValue(e.target.value)}} 
            />
            <div className = 'invalid-feedback' style = {error ? {display: 'block'} : {}}>{errorMessage}</div>
        </div>
    )
}


export const InputFormMergeGroup = ({type, placeholder, error, setError, errorMessage, label, value, setValue, children,}) => {
    return (
      <div className="form-group">
        <div className="row">
          <div className="col">
            <label>{label}</label>
          </div>
        </div>
        <div className="input-group input-group-merge">
          <input
            type={type}
            name={type}
            className={`form-control form-control-appended ${error ? "is-invalid" : ""}`}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              setError(false);
              setValue(e.target.value);
            }}
          />
          <div className="input-group-append">{children}</div>
          <div className="invalid-feedback">{errorMessage}</div>
        </div>
      </div>
    );
};


export const InputFormPrependGroup = ({type, placeholder, error, setError, errorMessage, label, value, setValue, children, pattern}) => {
    return (
      <div className="form-group">
        <div className="row">
          <div className="col">
            <label>{label}</label>
          </div>
        </div>
        <div className="input-group input-group-merge">
          <input
            type={type}
            className={`form-control form-control-prepended ${error ? "is-invalid" : ""}`}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              setError(false);
              setValue(e.target.value);
            }}
            pattern = {pattern}
          />
        <div className="input-group-prepend">{children}</div>
          <div className="invalid-feedback">{errorMessage}</div>
        </div>
      </div>
    );
  };






export const CheckBoxItem = ({description, value, setValue, help, isOverlay}) => {
  return(
    <div className="custom-control custom-checkbox checklist-control pl-5">
        <input className="custom-control-input" type="checkbox" checked = {value} onChange= {() => {}}/>
        <label className="custom-control-label curentWork" onClick = {() => setValue(!value)}></label>
        
        <span className=" small d-flex align-items-center" onClick = {() => setValue(!value)}>{description}
        {isOverlay && <OverlayTrigger  overlay = {<Tooltip>{help}</Tooltip>}>
            <AiOutlineInfoCircle className = 'ml-2'/>
        </OverlayTrigger>}
        </span>
    </div>
  )
}


