import { useState, useRef, useEffect,  useMemo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import RSelect from "react-select";

import DeleteIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";

import "./commonComponents.scss";

import { CommonFieldsType } from "../../Constants/FeedbackConstants";
import FeedbackTextEditor from "../../Components/FeedbackTextEditor";
import TextEditor from "../../Components/TextEditor";
import CodeBlockPopup from "../../Popups/CodeBlockPopup";
import ResultClarity from "../../../InterviewGuides/Components/ResultClarity";
import { renderToolTip } from "../../Components/RenderToolTip";
import styled from "styled-components";

const ReactSelect = styled(RSelect)`
  div:nth-child(3) > div > div>div:first-child
  {
    color: #3D3D3D;
    font-size: 13px;
    font-weight: 900;
  }
`;

const CommonComponents = ({
    fieldKey,
    fieldInfo,
    response,
    showValidationError,
    handleChange,
    parentInfo,
    childIndex,
    editable = true
}) => {

    const [isFocused, setFocused] = useState(false);
    const [isCodeDialogVisible, setCodeDialogVisibility] = useState(false);
    const updatedValue = useRef(response);

    //Capture the input value on change and store it in state
    const handleHover = (boolean) => {
        setFocused(boolean);
    };

    const handleOnKeyDown = (e) => {
        if (e?.key === 'Enter' || e?.type === 'blur') {
            handleChange(updatedValue.current);
        }
    }

    const handleOnChange = (value) => {
        updatedValue.current = value;
    }

    const renderCheckbox = () => {
        const onChange = (e) => {
            handleChange(e.target.checked ? true : false)
        }
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        sx={{ padding: '0px 9px' }}
                        size="small"
                        color="primary"
                        checked={response ? true : false}
                        onChange={onChange}
                    />
                }
                label={<Typography>{fieldInfo?.question}{fieldInfo?.required && <span className="asterisk">*</span>}</Typography>}
            />
        );
    };

    const renderRatingStaticBox = () => {
        return (<Box className="rating-static-block">
            <Typography alignSelf={'center'} className='mt-1 heading'>{fieldInfo?.heading}</Typography>

            <Typography alignSelf={'center'} className='mt-1 sub-heading'>{fieldInfo?.sub_heading}</Typography>

            <div className="ratings-bg mt-2">
                <ResultClarity ratingsList={fieldInfo?.data} />
            </div>
        </Box>)
    }

    return (<>

        {isCodeDialogVisible && <CodeBlockPopup response={response} handleChange={handleChange} setModalVisibility={setCodeDialogVisibility} />}

        {fieldInfo?.field_type === CommonFieldsType.STATIC_BLOCK.label && fieldInfo?.sub_type === 'Rating' ? renderRatingStaticBox()

            : <Box display="flex" className="common-component-container mt-3">
                
                <Typography style={{ width: fieldInfo?.question_width || '50%' }} className={`question ${showValidationError && !response && fieldInfo?.required ? "validation-error" : ""}`}>
                    {childIndex != null && parentInfo?.field_type === CommonFieldsType.ORDERED_LIST_SECTION.label && `${childIndex + 1}. `}

                    {
                        fieldInfo?.field_type === CommonFieldsType.CHECK_BOX.label
                            ? renderCheckbox()
                            : <>
                                {fieldInfo?.question}
                                {fieldInfo?.required && <span className="asterisk">*</span>}:
                            </>
                    }

                </Typography>

                <Box display="flex" flexDirection="column" style={{ width: fieldInfo?.field_width || '50%' }} className={`response ${parentInfo?.nested ? 'alignItemsEnd' : ''}`}>
                    <Box display="flex" className='w-100'>
                        {renderResponseFields(
                            fieldKey,
                            fieldInfo,
                            response,
                            showValidationError,
                            handleChange,
                            handleHover,
                            setCodeDialogVisibility,
                            handleOnKeyDown,
                            handleOnChange,
                            updatedValue,
                            editable,
                            parentInfo,
                            childIndex
                        )}

                        <Typography alignSelf={'center'} className='ml-2'>{fieldInfo?.field_info?.["post_label"]}</Typography>
                    </Box>

                    {
                        fieldInfo?.hints
                        && true //isFocused 
                        && (
                            <Box className="hint">
                                <div dangerouslySetInnerHTML={{ __html: fieldInfo?.hints }} />
                            </Box>
                        )}
                </Box>
            </Box>
        }
    </>
    );
};

export default CommonComponents;

const renderResponseFields = (
    fieldKey,
    fieldInfo,
    response,
    showValidationError,
    handleChange,
    handleHover,
    setCodeDialogVisibility,
    handleOnKeyDown,
    handleOnChange,
    updatedValue,
    editable,
    parentInfo,
    childIndex
) => {
    const handleIntChange = (value) => {
        const re = /^[0-9\b]+$/;

        if (value === "" || re?.test(value)) {
            handleChange(Number(value));
        }
    };

    const renderIntegerTextField = () => {
        return (
            <TextField
                name={fieldKey}
                placeholder={fieldInfo?.field_info?.placeholder}
                variant="outlined"
                inputProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { fontSize: 12, color: "GrayText" } }}
                sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                }}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}

                value={response}

                onChange={(e) => handleIntChange((e.target.value))}
                // onKeyDown={handleOnKeyDown}
                // onBlur={handleOnKeyDown}
            />
        );
    };

    const renderDecimalTextField = () => {
        return (
            <TextField
                name={fieldKey}
                type="number"
                placeholder={fieldInfo?.field_info?.placeholder}
                variant="outlined"
                inputProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { fontSize: 12, color: "GrayText" } }}
                sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                }}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}

                value={response}

                onChange={(e) => handleChange(parseFloat(e.target.value))}
                // onKeyDown={handleOnKeyDown}
                // onBlur={handleOnKeyDown}
            />
        );
    };

    const renderRichTextField = () => {
        return (
            <FeedbackTextEditor
                width='w-100'
                id="outlined-size-small"
                name={fieldKey}
                placeholder={fieldInfo?.field_info?.placeholder}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}

                value={response || ''}
                onChange={handleOnChange}
                onBlur={handleOnKeyDown}
                onKeyUp={handleOnKeyDown}
                readOnly={!editable}
            />
        );
    };

    const renderSingleLineWithAddMore = () => {
        const resp = response && response?.length > 0 ? [...response] : [''];


        const handleKeypress = (e) => {
            if (e?.key === "Enter") {
                // handleAddMore();

                const dupResponse = [...updatedValue.current];
                dupResponse.push("");
                updatedValue.current = dupResponse;
            }

            handleOnKeyDown(e);
        };
        const handleAddMore = () => {
            const dupResponse = [...resp];
            dupResponse.push("");
            handleChange(dupResponse);
        };

        const handleDelete = (index) => {
            const dupResponse = [...resp];
            dupResponse.splice(index, 1);
            handleChange(dupResponse);
        };

        const handleField = (e, index) => {
            const dupResponse = [...resp];
            dupResponse[index] = e;
            handleOnChange(dupResponse);
        };

        return (
            <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
                {resp?.map((data, index) => {
                    return (
                        <Box display="flex" sx={{ m: 0.5, gap: 1 }}>
                            <TextField
                                key={`${index + fieldKey + data}`}
                                size="small"
                                className="w-100"
                                name={fieldKey}
                                placeholder={fieldInfo?.field_info?.placeholder}
                                inputProps={{ style: { fontSize: 12 } }}
                                InputLabelProps={{ style: { fontSize: 12, color: "GrayText" } }}
                                onMouseEnter={() => handleHover(true)}
                                onMouseLeave={() => handleHover(false)}
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}

                                defaultValue={data}

                                onChange={(e) => handleField(e.target.value, index)}
                                onKeyDown={handleKeypress}
                                onBlur={handleOnKeyDown}
                            />

                            {resp?.length > 1 && (
                                <Button
                                    size="small"
                                    className="delete-button"
                                    onClick={(e) => handleDelete(index)}
                                >
                                    <DeleteIcon />
                                </Button>
                            )}

                            {index === resp?.length - 1 && (
                                <Button
                                    size="small"
                                    className="add-more"
                                    onClick={(e) => handleAddMore()}
                                >
                                    <AddIcon />
                                </Button>
                            )}
                        </Box>
                    );
                })}
            </Box>
        );
    };

    const renderRadioButton = () => {
        const handleClick = (event) => {
            if (event.target.value === response && !fieldInfo?.required) {
                handleChange("");

            } else {
                handleChange(event.target.value);
            }
        }

        return (
            <RadioGroup
                row
                name={fieldKey}
                value={response || ''}
                // onChange={(e) => handleChange(e.target.value)}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}
                disabled={!editable}
            >
                {fieldInfo?.field_info?.values?.map((data) => {
                    return (
                        <FormControlLabel value={data} control={<Radio  disabled={!editable} onClick={handleClick} />} label={data} />
                    );
                })}
            </RadioGroup>
        );
    };

    const renderCheckBox = () => {
        return (
            <></>
        )
    }
    const renderDropDown = () => {
        const result = fieldInfo?.field_info?.values.find(({ value }) => value == response);

        return (
            <Box display='flex' flexDirection='row' width={'100%'}  alignItems='center'>
            <Select
                size="small"
                defaultValue="none"
                value={response || 'none'}
                displayEmpty={true}
                name={fieldKey}
                placeholder={fieldInfo?.field_info?.placeholder}
                onChange={(e) => handleChange(e.target.value)}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}
                sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    width: '100%'
                }}
            >

              <MenuItem style={{ display: 'flex' }} disabled value="none" >{fieldInfo?.field_info?.placeholder}</MenuItem> 
                {
                    fieldInfo?.field_info?.values?.map((data) => {
                        return typeof (data) === 'object' ? <MenuItem style={{ display: 'flex' }} value={data?.value}>{data?.value}</MenuItem> : <MenuItem style={{ display: 'flex' }} value={data}>{data}</MenuItem>
                    })
                }
            </Select>
        {
              result && result?.tooltip_text && renderToolTip(result?.tooltip_text)
         }   
                </Box>
     
                
        );
    };

    const renderDropDownWithSubText = () => {
        const result = fieldInfo?.field_info?.values.find(({ value }) => value == response);

        return (
            <Box display='flex' flexDirection='column'>
                <Select
                    size="small"
                    value={response || ''}
                    name={fieldKey}
                    placeholder={fieldInfo?.field_info?.placeholder}
                    onChange={(e) => handleChange(e.target.value)}
                    onMouseEnter={() => handleHover(true)}
                    onMouseLeave={() => handleHover(false)}
                    sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        width: '100%'
                    }}
                >
                    {
                        fieldInfo?.field_info?.values?.map((data) => {
                            return typeof (data) === 'object' ? <MenuItem style={{ display: 'flex' }} value={data?.value}>{data?.value}</MenuItem> : <MenuItem style={{ display: 'flex' }} value={data}>{data}</MenuItem>
                        })
                    }
                </Select>

                {
                    result && <Box> <div className='dropdown-text mt-1' style={{ color: result.color }}>{result?.text}</div> <div className='dropdown-subtext mt-1'>{result?.sub_text}</div></Box>
                }

            </Box>
        );
    };

    const renderDropDownWrapper = () => {
        const handleChangeDropDown = (e) => {
            let result = fieldInfo?.field_info?.values?.find(data => (data?.question_asked || data?.question_statement) == e);
            handleChange({...result})
        }

        return (
            <Box display='flex' flexDirection='column' className="select-wrapper">
                <Select
                    className="dropdown"
                    displayEmpty
                    size="small"
                    value={response?.question_asked || response?.question_statement || ''}
                    name={fieldKey}
                    onChange={(e) => handleChangeDropDown(e.target.value)}
                    onMouseEnter={() => handleHover(true)}
                    onMouseLeave={() => handleHover(false)}
                    sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0, },
                    }}
                >
                    <MenuItem disabled value=""><em>{fieldInfo?.field_info?.placeholder}</em></MenuItem>
                    {
                        fieldInfo?.field_info?.values?.map((data) => {
                            return typeof (data) === 'object' ? <MenuItem style={{ display: 'flex' }} value={data?.question_asked  || data?.question_statement}>{data?.question_asked || data?.question_statement}</MenuItem> : <MenuItem style={{ display: 'flex' }} value={data}>{data}</MenuItem>
                        })
                    }
                </Select>

            </Box>
        );
    };

    const renderDropDownWithGroup = () => {
        let options = useMemo(() => {
            let otherOptionsObj = {};
            fieldInfo?.field_info?.values?.forEach((option) => {
                if (otherOptionsObj[option?.group] === undefined) {
                  otherOptionsObj[option?.group] = {
                    id: option?.group,
                    label: option?.group,
                    options: [{ label: option?.question_asked ? option?.question_asked : option?.question_statement, value: option }]
                  };
                } else {
                  otherOptionsObj[option.group].options.push({
                    label: option?.question_asked ? option?.question_asked : option?.question_statement ,
                    value: option
                  });
                }
              });
            let otherOptions = Object.keys(otherOptionsObj)?.map((key) => otherOptionsObj[key]);
            return otherOptions;
        }, [fieldInfo.field_info.values]);
        
        const handleChangeDropDown = (e) => {
            let result = e?.value;
            handleChange({...result});
        }
        
          return (
            <ReactSelect 
                className="dropdown dropdown-group"
                value={response?.question_asked?{label:response?.question_asked,value:response?.question_asked} :
                   response?.question_statement?{label:response?.question_statement,value:response?.question_statement} : options?.[0]?.options?.[0]}
                onChange={handleChangeDropDown}
                options={options}
                placeholder={options?.[0]?.label}
            />
          );
    }

    const renderNormalTextField = () => {
        return (
            <TextField
                name={fieldKey}
                placeholder={fieldInfo?.field_info?.placeholder}
                variant="outlined"
                sx={{
                    width: "100%",
                    padding: "0px",
                    "& legend": { display: "none" },
                }}
                inputProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { fontSize: 12, color: "GrayText" } }}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}

                defaultValue={response}

                onChange={(e) => handleOnChange(e.target.value)}
                onKeyDown={handleOnKeyDown}
                onBlur={handleOnKeyDown}
                disabled={!editable}
            />
        );
    };

    const renderMultiline = () => {
        return (
            <TextField
                name={fieldKey}
                placeholder={fieldInfo?.field_info?.placeholder}
                variant="outlined"
                multiline
                minRows={2}
                maxRows={5}
                sx={{
                    width: "100%",
                    padding: "0px",
                    "& legend": { display: "none" },
                }}
                inputProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { fontSize: 12, color: "GrayText" } }}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}

                defaultValue={response}

                onChange={(e) => handleOnChange(e.target.value)}
                onKeyDown={handleOnKeyDown}
                onBlur={handleOnKeyDown}
            />
        );
    }

    const renderCodeBlockField = () => {
        return (
            <TextEditor
                className='w-100'
                name={fieldKey}
                placeholder={fieldInfo?.field_info?.placeholder}
                value={response || ''}
                setValue={(value) => handleChange(value)}
            />
        );
    };


    const renderCodeBlockInDialogField = () => {
        return (
            <>
                <Button className="add-more-question mb-10" style={{ marginTop: '0px', marginRight: '5px', borderRadius: '0px' }}
                    onClick={() => setCodeDialogVisibility(true)}>
                    {response && response?.trim() !== "" ? "Edit" : "Add"} Solution Code
                </Button>

                {!response && <Typography style={{ fontStyle: 'italic' }}> (if any)</Typography>}
            </>
        );
    };

    switch (fieldInfo?.field_type) {
        case CommonFieldsType.INTEGER_TEXT.label:
            return renderIntegerTextField();

        case CommonFieldsType.DECIMAL_TEXT.label:
            return renderDecimalTextField();
    
        case CommonFieldsType.RICH_TEXT.label:
            return renderRichTextField();

        case CommonFieldsType.RADIO_BUTTON.label:
            return renderRadioButton();

        case CommonFieldsType.SINGLE_LINE_WITH_ADD_MORE.label:
            return renderSingleLineWithAddMore();

        case CommonFieldsType.DROP_DOWN.label:
            return renderDropDown();

        case CommonFieldsType.DROP_DOWN_WITH_SUBTEXT.label:
            return renderDropDownWithSubText();

        case CommonFieldsType.DROP_DOWN_WRAPPER.label:
            return renderDropDownWrapper();

        case CommonFieldsType.DROP_DOWN_WITH_GROUP.label:
            return renderDropDownWithGroup();
        
        case CommonFieldsType.NORMAL_TEXT.label:
            return renderNormalTextField();

        case CommonFieldsType.CODE_BLOCK.label:
            return renderCodeBlockField();

        case CommonFieldsType.CODE_BLOCK_IN_DIALOG_BOX.label:
            return renderCodeBlockInDialogField();

        case CommonFieldsType.CHECK_BOX.label:
            return renderCheckBox();


        case CommonFieldsType.MULTILINE_TEXT.label:
            return renderMultiline();

        case CommonFieldsType.STATIC_BLOCK.label:
        case CommonFieldsType.BUTTON.label:
            return <></>;

        case CommonFieldsType.ORDERED_LIST_SECTION.label:
        case CommonFieldsType.UNORDERED_LIST_SECTION.label:
            return <></>

        default:
            break;
    }
};
