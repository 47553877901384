import { React, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import './BackendValidationPopup.scss'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    p: 4,
};

export default function BackendValidationPopup({ backendValidation, setBackendValidationModal, canIgnoreWarning }) {
    const [open, setOpen] = useState(true);

    const type = backendValidation?.type === 'warning' ? 'warning_msg' : 'err_msg'; 

    const handleClose = () => {
        setOpen(false);
        setBackendValidationModal(false);
    }

    const handleIgnore = () => {
        setOpen(false);
        setBackendValidationModal(false);
        canIgnoreWarning(true);
    }

    const renderSummaryData = (section) => {
        return (
            Object.keys(backendValidation?.data?.[section]?.summary)?.map((data) => {
                if(Array.isArray(backendValidation?.data?.[section]?.summary?.[data])){
                    return  backendValidation?.data?.[section]?.summary?.[data]?.map((questionDataItem, questionDataItemIndex) => {
                          if (backendValidation?.data?.[section]?.summary?.[data][questionDataItemIndex]?.[type]) {
                              return (<div>
                                  <span className='modal-section-title'>{data?.replaceAll('_', ' ')} #{questionDataItemIndex+1}:</span>
                                  <span className='modal-section-error'>{backendValidation?.data?.[section]?.summary?.[data]?.[questionDataItemIndex]?.[type]}</span>
                              </div>)
                          }
                          })
                  } else if (backendValidation?.data?.[section]?.summary?.[data]?.[type]) {
                    return (
                        <p>
                            <span className='modal-section-title'>{data?.replaceAll('_', ' ')}:</span>
                            <span className='modal-section-error'>{backendValidation?.data?.[section]?.summary?.[data]?.[type]}</span>
                        </p>
                    )
                }
            })
        )

    }

    const renderQuestionData = (section) => {
        return (
            backendValidation?.data?.[section]?.questions?.map((questionData, index) => {
                let queErrorMessage = false;

                Object.keys(questionData)?.map((data) => {
                    if(Array.isArray(questionData?.[data])){
                        questionData?.[data]?.map((questionDataItem, questionDataItemIndex) => {
                            if (questionData?.[data][questionDataItemIndex]?.[type]) {
                                queErrorMessage = true;
                            }
                            })
                    }else{
                        if (questionData?.[data]?.[type]) {
                            queErrorMessage = true;
                      } else if(questionData?.[data]){    
                        Object.keys(questionData?.[data])?.map((quesDataItem, index) => {         
                          if(typeof(questionData?.[data]?.[quesDataItem]) === 'object' && questionData?.[data]?.[quesDataItem]?.[type]){
                            queErrorMessage = true ;
                          }
                        })
                        }
                    }
                   
                })

                if (queErrorMessage) {
                    return (
                        <p>
                            <div className='modal-section-question'>{`Question #${index + 1}`}:</div>
                            {
                                Object.keys(questionData)?.map((data) => {
                                    if(Array.isArray(questionData?.[data])){
                                      return  questionData?.[data]?.map((questionDataItem, questionDataItemIndex) => {
                                            if (questionData?.[data][questionDataItemIndex]?.[type]) {
                                                return (<div>
                                                    <span className='modal-section-title'>{data?.replaceAll('_', ' ')} #{questionDataItemIndex+1}:</span>
                                                    <span className='modal-section-error'>{questionData?.[data][questionDataItemIndex]?.[type]}</span>
                                                </div>)
                                            }
                                            })
                                    } else {
                                        if (questionData?.[data]?.[type]) {
                                        return (<div>
                                            <span className='modal-section-title'>{data?.replaceAll('_', ' ')}:</span>
                                            <span className='modal-section-error'>{questionData?.[data]?.[type]}</span>
                                        </div>)
                                    } else if(questionData?.[data]){
                                      return Object.keys(questionData?.[data])?.map((quesDataItem, index) => {
                                                           
                                        if(typeof(questionData?.[data]?.[quesDataItem]) === 'object' && questionData?.[data]?.[quesDataItem]?.[type]){
                                         return (<div>
                                            <span className='modal-section-title'>{quesDataItem}:</span>
                                            <span className='modal-section-error'>{questionData?.[data]?.[quesDataItem]?.[type]}</span>
                                        </div>)
                                        }
                                      })
                  
                                    }
                                    }
                                   
                                })
                            }
                        </p>
                    )
                }
            }))
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='backend-validation-modal-container'>
                    <Box className="modal-heading">
                        <span>
                            <CancelIcon style={{ position: "relative", top: "-5px", marginRight: "10px", color: '#D85554' }} />
                            <span className='modal-error'>{backendValidation?.type === 'warning' ? 'WARNING' : 'ERROR'}</span>
                        </span>
                        <ClearIcon onClick={handleClose} style={{ cursor: "pointer" }} />
                    </Box>

                    <Box className="modal-details">
                        {
                            Object.keys(backendValidation?.data)?.map((section) => {
                                return (
                                    <>
                                        <p className='modal-section-heading'> {section}</p>
                                        {(backendValidation?.data?.[section]?.summary) && renderSummaryData(section)}
                                        {renderQuestionData(section)}
                                    </>
                                )
                            })
                        }
                    </Box>

                    <button className="modal-ok-button ml-10" onClick={handleClose} >
                        OK
                    </button>

                   { backendValidation?.type === 'warning' && <button className="modal-ok-button ml-10" onClick={handleIgnore} >
                        Ignore
                    </button>}
                </Box>
            </Modal>
        </div>
    );
}