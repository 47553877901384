import { useState, useEffect } from "react";
import {useHistory} from "react-router-dom";
// apis....
import { getAuditReport } from "../../services/AuditForm";

import AuditReportShared from "@iv/shared/components/pages/AuditReport/AuditReport";

const AuditReport = () => {
  const [data, setData] = useState({});
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const idValue = urlParams.get("id");
  const urlToken = urlParams.get("token");

  const handleAuditReportIntegration = async () => {
    const auditResponse = await getAuditReport(idValue);
    setData(auditResponse?.data);
  };

  useEffect(() => {
    let sessionToken = sessionStorage.getItem("temporary_access_token");
    if(!urlToken && sessionToken)
    {
      let url = `${window.location.href}&token=${sessionToken}`;
      window.location.href = url;
      return;
    }
    else if(urlToken && !sessionToken)
    {
        history.push(`/audit-report/authorize?token=${urlToken}`);
        return;
    }
    handleAuditReportIntegration();
  }, []);

  return (
       <AuditReportShared data={data} setData={setData} canDownload={false}/> 
  );
};

export default AuditReport;
