import {Link} from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import moment from 'moment';

import emptyBox from '../../../images/empty-box.svg';

import './style.css';

const TableCard = ({id, title, tableHeaders, tableData , clsPrimarySoft="", clsTextDark=""}) => {

    const formatDate = (date) => {
        return moment(date, 'DD-MM-YY').format('MMM DD, YYYY');
    }

    return (
        <div className = 'card interviews-scheduled'>
            <div className = 'card-body'>
                <div className = 'row align-items-center mb-4'>
                <div className = 'col h4 mb-0 table-title'>{title}</div>
                    { tableData.length>0?
                        <Box display="flex" justifyContent="flex-start" className="see-all-link">
                            <Link to = {`/${id}`} className = 'col-auto small pointer btn-link text-decoration-underline'>See All</Link>
                            <span className="small">{">"}</span>
                        </Box>
                        :<span></span>
                    }
                </div>
                {tableData.length > 0 ? <div className = 'table-responsive rounded scrollable-table-wrapper'>
                    {(tableHeaders && tableHeaders.length > 0 && tableData && tableData.length > 0)&& <table className = "table card-table">
                        <thead className = ''>
                            <tr >
                                {tableHeaders.map(item => (<th key = {item.value} className = {item.label==="Date & Time"?"text-right":""}>{item.label}</th>))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map(item => (
                                <tr>
                                    {tableHeaders.map(header => {
                                        if(header.label==='Company'){
                                            return <td key = {header.value}>
                                                <Box display="flex" flexDirection="column" justifyContent="flex-start" className="table-value">
                                                    <Typography className="td-value">{item[header.value]}</Typography>
                                                </Box>
                                                </td>
                                        }else if(header.label==='Position'){
                                            return <td key = {header.value}>
                                                <Box display="flex" flexDirection="column" justifyContent="flex-start" className="table-value">
                                                    <Typography className="td-value">{item[header.value1]}</Typography>
                                                    <Typography className="td-value-sub">{item[header.value2]}</Typography>
                                                </Box>
                                            </td>
                                        }else{
                                            return <td key = {header.value}>
                                                <Box display="flex" flexDirection="column" justifyContent="flex-start" className="table-value text-right">
                                                <Typography className="td-value">{formatDate(item[header.value1])}</Typography>
                                                    <Typography className="td-value-sub">{item[header.value2]}</Typography>
                                                </Box>
                                            </td>
                                        }
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>}
                </div> : <Box class = 'py-6 my-4 h2 mb-0 text-muted text-center'>
                    <img src={emptyBox} alt="empty box img" />
                    <Typography className="no-data-text">You dont have any scheduled interview today.</Typography>
                </Box>}
            </div>
        </div>
    )
}


export default TableCard;