import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import "./Submitted.scss";
import Button from '@mui/material/Button';
import SuccessfullySubmitted from "../AuditFeedback/image/SuccessfullySubmitted.svg";

export const Submitted = ({handleSubmitClose}) => {

    return(<>
       <Box id="formSubmitted">
      <Box className="boxSize">
        <img src={SuccessfullySubmitted} alt="Successfully Submitted"/>
      </Box>
      <Box className="boxSize2">
        <Typography id="submitFirstText">
          Audit Report <span style={{color:"gray"}}>for this feedback is</span> successfully submitted
        </Typography>
        <Box id="submitSecondText">
          <Typography >
          <span style={{fontWeight:"600"}}>Refresh your dashboard</span> page, incase the Audit status is not updated there.
          </Typography>
        </Box>

        <Button variant="outlined" id="closeButton" onClick={handleSubmitClose}> Go to Audit! </Button>

      </Box>
    </Box>
    </>)
}