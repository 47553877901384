import axios from "./axios";

const getTemporaryFeedbackAccessToken = () => {
  const accessToken = sessionStorage.getItem('temporary_access_token');
  if (accessToken) {
    return {
      'X-Interviewer-Request-Access-Token': accessToken,
      'Authorization':null,
    };
  }

  return {};
};

export const audit_form_create = (payload)=>{
  return axios.post('/audit_reports/create',payload).then((res)=>{
    return res;
  })
  .catch((err)=>{
    return err.response;
  })
}

export const audit_form_edit = (ID, payload)=>{
  return axios.post(`/audit_reports/${ID}/edit`,payload).then((res)=>{
    return res;
  })
  .catch((err)=>{
    return err.response;
  })
}
export const downloadFile = async (url) => {
  const response = await axios({
    url: `/utils/file/download?${url}`, //your url
    method: "GET"
  });
  window.open(response.data, "_blank");
};

export const downloadVideo = (url) => {
  return axios.get(`/utils/file/download?${url}`)
  .then((response) => {
    return response;
  })
  .catch((err) => {
    return err.response;
  });
};

export const getAuditReport = (audit_id) => {
  return axios
    .get(`/audit_details/${audit_id}`, {
      headers: { ...getTemporaryFeedbackAccessToken() }})
    .then((response) => response.data)
    .catch((err)=>{
      return err?.response;
    })
};