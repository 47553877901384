import { useEffect, useState, useCallback } from 'react';
import { Box, Button, Typography, Modal, Checkbox, FormControlLabel } from '@material-ui/core';
import sortIcon from './images/sort-icon.svg';
import filterIcon from './images/filter-icon.svg';
import './filterAndSort.scss';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import BasicDateRangePicker from "../../../components/StartEndDatePicker/StartEndDatePicker"

const FilterAndSort = ({
    allPositions,
    allCompanies,
    editFilters,
    filters,
    setFilters,
    setRequests,
    resetRequired,
    setResetRequired,
    initFilters,
    fetchedRequests
}) => {
    const [sortModal, setSortModal] = useState(false); // State to track visibility of Sort modal
    const [filterModal, setFilterModal] = useState(false); // State to track visibility of Filter modal
    const [filterOption, setFilterOption] = useState('companies'); // State to track currently selected type of filter
    const [selectedCompany, setSelectedCompany] = useState(filters?.companies || 'All Companies'); // Value only, not { label, value }
    const [selectedPosition, setSelectedPosition] = useState(filters?.positions || 'All Positions'); // Value only, not { label, value }
    const [sortFilters, setSortFilters] = useState({ sortBy: '', order: '' });
    const [startDate, setStartDate] = useState(null); // Js native Date object
    const [endDate, setEndDate] = useState(null); // Js native Date object

    // Performs reset
    useEffect(() => {
        if (resetRequired) { // Perform reset only if needed
            // Perform reset actions
            setSelectedPosition('All Positions');
            setSelectedCompany('All Companies');
            setFilterOption('companies');
            setFilters(initFilters);
            setSortFilters({ sortBy: '', order: '' });
            setStartDate(null);
            setEndDate(null);
            setRequests(fetchedRequests); // Show all fetched requests

            // Set reset required to false in the end
           setResetRequired(false);
        }
    }, [resetRequired, setResetRequired, initFilters, setFilters, fetchedRequests, setRequests])

    // Handles setting date (unix secs) in Filters
    useEffect(() => {
        if (!!startDate && !!endDate) {
            let fromDate = moment(startDate).unix();
            let toDate = moment(endDate).set({ hour: 23 }).unix();
            if (fromDate > toDate) {
                cogoToast.error('To Date should be greater than from date');
                setEndDate(null);
                setFilters((prev) => ({ ...prev, fromDate, toDate: "" }))
            } else {
                setFilters((prev) => ({ ...prev, fromDate, toDate }))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    // Opens/closes sort modal
    const handleSortClick = () => {
        setSortModal(!sortModal);
    }

    // Opens/closes filter model
    const handleFilterClick = () => {
        setFilterModal(!filterModal);
    }

    // Clears filters
    const handleClearFilters = () => {
        setFilterOption('companies');
        setSelectedCompany(allCompanies[0]?.value);
        setSelectedPosition(allPositions[0]?.value);
    }

    // Closes filter modal
    const closeFilterModal = () => {
        handleClearFilters();
        setFilterModal(false);
    }

    // Function that applies filter
    const applyFilters = () => {
        if (selectedCompany?.value !== "") {
            editFilters(selectedCompany, 'companies');
        }
        if (selectedPosition?.value !== "") {
            editFilters(selectedPosition, 'positions');
        }
        setFilterModal(false);
    }

    // Handles sort options button click
    const handleSortOptionClick = (sortBy, order) => {
        setSortFilters({ sortBy, order });
        setSortModal(false);
    }

    // Handles sorting
    useEffect(() => {
        switch (sortFilters.sortBy) {
            case "timestamp":
                setRequests((prevRequests) => {
                    const newPrevRequests = [...prevRequests]
                    newPrevRequests?.sort((requestA, requestB) => {
                        if (sortFilters?.order === 'asc') {
                            return (requestA?.dateTime?.value < requestB?.dateTime?.value ? -1 : 1)
                        } else if (sortFilters.order === 'desc') {
                            return (requestA?.dateTime?.value >= requestB?.dateTime?.value ? -1 : 1)
                        }
                        return 0
                    })
                    return newPrevRequests;
                })
                break;
            case "companyName":
                setRequests((prevRequests) => {
                    const newPrevRequests = [...prevRequests]
                    newPrevRequests?.sort((requestA, requestB) => {
                        if (sortFilters?.order === 'asc') {
                            return (requestA?.companyName < requestB?.companyName ? -1 : 1)
                        } else if (sortFilters.order === 'desc') {
                            return (requestA?.companyName >= requestB?.companyName ? -1 : 1)
                        }
                        return 0
                    })
                    return newPrevRequests;
                })
                break;
            default: // No sorting
                break
        }
    }, [sortFilters, setRequests])

    // Function for date filter handling
    const handleDate = useCallback(([from, to]) => {
        setStartDate(from)
        setEndDate(to)
    }, [])

    return (
        <>
            {/* Date filters and Urgent filter */}
            <Box className="date-and-urgent-filters">
                {/* Date filters */}
                <Box>
                    <BasicDateRangePicker handleDate={handleDate} startDateEndDate={[startDate, endDate]}/>
                    {/*<DatePicker
                        popperPlacement="bottom-start"
                        selected={startDate}
                        onChange={setStartDate}
                        placeholderText="From date"
                    />
                    <DatePicker
                        popperPlacement="bottom-end"
                        selected={endDate}
                        onChange={setEndDate}
                        placeholderText="To date"
                        className="date-picker"
                    />*/}
                </Box>

                {/* Urgent filter */}
                <FormControlLabel className="urgent-wrapper" control={
                    <Checkbox color="primary" checked={filters?.isUrgent} onChange={(e) => {
                        editFilters(e.target.checked, 'isUrgent');
                    }} size="small" />
                } labelPlacement="start" label="Urgent" />

            </Box>

            {/* Sort modal */}
            <Modal open={sortModal} onClose={() => setSortModal(false)}>
                <Box className="sort-modal-wrapper" display="flex" flexDirection="column">
                    {/* Heading */}
                    <Box className='sort-modal-header'>
                        <Typography className='header-text'>Sort By</Typography>
                    </Box>

                    {/* Sort options */}
                    <Box className="sort-options">
                        <Button color="primary" variant="text" onClick={() => handleSortOptionClick('timestamp', 'desc')} className="sort-filter-button">Newest to Oldest</Button>
                        <Button color="primary" variant="text" onClick={() => handleSortOptionClick('timestamp', 'asc')} className="sort-filter-button">Oldest to Newest</Button>
                        <Button color="primary" variant="text" onClick={() => handleSortOptionClick('companyName', 'asc')} className="sort-filter-button">Company Name: A to Z</Button>
                        <Button color="primary" variant="text" onClick={() => handleSortOptionClick('companyName', 'desc')} className="sort-filter-button">Company Name: Z to A</Button>
                    </Box>
                </Box>

            </Modal>

            {/* Filter modal */}
            <Modal open={filterModal} onClose={() => setFilterModal(false)}>
                <Box className="filter-modal-wrapper" display="flex" flexDirection="column">

                    {/* Top AppBar for modal title and Clear button */}
                    <Box display="flex" justifyContent="space-between" alignItems="center" className='filter-modal-header'>
                        <Typography className='header-text'>Filters</Typography>
                        <Button variant="text" className='clearFilter-btn' onClick={handleClearFilters}>Clear All</Button>
                    </Box>

                    {/* Modal content - 2 Column layout */}
                    <Box display="flex" className='filter-modal-content'>

                        {/* Left column - Select type of filter */}
                        <Box display="flex" flexDirection="column" className='filters-list'>
                            <Typography
                                className={`filter-label ${filterOption === 'companies' ? "selected-filter" : ""}`}
                                onClick={() => setFilterOption('companies')}
                            >
                                All Companies
                            </Typography>
                            <Typography
                                className={`filter-label ${filterOption === 'positions' ? "selected-filter" : ""}`}
                                onClick={() => setFilterOption('positions')}
                            >
                                All Positions
                            </Typography>
                        </Box>

                        {/* Right column - Filter options */}
                        <Box display="flex" flexDirection="column" className='filter-options'>

                            {/* If company filter type is selected, show list of companies */}
                            {filterOption === 'companies' &&
                                allCompanies.map((company) =>
                                    <Box key={company.value} display="flex" alignItems="center" className='filter-option'>
                                        <Checkbox checked={selectedCompany === company.value} value={company.value} color="primary" onClick={() => setSelectedCompany(company?.value)} />
                                        <Typography className={`option-value ${selectedCompany === company.value && "checked"}`}>{company.label}</Typography>
                                    </Box>
                                )
                            }

                            {/* If position filter type is selected, show list of positions */}
                            {filterOption === 'positions' &&
                                allPositions.map((position) =>
                                    <Box key={position.value} display="flex" alignItems="center" className='filter-option'>
                                        <Checkbox checked={selectedPosition === position.value} value={position.value} color="primary" onClick={() => setSelectedPosition(position?.value)} />
                                        <Typography className={`option-value ${selectedPosition === position.value && "checked"}`}>{position.label}</Typography>
                                    </Box>
                                )
                            }

                        </Box>
                    </Box>

                    {/* Filter modal bottom AppBar - Close and Apply */}
                    <Box display="flex" className='filter-modal-footer'>
                        <Button variant="outlined" className='close-button' onClick={closeFilterModal}>Close</Button>
                        <Button variant="contained" className='apply-button' onClick={applyFilters}>Apply</Button>
                    </Box>
                </Box>

            </Modal>

            {/* Bottom AppBar - Sort and Filter */}
            <Box className="filters-footer">
                <Button variant="text" className='sort-button' onClick={handleSortClick}><img className='filter-icon' src={sortIcon} alt="Sort icon" />Sort</Button>
                <Button variant="text" className='filter-button' onClick={handleFilterClick}><img className='filter-icon' src={filterIcon} alt="Filter icon" />Filter</Button>
            </Box>
        </>
    )
}

export default FilterAndSort;
