import { styled } from "@material-ui/core/styles"
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from "@material-ui/core/TextField"
import { KeyboardDatePicker } from '@material-ui/pickers';

// Common

export const StyledSectionGridParent = styled(Grid)(({ theme }) => ({
    gap: theme.spacing(4)
}))

export const StyledSectionGridChild = styled(Grid)(({ theme }) => ({

}))

export const StyledWorkAndEducationForm = styled('form')(({ theme }) => ({
    width: "100%"
}))

export const StyledSectionPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3)
}))

export const StyledSectionPaperHeading = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    color: "#3D3D3D",
    marginBottom: theme.spacing(2)
}))

export const StyledSectionPaperHeadingBlue = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    color: theme.palette.primary.main,
    width: "fit-content"
}))

export const StyledSectionPaperSelect = styled(Select)(({ theme }) => ({
    marginTop: theme.spacing(3)
}))

export const StyledSectionPaperMenuItem = styled(MenuItem)(({ theme }) => ({

}))

export const StyledAddMoreButton = styled(Button)(({ theme }) => ({
    textTransform: "unset",
    fontSize: theme.typography.body1.fontSize,
    display: "block"
}))

export const StyledSectionPaperFormTextField = styled(TextField)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
}))

export const StyledSectionPaperFormDatePicker = styled(KeyboardDatePicker)(({ theme }) => ({

}))

export const StyledWorkFormSubmitButton = styled(Button)(({ theme }) => ({
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textTransform: "capitalize",
    fontSize: theme.typography.body1.fontSize,
    [theme.breakpoints.up("sm")]: {
        width: "fit-content",
        float: "right",
        marginRight: theme.spacing(2)
    }
}))

// For Resume section
export const StyledUploadIcon = styled(BackupOutlinedIcon)(({ theme }) => ({
    color: "#6A6A6A",
    height: "18px"
}))

export const StyledResumeBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
}))

export const StyledDocIcon = styled(InsertDriveFileOutlinedIcon)(({ theme }) => ({

}))

export const StyledDeleteResumeButton = styled(Button)(({ theme }) => ({
    textTransform: "unset",
    marginLeft: "auto"
}))

// Work section

export const StyledWorkFooter = styled(Box)(({ theme }) => ({
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
}))
