import React from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

//import reusables...
import Miscellaneous from "./Miscellaneous";
import InterviewQualityRating from "./InterviewQualityRating";
import SkillRatings from "./SkillRatings";
import FeedbackSummary from "./FeedbackSummary";
import SideComponent from "./SideComponent";
import Navbar from "./Navbar";
import FeedbackBox from "./FeedbackBox";
import InterviewProcessAssessment from "./InterviewProcessAssessment";

const stylesPDF = StyleSheet.create({
  page: {
    backgroundColor: "#F0F0F0",
  },

  bodySectionDirection: {
    display: "flex",
    flexDirection: "row",
  },
});

const stylesBody = StyleSheet.create({
  bodyPage: {
    backgroundColor: "#ffffff",
    marginLeft: 20,
    borderRadius: 14,
    padding: 20,
    width: 850,
  },
});

const Download = ({ data }) => {
  return (
    <Document>
      <Page size="A2" style={[stylesPDF.page]} wrap={false}>
        <Navbar />

        <View style={[stylesPDF.bodySectionDirection]}>
          <View>
            <SideComponent data={data} />
          </View>
          <View>
            <FeedbackBox data={data} />
            <Body data={data} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Download;

const Body = ({ data }) => {
  return (
    <View>
      {/* body content starts */}

      <View style={[stylesBody.bodyPage]}>
        {/* Interview Proces assessment */}
        <InterviewProcessAssessment data={data} />

        {/* feedback summary starts */}
        <FeedbackSummary data={data} />

        {/* Skill Ratings */}
        <SkillRatings data={data} />

        {/* interview quality ratings */}
        <InterviewQualityRating data={data} />

        {/* Miscellaneous */}
        <Miscellaneous data={data} />

        {/* bodyPage ends here at next view */}
      </View>

      {/* feedback box and body wrapper ends here */}
    </View>
  );
};
