import React, {useEffect, useState} from 'react'
import PersonalInfo from './PersonalInfo'
import Work from './Work'
import Account from './Account'
import BankDetails from './BankDetails'
import NavBar from '../Nav';
import { useDispatch, useSelector } from 'react-redux';
import {fetchProfile, editProfile, editBankDetails} from '../../services/profile'
import {profileAction} from '../../store/actions/Profile'
import cogoToast from 'cogo-toast'
import { updateTimeZone } from '../../store/actions/User'
import { HeaderView } from '../../components/Pages/Views'
import moment from 'moment'
import './User.scss';


function MyProfile(props) {

    const dispatch = useDispatch();
    const fetchedProfile = useSelector((state) => state && state.profile && state.profile);
    const [key, setKey] = useState('personal');
    const [profile, setProfile] = useState();
    const [loading, setLoading] = useState(false);
    const [pageName, setPageName] = useState(""); // State to store current sub-page name for navbar

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [])
   
    useEffect(() => {
        setProfile(fetchedProfile);
    }, [fetchedProfile])


    const fetchData = async () => {
        try{
            const res = await fetchProfile()
            dispatch(profileAction(res));
        } catch(err){
        }
    }


    useEffect(() => {
        setKey(props.match.params.id)
    }, [props.match.params.id])

    const saveProfile = async (data) => {
        try{
            setLoading(true);
            const res = await editProfile(data)
            cogoToast.success("Profile saved successfully");
            res.contact_number = res.phone;
            delete res.phone;
            dispatch(profileAction({...res, avatar: data.avatar}));
            if(data?.user_config?.timezone){
                dispatch(updateTimeZone(data?.user_config?.timezone));
            }
            setLoading(false);
        } catch(err){
            if(err.response.data === "Some Exception: Stale interview data! Please refresh your browser before modifying again.") cogoToast.error(err.response.data)
            setLoading(false);
        }
    }

    const saveBankDetails = async (data) => {
        try{
            setLoading(true);
            const res = await editBankDetails(data)
            cogoToast.success("Bank details saved successfully");
            dispatch(profileAction({...res, avatar: data?.avatar}));
            if(data?.user_config?.timezone){
                dispatch(updateTimeZone(data?.user_config?.timezone));
            }
            setLoading(false);
        } catch(err){
            setLoading(false);
        }
    }


    return (    
    <>
        <NavBar {...props} pageTitle={pageName}/>
    <div className="main-content UserComp">
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="header mt-md-5">
                        <div className="">
                        <HeaderView title = 'My profile' OwnComp={<div className="fl-col headerComp">
                            <span className="txt-align-right heading">Welcome</span>
                            <span className="sub-heading" >{moment().format("DD MMM YYYY, ddd")}</span>
                        </div>}/>
                    </div>
                </div>
                
  
               { profile && 
               <div className="profile-cont-auto overall-content">
                    
                    {key === "personal" &&
                        <PersonalInfo 
                            profile = {profile} 
                            setProfile = {setProfile} 
                            saveProfile = {saveProfile} 
                            loading = {loading}
                            setLoading = {setLoading}
                            setPageName={setPageName}
                        />
                    }

                    {key === "work-education" &&
                        <Work 
                            profile = {profile} 
                            setProfile = {setProfile} 
                            saveProfile = {saveProfile} 
                            loading = {loading}
                            setLoading = {setLoading}
                            setPageName={setPageName}
                        />
                    }

                    {key === "bank-details" &&
                        <BankDetails 
                            profile = {profile} 
                            setProfile = {setProfile} 
                            saveBankDetails = {saveBankDetails} 
                            loading = {loading}
                            setPageName={setPageName}
                        />
                    }

                    {key === "account" &&
                        <Account
                            profile = {profile} 
                            saveProfile = {saveProfile}  
                            setPageName={setPageName}
                        />
                    }
                </div>}
                </div>
            </div> 
        </div>
    </div>  
    </>
    )
}

export default MyProfile
