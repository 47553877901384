import { InterviewFeedbackStatus, FeedbackType, showCodingQuestions, isCodingQuestionsFilled, showProblemSolvingCodingFields, isTheoryQuestionFilled, isFlipkartFeedbackDesign, isGenericFeedback, getDataAccordingToFieldType, isV2SectionFilled } from "./../Constants/FeedbackConstants";

export const getResumeDataForAPI = (feedbackStatus, resumeIntroData) => {
  let obj = {
    group_type: "RESUME",
    time_spent: resumeIntroData?.timeSpent !== "" ? resumeIntroData?.timeSpent : null,
    questions: [
      {
        question_type: "GENERAL_RESUME",
        time_spent: resumeIntroData?.timeSpent !== "" ? resumeIntroData?.timeSpent : null,
        order: 1,

        feedback: [
          {
            question: "experience",
            response: resumeIntroData?.experience,
          },
          {
            question: "projects",
            response: resumeIntroData?.projects,
          },
          {
            question: "techStack",
            response: resumeIntroData?.techStack,
          },
          {
            question: "extraComments",
            response: resumeIntroData?.extraComments,
          },
        ],
      },
    ],
  };

  if (feedbackStatus !== InterviewFeedbackStatus.PENDING) {
    obj.id = resumeIntroData?.id;
    obj.questions[0].id = resumeIntroData?.questionId;
  }
  return obj;
};

export const getCodingDataForAPI = (feedbackStatus, codingQuestionData, feedbackType, interviewDetails) => {
  if (!isCodingQuestionsFilled(codingQuestionData, feedbackType, interviewDetails)) {
    return;
  }
  let totalTimeSpent = 0;
  let questionArray = [];
  for (let i = 0; i < codingQuestionData?.questions?.length; i++) {
    const data = codingQuestionData?.questions[i];
    if (data?.timeSpent !== "") {
      totalTimeSpent = totalTimeSpent + parseInt(data?.timeSpent);
    }

    let questionTypeData = interviewDetails?.feedback_steps?.find((data) => {
      if (data?.group_type === 'SYSTEM_DESIGN') {
        return true;
      }
    }).question_type;

    let questionType = feedbackType === FeedbackType.GENERIC && codingQuestionData?.questionType === 'GENERAL_PROBLEM_SOLVING' ? 'GENERAL_PROBLEM_SOLVING'
      : data?.questionType ? data?.questionType : isFlipkartFeedbackDesign(interviewDetails, data) ? questionTypeData
        : feedbackType === FeedbackType.PROBLEM_SOLVING_CODING ? "GENERAL_PROBLEM_SOLVING_CODING"
          : feedbackType === FeedbackType.PROBLEM_SOLVING ? "GENERAL_PROBLEM_SOLVING"
            : feedbackType === FeedbackType.HIGH_LEVEL_DESIGN ? "HIGH_LEVEL_DESIGN"
              : feedbackType === FeedbackType.LOW_LEVEL_DESIGN ? "LOW_LEVEL_DESIGN"
                : "GENERAL_PROBLEM_SOLVING_CODING"

    let questionObj = {
      question_type: questionType,
      time_spent: data?.timeSpent !== "" ? data?.timeSpent : null,
      order: i + 1,
      feedback: getCodingFeedback(feedbackType, data, interviewDetails, codingQuestionData),
    };

    if (feedbackStatus !== InterviewFeedbackStatus.PENDING) {
      questionObj.id = data?.questionId;
    }
    questionArray.push(questionObj);
  }

  let obj = {
    group_type: isGenericFeedback ? 'GENERIC' : showCodingQuestions(interviewDetails, codingQuestionData) ? (feedbackType === FeedbackType.PROBLEM_SOLVING_CODING ? "PROBLEM_SOLVING_CODING" : "PROBLEM_SOLVING") : "DESIGN",
    time_spent: totalTimeSpent,
    questions: questionArray,
  };

  if (feedbackStatus !== InterviewFeedbackStatus.PENDING) {
    if (codingQuestionData?.id)
      obj.id = codingQuestionData?.id;
  }
  return obj;
};

const getCodingFeedback = (feedbackType, data, interviewDetails, codingQuestionData) => {
  let feedback = [];
  feedback.push(
    {
      question: "questionLink",
      response: data?.questionLink || "",
    },
    {
      question: "questionAsked",
      response: data?.questionAsked?.trim(),
    },
    {
      question: "isQuestionCompleted",
      response: data?.isQuestionCompleted?.trim(),
    },
    {
      question: "isSolutionOptimal",
      response: data?.isSolutionOptimal?.trim(),
    }
  )
  if (showProblemSolvingCodingFields(interviewDetails, codingQuestionData)) {
    feedback.push(
      {
        question: "languageUsed",
        response: data?.languageUsed?.trim() || "",
      },
      {
        question: "solutionCode",
        response: data?.solutionCode?.trim() || "",
      },
    )
  }

  if (isFlipkartFeedbackDesign(interviewDetails, data)) {
    feedback.push(
      {
        question: "assumptions",
        response: data?.assumptions,
      },
      {
        question: "heuristics",
        response: data?.heuristics,
      },
      {
        question: "entityModel",
        response: data?.entityModel,
      },
      {
        question: "apiDesign",
        response: data?.apiDesign,
      },
      {
        question: "concurrency",
        response: data?.concurrency,
      },
      {
        question: "subcomponentBreakdown",
        response: data?.subcomponentBreakdown,
      },
      {
        question: "asyncSyncInteraction",
        response: data?.asyncSyncInteraction,
      },
      {
        question: "designTradeoffs",
        response: data?.designTradeoffs,
      },
      {
        question: "familiarity",
        response: data?.familiarity,
      },
      {
        question: "correctness",
        response: data?.correctness,
      })
  } else if (showCodingQuestions(interviewDetails, codingQuestionData)) {
    feedback.push(
      {
        question: "approach",
        response: data?.approach,
      },
      {
        question: "positives",
        response: data?.positives,
      },
      {
        question: "neutrals",
        response: data?.neutrals
      },
      {
        question: "negatives",
        response: data?.negatives
      },
    )

    if (showProblemSolvingCodingFields(interviewDetails, codingQuestionData)) {
      feedback.push(
        {
          question: "spaceComplexity",
          response: data?.spaceComplexity?.trim(),
        },
        {
          question: "timeComplexity",
          response: data?.timeComplexity?.trim(),
        },
      )
    }
  } else {
    feedback.push(
      {
        question: "designProcess",
        response: data?.designProcess,
      },
      {
        question: "requirementGathering",
        response: data?.requirementGathering,
      },
      {
        question: "scalability",
        response: data?.scalability,
      },
      {
        question: "dbchoices",
        response: data?.dbchoices,
      },
      {
        question: "caching",
        response: data?.caching
      },
      {
        question: "extraComments",
        response: data?.extraComments
      },
    )

    if (feedbackType === FeedbackType.HIGH_LEVEL_DESIGN) {
      feedback.push(
        {
          question: "highLevelDesign",
          response: data?.highLevelDesign,
        })
    } else {
      feedback.push(
        {
          question: "lowLevelDesign",
          response: data?.lowLevelDesign,
        })
    }
  }

  return feedback;

}

export const getTheoryDataForAPI = (feedbackStatus, theoryQuestionsData) => {

  let isTheoryQuesFilled = isTheoryQuestionFilled(theoryQuestionsData);
  if (!isTheoryQuesFilled) {
    return;
  }

  let totalTimeSpent = theoryQuestionsData?.timeSpent !== "" ? theoryQuestionsData?.timeSpent : null;
  let questionArray = [];
  for (let i = 0; i < theoryQuestionsData?.questions?.length; i++) {
    const data = theoryQuestionsData?.questions[i];

    let questionObj = {
      question_type: "GENERAL_THEORY",
      time_spent: theoryQuestionsData?.timeSpent !== "" ? theoryQuestionsData?.timeSpent : null,
      order: i + 1,
      feedback: [
        {
          question: "questionAsked",
          response: data?.questionAsked?.trim(),
        },
        {
          question: "codeText",
          response: data?.codeText,
        },
        {
          question: "candidateResponse",
          response: data?.candidateResponse?.trim(),
        }
      ],
    };

    if (feedbackStatus !== InterviewFeedbackStatus.PENDING && !(data?.newQuestion)) {
      questionObj.id = data?.questionId;
    }
    questionArray.push(questionObj);
  }

  let obj = {
    group_type: "THEORY",
    time_spent: totalTimeSpent || null,
    questions: questionArray,
  };

  if (feedbackStatus !== InterviewFeedbackStatus.PENDING) {
    if (theoryQuestionsData?.id)
      obj.id = theoryQuestionsData?.id;
  }
  return obj;
};

export const getSectionV2DataForAPI = (interviewDetails) => {
  if (isV2SectionFilled('theory', interviewDetails)) {
      const resp = getSectionionedDataV2ForAPI('theory', interviewDetails)
      interviewDetails.feedback_pages.raw_response.theory = { ...resp }
  } else {
    interviewDetails.feedback_pages.raw_response = {
      ...interviewDetails?.feedback_pages?.raw_response, theory: {}
    }
  }

  if (isV2SectionFilled('coding', interviewDetails)) {
      const resp = getSectionionedDataV2ForAPI('coding', interviewDetails)
      interviewDetails.feedback_pages.raw_response.coding = { ...resp }
  } else {
    interviewDetails.feedback_pages.raw_response = {
      ...interviewDetails.feedback_pages.raw_response, coding: {}
    }
  }

  if (isV2SectionFilled('resume', interviewDetails)) {
    const resp = getSectionionedDataV2ForAPI('resume', interviewDetails)
    interviewDetails.feedback_pages.raw_response.resume = { ...resp }
} else {
  interviewDetails.feedback_pages.raw_response = {
    ...interviewDetails.feedback_pages.raw_response, resume: {}
  }
}

if (isV2SectionFilled('summary', interviewDetails)) {
  const resp = getSectionionedDataV2ForAPI('summary', interviewDetails)
  interviewDetails.feedback_pages.raw_response.summary = { ...resp }
} else {
interviewDetails.feedback_pages.raw_response = {
  ...interviewDetails.feedback_pages.raw_response, summary: {}
}
}

  return interviewDetails?.feedback_pages;
};

const getSectionionedDataV2ForAPI = (section, interviewDetails) => {
  let rawForm = interviewDetails?.feedback_pages?.raw_form?.[section];
  let rawResponse = interviewDetails?.feedback_pages?.raw_response?.[section];

  const dupResponse = { ...rawResponse };

  rawForm?.summary?.field_order?.map((data) => {
    dupResponse.summary[data] = getDataAccordingToFieldType(rawForm?.summary?.fields?.[data]?.field_type, rawResponse?.summary?.[data])
  })

  rawResponse?.questions?.map((questionData, index) => {
    rawForm?.questions?.field_order?.map((data) => {
      dupResponse.questions[index][data] = getDataAccordingToFieldType(rawForm?.questions?.fields?.[data]?.field_type, questionData?.[data])
    })
  })

  return dupResponse;
}
