import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import HighlighterStrap from "../HighlighterStrap/HighlighterStrap";
import EmptyBox from '../EmptyBox/EmptyBox';
import "./Accordian.scss";

//ASSETS
import arrowImg from '../../images/arrow-down-blue.svg';

const Accordian = ({ data }) => {
  console.warn("data Accordian", data);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box className="accordian-wrapper">
      {data?.length > 0 ?<Accordion expanded={expanded === "one"} onChange={handleChange("one")}>
        <AccordionSummary
          expandIcon={<img src={arrowImg} alt="arrow img" />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box display='flex' alignItems='center'>
          <Typography className="heading-text color-black">
            Today,
            {/* <span className="heading-text color-grey">Dec 02</span> */}
          </Typography>
          <Typography className=" ml-2 heading-text color-black">
            {data?.length} interviews
          </Typography>
          {/* <Typography className='arrow-text'>Show</Typography> */}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {data.map((item) => {
            return (
              <HighlighterStrap
                time={item?.time}
                position={`${item?.positionName} | ${item?.roundName}`}
                clientName={item?.companyName}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
      :
      <EmptyBox
       text={"You don’t have any interviews scheduled."}
      />
      }
    </Box>
  );
};

export default Accordian;
