import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import './LabelSeperator.scss';

const LabelSeperator = ({ labelLeft, labelRight, onClick }) => {
  return (
    <Box display="flex" justifyContent="space-between" className='seperator-wrapper'>
      <Typography className='text'>{labelLeft}</Typography>
      <Typography className='text-with-underline' onClick={onClick}>{labelRight}</Typography>
    </Box>
  );
};

export default LabelSeperator;
