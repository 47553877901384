export const SET_USER = 'SET_USER';
export const USER_TIMEZONE_UPDATE = 'USER_TIMEZONE_UPDATE';

export const setUser = (user) => ({
  type: SET_USER,
  payload: user
});

export const resetUser = () => ({
  type: SET_USER,
  payload: {}
});

export const updateTimeZone = (zone)=>({
  type: USER_TIMEZONE_UPDATE,
  payload: zone
});
