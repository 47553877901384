import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

// images...
import worst from "./Images/worst.png";
import bad from "./Images/bad.png";
import okay from "./Images/okay.png";
import good from "./Images/good.png";
import best from "./Images/best.png";

Font.register({
  family: "Poppins",
  src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
  fonts: [
    {
      src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
    },
    {
      src: `/assets/poppins-fonts/Poppins-Thin.ttf`,
      fontWeight: "thin",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Medium.ttf`,
      fontWeight: "medium",
    },
    {
      src: `/assets/poppins-fonts/Poppins-SemiBold.ttf`,
      fontWeight: "semibold",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Italic.ttf`,
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-BoldItalic.ttf`,
      fontWeight: "bold",
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Bold.ttf`,
      fontWeight: "bold",
    },
  ],
});

Font.registerHyphenationCallback((word) => [word]);

const stylesIqr = StyleSheet.create({
  Heading: {
    width: 310,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 17,
    letterSpacing: 0.005,
    marginTop: 17,
    color: "#123FBC",
  },

  subHeading: {
    width: 298,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: 0.005,
    marginTop: 17,
    color: "#848484",
    marginTop: 17,
  },

  emojiBox: {
    width: 290,
    height: 78,
    backgroundColor: "#F5F8FF",
    borderRadius: 58,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },

  ratingText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 21,
    letterSpacing: 0.005,
    color: "#3D3D3D",
  },

  ratingNumber: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 16,
    letterSpacing: 0.025,
    color: "#1844AD",
  },

  box: {
    marginBottom: 10,
    marginBottom: 10,
  },
});

const information = (info) => {
  return (
    <View style={[stylesIqr.emojiBox]} wrap={false}>
      <View>
        <Image
          src={
            info == "WORST"
              ? worst
              : info == "BAD"
              ? bad
              : info == "OKAY"
              ? okay
              : info == "GOOD"
              ? good
              : best
          }
          style={[{ width: 49, height: 49, marginLeft: 15 }]}
        />
      </View>

      <View style={[stylesIqr.box, { marginLeft: 10 }]}>
        <View>
          <Text style={[stylesIqr.ratingText]}>
            {" "}
            {info == "WORST"
              ? "Worst"
              : info == "BAD"
              ? "Bad"
              : info == "OKAY"
              ? "Okay"
              : info == "GOOD"
              ? "Good"
              : "Best"}
          </Text>
        </View>
        <View
          style={[
            {
              display: "flex",
              flexDirection: "row",
            },
          ]}
        >
          <Text style={[stylesIqr.ratingNumber, { marginRight: 5 }]}>
            {info == "WORST"
              ? "1"
              : info == "BAD"
              ? "2"
              : info == "OKAY"
              ? "3"
              : info == "GOOD"
              ? "4"
              : "5"}
          </Text>
          <Text>out of</Text>
          <Text style={[stylesIqr.ratingNumber, { marginLeft: 5 }]}>5</Text>
        </View>
      </View>
    </View>
  );
};

const InterviewQualityRating = ({ data }) => {
  return (
    <View
      style={[
        {
          border: "1 solid #cdcdcd",
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
          paddingBottom: 50,
          width: 800,
          marginTop: 20,
        },
      ]}
    >
      <View style={[stylesIqr.Heading]}>
        <Text>Interview Quality Rating</Text>
      </View>

      {/* two views in row */}
      <View style={[{ display: "flex", flexDirection: "row" }]}>
        {/* overall rating of interview parent */}
        <View>
          <View>
            <Text style={[stylesIqr.subHeading]}>
              Overall rating of the interview
            </Text>
          </View>

          <View>{information(data?.audit_report?.interview_rating)}</View>
        </View>

        {/* overall rating of feedback */}
        <View>
          <View>
            <Text style={[stylesIqr.subHeading, { marginLeft: 22 }]}>
              Overall rating of the feedback
            </Text>
          </View>

          <View style={[{ marginLeft: 20 }]}>
            {information(data?.audit_report?.feedback_rating)}
          </View>
        </View>
      </View>
      {/* interview quality rating ends here */}
    </View>
  );
};

export default InterviewQualityRating;
