import tickIcon from "../../../../../assets/images/feedback/check-mark.svg";
import wrongIcon from "../../../../../assets/images/feedback/wrong-icon.svg";
export const codingTable = {
    APPROACH : {
        width:'25%',
        text : "Approach",
        apiKey : "approach_shared",
        imgType: "unicode",
        imgSrc : "",
        data : null,
        style: {paddingLeft:"15px", textIndent:'-15px'}
    },
    POSITIVES : {
        width:'25%',
        text : "Positives",
        apiKey : "positives",
        imgType: "icon",
        imgSrc : tickIcon,
        data : null,
        style: {paddingLeft:"25px", textIndent:'-25px'}
    },
    NEUTRALS : {
        width:'25%',
        text : "Neutral",
        apiKey : "neutrals",
        imgType: "unicode",
        imgSrc : "",
        data : null,
        style: {paddingLeft:"15px", textIndent:'-15px'}
    },
    NEGATIVES : {
        width:'25%',
        text : "Negatives",
        apiKey : "negatives",
        imgType: "icon",
        imgSrc : wrongIcon,
        data : null,
        style: {paddingLeft:"20px", textIndent:'-18px'}
    },
    
}

export const designTable = {
    POSITIVES : {
        width:'33%',
        text : "Positives",
        apiKey : "positives",
        imgType: "icon",
        imgSrc : tickIcon,
        data : null,
        style: {paddingLeft:"25px", textIndent:'-25px'}
    },
    NEUTRALS : {
        width:'33%',
        text : "Neutral",
        apiKey : "neutrals",
        imgType: "unicode",
        imgSrc : "",
        data : null,
        style: {paddingLeft:"15px", textIndent:'-15px'}
    },
    NEGATIVES : {
        width:'33%',
        text : "Negatives",
        apiKey : "negatives",
        imgType: "icon",
        imgSrc : wrongIcon,
        data : null,
        style: {paddingLeft:"20px", textIndent:'-18px'}
    },
    
}

export const ResumeTable = {
    EXPERIENCE : {
        width:'33%',
        text : "Experience",
        apiKey : "",
        imgType: "hiphen",
        imgSrc : "",
        data : null,
        style: {paddingLeft:"20px", textIndent:'-18px'}
    },
    TECH_STACK : {
        width:'33%',
        text : "Tech Stack Knowledge",
        apiKey : "",
        imgType: "hiphen",
        imgSrc : "",
        data : null,
        style: {paddingLeft:"20px", textIndent:'-18px'}
    },    
    PROJECTS : {
        width:'33%',
        dataType:'multi-array',
        text : "Relevant Projects",
        apiKey : "",
        imgType: "",
        imgSrc : '',
        data : null,
        style: {paddingLeft:"30px", textIndent:'-20px'}
    },
}
export const codeBoxHeading = {
    CODING : "CODING QUESTIONS",
    THEORY : "",
}