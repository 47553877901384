import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

Font.register({
  family: "Poppins",
  src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
  fonts: [
    {
      src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
    },
    {
      src: `/assets/poppins-fonts/Poppins-Thin.ttf`,
      fontWeight: "thin",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Medium.ttf`,
      fontWeight: "medium",
    },
    {
      src: `/assets/poppins-fonts/Poppins-SemiBold.ttf`,
      fontWeight: "semibold",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Italic.ttf`,
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-BoldItalic.ttf`,
      fontWeight: "bold",
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Bold.ttf`,
      fontWeight: "bold",
    },
  ],
});

Font.registerHyphenationCallback((word) => [word]);

const stylesMiscellaneous = StyleSheet.create({
  Heading: {
    marginTop: 20,
    width: 310,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 17,
    letterSpacing: 0.005,
    color: "#123FBC",
  },

  MiscText: {
    width: 298,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: 0.005,
    color: "#848484",
    marginTop: 15,
  },

  MiscComments: {
    width: 718,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    letterSpacing: 0.005,
    color: "#464646",
    marginTop: 10,
    marginLeft: 30,
  },

  list: {
    fontWeight: 1000,
    fontSize: 20,
    marginRight: 5,
    marginTop: -10,
  },
});

const information = (info) => {
  return (
    <View style={[stylesMiscellaneous.MiscComments]}>
      {info?.map((item) => {
        return (
          <View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={[stylesMiscellaneous.list]}>.</Text>
            <Text>{item}</Text>
          </View>
        );
      })}
    </View>
  );
};

const Miscellaneous = ({ data }) => {
  return (
    <View>
      <View>
        <Text style={[stylesMiscellaneous.Heading]}>Miscellaneous</Text>
      </View>
      <View>
        <Text style={[stylesMiscellaneous.MiscText]}>
          Missing informationfrom the feedback
        </Text>
        <View>{information(data?.audit_report?.feedback_missing_info)}</View>
      </View>

      <View>
        <Text style={[stylesMiscellaneous.MiscText]}>
          {" "}
          Scope of improvement for interviewer
        </Text>
        <View>{information(data?.audit_report?.scope_of_improvement)}</View>
      </View>

      <View>
        <Text style={[stylesMiscellaneous.MiscText]}>Extra Comments</Text>
        <View style={[stylesMiscellaneous.MiscComments]}>
          {data?.audit_report?.extra_comments?.length > 0 ? (
            <View style={[{ marginLeft: -30, marginTop: -10 }]}>
              {information(data?.audit_report?.extra_comments)}
            </View>
          ) : (
            <Text>NA</Text>
          )}
        </View>
      </View>
    </View>
  );
};

export default Miscellaneous;
