import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import "./previewSection.scss";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
  };
export const CodeBox = ({isDialogOpen,handleClose, solutionCode, type, heading}) => {
    return <Modal
    open={isDialogOpen}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style} className="codebox-main-container">
        <Box padding="10px 16px">
            <Typography className='title'>SOLUTION CODE</Typography>
            <Typography className='sub-title'>{heading}</Typography>
        </Box>
        <Divider/>
        <Box padding="15px">
        <Box padding="5px 16px" className='code'>
        {
          type && (type==='coding' || type==='theory' || type==='design')?<pre>
            <code className='code-inner-box'>
                {solutionCode?solutionCode:""}
            </code>
        </pre> : <Typography>{solutionCode?solutionCode:""}</Typography>
        }
          
        </Box>
        </Box>
    </Box>
  </Modal>
}