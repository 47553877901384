import React from "react";
import {BrowserRouter as Router, Switch} from "react-router-dom";
import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import Login from '../views/Auth/Login';
import AuthorizedLogin from '../views/Auth/AuthorizedLogin';
import ForgotPassword from '../views/Auth/ForgotPassword'
import ResetPassword from '../views/Auth/ResetPassword'
import Dashboard from '../views/Dashboard'
import History from '../views/History'
import Audit from '../views/Audit'
import Scheduled from '../views/Scheduled'
import Requests from '../views/Requests'
import MyProfile from '../views/User/MyProfile'
import Availability from '../views/Availability'
import Reset from '../views/ResetPassword'
import Feedback from '../views/Feedback'
import ProtectedRoute from "./ProtectedRoute";
import {verifyFeedbackAccess, verifyGuideAccess, verifyReportAccess} from "../services/auth";
import GuidePreview from '../views/InterviewGuide/GuidePreview'
import AuditFeedback from "../views/AuditFeedback";
import AuditReport from "../views/AuditReport";

import {DashboardType} from "@iv/shared/constants";
const AppRouter = () => {
  const user = useSelector(state => state.user);
  const state = useSelector(state => state);
  const isAuthenticated = !!user.id;
  return (
      <>
        <Router>
          <Switch>
            <PublicRoute exact path="/authorize" component={AuthorizedLogin} isAuthenticated={false}/>
            <PublicRoute exact path="/login" component={Login} isAuthenticated={isAuthenticated}/>
            <PublicRoute exact path="/reset-password/:id" component={ResetPassword}/>
            <PublicRoute exact path="/reset" component={Reset} isAuthenticated={isAuthenticated}/>
            <PublicRoute exact path="/audit" component={Audit}/>
            <PublicRoute exact path="/forgot-password" component={ForgotPassword} isAuthenticated={isAuthenticated}/>
            <PrivateRoute exact path="/requests" component={Requests} isAuthenticated={isAuthenticated}/>
            <PrivateRoute exact path="/scheduled" component={Scheduled} isAuthenticated={isAuthenticated}/>
            <PrivateRoute exact path="/profile/:id" component={MyProfile} isAuthenticated={isAuthenticated}/>
            <PrivateRoute exact path="/availability" component={Availability} isAuthenticated={isAuthenticated}/>
            <PrivateRoute exact path="/home" component={Dashboard} isAuthenticated={isAuthenticated}/>
            <PrivateRoute exact path="/history" component={History} isAuthenticated={isAuthenticated}/>
            <PrivateRoute exact path="/audit-feedback-form/:id?" component={AuditFeedback} isAuthenticated={isAuthenticated}/>
            <PublicRoute path="/interview-feedback" component={Feedback}/>
            <PublicRoute path="/audit-feedback-report" component={AuditReport}/>
            <PublicRoute path="/interview-guides" component={GuidePreview}/>
            <ProtectedRoute
                path="/feedback"
                redirect="/interview-feedback?i_id=${}&token=${}"
                params={["interview_id", "token"]}
                authenticator={verifyFeedbackAccess}
                isAuthenticated={isAuthenticated}
            />
            <ProtectedRoute
                path="/guides"
                redirect="/interview-guides?guide_id=${}&position_id=${}"
                params={["guide_id","position_id"]}
                authenticator={verifyGuideAccess}
                isAuthenticated={isAuthenticated}
            />
            <ProtectedRoute
                path="/audit-report/authorize"
                redirect="/audit-feedback-report?id=${}"
                params={["audit_id"]}
                authenticator={verifyReportAccess}
                isAuthenticated={isAuthenticated}
            />
            <Redirect from="*" to="/home"/>
          </Switch>
        </Router>
      </>
  );
};

export default AppRouter;


