import { useEffect, useState } from 'react';
import { Box, Button, Typography , Modal, Checkbox} from '@material-ui/core';
import DatePicker from "react-datepicker";
import sortIcon from './images/sort-icon.svg';
import filterIcon from './images/filter-icon.svg';
import BasicDateRangePicker from '../../components/StartEndDatePicker/StartEndDatePicker';
import './toolbar.scss';


const FilterToolbar = ({
    title,
    startDate,
    setStartDate,
    EndDate,
    setEndDate,
    ExampleCustomInput,
    handleRestoreBttn,
    allPositions,
    allCompanies,
    feedbackOptions,
    handleCompanySelection,
    editFilters,
    filters,
    clientPosData
}) => {
    const [positions, setPositions] = useState(allPositions);
    const [sortModal, setSortModal] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [filterOption, setFilterOption] = useState('');
    const [selectedCompany, setSelectedCompany] = useState('All Companies');
    const [selectedPosition, setSelectedPosition] = useState('All positions');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [sortFilters, setSortFilters] = useState({ sortBy: '', order:''});

    useEffect(() => {
        setFilterOption('companies');
        setSelectedCompany((title == 'Scheduled') 
            ? { value: filters.companies, label: filters.companies } : filters.companies);
        setSelectedPosition((title == 'Scheduled') 
        ? { value: filters.positions, label: filters.positions } : filters.positions);
    },[])

    useEffect(() => {
        const updatedPositions = (selectedCompany?.value != "" && clientPosData[selectedCompany?.value]) ? Object.entries(clientPosData[selectedCompany?.value].position_dict).map((item => {
            return { value: item[0], label: item[1] }
        })): [];
        setPositions([{ value: "", label: 'All positions' }, ...updatedPositions])
    },[selectedCompany]);

    const handleSortClick = () => {
        setSortModal(!sortModal);
    }
    const handleFilterClick = () => {
        setFilterModal(!filterModal);
    }
    const handleClearFilters = () => {
        setFilterOption('companies');
        setSelectedCompany(allCompanies[0]);
        setSelectedPosition(positions[0]);
        setSelectedStatus("")
    }
    const closeFilterModal = () => {
        handleClearFilters();
        setFilterModal(false);
    }    
    const applyFilters = () => {
        handleCompanySelection(selectedCompany.value)
        if(title != "Scheduled" || selectedCompany?.value != "") editFilters((title == "Scheduled") ? selectedCompany.value : selectedCompany, 'companies');
        if(title != "Scheduled" || selectedPosition?.value != "") editFilters((title == "Scheduled") ? selectedPosition?.value : selectedPosition, 'positions');
        if(title == "Scheduled") editFilters(selectedStatus?.value, 'feedbackStatus')
        setFilterModal(false);
    }
    const handleSortFilters = (value, order) => {
        editFilters(value, 'sortBy');
        editFilters(order, 'order');
        setSortModal(false);
    }

    const handleSelectedDate = (value) => {
       setStartDate(value?.[0]);
       setEndDate(value?.[1]);
    }
    
    return(
        <>
            <Modal
                open={sortModal}
                onClose={() => setSortModal(false)}
            >
                <Box className="sort-modal-wrapper" display="flex" flexDirection="column">
                    <Box className='sort-modal-header'>
                        <Typography className='header-text'>Sort By</Typography>
                    </Box>
                    <Box className='sort-modal-content'>
                        <SortItem
                            firstOption="Newest to Oldest"
                            secondOption="Oldest to Newest"
                            handleFirstOption={() => handleSortFilters('scheduled_start_timestamp','DESC')}
                            handleSecondOption={() => handleSortFilters('scheduled_start_timestamp','ASC')}
                        />

                        {title === 'Scheduled' &&<SortItem
                            firstOption="Status: Scheduled"
                            secondOption="Status: Rescheduled"
                            handleFirstOption={() => handleSortFilters('interview_status','SCHEDULED')}
                            handleSecondOption={() => handleSortFilters('interview_status','RESCHEDULED')}
                        />}
                         {title === 'History' && <SortItem
                            firstOption="Status: Deleted"
                            secondOption="Status: Rescheduled"
                            handleFirstOption={() => handleSortFilters('interview_status','DELETED')}
                            handleSecondOption={() => handleSortFilters('interview_status','CANCELLED')}
                        />}

                    </Box>
                </Box>

            </Modal>
            <Modal
                open={filterModal}
                onClose={() => setFilterModal(false)}
            >
                <Box className="filter-modal-wrapper" display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="space-between" alignItems="center" className='filter-modal-header'>
                        <Typography className='header-text'>Filters</Typography>
                        <Button variant="text" className='clearFilter-btn' onClick={handleClearFilters}>Clear All</Button>
                    </Box>
                    <Box display="flex" className='filter-modal-content'>
                        <Box display="flex" flexDirection="column" className='filters-list'>
                            <Typography 
                                className={`filter-label ${filterOption === 'companies' ? "selected-filter" : ""}`}
                                onClick={() => setFilterOption('companies')}
                            >
                                All Companies
                            </Typography>
                            <Typography 
                                className={`filter-label ${filterOption === 'positions' ? "selected-filter" : ""}`}
                                onClick={() => setFilterOption('positions')}
                            >
                                All Positions
                            </Typography>
                            {title==='Scheduled' &&
                            <Typography 
                                className={`filter-label ${filterOption === 'feedbackStatus' ? "selected-filter" : ""}`}
                                onClick={() => setFilterOption('feedbackStatus')}
                            >
                                Feedback Status
                            </Typography>
                            }
                        </Box>
                        <Box display="flex" flexDirection="column" className='filter-options'>
                            {filterOption === 'companies' && 
                                allCompanies.map((company) => 
                                    <Box display="flex" alignItems="center" className='filter-option'>
                                        <Checkbox checked={selectedCompany?.value === company.value} value={company.value} color="primary" onClick={() => setSelectedCompany(company)}/>
                                        <Typography className='option-value'>{company.label}</Typography>
                                    </Box>
                                )
                            }
                            {filterOption === 'positions' && 
                                allPositions.map((position) => 
                                    <Box display="flex" alignItems="center" className='filter-option'>
                                        <Checkbox checked={selectedPosition?.value === position.value} value={position.value} color="primary" onClick={() => setSelectedPosition(position)}/>
                                        <Typography className='option-value'>{position.label}</Typography>
                                    </Box>
                                )
                            }
                            {filterOption === 'feedbackStatus' && 
                                feedbackOptions.map((status) => 
                                    <Box display="flex" alignItems="center" className='filter-option'>
                                        <Checkbox checked={selectedStatus?.value === status.value} value={status.value} color="primary" onClick={() => setSelectedStatus(status)}/>
                                        <Typography className='option-value'>{status.label}</Typography>
                                    </Box>
                                )
                            }
                        </Box>
                    </Box>
                    <Box display="flex" className='filter-modal-footer'>
                        <Button variant="outlined" className='close-button' onClick={closeFilterModal}>Close</Button>
                        <Button variant="contained" className='apply-button' onClick={applyFilters}>Apply</Button>
                    </Box>
                </Box>

            </Modal>
            <Box className="toolbar-mobile">
                {/* <Box display="flex" justifyContent="space-between" alignItems="center" className="header-section">
                    <Typography className="page-title">{title}</Typography>
                    <Button color="primary" variant="text" onClick={handleRestoreBttn} className="restore-button">Restore Default</Button>
                </Box> */}
                <Box display="flex" justifyContent="space-between" className="date-filters">
                    <Box display="flex">
                            <BasicDateRangePicker handleDate={handleSelectedDate}/>
                        {/* <div className="pos-rel mr-10">
                    
                            <DatePicker
                                popperPlacement="bottom-start"      
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                placeholderText="From date"
                                customInput={<ExampleCustomInput />}
                            />
                        </div>
                        <div className="pos-rel mr-30">
                            <DatePicker
                                popperPlacement="bottom-end"      
                                selected={EndDate}
                                onChange={(date) => setEndDate(date)}
                                placeholderText="To date"
                                className="date-picker"
                                customInput={<ExampleCustomInput />}
                            />
                        </div> */}
                    </Box>
                </Box>
            </Box>
            <Box display="flex" className="filters-footer">
                <Button variant="text" className='filter-button left-button' onClick={handleSortClick}><img className='filter-icon' src={sortIcon}/>Sort</Button>
                <Button variant="text" className='filter-button' onClick={handleFilterClick}><img className='filter-icon' src={filterIcon}/>Filter</Button>
            </Box>
        </>
        )
}

const SortItem = ({
    label,
    firstOption,
    secondOption,
    handleFirstOption,
    handleSecondOption
}) => {
    return (
        <Box display="flex" className="sort-item">
            {/* <Box flex={1}>
                <Typography className="sort-label">{label}:</Typography>
            </Box> */}
            <Box display="flex" flexDirection="column" flex={1} alignItems="flex-start">
                <Button color="primary" variant="text" onClick={handleFirstOption} className="sort-filter-button">{firstOption}</Button>
                <Button color="primary" variant="text" onClick={handleSecondOption} className="sort-filter-button">{secondOption}</Button>
            </Box>
        </Box>
    )
}

export default FilterToolbar;