export const formatTemplate = (template) => {
  return function (...vals) {
    return template
        .split("${}")
        .map(function (s, i) {
          return `${s}${vals[i] || ""}`;
        })
        .join("");
  };
};
