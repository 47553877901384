import React, { useState } from "react";
import cogoToast from "cogo-toast";
import { resetPassword } from "../../services/auth";
import "./ResetPassword.css";
import { validateEmail } from "../../utilis/validation";

const ResetPassword = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const myUuid = urlParams.get("uuid");
  const myEmail = urlParams.get("email");

  const [email, setEmail] = useState(myEmail);
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [showPassword, setShowPassword] = useState([false, false]);

  const [loading, setLoading] = useState(false);

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");

  const changePassword = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setErrorEmail(true);
    }
    if (password === "") {
      setErrorPassword("Enter new password");
    }
    if (password1 === "") {
        setErrorConfirmPassword("Re-enter new password");
    }
    if (password !== password1) {
        setErrorConfirmPassword("Password didn't match");
    }

    if(!validateEmail(email) || password === "" || password1 === "" || password !== password1){
        return;
    }

    try {
      setLoading(true);
      const req_body = {
        email,
        forgot_password_uuid: myUuid,
        new_password: password,
      };
      await resetPassword(req_body);
      cogoToast.success("Your password has been successfully changed");
      setLoading(false);
      props.history.push("/login");
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div
      className="d-flex align-items-center bg-auth border-top border-top-2 border-primary main-container-height"
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
            <div className="text-center">
              <div className="relax-text">Relax!</div>
              <div className="get-back-text">WE GOT YOUR BACK.</div>
              <img
                src="/assets/img/illustrations/reset-bg.svg"
                alt="..."
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-10 col-md-5 col-xl-4 order-md-1 my-5">
            <h1 className="display-4 text-center mb-3">Reset Password</h1>
            <p className="text-muted text-center mb-5">
              To reset your password for your account please enter your email ID
              and new password.
            </p>
            <form>
              <div className="row">
                <div className="col">
                  <label>Email</label>
                </div>
              </div>

              <div className="form-group">
                <input
                  placeholder={"Email"}
                  className={
                    errorEmail ? "form-control is-invalid" : "form-control"
                  }
                  value={email}
                  onBlur={() => {
                    if (!validateEmail(email)) setErrorEmail(true);
                  }}
                  onChange={(e) => {
                    setErrorEmail(false);
                    setEmail(e.target.value);
                  }}
                />
                <div className="invalid-feedback">
                  {"Invalid Email address"}
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <label>New Password</label>
                  </div>
                </div>

                <div className="input-group input-group-merge">
                  <input
                    type={showPassword[0] ? "text" : "password"}
                    className={`form-control form-control-appended ${
                      errorPassword !== "" ? "is-invalid" : ""
                    }`}
                    placeholder={"New Password"}
                    value={password}
                    onChange={(e) => {
                      setErrorPassword("");
                      setPassword(e.target.value);
                    }}
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      onClick={() => {
                        const arr = [...showPassword];
                        arr[0] = !arr[0];
                        setShowPassword(arr);
                      }}
                    >
                      {!showPassword[0] ? (
                        <i className="far pointer fa-eye"></i>
                      ) : (
                        <i className="far pointer fa-eye-slash"></i>
                      )}
                    </span>
                  </div>
                  <div className="invalid-feedback">{errorPassword}</div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <label>Re-enter New Password</label>
                  </div>
                </div>

                <div className="input-group input-group-merge">
                  <input
                    type={showPassword[1] ? "text" : "password"}
                    className={`form-control form-control-appended ${
                      errorConfirmPassword !== "" ? "is-invalid" : ""
                    }`}
                    placeholder={"New Password"}
                    value={password1}
                    onChange={(e) => {
                        setErrorConfirmPassword("");
                      setPassword1(e.target.value);
                    }}
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      onClick={() => {
                        const arr = [...showPassword];
                        arr[1] = !arr[1];
                        setShowPassword(arr);
                      }}
                    >
                      {!showPassword[1] ? (
                        <i className="far pointer fa-eye"></i>
                      ) : (
                        <i className="far pointer fa-eye-slash"></i>
                      )}
                    </span>
                  </div>
                  <div className="invalid-feedback">{errorConfirmPassword}</div>
                </div>
              </div>

              <button
                disabled={errorPassword !== "" || errorConfirmPassword !== ""}
                className="btn btn-lg btn-block btn-primary mb-3"
                onClick={(e) => changePassword(e)}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm mr-2"></span>
                )}
                Reset Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
