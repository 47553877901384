import AppRouter from "./routes";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react'
import configureStore from "./store";

const { persistor, store } = configureStore();

const App = () => {
  return (
      <Provider store={store}>
          <PersistGate
              persistor={persistor}>
              <div className="App">
                  <AppRouter />
              </div>
          </PersistGate>
      </Provider>
  );
}

export default App;




