import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import AppropriateSmall from "./Images/Appropriate-small.png";
import OverratedSmall from "./Images/overrated-small.png";
import UnderratedSmall from "./Images/underrated-small.png";

Font.register({
  family: "Poppins",
  src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
  fonts: [
    {
      src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
    },
    {
      src: `/assets/poppins-fonts/Poppins-Thin.ttf`,
      fontWeight: "thin",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Medium.ttf`,
      fontWeight: "medium",
    },
    {
      src: `/assets/poppins-fonts/Poppins-SemiBold.ttf`,
      fontWeight: "semibold",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Italic.ttf`,
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-BoldItalic.ttf`,
      fontWeight: "bold",
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Bold.ttf`,
      fontWeight: "bold",
    },
  ],
});

Font.registerHyphenationCallback((word) => [word]);

const stylesFeedbackSummaryBody = StyleSheet.create({
  feedbackSummaryHeading: {
    marginTop: 25,
    width: 310,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 17,
    letterSpacing: 0.005,
    color: "#123FBC",
  },

  highlightText: {
    width: 354,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.005,
    color: "#3D3D3D",
  },

  commentsHeading: {
    width: 232,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 13,
    letterSpacing: 0.005,
    color: "#848484",
    marginLeft: 20,
    marginTop: 10,
  },

  correctionText: {
    width: 609,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    letterSpacing: 0.005,
    color: "#464646",
    marginLeft: 20,
    marginTop: 10,
  },
});

const information = (info) => {
  return (
    <View>
      <Image
        src={
          info == "Appropriate"
            ? AppropriateSmall
            : info == "OverRated"
            ? OverratedSmall
            : info == "UnderRated"
            ? UnderratedSmall
            : ""
        }
        style={[
          info == "Appropriate"
            ? { width: 104, height: 16, marginLeft: -230, marginTop: 5 }
            : { width: 135, height: 20, marginLeft: -235, marginTop: 5 },
        ]}
      />
    </View>
  );
};

const corrections = (comments) => {
  return (
    <View>
      {comments == "" ? (
        <Text></Text>
      ) : (
        <>
          <Text style={[stylesFeedbackSummaryBody.commentsHeading]}>
            Corrections:
          </Text>
          <Text style={[stylesFeedbackSummaryBody.correctionText]}>
            {comments}
          </Text>
        </>
      )}
    </View>
  );
};

const FeedbackSummary = ({ data }) => {
  return (
    <View
      style={[
        {
          border: "1 solid #cdcdcd",
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
          paddingBottom: 50,
        },
      ]}
    >
      <View>
        <Text style={[stylesFeedbackSummaryBody.feedbackSummaryHeading]}>
          Feedback Summary
        </Text>
      </View>

      <View
        style={[
          {
            marginLeft: 15,
            display: "flex",
            flexDirection: "row",
            marginTop: 15,
          },
        ]}
      >
        <Text style={[stylesFeedbackSummaryBody.highlightText]}>
          1. Highlights
        </Text>
        {/* reusable */}

        <View>
          {information(
            data?.audit_report?.feedback_assessment?.highlights?.rating
          )}
        </View>
      </View>

      <View>
        {corrections(
          data?.audit_report?.feedback_assessment?.highlights?.comments
        )}
      </View>

      <View
        style={[
          {
            marginLeft: 15,
            display: "flex",
            flexDirection: "row",
            marginTop: 15,
          },
        ]}
      >
        <Text style={[stylesFeedbackSummaryBody.highlightText]}>
          2. Lowlights{" "}
        </Text>
        <View>
          {information(
            data?.audit_report?.feedback_assessment?.lowlights?.rating
          )}
        </View>
      </View>

      <View>
        {corrections(
          data?.audit_report?.feedback_assessment?.lowlights?.comments
        )}
      </View>

      {/* feedback summary ends here */}
    </View>
  );
};

export default FeedbackSummary;
