import AuditFeedbackShared from "@iv/shared/components/pages/AuditFeedback";

import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';

import { toastSuccess, toastError } from '../../utilis/Toast';

import { audit_form_edit, audit_form_create } from '../../services/AuditForm';
import axios from '../../services/axios';
import { downloadFile } from '../../services/file';

const AuditFeedback = () => {
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const idValue = urlParams.get("id");
  const ID = idValue;

  const [open, setOpen] = React.useState(false);
  const [newPerformance, setNewPerformance] = React.useState([]);
  const [sidebarData, setSideBarData] = useState({});
  const [auditReport, setAuditReport] = useState();
  const [successful, setSuccessful] = React.useState(false);
  const [apiInProgress, setApiInProgress] = useState(false)

  React.useEffect(()=>{
    handleAuditForm();
  },[]);

  const handleAuditForm = async() =>{
     const resp = await axios.get(`/audit_details/${ID}`);
     setSideBarData(resp?.data?.data?.audit_details);
     let tempPer = (resp?.data?.data?.audit_details?.interviewer_feedback?.evaluation_ratings);
     tempPer.map((item)=>{
       item.auditRating = ''
      })
    setNewPerformance([...tempPer]);
    setAuditReport(resp?.data?.data?.audit_report);
  }

  const goToAuditList = () => {
    history.push("/interviews/audit")
  }

  const confirmSubmit = async(fn) => {
    setApiInProgress(true);
    const newSub=fn();
    let payload;
    let res ;
    if (Object.keys(auditReport)?.length === 0){
      payload = {
        audit_id : ID,
        audit_instance:newSub,
      }
      res = await audit_form_create(payload);
    }else{
      payload = {
        audit_instance:newSub,
      }
      res = await audit_form_edit(auditReport?.id, payload);
    }

    try{
      if(res?.status === 200){
        toastSuccess("Successfully Create");
          setOpen(!open);
          setSuccessful(!successful);
          setApiInProgress(false);
        }
        else{
          toastError("Something went wrong!")
          setOpen(!open);
          setApiInProgress(false);
      }
    }catch{
      toastError("Something went wrong!")
      setApiInProgress(false);
      // setOpen(!open);
    }
  }

  return <div>

    {auditReport ? <AuditFeedbackShared confirmSubmit={confirmSubmit} handleAuditForm={handleAuditForm}
      newPerformance={newPerformance} setNewPerformance={setNewPerformance}
      sidebarData={sidebarData} setSideBarData={setSideBarData}
      auditReport={auditReport} setAuditReport={setAuditReport}
      open={open} setOpen={setOpen}
      successful={successful} setSuccessful={setSuccessful} goToAuditList={goToAuditList} downloadFile={downloadFile} apiInProgress={apiInProgress}
    /> : <></>}</div>

}
export default AuditFeedback;