import { Box, Typography } from '@material-ui/core';
import './RequestCard.scss';
import moment from "moment"

const RequestCard = ({ request, index, handleCardClick }) => {

  return (
    <Box onClick={handleCardClick}
      className={`request-card-wrapper ${index % 2 !== 0 && 'card-odd'}`}>

      {/* Company name */}
      <Typography className="info-label">Company Name:</Typography>
      <Typography className="info-value">{request?.companyName || "-"}</Typography>

      {/* Position */}
      <Typography className="info-label">Position:</Typography>
      <Typography className="info-value">{request?.positionName || "-"}</Typography>

      {/* Round */}
      <Typography className="info-label">Round:</Typography>
      <Typography className="info-value">{request?.roundName || "-"}</Typography>

      {/* Date & Time */}
      <Typography className="info-label">Date & Time:</Typography>
      <Box>
        {request?.dateTime?.label?.map((unixSec, index) => (
          <Typography className="info-value" key={index}>
            {moment.unix(unixSec).format("DD-MM-YYYY | hh:mm A")}
          </Typography>
        ))}
      </Box>

    </Box>
  );
};

export default RequestCard;