import axios from "./axios";



export const fetchAvailability = async (payload) => {
    try{
        const res = await axios.post('/interviewer/slots', payload);
        return res.data
    } catch(err){
        throw err
    }
};

export const saveAvailability = async (slots) => {
    try{
        await axios.post('/interviewer/edit-slots', slots)
    } catch(err){
        throw err
    }
};

// For parent (default) max interviews
export const setDefaultMaxInterviewsNum = async (lastUpdatedTimestamp, maxInterviewsNum) => {
    try{
        const { data } = await axios.post('/interviewer/edit', {
            "last_updated_timestamp": lastUpdatedTimestamp,
            "max_interviews_per_day": maxInterviewsNum
        })
        return data[0] // Updated profile
    } catch(err){
        throw err
    }
};

// For child max interviews
export const getMaxInterviewsNum = async (fromDate, toDate) => {
    try{
        const res = await axios.post('/interviewer/interviews_count', {
            "from_date": fromDate,
            "to_date": toDate
        })
        return res.data
    } catch(err){
        throw err
    }
}

export const setMaxInterviewsNum = async (datesAndCountsList) => {
    try{
        const { data } = await axios.post('/interviewer/edit/interviews_count', {
            "interviews_count": datesAndCountsList
        })
        return data
    } catch(err){
        throw err
    }
};