import TooltipMUI, { tooltipClasses } from "@mui/material/Tooltip";
import {styled} from "@mui/material/styles";
export const Tooltip = styled(({ className, ...props }) => (
    <TooltipMUI {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontFamily: 'Poppins',
      fontSize: "13px",
      letterSpacing: "0.005em",
      backgroundColor: "#3D3D3D",
      color: 'ffffff',
      borderRadius: "8px",
      padding:"auto 6px",
    },
  }));
 