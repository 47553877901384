import * as React from "react";
import { useState } from "react";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "./DetailedAudit.scss";
import {downloadFile, downloadVideo} from '../../services/AuditForm';
import VideoModal from "../VideoModal";
import SwtAlert from "../../utilis/sweetalert/SwtAlert";

import Tooltip  from '@mui/material/Tooltip';
import tooltipClasses  from '@mui/material/Tooltip/tooltipClasses';
import { styled } from '@mui/material/styles';

export default function AcccessibleTable({
  demoData,
  setViewDescription,
  setViewSideModal,
  currentTimezone,
}) {
  const [jobpopup, setJobPopup] = useState(false);
  const [feedbackVideoModal,setFeedbackVideoModal] = useState(false);
  const [isVideoArchive, setIsVideoArchive] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const history = useHistory();
  let item = "Proceed";
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
   
    [`& .${tooltipClasses.tooltip}`]: {
      padding:"12px",
      borderRadius:"9px",
      backgroundColor:"#495057",
      fontFamily: 'Times New Roman',
      fontWeight: "400",
      fontSize: "13px",
      letterSpacing: "0.025em",
      color: "#FFFFFF",
      whiteSpace:'pre-wrap'
    },
  }));
  const handleJobDescription = () => {
    setViewSideModal(false);
    setViewDescription(true);
  };

  const handleFeedbackFormRouting = (idValue) => {
    history.push(`/audit-feedback-form?id=${idValue}`);
  };

  const colorText = (data) => {
    let colorData = {
      REJECT: {
        value: "Reject",
        color: "red",
      },
      PROCEED: {
        value: "Proceed",
        color: "#12BC93",
      },
      STRONG_REJECT: {
        value: "Strong Reject",
        color: "red",
      },
      STRONG_PROCEED: {
        value: "Strong Proceed",
        color: "#12BC93",
      },
    };
    return (
      <span style={{ color: colorData[data]?.color, fontWeight: "500" }}>
        {colorData[data]?.value}
      </span>
    );
  };

  const handleArrayLikeDisLikes = (comment, arr)=>{
    let temp = [];
    for (let i=0;i<arr?.length;i++){
      if (arr[i]?.question === comment){
        const responseArray = arr[i]?.response || []
        temp = [...temp, ...responseArray];
      }
    }
    return temp;
  }
  const  handleVideoClick = () => {
    // setShowSideBar(false);
    handleFeedbackVideoModal();
  }
  const handleCloseVideoModal = ()=>{
    // setFeedbackReportLink(null)
    setFeedbackVideoModal(false);
  }

  const handleFeedbackVideoModal  = async() => {
    if(demoData?.audit_details?.interview_details?.feedback_video_link){
    const videoUrl =  await downloadVideo(demoData?.audit_details?.interview_details?.feedback_video_link);
    if(videoUrl?.status  === 500 && videoUrl?.data === "Failed to generate url. Error:Object is in Deep Glacier"){
      setIsVideoArchive(true);
      setFeedbackVideoModal(true);
    }else if(videoUrl?.status  === 200){
      setVideoUrl(videoUrl?.data);
      setFeedbackVideoModal(true);
    }else{
      SwtAlert("Something Went Wrong", 2500, "error", false);
    }
    }
  }

  const renderAuditDetails = () => {
    return (
      <Box component={Paper} className="audit-detail-section">
        <Box className="heading">Audit Details</Box>
        <Box display="flex" alignItems="center">
          <Typography className="label">Assigned Date & Time</Typography>
          <Typography className="value">
            {moment(demoData?.audit_details?.created_at * 1000)?.tz(currentTimezone)?.format(
              "MMM DD, YYYY | hh:mm A"
            )}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" style={{margin:'8px 0 8px 0'}}>
          <Typography className="label">Interview Result</Typography>
          <span className="value" style={{padding:'0'}}>
            {colorText(
              demoData?.audit_details?.interview_details?.interview_result
            )}
          </span>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography className="label">Interviewer Rating</Typography>
          <Typography className="value">
            {demoData?.audit_details?.interviewer_feedback?.overall_rating ===
            (null || undefined || '')
              ? "---"
              : demoData?.audit_details?.interviewer_feedback?.overall_rating}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Typography className="label">Audit Reason</Typography>
          <Typography className="value">

            <ul style={{ marginLeft: "13px", padding: "0", color: "#480606" }}>
              {demoData?.audit_details?.reasons.map((item) => (
                <li className="comment-section">{item}</li>
              ))}
            </ul>
          </Typography>
        </Box>

        <Box display="flex">
          <Typography className="label">Comments for Auditor</Typography>
          { demoData?.audit_details?.comments?.length > 0
              ? <BootstrapTooltip title={demoData?.audit_details?.comments[0]} placement='bottom-start'>
                  <Typography className="value comments-for-auditor comment-section" style={{ whiteSpace: 'pre-wrap' }} > {demoData?.audit_details?.comments[0]} </Typography>
              </BootstrapTooltip>
              : "---"}            
        </Box>

        <Box display="flex" sx={{ marginTop: "20px" }}>
          <Box sx={{ width: "50%"}}>

            {
             (!demoData?.audit_details?.round_details?.guide_id && demoData?.audit_details?.round_details?.interview_guide_link === (undefined || null || '')) ?
              <span style={{color:'grey', cursor:'not-allowed'}}>Round Guide Link</span>:
              <a href={demoData?.audit_details?.round_details?.guide_id ?  `/interview-guides/preview?guide_id=${demoData?.audit_details?.round_details?.guide_id}` : demoData?.audit_details?.round_details?.interview_guide_link} className="audit-sidebar-link" target="__blank">Round Guide Link</a>
            }
          </Box>


          <Box sx={{ width: "50%"}}>
            { demoData?.audit_details?.interview_details?.feedback_video_link === (undefined || null || '')? <p style={{cursor:'not-allowed', color:'grey'}}>  Video Link</p> :
                <p style={{cursor:'pointer', color:'blue', textDecoration: 'underline', fontWeight:'500'}} onClick={handleVideoClick}>Video Link</p>
              }
          </Box>
        </Box>

        <Box display="flex">
          <Box sx={{ width: "50%", marginBottom: "10px" }}>
            {
              demoData?.audit_details?.candidate_details?.resume_url === (undefined || null || '')?
                <p style={{cursor:'not-allowed', color:'grey'}}> Resume</p> :
                <a className="audit-sidebar-link" href="javascript:void(0);" onClick={() => downloadFile(demoData?.audit_details?.candidate_details?.resume_url)}> Resume</a>
            }
          </Box>
          <Box sx={{ width: "50%", marginBottom: "10px" }}>
            {
              <a href={`/interview-feedback/?c_id=${demoData?.audit_details?.client_details?.id}&i_id=${demoData?.audit_details?.interview_details?.id}`} style={{color:'blue'}} className="audit-sidebar-link" target="__blank">
                Feedback Link
              </a>
            }
          </Box>
        </Box>

        <Box display="flex">
          <Box sx={{ width: "50%", marginBottom: "10px" }}>
            <Box onClick={() => setJobPopup(true)}>
              <span
                onClick={handleJobDescription}
                style={{ color: "blue", cursor: "pointer" }}
                className="audit-sidebar-link"
              >
                Job Description Link
              </span>
            </Box>
          </Box>

          <Box sx={{ width: "50%", marginBottom: "10px" }}>
            {
              demoData?.audit_details?.interview_details?.extra_info?.coderpad_link === (undefined || null || '') ?
              <span style={{color:'grey', cursor:'not-allowed'}}>Coderpad Link</span>:
              <a href={demoData?.audit_details?.interview_details?.extra_info?.coderpad_link} className="audit-sidebar-link" target="__blank">Coderpad Link</a>
            }
          </Box>
        </Box>

        {!demoData?.audit_details?.candidate_details?.resume_url && <div className='resume-optional-warning'>*The resume has not been uploaded for this candidate.</div>}

        <Box sx={{ marginTop: "28px" }}>
          <button
            className="audit-feedback-sidebar-btn"
            onClick={() =>
              handleFeedbackFormRouting(demoData?.audit_details?.id)
            }
          >
            Fill Audit Feedback
          </button>
        </Box>
      </Box>
    );
  };

  const renderCandidateFeedbackSection = () => {
    return (
      <Box className="candidate-feedback-wrapper">
        <Box className="heading">Candidate Feedback</Box>
        <Box display="flex" sx={{ marginBottom: "8px" }}>
          <Typography className="label"> Overall Rating </Typography>
          <Typography className="value">

            {demoData?.audit_details?.candidate_feedback_details
              ?.overall_experience === undefined
              ? "---"
              : demoData?.audit_details?.candidate_feedback_details
                  ?.overall_experience}
          </Typography>
        </Box>

        <Box display="flex" sx={{ marginBottom: "8px" }}>
          <Typography className="label"> Difficulty Rating</Typography>
          <Typography className="value">

            {demoData?.audit_details?.candidate_feedback_details
              ?.difficulty_level === (undefined || null || '')
              ? "---"
              : demoData?.audit_details?.candidate_feedback_details
                  ?.difficulty_level}
          </Typography>
        </Box>


         {/* handle Likes and Dislikes */}
        <Box display="flex" sx={{ marginBottom: "8px" }}>
          <Typography className="label"> Dislikes </Typography>
          <Typography className="value">
          <ul style={{paddingLeft:'0px', listStyleType:'none'}}>
          {
            demoData?.audit_details?.candidate_feedback_details?.candidate_response === (undefined || null || '') ? '---':
            handleArrayLikeDisLikes("improvement",demoData?.audit_details?.candidate_feedback_details?.candidate_response)?.map((item, index)=><li key={index}>{'> '}{item}</li>)
          }
          </ul>
          </Typography>
        </Box>

        <Box display="flex" sx={{ marginBottom: "8px" }}>
          <Typography className="label"> Likes </Typography>
          <Typography className="value">
          <ul style={{paddingLeft:'0px', listStyleType:'none'}}>
          {
            demoData?.audit_details?.candidate_feedback_details?.candidate_response === (undefined || null || '') ? '---':
            handleArrayLikeDisLikes("likes",demoData?.audit_details?.candidate_feedback_details?.candidate_response)?.map((item, index)=><li key={index}>{'> '}{item}</li>)
          }
          </ul>
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderPositionClientDetails = () => {
    return (
      <Box className="candidate-feedback-wrapper">
        <Box className="heading">Position & Client Details</Box>

        <Box display="flex" sx={{ marginBottom: "8px" }}>
          <Typography className="label"> Client </Typography>
          <Typography className="value">

            {demoData?.audit_details?.client_details?.client_name === (undefined || null || '')
              ? "---"
              : demoData?.audit_details?.client_details?.client_name}
          </Typography>
        </Box>

        <Box display="flex" sx={{ marginBottom: "8px" }}>
          <Typography className="label"> Position </Typography>
          <Typography className="value">
            {demoData?.audit_details?.position_details?.position_name ===
            (undefined || null || '')
              ? "---"
              : demoData?.audit_details?.position_details?.position_name}
          </Typography>
        </Box>

        <Box display="flex" sx={{ marginBottom: "8px" }}>
          <Typography className="label">Round </Typography>
          <Typography className="value">
            {demoData?.audit_details?.round_details?.round_category}
          </Typography>
        </Box>

        <Box display="flex" sx={{ marginBottom: "8px" }}>
          <Typography className="label"> Round Guide </Typography>
          <Typography className="value">
            { !demoData?.audit_details?.round_details?.guide_id && demoData?.audit_details?.round_details?.interview_guide_link === (undefined || null || '') ? (
              "---"
            ) : (
              <a
                href={
                  demoData?.audit_details?.round_details?.guide_id ?  `/interview-guides/preview?guide_id=${demoData?.audit_details?.round_details?.guide_id}` : demoData?.audit_details?.round_details?.interview_guide_link
                }
                target="__blank"
                className="audit-sidebar-link"
              >
                Round Guide
              </a>
            )}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <div id="detailsContainer">
      {renderAuditDetails()}
      {renderCandidateFeedbackSection()}
      {renderPositionClientDetails()}
       {/* Recording Video Modal */}
       <VideoModal
            open={feedbackVideoModal}
            isArchive={isVideoArchive}
            videoUrl={videoUrl}
            onCloseModal={handleCloseVideoModal}
            />
    </div>
  );
}
