import { Table } from "reactstrap";
import Box from "@mui/material/Box";
import styled from "styled-components";
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import Typography from "@mui/material/Typography";

export const CommonTable = ({ tableData }) => {
  const CustomTable = styled(Table)`
    border-radius: 10px;
    > thead {
      height: 35px;
      background: #f5f8ff;
      border-radius: 6px;
    }
    > thead > tr > th {
      vertical-align: middle;
      border: none;
      border-right: 1px solid #bcc7e5;
      padding: 10px;
      padding-left: 15px;
    }
    > thead > tr > th:last-child {
      border-right: none;
    }
    > tbody > tr > td {
      border: none;
      padding: 10px;
      padding-left: 15px;
      background: white;
      vertical-align: top;
    }
  `;

  const renderIcon = (imgType, imgSrc) => {
    return (
      <>{imgType && imgType === "icon" 
      ? <img src={imgSrc} /> 
      : imgType === 'hiphen' ? <>- </> : <>&#8226;</>}</>
    );
  };
  const renderRowContent = (imgType, imgSrc, text, imgStyle) => {
    return (
      <Box>
        {Array.isArray(text) ? (
          text?.map((item) => {
            return (
              <>
              { item ?  <Box style={imgStyle}> {renderIcon(imgType, imgSrc)} &nbsp;{item} </Box>: <></> }
              </>
            );
          })
        ) : (
          <>
         { text ?  <Box style={imgStyle}>  {renderIcon(imgType, imgSrc)} &nbsp;{text} </Box> : <></>}
          </>
        )}
      </Box>
    );
  };

  const renderHeadingRows = () => {
    return (
      <tr>
        {Object.values(tableData)?.map((item) => {
          return <th style={{width:item?.width}}>{item?.text}</th>;
        })}
      </tr>
    );
  };

  const renderRowData = (item) => {
    if(item?.dataType === 'multi-array'){
      return (
        <td>
          {item?.data?.map((data) => {
            return data?.data?.map((innerData, index) => {
              return renderRowContent(data?.imgType, data?.imgSrc, innerData, item?.style);
            })
          })}
        </td>
      ) 
    } else if (item?.data?.length === 0) {
      return (
        <td>
          <Box style={{ display: "flex", color: "#8FAECF" }}>
            <FiberManualRecordRoundedIcon style={{ color: "#8FAECF", marginTop: "6px", height:'10px', width:'10px', marginRight:'5px' }} />
            <Typography>  None</Typography></Box>
        </td>
      );
    } else if (typeof(item?.data) === 'string') {
      return (
        <td style={{whiteSpace:'break-spaces'}}>
         {  renderRowContent(item?.imgType, item?.imgSrc, item?.data, item?.style)}
        </td>
      );
    } else {
      return (
        <td>
          {item?.data?.map((data) => {
            return renderRowContent(item?.imgType, item?.imgSrc, data, item?.style);
          })}
        </td>
      );
    }
  };

  const renderTableBody = () => {
    return (
      <tr>
        {Object.values(tableData)?.map((item) => {
          return renderRowData(item);
        })}
      </tr>
    );
  };

  return (
    <Box className="custom-common-table">
      {tableData ? (
        <CustomTable striped>
          <thead>{renderHeadingRows()}</thead>
          <tbody>{renderTableBody()}</tbody>
        </CustomTable>
      ) : (
        ""
      )}
    </Box>
  );
};
