import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./../../feedback.scss";
import "./../../SectionsV1/Preview/preview.scss";

import completeSolutionSVG from "../../../../../assets/images/feedback/complete-solution.svg"
import incompleteSolutionSVG from "../../../../../assets/images/feedback/incomplete-solution.svg"
import optimalSolution from "../../../../../assets/images/feedback/optimal-solution.svg"
import notOptimalSolution from "../../../../../assets/images/feedback/not-optimal-solution.svg"

import { AccordionSummary, AccordionDetails } from './../../Components/Accordion'

import {
  CommentConstants, FeedbackType, showDesignQuestions, showCodingQuestions, showProblemSolvingCodingFields, isFlipkartFeedbackDesign
} from "../../Constants/FeedbackConstants";

const CodingQuestions = ({ codingQuestionData, feedbackType, interviewDetails, showFeedbackComments, renderAddCommentSection, renderViewUnresolvedCommentSection, CustomizedDialogs }) => {
  const getTotalTimeSpent = () => {
    let totalTimeSpent = 0;
    for (let i = 0; i < codingQuestionData?.questions?.length; i++) {
      const data = codingQuestionData?.questions[i];
      if (data?.timeSpent !== "") {
        totalTimeSpent = totalTimeSpent + parseInt(data?.timeSpent);
      }
    };
    return totalTimeSpent;
  }

  const [showSolutionCodeDialog, setSolutionCodeDialogVisibility] = useState(false);
  const [solutionCodeData, setSolutionCodeData] = useState({ index: null, questionData: null });

  const handleSolutionCodeDialogClose = (e) => {
    setSolutionCodeDialogVisibility(false);
    setSolutionCodeData({ index: null, questionData: null });
  };

  const viewSolutionCode = (e, index) => {
    setSolutionCodeDialogVisibility(true);
    setSolutionCodeData({ index: index, questionData: codingQuestionData.questions[index] })
  }

  const renderDesignFields = (data, name, label) => {
    return (
      <div className="form-row-style">
        <div className="coding-hld-lld-label">{label}</div>
        <div className="width-75">
          {data[name]?.map((datum) => {
            return <div className="table-data">{"- " + datum + "\n"}</div>
          })}
        </div>
      </div>
    )
  }

  const renderFlipkartFieldHeading = (label) => {
    return (
      <div className="flipkart-preview-heading">{label}</div>
    )
  }
  const renderFlipkartFields = (data, name, label) => {
    return (
      <div className="form-row-style">
        <div className="coding-hld-lld-label">{label}</div>
        <div className="width-75">
          {data[name].split("\n").map((data) => {
            return <div className="table-data">{"- " + data + "\n"}</div>
          })}
        </div>
      </div>
    )
  }
  const openQuestionLink = (link) => {
    if (link) {
      window.open(link, "_blank")
    }
  }

  const [expanded, setExpanded] = React.useState(true);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded);
  };
  
  return (
    <>
      <CustomizedDialogs
        isDialogOpen={showSolutionCodeDialog}
        handleClose={handleSolutionCodeDialogClose}
        solutionCodeData={solutionCodeData}
      />

      <Accordion id="add-comment-parent" className="mtb-20"  expanded={expanded} onChange={handleChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" style={{ width: "100%", alignItems: "center" }}>
            <div className="preview-subheader-title"> {showCodingQuestions(interviewDetails, codingQuestionData) ? 'Coding Questions' : 'Design Questions'}</div>
            <div className="preview-subheader-subtitle mrl-20">
              Time Spent: {getTotalTimeSpent()} Mins
            </div>
          </Box>
          {showFeedbackComments && renderViewUnresolvedCommentSection(CommentConstants.CODING_QUESTIONS)}
          {showFeedbackComments && renderAddCommentSection(CommentConstants.CODING_QUESTIONS)}
        </AccordionSummary>

        <AccordionDetails>
          <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
            {codingQuestionData?.questions?.map((data, index) => {
              return (
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ border: "1px solid #C7C7C7", padding: "20px" }}
                  key={`coding-preview-${index + 1}`}
                >
                  <Box display="flex" justifyContent='space-between'>

                    <div className={`theory-questions-unformatted ${data?.questionLink ? 'underline' : ''}`}
                      dangerouslySetInnerHTML={{ __html: index + 1 + ". " + data?.questionAsked }} onClick={(e) => openQuestionLink(data?.questionLink)}></div>

                    <Box display="flex" height='fit-content'>
                      {data?.isQuestionCompleted &&
                        <Box display="flex" className={`coding-solution ${data?.isQuestionCompleted === "true" ? "coding-solution-true" : "coding-solution-false"}`}>
                          <img src={data?.isQuestionCompleted === "true" ? completeSolutionSVG : incompleteSolutionSVG} />
                          <div className="ml-10">{data?.isQuestionCompleted === "true" ? "Complete Solution" : "Incomplete Solution"}</div>
                        </Box>
                      }
                      {data?.isSolutionOptimal &&
                        <Box display="flex" className={`coding-solution ${data?.isSolutionOptimal === "true" ? "coding-solution-true" : "coding-solution-false"}`}>
                          <img src={data?.isSolutionOptimal === "true" ? optimalSolution : notOptimalSolution} />
                          <div className="ml-0">{data?.isSolutionOptimal === "true" ? "Optimal" : "Not Optimal"}</div>
                        </Box>
                      }
                      {showProblemSolvingCodingFields(interviewDetails, codingQuestionData) &&
                        <button style={{ backgroundColor: "#0d3270" }} className="add-more-text-button ml-10" disabled={!(data?.solutionCode && data?.solutionCode?.trim() !== "")} onClick={(e) => viewSolutionCode(e, index)}>
                          View Code
                        </button>}

                      {(isFlipkartFeedbackDesign(interviewDetails, data) || showDesignQuestions(feedbackType)) &&
                        <div style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
                          <span className='coding-hld-lld-label' style={{ width: "fit-content", marginRight: "10px", marginLeft: "10px" }}>Time Spent: {"  "}</span>
                          <span>{data?.timeSpent} mins</span>
                        </div>}
                    </Box>
                  </Box>
                  <div
                    style={{
                      border: "1px solid #CDCDCD",
                      margin: "20px -20px",
                    }}
                  />

                  {!isFlipkartFeedbackDesign(interviewDetails, data) && showCodingQuestions(interviewDetails, codingQuestionData) &&
                    <>
                      <div className="coding-questions-questions" style={{ display: "flex" }}>
                        <div style={{ display: "flex" }}>
                          <span style={{ marginRight: "10px" }}>Time Spent: {"  "}</span>
                          <span>{data?.timeSpent} mins</span>
                        </div>

                        {showProblemSolvingCodingFields(interviewDetails, codingQuestionData) &&
                          <>
                            <div style={{ display: "flex" }}>
                              <span style={{ marginRight: "10px", marginLeft: "40px" }}>Time Complexity:{"  "}</span>
                              <span>{data?.timeComplexity}</span>
                            </div>
                            <div style={{ display: "flex" }}>
                              <span style={{ marginRight: "10px", marginLeft: "40px" }}>Space Complexity:{"  "}</span>
                              <span>{data?.spaceComplexity}</span>
                            </div>
                          </>}

                        {showProblemSolvingCodingFields(interviewDetails, codingQuestionData) &&
                          <div style={{ display: "flex" }}>
                            <span style={{ marginRight: "10px", marginLeft: "40px" }}>Language Used: {"  "}</span>
                            <span>{data?.languageUsed}</span>
                          </div>
                        }
                      </div>

                      <table>
                        <tr style={{ background: "#F2F2F2", height: "41px" }}>
                          <th className="center-align table-header">Approach</th>
                          <th className="center-align table-header">Positives</th>
                          <th className="center-align table-header">Neutrals</th>
                          <th className="center-align table-header">Negatives</th>
                        </tr>
                        <tr>
                          <td className="width-25 p-10 vertical-align">
                            {data?.approach?.map((data) => {
                              return <div className="table-data">{"- " + data + "\n"}</div>
                            })}
                          </td>
                          <td className="width-25 p-10 vertical-align">
                            {data?.positives?.map((data) => {
                              return <div className="table-data">{"- " + data + "\n"}</div>
                            })}
                          </td>
                          <td className="width-25 p-10 vertical-align">
                            {data?.neutrals?.map((data) => {
                              return <div className="table-data">{"- " + data + "\n"}</div>
                            })}
                          </td>
                          <td className="width-25 p-10 vertical-align">
                            {data?.negatives?.map((data) => {
                              return <div className="table-data">{"- " + data + "\n"}</div>
                            })}
                          </td>
                        </tr>
                      </table>
                    </>
                  }

                  {!isFlipkartFeedbackDesign(interviewDetails, data) && showDesignQuestions(feedbackType) &&
                    <>
                      {renderDesignFields(data, 'designProcess', 'Design Process:')}
                      {renderDesignFields(data, 'requirementGathering', 'Requirement Gathering:')}
                      {feedbackType === FeedbackType.HIGH_LEVEL_DESIGN && renderDesignFields(data, 'highLevelDesign', 'High Level Design:')}
                      {feedbackType === FeedbackType.LOW_LEVEL_DESIGN && renderDesignFields(data, 'lowLevelDesign', 'Low Level Design:')}
                      {renderDesignFields(data, 'scalability', 'Scalability:')}
                      {renderDesignFields(data, 'dbchoices', 'DB Choices:')}
                      {renderDesignFields(data, 'caching', 'Caching:')}
                      {renderDesignFields(data, 'extraComments', 'Extra Comments:')}
                    </>
                  }

                  {isFlipkartFeedbackDesign(interviewDetails, data) &&
                    <>
                      {renderFlipkartFieldHeading('Requirement Gathering')}
                      {renderFlipkartFields(data, 'assumptions', 'Assumptions:')}
                      {renderFlipkartFields(data, 'heuristics', 'Heuristics:')}

                      {renderFlipkartFieldHeading('Class Design')}
                      {renderFlipkartFields(data, 'entityModel', 'Entity Model:')}
                      {renderFlipkartFields(data, 'apiDesign', 'API Design:')}
                      {renderFlipkartFields(data, 'concurrency', 'Concurrency:')}

                      {renderFlipkartFieldHeading('System Decomposition')}
                      {renderFlipkartFields(data, 'subcomponentBreakdown', 'Subcomponent Breakdown:')}
                      {renderFlipkartFields(data, 'asyncSyncInteraction', 'Async/Sync Interaction:')}
                      {renderFlipkartFields(data, 'designTradeoffs', 'Design TradeOffs:')}

                      {renderFlipkartFieldHeading('Component Tech Choices')}
                      {renderFlipkartFields(data, 'familiarity', 'Familiarity:')}
                      {renderFlipkartFields(data, 'correctness', 'Correctness:')}
                    </>
                  }
                </Box>
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default CodingQuestions;