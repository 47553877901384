import React, {useEffect, useRef, useState} from 'react'
import MyDropzone from '../../../components/Dropzone/MyDropzone'
import { getFileURL, uploadFileV2 } from '../../../services/file'
import cogoToast from 'cogo-toast'
import { StyledSectionGridParent, StyledSectionGridChild, StyledSectionPaper, StyledSectionPaperHeading, StyledSectionPaperHeadingBlue, StyledUploadIcon, StyledResumeBox, StyledDocIcon, StyledDeleteResumeButton, StyledSectionPaperSelect, StyledSectionPaperMenuItem, StyledAddMoreButton, StyledSectionPaperFormTextField, StyledSectionPaperFormDatePicker, StyledWorkFooter, StyledWorkAndEducationForm, StyledWorkFormSubmitButton } from "./styles"
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const Work = ({profile, setProfile, loading, saveProfile, setLoading, setPageName}) => {

    const [file, setFile] = useState([]);
    const [workErrors, setWorkErrors] = useState([...Array(profile.work?.length).fill().map(item => ({...initWorkError}))]);
    const [educationErrors, setEducationErrors] = useState([...Array(profile.education?.length).fill().map(item => ({...initEducationError}))]);
    
    // Sets page title
    useEffect(() => {
        setPageName("Work & Education")
    }, [setPageName])

    const handleSave = async (e) => {
        e.preventDefault();
        const new_work_errors = [...Array(profile.work?.length).fill().map(item => ({...initWorkError}))]
        const new_education_errors = [...Array(profile.education?.length).fill().map(item => ({...initEducationError}))]
        for(let i=0; i<profile.work?.length; i++){
            if(profile.work[i].profile_title === '') new_work_errors[i].profile_title = true
            if(profile.work[i].company_name === '') new_work_errors[i].company_name = true
            if(profile.work[i].location === '') new_work_errors[i].location = true
            if(profile.work[i].start_date === '') new_work_errors[i].start_date = true
            if(!profile.work[i].current_work_flag){
                if(profile.work[i].end_date === '') new_work_errors[i].end_date = true
            }
        }
        for(let i=0; i<profile.education?.length; i++){
            if(profile.education[i]?.specialisation === '') new_education_errors[i].specialisation = true
            if(profile.education[i]?.institue_name === '') new_education_errors[i].institue_name = true
            if(profile.education[i]?.year_of_passing === '' || isNaN(profile.education[i]?.year_of_passing)) new_education_errors[i].year_of_passing = true
        }

        setWorkErrors(new_work_errors);
        setEducationErrors(new_education_errors);

        if(new_work_errors.map(item => Object.values(item)).flat().includes(true) || new_education_errors.map(item => Object.values(item)).flat().includes(true)) return;
        console.log(file)
        if(file && file.length > 0){
            setLoading(true)
            const res = await uploadFileV2(file[0])
            cogoToast.success('Resume uploaded successfully')
            const avatar_link = (res.data.fields['x-amz-meta-internal-key'])
            setProfile(prev => ({...prev, resume: avatar_link}))
            const newProfile = {...profile, resume: avatar_link}
            await saveProfile(newProfile);
        } else await saveProfile(profile);
    }

    
    const addEducation = () => {
        setProfile(prev => ({...prev, education: (!!prev.education ? [...prev.education, initEducation] : [initEducation] ) }))
        setEducationErrors(prev => ({...prev, initEducationError}))
    }
    
    const addWork = () => {
        setProfile(prev => ({...prev, work: (!!prev.work ? [...prev.work, initWork] : [initWork] )}))
        setWorkErrors(prev => ({...prev, initWorkError}))
    }

    const deleteEducation = (index) => {
        const newErrors = [...Array(profile.education?.length).fill().map(item => ({...initEducationError}))];
        newErrors.splice(index, 1) 
        setEducationErrors(newErrors)
        const newEducation = [...profile.education]
        newEducation.splice(index, 1)
        setProfile(prev => ({...prev, education: newEducation}))
    }

    const deleteWork = (index) => {
        const newErrors = [...Array(profile.work?.length).fill().map(item => ({...initWorkError}))];
        newErrors.splice(index, 1) 
        setWorkErrors(newErrors)
        const newWork = [...profile.work]
        newWork.splice(index, 1)
        setProfile(prev => ({...prev, work: newWork}))
    }

    const editEducation = (index, value, name) => {
        const newEducation = [...profile.education]
        newEducation[index][name] = value;
        setProfile(prev => ({...prev, education: newEducation}))
        
        const newErrors = [...Array(profile.education?.length).fill().map(item => ({...initEducationError}))];
        newErrors[index][name] = false;
        setEducationErrors(newErrors)
    }

    const editWork = (value, index, name) => {
        const newWork = [...profile.work]
        newWork[index][name] = value;
        setProfile(prev => ({...prev, work: newWork}))

        const newErrors = [...Array(profile.work?.length).fill().map(item => ({...initWorkError}))];
        newErrors[index][name] = false;
        setWorkErrors(newErrors)
    }

    const moveWork = (index, direction) => { // Moves work card up/down
        if(!!profile && !!profile?.work && profile?.work?.length !== 0){
            const newWorkList = [...profile?.work]
            // Swap works
            if (direction === 'up' && index !== 0){ // Move up
                const prevWork = { ...newWorkList[index - 1] }
                newWorkList[index - 1] = newWorkList[index]
                newWorkList[index] = prevWork
            } else if (direction === 'down' && index !== profile.work.length - 1){ // Move down
                const nextWork = { ...newWorkList[index + 1] }
                newWorkList[index + 1] = newWorkList[index]
                newWorkList[index] = nextWork
            }
            setProfile(prev => ({ ...prev, work: newWorkList }))
        }
    }

    const moveEducation = (index, direction) => { // Moves work card up/down
        if(!!profile && !!profile?.education && profile?.education?.length !== 0){
            const newEducationList = [...profile?.education]
            // Swap education
            if (direction === 'up' && index !== 0){ // Move up
                const prevEducation = { ...newEducationList[index - 1] }
                newEducationList[index - 1] = newEducationList[index]
                newEducationList[index] = prevEducation
            } else if (direction === 'down' && index !== profile.education.length - 1){ // Move down
                const nextEducation = { ...newEducationList[index + 1] }
                newEducationList[index + 1] = newEducationList[index]
                newEducationList[index] = nextEducation
            }
            setProfile(prev => ({ ...prev, education: newEducationList }))
        }
    }
   
    const fetchResume = async () => {
        try{
            if(profile.resume){
                const res = await getFileURL(profile.resume)
                window.open(res, '_blank')
            } 
        } catch(err){
            console.log(err)
        }
    }

    const deleteResume = () => {
        setProfile(prev => ({...prev, resume: ''}))
    }

    
    return (
    <div>
        <StyledSectionGridParent container>

            {/* Upload resume */}
            <StyledSectionGridChild item xs={12}>
                <StyledSectionPaper elevation={4}>
                    <StyledSectionPaperHeading variant="body2">
                        Resume
                    </StyledSectionPaperHeading>
                    <StyledSectionPaperHeadingBlue variant="body1">
                        Upload Resume <StyledUploadIcon />
                    </StyledSectionPaperHeadingBlue>
                    
                    <MyDropzone height='100px' onFileChange = {setFile} type = {'.pdf'} isMulti={false} />

                    {profile.resume &&
                        <StyledResumeBox>
                            <IconButton color="primary">
                                <StyledDocIcon onClick={fetchResume} alt="Download resume"/>
                            </IconButton>
                            <StyledSectionPaperHeadingBlue variant="body2" onClick={fetchResume}>
                                Resume
                            </StyledSectionPaperHeadingBlue>
                            <StyledDeleteResumeButton variant="text" color="primary" onClick={deleteResume}>
                                Delete resume
                            </StyledDeleteResumeButton>
                        </StyledResumeBox>
                    }

                    <FormControl fullWidth>
                        <InputLabel>Total relevant experience</InputLabel>
                        <StyledSectionPaperSelect variant="standard" >
                            <StyledSectionPaperMenuItem>1 year</StyledSectionPaperMenuItem>
                            <StyledSectionPaperMenuItem>2+ years</StyledSectionPaperMenuItem>
                            <StyledSectionPaperMenuItem>5+ years</StyledSectionPaperMenuItem>
                        </StyledSectionPaperSelect>
                    </FormControl>

                </StyledSectionPaper>

            </StyledSectionGridChild>

            <StyledWorkAndEducationForm>
                <StyledSectionGridParent item container xs={12}>
                    
                    {/* Work */}
                    <StyledSectionGridChild item xs={12}>
                        <StyledSectionPaperHeading variant="body1">
                            Work
                        </StyledSectionPaperHeading>
                        <StyledSectionPaper elevation={4}>
                            {profile.work?.map((item, index) => 
                                <WorkExperience 
                                    index= {index} 
                                    editWork = {editWork} 
                                    deleteWork = {deleteWork} 
                                    data = {item}
                                    error = {workErrors[index]}
                                    moveWork={moveWork}
                                    key={index}
                            />)}
                            <StyledAddMoreButton variant="text" color="primary" onClick = {() =>    addWork()}>+ Add work</StyledAddMoreButton>
                        </StyledSectionPaper>
                    </StyledSectionGridChild>

                    {/* Education */}
                    <StyledSectionGridChild item xs={12}>
                        <StyledSectionPaperHeading variant="body1">
                            Education
                        </StyledSectionPaperHeading>
                        <StyledSectionPaper elevation={4}>
                        {profile.education?.map((item, index) => 
                            <Education 
                                index= {index} 
                                editEducation = {editEducation} 
                                deleteEducation = {deleteEducation} 
                                data = {item}
                                error = {educationErrors[index]}
                                moveEducation={moveEducation}
                                key={index}
                            />)}
                            <StyledAddMoreButton variant="text" color="primary" onClick = {() =>    addEducation()}>+ Add Education</StyledAddMoreButton>
                        </StyledSectionPaper>
                    </StyledSectionGridChild>
                </StyledSectionGridParent>

                {/* Submit button */}
                <StyledWorkFormSubmitButton onClick = {(e) => { e.preventDefault(); handleSave(e) }} disabled={loading} color="primary" variant="contained" type="submit">
                    {loading ? "Saving" : "Save Changes"}
                </StyledWorkFormSubmitButton>
            </StyledWorkAndEducationForm>

        </StyledSectionGridParent>
    </div>)
}

export default Work





const WorkExperience = ({data, editWork, index, deleteWork, error, moveWork}) => {
    const [menuOpen, setMenuOpen] = useState(false) // Tracks open/close state of menu
    const menuIconRef = useRef(null) // Ref to menu icon

    return(

        <>
            {/* Menu to move this up, down, or delete */}
            <Box display="flex" flexWrap="nowrap" justifyContent="flex-end" marginBottom="-28px">
                <IconButton ref={menuIconRef} onClick={() => { setMenuOpen(prev => !prev); console.log("Menu") }}>
                    <MoreHorizIcon />
                </IconButton>
                <Menu anchorEl={menuIconRef?.current} keepMounted open={menuOpen} onClose={() => { setMenuOpen(false) }}>
                    <MenuItem onClick={() => {
                        deleteWork(index);
                        setMenuOpen(false);
                    }}>Delete</MenuItem>
                    <MenuItem onClick={() => {
                        moveWork(index, "up");
                        setMenuOpen(false);
                    }}>Move Up</MenuItem>
                    <MenuItem onClick={() => {
                        moveWork(index, "down");
                        setMenuOpen(false);
                    }}>Move Down</MenuItem>
                </Menu>
            </Box>

            <StyledSectionPaperFormTextField 
                value={data.profile_title || ""}
                onChange={(e) => editWork(e.target.value, index, 'profile_title')}
                label='Profile Title'  
                error={error && error.profile_title} 
                helperText={error && error.profile_title && "Please enter a valid profile title"}
                setError = {() => {}} 
                color="primary" fullWidth
            />

            <StyledSectionPaperFormTextField 
                value={data.company_name || ""}
                onChange={(e) => editWork(e.target.value, index, 'company_name')}
                label='Company Name'  
                error={error && error.company_name} 
                helperText={error && error.company_name && "Please enter a valid company name"}
                color="primary" fullWidth
                setError = {() => {}} 
            />

            <StyledSectionPaperFormTextField 
                value={data.location || ""}
                onChange={(e) => editWork(e.target.value, index, 'location')}
                label='Location'  
                error={error && error.location} 
                helperText={error && error.location && "Please enter a valid location"}
                setError = {() => {}}
                color="primary" fullWidth
            />

            <MuiPickersUtilsProvider utils={MomentUtils}>
                <StyledSectionPaperFormDatePicker
                    disableToolbar
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    id="work-start-date-picker"
                    label="Start date"
                    error={error && error.start_date}
                    helperText={error && error.start_date && 'Invalid Date'}
                    value={!!data.start_date ? new Date(data.start_date * 1000) : null}
                    onChange={(selectedDate) => { editWork(selectedDate.unix(), index, 'start_date') }}
                    fullWidth color="primary"
                    KeyboardButtonProps={{
                      'aria-label': 'Select start date',
                    }}
                />
            </MuiPickersUtilsProvider>

            <MuiPickersUtilsProvider utils={MomentUtils}>
                <StyledSectionPaperFormDatePicker
                    disableToolbar
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    id="work-end-date-picker"
                    label="End date"
                    error={error && error.end_date}
                    helperText={error && error.end_date && 'Invalid Date'}
                    value={data.end_date ? new Date(data.end_date * 1000) : null}
                    onChange={(selectedDate) => { editWork(selectedDate.unix(), index, 'end_date') }}
                    fullWidth color="primary"
                    KeyboardButtonProps={{
                      'aria-label': 'Select end date',
                    }}
                />
            </MuiPickersUtilsProvider>

            <StyledWorkFooter>
                <FormControlLabel labelPlacement="end" label="I currently work here"
                    control={
                        <Checkbox 
                            checked={data.current_work_flag === 'false' ? false : true} 
                            onChange = {(e) => {
                                editWork(e.target.checked.toString(), index, 'current_work_flag')
                                if(e.target.checked) editWork('', index, 'end_date')
                            }}
                        />
                    }
                />
            </StyledWorkFooter>

        </>
    )
}




const Education = ({index, editEducation, deleteEducation, data, error, moveEducation}) => {
    const [menuOpen, setMenuOpen] = useState(false) // Tracks open/close state of menu
    const menuIconRef = useRef(null) // Ref to menu icon

    return(
        <>
            {/* Menu to move this up, down, or delete */}
            <Box display="flex" flexWrap="nowrap" justifyContent="flex-end" marginBottom="-28px">
                <IconButton ref={menuIconRef} onClick={() => { setMenuOpen(prev => !prev); console.log("Menu") }}>
                    <MoreHorizIcon />
                </IconButton>
                <Menu anchorEl={menuIconRef?.current} keepMounted open={menuOpen} onClose={() => { setMenuOpen(false) }}>
                    <MenuItem onClick={() => {
                        deleteEducation(index);
                        setMenuOpen(false);
                    }}>Delete</MenuItem>
                    <MenuItem onClick={() => {
                        moveEducation(index, "up");
                        setMenuOpen(false);
                    }}>Move Up</MenuItem>
                    <MenuItem onClick={() => {
                        moveEducation(index, "down");
                        setMenuOpen(false);
                    }}>Move Down</MenuItem>
                </Menu>
            </Box>

            <StyledSectionPaperFormTextField 
                    value={data.specialisation || ""}
                    onChange={(e) => editEducation(index, e.target.value, 'specialisation')}
                    label = 'Specialisation'  
                    error={error && error.specialisation}
                    helperText={error && error.specialisation && "Please enter a valid specialisation"}
                    setError = {() => {}}
                    color="primary" fullWidth
                />

                <StyledSectionPaperFormTextField 
                    value={data.institue_name || ""}
                    onChange={(e) => editEducation(index, e.target.value, 'institue_name')}
                    label = 'Institute Name'  
                    error={error && error.institue_name} 
                    helperText={error && error.institue_name && "Please enter a valid institue name"}
                    setError = {() => {}}
                    color="primary" fullWidth
                />

                <StyledSectionPaperFormTextField 
                    value={data.year_of_passing || ""}
                    onChange = {(e) => editEducation(index, e.target.value, 'year_of_passing')}
                    label='Year of Passing'  
                    error={error && error.year_of_passing} 
                    helperText={error && error.year_of_passing && "Please enter a valid year"}
                    setError = {() => {}}
                    color="primary" fullWidth
                />
        </>
    )
}


const initEducation = {
    specialisation: "",
    institue_name: "",
    year_of_passing: ""
}

const initWork = {
    profile_title: "",
    company_name: "",
    location: "",
    start_date: "",
    end_date: "",
    current_work_flag: "false"
}


const initWorkError = {
    profile_title: false,
    company_name: false,
    location: false,
    start_date: false,
    end_date: false,
}

const initEducationError = {
    specialisation: false,
    institue_name: false,
    year_of_passing: false
}
