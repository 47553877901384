import React, { useState, useEffect, useMemo, useCallback } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import SkillEvaluationPopup from "../../Popups/SkillEvaluationPopup/SkillEvaluationPopup";
import { CommonFieldsType, isV2SectionFilled } from "../../Constants/FeedbackConstants";

import "./section.scss";
import CommonComponents from "../CommonComponents";

const SectionV2 = ({ section, interviewDetails, rawForm, rawResponse, setRawResponse, showError }) => {
    //Constants to be replaced with props from parent class.
    const isFormRequired = rawForm?.required;
    const isFormEmpty = !isV2SectionFilled(section, interviewDetails);
    const showValidationError = showError;
    //End of constants which need to be updated.

    const [response, setResponse] = useState();
    const [openSkillEvalModal, setSkillEvalModalVisibility] = useState(false)

    useEffect(() => {
        if (rawResponse && Object.keys(rawResponse).length > 0) {
            setResponse({ ...rawResponse });
        } else {
            setResponse(getDefaultResponse());
        }
    }, []);


    useEffect(() => {
        setRawResponse(response)
    }, [response]);


    const getDefaultResponse = () => {
        const response = {};
        if (rawForm?.summary) {
            response.summary = {};
            rawForm?.summary?.field_order?.map((data) => {
                const fieldType = (rawForm?.summary?.fields[data]?.field_type?.replaceAll('-', '_').toUpperCase());
                response.summary[data] = CommonFieldsType[fieldType]?.defaultValue || null;
            });
        }
        response.questions = [];
        getDefaultQuestionResponse(response.questions);
        return response;
    };

    const getDefaultQuestionResponse = (question) => {
        if (rawForm?.questions) {
            const questionObj = {};

            rawForm?.questions?.field_order?.map((data) => {
                const fieldType = (rawForm?.questions?.fields[data]?.field_type?.replaceAll('-', '_')?.toUpperCase());
                questionObj[data] = CommonFieldsType[fieldType]?.defaultValue || null;
            });
            question.push(questionObj);
        }
        return question;
    };

    const addQuestion = () => {
        const defResponse = { ...response };
        getDefaultQuestionResponse(defResponse?.questions);
        setResponse(defResponse);
    };

    const deleteQuestion = (index) => {
        const defResponse = { ...response };
        defResponse.questions.splice(index, 1);
        setResponse(defResponse);
    };

    const handleSummaryChange = (e, fieldInfo, questionFieldData) => {
        const duplicateResponse = { ...response };

        if (fieldInfo?.field_type === CommonFieldsType.RADIO_BUTTON.label && !e) {
            if (duplicateResponse?.summary?.[questionFieldData]) {
                delete duplicateResponse.summary[questionFieldData];
            }
        } else {
            duplicateResponse.summary[questionFieldData] = e;
        }

        setResponse(duplicateResponse)
    };
    const handleQuestionChange = (e, fieldInfo, index, questionFieldData) => {
        const duplicateResponse = { ...response };

        if (fieldInfo?.field_type === CommonFieldsType.RADIO_BUTTON.label && !e) {
            if (duplicateResponse?.questions?.[index]?.[questionFieldData]) {
                delete duplicateResponse.questions[index][questionFieldData];
            }
        } else {
            duplicateResponse.questions[index][questionFieldData] = e;
        }

        setResponse(duplicateResponse)
    };
    const handleSummaryNestedFieldChange = (e, fieldInfo, questionFieldData, nestedField) => {
        const duplicateResponse = { ...response };

        if (fieldInfo?.field_type === CommonFieldsType.RADIO_BUTTON.label && !e) {
            if (duplicateResponse?.summary?.[questionFieldData]?.[nestedField]) {
                delete duplicateResponse.summary[questionFieldData][nestedField];
            }
        } else {
            if (duplicateResponse?.summary?.[questionFieldData]) {
                duplicateResponse.summary[questionFieldData][nestedField] = e;
            } else {
                duplicateResponse.summary[questionFieldData] = {
                    [nestedField]: e
                }
            }
        }
        setResponse(duplicateResponse)
    };

    const handleQuestionNestedFieldChange = (e, fieldInfo, index, questionFieldData, nestedField) => {
        const duplicateResponse = { ...response };

        if (fieldInfo?.field_type === CommonFieldsType.RADIO_BUTTON.label && !e) {
            if (duplicateResponse.questions?.[index]?.[questionFieldData]?.[nestedField]) {
                delete duplicateResponse.questions[index][questionFieldData][nestedField];
            }
        } else {
            if (duplicateResponse.questions[index][questionFieldData]) {
                duplicateResponse.questions[index][questionFieldData][nestedField] = e;
            } else {
                duplicateResponse.questions[index][questionFieldData] = {
                    [nestedField]: e
                }
            }
        }

        setResponse(duplicateResponse)
    };

    const handleFieldChangeDropDownWrapper = (e,fieldItem, questionFieldData,  index, fieldInfo) => {
        const duplicateResponse = { ...response };

        if (fieldInfo?.field_type === CommonFieldsType.RADIO_BUTTON.label && !e) {
            if (duplicateResponse.questions?.[index]?.[questionFieldData]?.[fieldItem]) {
                delete duplicateResponse.questions[index][questionFieldData][fieldItem];
            }
        } else {
            if (duplicateResponse.questions[index][questionFieldData]) {
                duplicateResponse.questions[index][questionFieldData][fieldItem] = e;
            } else {
                duplicateResponse.questions[index][questionFieldData] = {
                    [fieldItem]: e
                }
            }
        }

        setResponse(duplicateResponse)
    }

    const renderSkillsButton = (data, nestedField, parentInfo, fieldInfo, response) => {
        const handleChangeEvalSkills = (e) => {
            handleSummaryNestedFieldChange(e, fieldInfo, data, nestedField)
        }

        const handleAddSkillChange = (e, fieldInfo, data, nestedField, responseKey) => {
            handleSummaryNestedFieldChange({ ...response, [responseKey]: e }, fieldInfo, data, nestedField)
        }

        return (
            <Box style={{marginLeft: '30px', marginRight:'-32px'}}  sx={{ width: '100%' }}>
            <Box sx={{ width: '100%' }}>

              { Object.keys(response || {}).length > 0 && 
              <Typography style={{margin:'0 15'}}>Others</Typography>}

                <Grid container columnSpacing={4} xs={12} sx={{ width: '100%' }}>
                    {
                        Object.keys(response || {})?.map((responseKey, childIndex) => {
                            return (
                                <Grid item xs={12 / (parentInfo?.grid?.horizontal || 1)}>
                                    <Box sx={{ width: '100%' }}>
                                        <CommonComponents
                                            key={`summary-listed-${responseKey}-${childIndex}`}
                                            fieldKey={responseKey}
                                            fieldInfo={{ ...fieldInfo?.generic_field_info, question: responseKey }}
                                            response={response[responseKey]}
                                            showValidationError={
                                                showValidationError && (isFormRequired || !isFormEmpty)
                                            }
                                            handleChange={(e) => handleAddSkillChange(e, { ...fieldInfo?.generic_field_info, question: responseKey }, data, nestedField, responseKey)}
                                            parentInfo={parentInfo}
                                            childIndex={childIndex}
                                        />
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <Box className="skill-button">
                    <SkillEvaluationPopup openSkillEvalModal={openSkillEvalModal} setSkillEvalModalVisibility={setSkillEvalModalVisibility} evaluationSkillsNestedFields={parentInfo?.field_info?.nested_fields} addSkillsList={response} handleChangeEvalSkills={handleChangeEvalSkills} />

                    <Button className="button" onClick={() => setSkillEvalModalVisibility(true)}> + Add Skills</Button>
                    <Typography className="post-text mt-2 mb-3">{fieldInfo?.post_text}</Typography>
                </Box>
            </Box></Box>)
    }

    if (!response) {
        return (<>Loading....</>)
    }
    return (
        <Box display="flex" flexDirection="column" className="section-v2-container">
            {rawForm?.["required_text"] && (
                <Typography className="required-text">
                    <InfoOutlinedIcon sx={{ fontSize: 12, marginRight: "3px" }} />
                    {rawForm?.["required_text"]}
                </Typography>
            )}

            <Typography className="heading mt-1">{rawForm?.page_name}</Typography>

            {rawForm?.summary?.field_order?.map((data) => {
                return (
                    <>
                        {
                            rawForm?.summary?.fields?.[data]?.nested ?

                                <Box display="flex" flexDirection={'column'} className={`listed-section ${(section === 'hld_design' || section === 'lld_design') ? 'design-bg' : ''}`}>
                                    <Typography className="heading">
                                        {rawForm?.summary?.fields?.[data]?.field_info?.heading}
                                    </Typography>

                                    <Typography className="sub-heading">
                                        {rawForm?.summary?.fields?.[data]?.field_info?.sub_heading}
                                    </Typography>


                                    <Grid container columnSpacing={4} xs={12} sx={{ width: '100%' }}>

                                        {rawForm?.summary?.fields?.[data]?.field_info?.nested_fields_order?.map((nestedField, childIndex) => {
                                            return (
                                                <>
                                                    {rawForm?.summary?.fields?.[data]?.field_info?.nested_fields?.[nestedField]?.field_type === CommonFieldsType.BUTTON.label && rawForm?.summary?.fields?.[data]?.field_info?.nested_fields?.[nestedField]?.sub_type === 'skills_static_data'
                                                        ?
                                                        renderSkillsButton(data, nestedField, rawForm?.summary?.fields?.[data], rawForm?.summary?.fields?.[data]?.field_info?.nested_fields?.[nestedField], response?.summary?.[data]?.[nestedField])

                                                        : <Grid item xs={rawForm?.summary?.fields?.[data]?.field_info?.nested_fields?.[nestedField]?.field_type === CommonFieldsType.BUTTON.label ? 12 : 12 / (rawForm?.summary?.fields?.[data]?.grid?.horizontal || 1)}>
                                                            <Box sx={{ width: '100%' }}>
                                                                <CommonComponents
                                                                    key={`summary-listed-${nestedField}`}
                                                                    fieldKey={nestedField}
                                                                    fieldInfo={rawForm?.summary?.fields?.[data]?.field_info?.nested_fields?.[nestedField]}
                                                                    response={response?.summary?.[data]?.[nestedField]}
                                                                    showValidationError={
                                                                        showValidationError && (isFormRequired || !isFormEmpty)
                                                                    }
                                                                    handleChange={(e) => handleSummaryNestedFieldChange(e, rawForm?.summary?.fields?.[data]?.field_info?.nested_fields?.[nestedField], data, nestedField)}
                                                                    parentInfo={rawForm?.summary?.fields?.[data]}
                                                                    childIndex={childIndex}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    }
                                                </>
                                            );
                                        })}
                                    </Grid>
                                </Box>
                                :
                                <CommonComponents
                                    key={`summary-${data}`}
                                    fieldKey={data}
                                    fieldInfo={rawForm?.summary?.fields?.[data]}
                                    response={response?.summary?.[data]}
                                    showValidationError={
                                        showValidationError && (isFormRequired || !isFormEmpty)
                                    }
                                    handleChange={(e) => handleSummaryChange(e, rawForm?.summary?.fields?.[data], data)}
                                />}
                    </>
                );
            })}

            {response?.questions?.map((data, index) => {

                return (
                    <Box>
                        <Box display="flex" justifyContent="space-between">
                            <Typography className="question-title">
                                {" "}
                                Question {index + 1}.{" "}
                            </Typography>

                            {response?.questions?.length > 1 && (
                                <IconButton onClick={(e) => deleteQuestion(index)}>
                                    {" "}
                                    <DeleteIcon />{" "}
                                </IconButton>
                            )}
                        </Box>

                        <Box className="question-body">
                            {rawForm?.questions?.field_order?.map((questionFieldData) => {
                                return (
                                    <>
                                        {
                                            rawForm?.questions?.fields?.[questionFieldData].nested ?

                                                <Box display="flex" flexDirection={'column'} className={`listed-section ${(section === 'hld_design' || section === 'lld_design') ? 'design-bg' : ''}`}>
                                                    <Typography className="heading">
                                                        {rawForm?.questions?.fields?.[questionFieldData]?.field_info?.heading}
                                                    </Typography>

                                                    <Typography className="sub-heading">
                                                        {rawForm?.questions?.fields?.[questionFieldData]?.field_info?.sub_heading}
                                                    </Typography>

                                                    <Grid container columnSpacing={4} xs={12} sx={{ width: '100%' }}>

                                                        {rawForm?.questions?.fields?.[questionFieldData]?.field_info?.nested_fields_order?.map((nestedField, childIndex) => {
                                                            return (
                                                                <Grid item xs={rawForm?.questions?.fields?.[questionFieldData]?.field_info?.nested_fields?.[nestedField]?.field_type === CommonFieldsType.BUTTON.label ? 12 : 12 / (rawForm?.questions?.fields?.[questionFieldData]?.grid?.horizontal || 1)}>
                                                                    <Box sx={{ width: '100%' }}>
                                                                        <CommonComponents
                                                                            key={`question-${index + 1}-listed-${nestedField}`}
                                                                            fieldKey={nestedField}
                                                                            fieldInfo={rawForm?.questions?.fields?.[questionFieldData]?.field_info?.nested_fields?.[nestedField]}
                                                                            response={response?.questions?.[index]?.[questionFieldData]?.[nestedField]}
                                                                            showValidationError={
                                                                                showValidationError && (isFormRequired || !isFormEmpty)
                                                                            }
                                                                            handleChange={(e) => handleQuestionNestedFieldChange(e, rawForm?.questions?.fields?.[questionFieldData]?.field_info?.nested_fields?.[nestedField], index, questionFieldData, nestedField)}
                                                                            parentInfo={rawForm?.questions?.fields?.[questionFieldData]}
                                                                            childIndex={childIndex}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                </Box>
                                                : <CommonComponents
                                                    key={`question-${index + 1}-${questionFieldData}`}
                                                    fieldKey={questionFieldData}
                                                    fieldInfo={rawForm?.questions?.fields?.[questionFieldData]}
                                                    response={response?.questions[index]?.[questionFieldData]}
                                                    showValidationError={
                                                        showValidationError && (isFormRequired || !isFormEmpty)
                                                    }
                                                    handleChange={(e) => handleQuestionChange(e, rawForm?.questions?.fields?.[questionFieldData], index, questionFieldData)}
                                                />
                                        }

                                        {
                                             response?.questions?.[index]?.[questionFieldData] &&

                                            (rawForm?.questions?.fields?.[questionFieldData]?.field_type === CommonFieldsType.DROP_DOWN_WRAPPER.label ||
                                             rawForm?.questions?.fields?.[questionFieldData]?.field_type === CommonFieldsType?.DROP_DOWN_WITH_GROUP?.label ) &&

                                            rawForm?.questions?.fields?.[questionFieldData]?.field_info?.list_order?.map((fieldItem, listOrderIndex) => {
                                                return (<CommonComponents
                                                    key={fieldItem === 'question_asked' ? `dropdown-wrapper-${questionFieldData}-${fieldItem}-${response?.questions?.[index]?.[questionFieldData]?.['question_statement']}-${listOrderIndex}` : `dropdown-wrapper-${questionFieldData}-${fieldItem}-${response?.questions?.[index]?.[questionFieldData]?.[fieldItem]}-${listOrderIndex}`}
                                                    fieldKey={fieldItem === 'question_asked' ? 'question_statement' : fieldItem}
                                                    fieldInfo={rawForm?.questions?.fields?.[questionFieldData]?.field_info?.list_items[fieldItem]}
                                                    response={fieldItem === 'question_asked' ? (response?.questions?.[index]?.[questionFieldData]?.['question_statement']) : response?.questions?.[index]?.[questionFieldData]?.[fieldItem]}
                                                    showValidationError={showValidationError && (isFormRequired || !isFormEmpty)}
                                                    handleChange={(e) => handleFieldChangeDropDownWrapper(e,fieldItem === 'question_asked' ? 'question_statement'  : fieldItem, questionFieldData,  index, fieldItem === 'question_asked' ? rawForm?.questions?.fields?.[questionFieldData]?.field_info?.list_items['question_statement'] : rawForm?.questions?.fields?.[questionFieldData]?.field_info?.list_items[fieldItem] )}
                                                    editable={response?.questions?.[index]?.[questionFieldData]?.editable}
                                                />)
                                            })
                                        }
                                    </>
                                );
                            })}
                        </Box>
                    </Box>
                );
            })}

            {rawForm?.questions && (
                <Button
                    variant="contained"
                    className="add-more-question mb-10"
                    onClick={addQuestion}
                >Add more questions</Button>
            )}
        </Box>
    );
};

export default React.memo(SectionV2);
