import { styled } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Card from "@material-ui/core/Card"
import Button from "@material-ui/core/Button"

export const StyledForm = styled('form')(({ theme }) => ({
    width: "100%",
    padding: "30px 10px 60px 10px"
}))

export const ParentCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    width: "100%",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(3),
    boxShadow: "0px 2px 12px rgba(18, 63, 188, 0.12)",
    borderRadius: "4px"
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    width: "96%",
    
}))

export const StyledButton = styled(Button)(({ theme }) => ({
    width: "100%",
    textTransform: "capitalize",
    fontSize: theme.typography.body1.fontSize,
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
        width: "fit-content",
        float: "right"
    }
}))