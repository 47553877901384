import { styled } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Popover from '@material-ui/core/Popover';
import Modal from '@material-ui/core/Modal';

export const ParentBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    width: "100%",
    padding: theme.spacing(4)
}))

export const StyledChangePwdHeading = styled(Typography)(({ theme }) => ({
    color: "#3D3D3D",
    fontWeight: 600,
    marginBottom: theme.spacing(1)
}))

export const StyledWhatMakesPwdStrongBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: theme.spacing(1)
}))

export const StyledShieldWithTickIcon = styled('img')(({ theme }) => ({
    height: "100%"
}))

export const StyledWhatMakesPwdStrongHeading = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: 600,
    cursor: "pointer"
}))

export const StyledPasswordForm = styled("form")(({ theme }) => ({
    marginTop: theme.spacing(3)
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(1)
}))

export const StyledInfoText = styled(Typography)(({ theme }) => ({
    color: "#6a6a6a",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
}))

export const StyledActionButtons = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        flexDirection: "row",
    }
}))

export const StyledButton = styled(Button)(({ theme }) => ({
    width: "100%",
    textTransform: "capitalize",
    fontSize: theme.typography.body1.fontSize,
    [theme.breakpoints.up("sm")]: {
        width: "fit-content"
    }
}))

export const StyledPwdPolicyModal = styled(Modal)(({ theme }) => ({

}))

export const StyledPwdPolicyPopover = styled(Popover)(({ theme }) => ({
    
}))

export const StyledUnorderedList = styled("ul")(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: "0 !important"
}))

export const StyledUnorderedListItem = styled("li")(({ theme }) => ({
    color: "#585858"
}))