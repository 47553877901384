import { toast } from "react-toastify"

export const toastError = (message) => {
    toast.error(message, {
        toastId: `ERROR_TOAST - ${message}`
    });
}

export const toastSuccess = (message) => {
    toast.success(message, {
        toastId: `SUCCESS_TOAST - ${message}`
    });
}

export const toastInfo = (message) => {
    toast.info(message, {
        toastId: `INFO_TOAST - ${message}`
    });
}

export const toastWarn = (message) => {
    toast.warn(message, {
        toastId: `WARN_TOAST - ${message}`
    });
}
