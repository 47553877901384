import React, {useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import {useDropzone} from 'react-dropzone';
import './fileUpload.scss';
import downloadImg from '../../../images/download.svg';
import { downloadFile } from '../../../services/file';


const FileUpload = ({onFileChange, btnText, labelText, name, type, isMulti, preSavedFile, isDisabled}) => {

    const [fileName, setFileName] = useState();
    
    let query= "";
    let fetchedFileName = "";

    if(typeof(preSavedFile) === "string"){
      query = new URLSearchParams(preSavedFile);
      fetchedFileName = query?.get("s3_key");
    }

    const fileIndex = fetchedFileName?.indexOf("_")+1;
    const newFileName = fetchedFileName?.substring(fileIndex, fetchedFileName?.length);
     
    const onDrop = useCallback(acceptedFiles => {
        setFileName(acceptedFiles.map(item => item.path).join(', '))
        onFileChange(acceptedFiles)
    }, [onFileChange])
    
    const { open, getInputProps } = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
        accept: type,
        multiple: isMulti
      });

      const removeFile = ()=>{
          onFileChange("");
          setFileName("");
      }

      const handleDownload = (imgUrl) => {
        downloadFile(imgUrl, newFileName); 
      }

    return(
        <div>
            <input {...getInputProps()} />
            <Box className='file-box'>
                <Box className='img-container'>
                    {!isDisabled ? <Box className='upload-box' onClick={open}>{btnText} {name === "panCardFile" && <span className='c-red'>*</span>}</Box> 
                      : <Box>{labelText}:</Box>}

                    {(fileName || newFileName || fetchedFileName) && <><Box>{fileName || newFileName || fetchedFileName}</Box>

                    {isDisabled ? <Box onClick={()=>{ handleDownload(preSavedFile) }}><img src={downloadImg} alt="download" height="17px" width="17px"/></Box> :
                    <Box onClick={removeFile} className='remove-text'>Remove</Box>} </>}
                </Box>

                {!isDisabled && <Box className='instruction'> File format: PNG, PDF or JPG, size not more than 5 mb.</Box>}
            </Box>    
        </div>
    )
}

export default FileUpload;