import * as React from "react";
import { useState } from "react";
import Modal from '@mui/material/Modal';
import JobDescription from "./JobDescription";

export default function BasicModal({setViewDescription, demoData}) {
    const [open, setOpen] = useState(true);
    const handleClose = () => setOpen(false);
  
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <JobDescription setViewDescription={setViewDescription} demoData={demoData} />
        </Modal>
      </div>
    );
  }