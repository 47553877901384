import React from "react";
import { Text, View } from '@react-pdf/renderer';
import Html from 'react-pdf-html';

import { stylesPDF, styleQuestions, stylesFeedbackSummary } from "./Style";

export const TheoryQuestions = ({ theoryQuestionsData }) => {
    return (
        <View style={[{ margin: 15, border: "1 solid #cdcdcd", backgroundColor: "#fff" }]}>

            <View style={[stylesPDF.rowstyle, { width: "100%", backgroundColor: "#C5D4FF", minHeight: 42, padding: 15 }]}>
                <Text style={[stylesFeedbackSummary.previewSubheaderTitle]}> Theory Questions</Text>
                <Text style={[stylesFeedbackSummary.previewSubheaderSubtitle, { margin: '0 15' }]}>  Time Spent: {theoryQuestionsData?.timeSpent} Mins
                </Text>
            </View>

            <View style={[stylesPDF.columnstyle, { width: "100%", padding: 20 }]}>
                {theoryQuestionsData?.questions?.map((data, index) => {
                    return (
                        <View style={[stylesPDF.columnstyle, { width: "100%" }]}>
                            <View style={[stylesPDF.rowstyle, styleQuestions.theoryQuestionsUnformatted]}>
                                <Text style={[data?.questionAsked?.length > 0 && data?.questionAsked[0] === '<' ? { marginTop: '10' } : { marginTop: 0 }]}> {index + 1}. </Text>
                                <Html style={{ fontSize: 11, }}>{data?.questionAsked}</Html>
                            </View>

                            <Text style={[data?.codeText ? styleQuestions.codingPart : { height: "0px" }]}> {data?.codeText || ""} </Text>

                            <View style={[stylesPDF.rowstyle, styleQuestions.theoryAnswers]}>
                                <Html style={{ fontSize: 11 }} >{data?.candidateResponse}</Html>
                            </View>

                            {index + 1 !== theoryQuestionsData?.questions?.length && (
                                <View style={[{ width: "100%", border: "1 solid #CDCDCD", margin: 16 }]}></View>
                            )}
                        </View>
                    );
                })}
            </View>
        </View>
    );
};

