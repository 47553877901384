import Box from '@mui/material/Box';
import { KarmaScoreRange } from './Constant';
import "./KarmaScore.scss";

const KarmaScore = ({score}) => {

  const totalPercentage = score * KarmaScoreRange.INITIAL_VALUE;
  const belowAverage = KarmaScoreRange.BELOW_AVERAGE;
  const average = KarmaScoreRange.AVERAGE;
  const good = KarmaScoreRange.GOOD;

  const karmaScoreText =
    totalPercentage > good
      ? "Excellent"
      : totalPercentage >= average
      ? "Good"
      : totalPercentage >= belowAverage
      ? "Average"
      : "Below Average";

  return (
    <Box className='karma-box'>
      <Box class="multi-graph margin">
        { score ? <Box className='fw-700 fs-30' >{score}</Box> : "--"}

        { !score ? <Box class="graph"><div class="thumb no-display"></div></Box> 
        : <>
        <Box
          class="graph"
          style={{ "--percentage": totalPercentage, "--fill": "#008435" }}
        >
          {totalPercentage > good && (
            <div class="thumb dark-green-thumb"></div>
          )}
        </Box>
        <Box
          class="graph"
          style={{
            "--percentage":
              totalPercentage >= average && totalPercentage <= good
                ? totalPercentage
                : totalPercentage >= good
                ? good
                : 0,
            "--fill": "#00A642"
          }}
        >
          {totalPercentage >= average && totalPercentage <= good && (
            <div class="thumb green-thumb"></div>
          )}
        </Box>
        <Box
          class="graph"
          style={{
            "--percentage":
              totalPercentage > average
                ? average
                : totalPercentage >= belowAverage && totalPercentage < average
                ? totalPercentage
                : 0,
            "--fill": "#FFB800"
          }}
        >
          {totalPercentage >= belowAverage && totalPercentage < average && (
            <div class="thumb yellow-thumb"></div>
          )}
        </Box>
        <Box
          class="graph"
          style={{
            "--percentage":
              totalPercentage > belowAverage ? belowAverage : totalPercentage,
            "--fill": "#E10D0D"
          }}
        >
          {totalPercentage < belowAverage && (
            <div class="thumb red-thumb"></div>
          )}
        </Box>
        </>}
      </Box>

      { score ? <Box className='karma-text'>Your Karma Score is <span 
        className={ score <= 3 ? "red-text" : 
          score < 4 ? "yellow-text" : 
          score < 5 ? "green-text" : "dark-green-text" }>
        {karmaScoreText}
       </span>
      </Box> : <Box className='karma-text'>No Karma Score to display </Box>}
    </Box>
    )
}
export default KarmaScore;