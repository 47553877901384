import { React, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import './modal.scss'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  p: 4,
};


export default function BasicModal({ modalError, setShowErrorModal }) {
  // console.log(modalError);
  const { lowLight, highLight, lowCnt, highCnt, rating } = modalError;
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    setShowErrorModal(false);
  }
  const [color, setColor] = useState('');
  const [title, setTitle] = useState('');

  const setRating = (rating) => {
    let color, title;
    if (rating <= 3) {
      color = "#B60000";
      title = "Strong Reject";
    }
    else if (rating <= 5) {
      color = "#DE9811";
      title = "Reject";
    }
    else if (rating <= 8) {
      color = "#84C51B";
      title = "Proceed";
    }
    else if (rating >= 9 && rating <= 10) {
      color = "#0B8701";
      title = "Strong Proceed";
    }
    setColor(color);
    setTitle(title);
  }

  useEffect(() => {
    setRating(rating);
  }, [])
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className="modal-heading">
            <span>
              <CancelIcon style={{ position: "relative", top: "-5px", marginRight: "10px", color: '#D85554' }} />
              <span className='modal-error'>ERROR</span>
            </span>
            <ClearIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </Box>
          <Box className="modal-details">
            <p className="modal-feedback-summary">
              FEEDBACK SUMMARY
            </p>
            {highLight &&
              (<Box className="modal-highlight-box">
                <p className="modal-highlights">
                  Highlights
                </p>
                <p className="modal-five-points">
                  There must be atleast {highCnt} Highlight Points.
                </p>
                <p className="modal-five-points">
                  Please add atleast 5 words in each bullet point.
                </p>
              </Box>)
            }

            {
              lowLight &&
              <Box className="modal-highlight-box">
                <p className="modal-highlights">
                  Lowlights
                </p>
                <p className="modal-five-points">
                  There must be atleast {lowCnt} LowLight Points.
                </p>
                <p className="modal-five-points">
                  Please add atleast 5 words in each bullet point.
                </p>
              </Box>
            }
            <Box className="modal-recomend-box">
              <p className="modal-highlights">
                Overall Ratings: {rating} <span style={{ color: color }}>({title})</span>
              </p>
              {/* <p className="modal-five-points">
                Please add 1 more Highlights to justify your overall rating.
              </p> */}
              {/* <p className="modal-proceed-recomand">
                For Proceed recommendation, there must be atleast 3 Highlights and 1 Lowlight.
              </p> */}
            </Box>
          </Box>
          <button className="modal-ok-button" onClick={handleClose} >
            OK
          </button>
        </Box>
      </Modal>
    </div>
  );
}