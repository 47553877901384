import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ component: Component, isAuthenticated, ...children }) => (

  isAuthenticated
    ? <Route {...children} render={props => <Component {...props} />} />
    : <Redirect to="/login" />
);

export default PrivateRoute;
