import { FETCH_HISTORY, CANCELLED_INTERVIEWS } from "../actions/History";

export const initialState = {
  list: [],
  cancelled_interviews: '',
  completedCount : 0,
}

const histroyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HISTORY:
      return {
        ...state,
        list: action.payload.interview_list,
        completedCount : action.payload.metadata_dict.total || 0
      };
    case CANCELLED_INTERVIEWS:
      return {
        ...state,
        cancelled_interviews: action.payload
      };
    default:
      return state;
  }
}

export default histroyReducer;