import React, {useState, useRef, useEffect} from 'react'
import cogoToast from 'cogo-toast'
import axios from '../../../services/axios';
import { useSelector } from 'react-redux';
import { ParentBox, StyledChangePwdHeading, StyledWhatMakesPwdStrongBox, StyledShieldWithTickIcon, StyledWhatMakesPwdStrongHeading, StyledPasswordForm, StyledTextField, StyledInfoText, StyledButton, StyledActionButtons, StyledPwdPolicyPopover, StyledPwdPolicyModal, StyledUnorderedList, StyledUnorderedListItem } from "./styles"


const AccountSettings = ({ setPageName }) => {

    const userData = useSelector((state) => state && state.user && state.user);

    const [state, setState] = useState({
        prevPassword: "",
        password: "",
        password1: "",
    })

    const [errors, setErrors] = useState(Array(3).fill(null));
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const pwdPolicyTextRef = useRef(null); // Ref to "What makes a strong password?"
    const [pwdPolicyShow, setPwdPolicyShow] = useState(false); // State to track open state of pwd policy popover

    // Sets page title
    useEffect(() => {
        setPageName("Account Settings")
    }, [setPageName])

    const editErrors = (value, index) => {
        const prevErrors = [...errors]
        prevErrors[index] = value;
        setErrors(prevErrors)
    }

    const forgotPassword = async () => {
        try{
            setLoading(true);
            await axios.post('/forgot-password', {email: userData.email})
            cogoToast.success('A email has been sent to you with password resetting instructions', {position: 'top-right'})
            setLoading(false);
        } catch(err){
            setLoading(false);
        }
    }

    const updatePassword = async (e) => {
        e.preventDefault();
        if(validation()){
            try{
                setLoading1(true);
                await axios.post('/update', {
                    name: userData.name,
                    old_password: state.prevPassword,
                    password: state.password,
                    user_id: userData.id
                    
                })
                cogoToast.success("You have successfully changed your password") 
                setLoading1(false);
            } catch(err){
                setLoading1(false);
            }  
        }
    }

    const validation = () => {
        if(state.prevPassword === ''){
            editErrors(true, 0)
            return false;
        }
        if(state.password !== state.password1) {
            editErrors(true, 1)
            return false;
        }
        else return true;
    }

    return (
        <React.Fragment>
            <ParentBox>
                <StyledChangePwdHeading variant="body1">
                    Change Password
                </StyledChangePwdHeading>
                <StyledWhatMakesPwdStrongBox>
                    <StyledShieldWithTickIcon src="/assets/icons/shield-with-tick.svg" alt="Shield with tick"/>

                    <StyledWhatMakesPwdStrongHeading ref={pwdPolicyTextRef} onClick={() => { setPwdPolicyShow(true) }}>
                        What makes a strong password?
                    </StyledWhatMakesPwdStrongHeading>

                    <StyledPwdPolicyModal open={pwdPolicyShow} onClose={() => { setPwdPolicyShow(false) }}>
                        <StyledPwdPolicyPopover anchorEl={pwdPolicyTextRef?.current} open=  {pwdPolicyShow} onClose={() => { setPwdPolicyShow(false) }} anchorOrigin={{
                        vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top',
                        horizontal: 'center' }}>
                            <StyledUnorderedList>
                                <StyledUnorderedListItem>
                                    Minimum 8 character
                                </StyledUnorderedListItem>

                                <StyledUnorderedListItem>
                                    At least one special character
                                </StyledUnorderedListItem>

                                <StyledUnorderedListItem>
                                    At least one number
                                </StyledUnorderedListItem>

                                <StyledUnorderedListItem>
                                    Can’t be the same as a previous password
                                </StyledUnorderedListItem>
                            </StyledUnorderedList>
                        </StyledPwdPolicyPopover>
                    </StyledPwdPolicyModal>
                </StyledWhatMakesPwdStrongBox>
                <StyledPasswordForm>
                    <StyledTextField label="Current Password" color="primary" fullWidth value={state.prevPassword || ""} onChange={(e) => {setState(prev => ({...prev, prevPassword: e.target.value }))}} error={errors[0]} helperText={errors[0] && "Please enter the correct password"}/>

                    <StyledTextField label="New Password" color="primary" fullWidth value={state.password || ""} onChange={(e) => {setState(prev => ({...prev, password: e.target.value }))}} error={false} helperText={false && "Password must meet minimum requirements"} type="password"/>

                    <StyledTextField label="Retype New Password" color="primary" fullWidth value={state.password1 || ""} onChange={(e) => {setState(prev => ({...prev, password1: e.target.value }))}} error={errors[1]} helperText={errors[1] && "Your new password dont match"} type="password"/>

                    <StyledInfoText variant="body2">
                        We will email you a confirmation when changing your password,  so please expect that email after submitting.
                    </StyledInfoText>

                    <StyledActionButtons>
                        <StyledButton onClick={e => { e.preventDefault(); updatePassword(e); }}    variant="contained" color="primary" disabled={loading1} type="submit">
                            {loading1 ? "Updating" : "Update Password"}
                        </StyledButton>

                        <StyledButton onClick={() => forgotPassword()} variant="text" color="primary" disabled={loading}>
                            Forgot your password?
                        </StyledButton>
                    </StyledActionButtons>
                </StyledPasswordForm>
            </ParentBox> 
        </React.Fragment>
    )
}


export default AccountSettings
