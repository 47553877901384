import * as React from 'react';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';

export default function BasicDateRangePicker({handleDate, startDateEndDate}) {
  const [value, setValue] = React.useState([null, null]); // This internal date state would only be used if component is used in uncontrolled mode.
  console.warn("value", value);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        startText="From-Date"
        endText="To-Date"
        value={!!startDateEndDate ? startDateEndDate : value} // Use internal state only if uncontrolled
        onChange={(newValue) => {
          if(!startDateEndDate) setValue(newValue); // Change internal state only if uncontrolled
          handleDate(newValue);
        }}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField {...startProps} />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField {...endProps} />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
}