import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import soonImg from "../../images/soon.svg";
import "./CountCard.scss";

const CountCard = ({ text, subtext, count, color, bannerEnable }) => {
  return (
    <Box
      className="interview-card-wrapper"
      display="flex"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column" alignSelf={'center'}>
        <Typography className="interview-status-label">{text}</Typography>
        <Typography className="interview-month">{subtext}</Typography>
      </Box>
      <Box>
        {!bannerEnable ? (
          <Typography className={`interview-count ${color}`}>
            {count}
          </Typography>
        ) : (
          <Box className="banner">
            <img src={soonImg} alt="soon Img" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CountCard;
