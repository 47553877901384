import axios from "./axios";
import { getFileURL } from "./file";

export const fetchProfile = async () => {
    try{
        const resp = await axios.get('/interviewer/details');
        let avatar = ''
        if(resp.data.profile_image && resp.data.profile_image.slice(0, 11) === 'bucket_name'){
            const res = await getFileURL(resp.data.profile_image)
            avatar = res
        } else {
            avatar = 'https://icon2.cleanpng.com/20180626/ehy/kisspng-avatar-user-computer-icons-software-developer-5b327cc951ae22.8377289615300354013346.jpg'
        }

        return {...resp.data, avatar: avatar}


    } catch(err){
        throw err;
    }
};

export const editProfile = async (profile) => {
    try{
        const resp = await axios.post('/interviewer/edit', profile);
        return resp.data[0]

    } catch(err){
        throw err;
    }
};

export const editBankDetails = async (profile) => {
    try{
        const resp = await axios.post('/interviewer/edit/bank-details', profile);
        return resp.data;

    } catch(err){
        throw err;
    }
}

// Function to get supported genders
export const getGendersList = async () => {
    try {
        const resp = await axios.get("/interviewer/gender-enum");
        return resp.data;
    } catch (err) {
        console.error("Error while fetching list of genders", err)
    }
}

