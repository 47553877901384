import React from 'react'

export const PageView = ({children, width}) => {
  return (
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className={`col-12 col-xl-${width}`} style={{transition: 'none'}}>
              {children}
            </div>
          </div>
        </div>
      </div>
  )
};

export const HeaderView = ({title, OwnComp}) => {
  return (
      <div className="header mt-md-4">
        <div className="header-body">
          <div className="">
            <div className="col">
              <div className="row align-items-center mb-0">
                <div className="col align-items-center col justify-content-between row ">
                  <h1 className="header-title tittle text-truncate mb-10">{title}</h1>
                  {OwnComp ? OwnComp:null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
};

export const DashboardView = ({children}) => {
  return (
      <div className="main-content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-12 col-xl-12">
              {children}
            </div>
          </div>
        </div>
      </div>
  )
};
