import React, { useState, useRef } from "react";
import Box from "@material-ui/core/Box";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import IconButton from "@material-ui/core/IconButton";
import cx from "classnames";
import Popup from 'reactjs-popup';
import { TextField, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

const moment = require("moment-timezone");

import addCommentReplySVG from "./../../../../assets/images/feedback/add-comment-reply.svg"
import markAsUnresolved from "./../../../../assets/images/feedback/mark-as-unresolved-sign.svg"
import markAsResolved from "./../../../../assets/images/feedback/mark-as-resolved-tick.svg"

import { CommentsResolveStatus } from "./../Constants/FeedbackConstants";


export const CommentsSideDrawer = ({ state, showDrawer, setDrawerVisibility, allComments, editCommentStatus, addCommentReply }) => {
    const user = state?.user
    const ref = useRef();
    const [reply, setReply] = useState("");
    const [showReply, setReplyVisibility] = useState([]);

    const renderAddReplySection = (parentCommentData) => {
        const closeTooltip = () => ref.current.close();

        const addSectionCommentReply = () => {
            if (reply !== "") {
                const payload = {
                    feedback_comment_id: parentCommentData?.id,
                    feedback_reply: reply,
                }
                addCommentReply(payload);
            }
            closeTooltip();
            setReply("")
        }

        return (
            <Popup ref={ref} trigger={open => (
                <div className="comments-button-background pointer">
                    <img src={addCommentReplySVG} className="add-comment-reply" />
                </div>)}
                position="bottom right"
            >
                <div className="p-15">
                    <Typography className="suggest-edit-dialog-title">Add Reply</Typography>
                    <TextField
                        key={`add-reply`}
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        placeholder="Type here.."
                        name="reply"
                        rows={2}
                        value={reply}
                        onChange={(e) => { setReply(e.target.value) }}
                        type="textarea"
                        multiline
                        rowsMax={5}
                        className="comment-popup-row-style"
                    />

                    <Box display='flex' justifyContent='end' className="comment-popup-row-style">
                        <button onClick={closeTooltip} className='white-bg-button'>
                            Cancel
                        </button>
                        <button onClick={addSectionCommentReply} className='blue-bg-button ml-10'>
                            Enter
                        </button>
                    </Box>
                </div>
            </Popup>
        )
    }

    const setRepliesVisibility = (index) => {
        const showReplyData = [...showReply];
        showReplyData[index] = !showReply[index];
        setReplyVisibility(showReplyData);
    }

    return (
        <div style={{ marginTop: "-20px" }} className={`side-drawer-feedback side-drawer-bg ${showDrawer && "open"}`} >

            <Box display="flex" flex="row" justifyContent="space-between" alignItems="center" className="side-drawer-heading" >
                All Comments
                <IconButton onClick={() => { setDrawerVisibility(false) }}><CloseOutlined /></IconButton>
            </Box>

            {allComments?.map((list, index) => {
                const date = moment.unix(list?.created_at).format("MMM DD, hh:mm a");;

                return (
                    <>
                        <Box display="flex" flexDirection="column" className={`padding-comment-box ${list?.status === CommentsResolveStatus.UNRESOLVED ? "unresolved-bg" : "resolved-bg"}`}>
                            <div className='side-drawer-date'> <b> <span style={{ color: "#3D3D3D" }}>{list?.section.replace("_", "-")} {" - "}</span> </b> {date}</div>
                            <div className="side-drawer-name">{list.created_by_name} <span className='side-drawer-profile'>{list?.created_by_profile ? `(${list?.created_by_profile.replace("_", " ")})` : ""}</span></div>
                            <div className="side-drawer-comments mt-10"> {list?.comment}  </div>
                            <Box display="flex" className="mt-10" justifyContent="space-between" alignContent="center">
                                <Box display="flex">
                                    <Tooltip title={list?.status === CommentsResolveStatus.UNRESOLVED ? "Mark as Resolved" : "Mark as Unresolved"}>
                                        <div className="comments-button-background mr-1 pointer" onClick={(e) => editCommentStatus(list?.id, list?.status)}>
                                            <img src={list?.status === CommentsResolveStatus.UNRESOLVED ? markAsResolved : markAsUnresolved} style={{ alignSelf: "center", width: "100%", height: "100%", padding: "5px" }} />
                                        </div>
                                    </Tooltip>
                                    {list?.status === CommentsResolveStatus.UNRESOLVED && renderAddReplySection(list)}
                                </Box>
                                {list?.feedback_thread?.length > 0 &&
                                    <div style={{ alignSelf: "center" }} className="side-drawer-show-hide-replies">
                                        <span className={cx('pointer', { 'mark-as-style-resolved': true })}
                                            onClick={(e) => setRepliesVisibility(index)}>
                                            {showReply[index] ? "Hide" : "Show"}{" Replies ("}{list?.feedback_thread?.length}{")"}
                                        </span>
                                    </div>
                                }
                            </Box>
                        </Box>
                        {showReply[index] && list?.feedback_thread?.map((replyList) => {
                            const date = moment.unix(replyList?.created_timestamp).format("MMM DD, hh:mm a");;
                            return (
                                <Box display="flex">
                                    <div className="vertical-reply-divider vertical-line-comment-margins" />
                                    <Box display="flex" flexDirection="column" className={`m-5-10 padding-comment-box comment-reply-bg w-100`}>
                                        <div className='side-drawer-date'>{date}</div>
                                        <div className="side-drawer-name">{replyList?.created_by_name} <span className='side-drawer-profile'>{replyList?.created_by_profile ? `(${replyList?.created_by_profile.replace("_", " ")})` : ""}</span></div>
                                        <div className="side-drawer-comments mt-10"> {replyList?.text}  </div>
                                    </Box>
                                </Box>
                            )
                        })}
                    </>
                );
            })}
        </div>
    );
};