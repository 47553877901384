import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import { StyledForm, ParentCard, StyledTextField, StyledButton } from "./styles"
import Box from '@mui/material/Box';
import { uploadFileV2 } from '../../../services/file';
import { isAlpha, isAlphanumeric } from "validator";
import cogoToast from 'cogo-toast'
import FileUpload from './fileUpload';
import './fileUpload.scss';

const BankDetails = ({profile, setProfile, loading, saveBankDetails, setPageName}) => {

    const fetchedProfile = useSelector((state) => state && state.profile);  
    const [errors, setErrors] = useState(Array(5).fill(false));
    const [files, setFiles]= useState({
        panCardFile: "",
        gstCertificateFile: ""
    })
    const {panCardFile, gstCertificateFile } = files;

    useEffect(()=>{
        if(profile?.pan_card)
            setFiles((prev)=> ({...prev, panCardFile : profile.pan_card}));
           
        if(profile?.gst_certificate)
            setFiles((prev)=> ({...prev, gstCertificateFile : profile.gst_certificate}));
            
    },[profile.pan_card, profile.gst_certificate])

    const validate = async (e) => {
        e.preventDefault();
        const new_errors = Array(5).fill(false);
        if(profile?.account_holder_name === '' || !isAlpha(profile?.account_holder_name,['en-US'],{ignore: '\s'})) new_errors[0] = true;
        if(profile?.bank_name === '' || !isAlpha(profile?.bank_name,['en-US'],{ignore: '\s'})) new_errors[1] = true;
        if(profile?.account_number === '' || isNaN(profile.account_number)) new_errors[2] = true;
        if(profile?.ifsc === '' || !isAlphanumeric(profile?.ifsc) || profile?.ifsc?.length !== 11) new_errors[3] = true;
        if(profile?.pan_number === '' || !isAlphanumeric(profile?.pan_number) || profile?.pan_number?.length !== 10) new_errors[4] = true;
        if(!panCardFile || panCardFile?.length === 0 ) new_errors[5] = true;
        if(profile?.upi && !profile?.upi.includes('@')) new_errors[6] = true;
        if(profile?.gst_number && (!isAlphanumeric(profile?.gst_number) || profile?.gst_number?.length !== 15)) new_errors[7] = true;

        setErrors(new_errors);

        if(new_errors.includes(true)) return;

        else {
            let pan_card_link;
            let newProfile = profile;
            if(typeof panCardFile !== "string" && panCardFile?.length > 0){
                try {
                    let response = await uploadFileV2(panCardFile[0])
                    pan_card_link = (response.data.fields['x-amz-meta-internal-key']) 
                    setProfile({...newProfile, pan_card: pan_card_link})
                    newProfile = {...newProfile, pan_card: pan_card_link || newProfile.pan_card}
                } catch(error){
                    cogoToast.error(error.message || error?.toString());
                }
            }
         
            if(typeof gstCertificateFile !== "string" && gstCertificateFile?.length > 0){
                try{
                    let response2 = await uploadFileV2(gstCertificateFile[0])
                    const gst_certificate_link = (response2.data.fields['x-amz-meta-internal-key'])
                    setProfile({...newProfile, gst_certificate: gst_certificate_link})
                    newProfile = {...newProfile, gst_certificate: gst_certificate_link || newProfile.gst_certificate, 
                        pan_card: pan_card_link || newProfile.pan_card}
                } catch(error){
                    cogoToast.error(error.message || error?.toString());
                } 
            } 
            else if(gstCertificateFile === "") {
                newProfile = {...newProfile, gst_certificate: ""}
            }

            await saveBankDetails(newProfile);
        }
    }

    const checkFilledData = () => {
        if(profile?.account_holder_name || profile?.bank_name || profile?.account_number || profile?.ifsc ||
           profile?.pan_number || profile?.pan_card || profile?.upi || profile?.gst_number || profile?.gst_certificate){
            return true;
        }
        return false;
    }
    
    const checkFetchedData = () => {
       if(fetchedProfile?.account_holder_name && fetchedProfile?.bank_name && fetchedProfile?.account_number && fetchedProfile?.ifsc && 
          fetchedProfile?.pan_number && fetchedProfile?.pan_card && fetchedProfile?.upi && fetchedProfile?.gst_number && fetchedProfile?.gst_certificate ){
              return true;
          }
        return false;
    }


    // Sets page title
    useEffect(() => {
        setPageName("Bank Details")
    }, [setPageName])
    
    return (           
        <StyledForm onSubmit={(e) => validate(e)}>

            {/* Bank details */}
          <Box className='bank-details'>
            <Box display="flex" gap="12px" marginBottom="10px">
                <Box className='info-container'>Note:</Box>
                { !checkFilledData() ?
                    <Box><Box>Once the below details are filled and submitted, you wont be able to update the details directly.</Box>
                    <Box>To update the details please reach out to <a href='mailto:ivcare@interviewvector.com' target="_blank" className='td-uline'>ivcare@interviewvector.com</a></Box>
                    </Box>
                : <Box>If you want to edit the already filled data please reach out to <a href='mailto:ivcare@interviewvector.com' target="_blank" className='td-uline'>
                    ivcare@interviewvector.com</a></Box> }
            </Box>

            <ParentCard>
                 {/* Account holder name */}
                 <StyledTextField 
                    value={profile.account_holder_name || ""} 
                    onChange={(e) => setProfile(prev => ({...prev, account_holder_name: e.target.value}))}
                    label='Account Holder Name' 
                    error={errors[0]}
                    helperText={errors[0] && "Please enter a valid account holder name"}
                    placeholder='Account Holder Name'
                    disabled={fetchedProfile?.account_holder_name}
                    fullWidth
                    required
                />

                {/* Bank name */}
                <StyledTextField 
                    value={profile.bank_name || ""} 
                    onChange={(e) => setProfile(prev => ({...prev, bank_name: e.target.value}))}
                    label='Bank Name'  
                    error={errors[1]}
                    helperText={errors[1] && "Please enter a valid bank name"}
                    placeholder='Bank Name'
                    disabled={fetchedProfile?.bank_name}
                    fullWidth
                    required
                />

                {/* Account number */}
                <StyledTextField 
                    value={profile.account_number || ""} 
                    onChange={(e) => setProfile(prev => ({...prev, account_number: e.target.value}))}
                    label='Account Number'  
                    error={errors[2]}
                    helperText={errors[2] && "Please enter a valid account number"}
                    placeholder='Account Number'
                    disabled={fetchedProfile?.account_number}
                    fullWidth
                    required
                />

                {/* IFSC code */}
                <StyledTextField 
                    value={profile.ifsc || ""}
                    onChange={(e) => setProfile(prev => ({...prev, ifsc: e.target.value}))}
                    label='IFSC Code'  
                    error={errors[3]}
                    helperText={errors[3] && "Please enter a valid IFSC code"}
                    placeholder='IFSC Code'
                    disabled={fetchedProfile?.ifsc}
                    fullWidth
                    required
                />

                {/* PAN details */}
                <StyledTextField 
                    value={profile.pan_number || ""}
                    onChange={(e) => setProfile(prev => ({...prev, pan_number: e.target.value}))}
                    label='PAN Number'  
                    error={errors[4]}
                    helperText={errors[4] && "Please enter a valid PAN number"}
                    placeholder='PAN Number'
                    disabled={fetchedProfile?.pan_number}
                    fullWidth
                    required
                />
                <FileUpload 
                    btnText="Upload PAN Card Image" 
                    onFileChange={(file)=>{
                        setFiles((prev)=> ({...prev, panCardFile: file}))
                    }} 
                    name="panCardFile"
                    type={{ "image/png" : [".png",".jpg"], "application/pdf": [".pdf"] }} 
                    isMulti={false} 
                    preSavedFile={panCardFile}
                    labelText= "PAN Card Image"
                    isDisabled={fetchedProfile?.pan_card}
                />
                {errors[5] &&  <span className='error-msg'>Please upload a PAN card</span>}

            </ParentCard>
          </Box>

            {/* UPI and GST details */}
            <ParentCard>
                {/* VPA (UPI Id)*/}
                <StyledTextField 
                    value={profile.upi || ""}
                    onChange={(e) => setProfile(prev => ({...prev, upi: e.target.value}))}
                    label='UPI'
                    error={errors[6]}
                    helperText={errors[6] && "Please enter a valid UPI Id"}
                    placeholder='UPI'
                    disabled={fetchedProfile?.upi}
                    fullWidth
                />
 
                {/* GST number*/}
                <StyledTextField 
                    value={profile.gst_number || ""}
                    onChange={(e) => setProfile(prev => ({...prev, gst_number: e.target.value}))}
                    label='GST Number'
                    error={errors[7]}
                    helperText={errors[7] && "Please enter a valid GST number"}
                    placeholder='GST Number'
                    disabled={fetchedProfile?.gst_number}
                    fullWidth
                />
                <FileUpload 
                    btnText="Upload GST Certificate" 
                    onFileChange={(file)=>{
                        setFiles((prev)=> ({...prev, gstCertificateFile: file}))
                    }} 
                    name="gstCertificateFile" 
                    type={{ "image/png" : [".png",".jpg"], "application/pdf": [".pdf"] }} 
                    isMulti={false}
                    preSavedFile={gstCertificateFile}
                    labelText= "GST Certificate"
                    isDisabled={fetchedProfile?.gst_certificate}
                />
            </ParentCard>

            {/* Submit button */}
            {!checkFetchedData() && <StyledButton disabled={loading || !checkFilledData()} type="submit" variant="contained" color="primary">
                Submit
            </StyledButton>}

        </StyledForm>
    )
}

export default BankDetails