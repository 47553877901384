import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import CustomizedDialogs from "./ViewQuestion";
import { QuestionCard } from "./QuestionCard";
import { CommentStatus, GuideCommentIconClass, GuidelineIntroductionType, GuideSectionCssClass, StagesType, TechnicalType, TheoreticalType } from "../Constants";
import "./GuidePreview.scss";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ResultClarity from "../Components/ResultClarity";
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import logoiv from "../../../../assets/images/logo-iv.png";
import copy from "../../../../assets/images/copy.svg";
import Arrow from "../../../../assets/images/Arrow.svg";
import redirectLink from "../../../../assets/images/redirect-link.svg";
import { Comments } from "../Components/Comments/Comments";
import { RightCommentBox } from "./CommentBox";
import SwtAlert from "../../../../Utils/SwtAlert";
import { DashboardType } from "../../../../constants";
const GuidePreview = ({ dataPreview, previewShareLinkData, copyLinkVisibility = false,  getGuideShareableLinkData,guideCommentAPIS,guideCommentData,jobDescriptionLink }) => {
  const { search } = useLocation();
  const parsedQueryString = queryString.parse(search);
  const guide_id = parsedQueryString?.guide_id;
  const [guideData, setGuideData] = useState(dataPreview);
  const [technicalQuestionTopic, setTechnicalQuestionTopics] = useState([]);
  const [theoreticalQuestionTopics, setTheoreticalQuestionTopics] = useState([]);
  const [dataPreviewList, setDataPreviewList] = useState([]);
  const [filterEvaluationObject, setFilterEvaluationObject] = useState({});
  const [shareLinkCopied, setShareLinkCopied] = useState({ open: false, vertical: 'top', horizontal: 'center'});
  const localStorageData = JSON.parse(localStorage.getItem("guide_commenter_details"));
  const [commentData,setCommentData] = useState({
    name:guideCommentData?.name && guideCommentData?.name?.length>0?guideCommentData?.name:localStorageData?.name?localStorageData?.name:"",
    email:guideCommentData?.email && guideCommentData?.email?.length>0?guideCommentData?.email:localStorageData?.email?localStorageData?.email:"",
    guide_id:guide_id,
    data:{},
    isError:{
      name:null,
      email:null,
    },
    dashboardType:guideCommentData?.dashboardType,
  })
  const [guideSectionCssEffect,setGuideSectionCssEffect] = useState({});
  const [showNewCommentBox,setShowNewCommentBox] = useState({
    status:true
  });
  const [isProfileDialogOpen,setIsProfileDialogOpen] = useState(false);
  const { vertical, horizontal, open } = shareLinkCopied;
  let importantGenericInstructions = [];
  let normalGenericInstructions = [];
  const {getAllGuideCommentsAPI} = guideCommentAPIS;
  const [technicalArray, setTechnicalArray] = useState([]);
  const [theoreticalArray, setTheoreticalArray] = useState([]);

  let questionIndex = 0;
  const getGuidePreviewData = async () => {
    setGuideData(dataPreview);
    setDataPreviewList(dataPreview?.stages || []);
    setTechnicalArray(dataPreview?.stages ?  (dataPreview?.stages[1]?.sections[0]?.values?.question_topic || []) : [])
    setTheoreticalArray(dataPreview?.stages ?  (dataPreview?.stages[2]?.sections[0]?.values?.question_topic || []) : [])
    const guidelinesIntroduction = dataPreview?.stages?.find(stage => stage?.type === StagesType.GUIDELINES_INTRODUCTION) || []
    const skillEvaluation = guidelinesIntroduction?.sections?.find(section => section?.type === GuidelineIntroductionType.SKILL_EVALUATION)
    filterEvaluation(skillEvaluation);
  };

  const structureCssEffect = (type) => {
    const cssEffect = {
      GUIDELINES_INTRODUCTION : {},
      TECHNICAL : {},
      THEORETICAL : {},
    };
    for(let key in GuidelineIntroductionType)
    {
        cssEffect.GUIDELINES_INTRODUCTION[GuidelineIntroductionType[key]] = GuideSectionCssClass?.INITIAL;
    }

    for(let key in TechnicalType)
    {
      cssEffect.TECHNICAL[TechnicalType[key]] = GuideSectionCssClass?.INITIAL;
    }

    for(let key in TheoreticalType)
    {
      cssEffect.THEORETICAL[TheoreticalType[key]] = GuideSectionCssClass?.INITIAL;
    } 
    if(type!=='reset')
    {
      setGuideSectionCssEffect(cssEffect);
    }
    return cssEffect;
  }

  const structureData = (data) => {
        const tempObj = {
          GUIDELINES_INTRODUCTION : {},
          TECHNICAL : {},
          THEORETICAL : {},
        };

        for(let key in GuidelineIntroductionType)
        {
          const tempArr = data?.filter(item=>item?.section===GuidelineIntroductionType[key]) || [];
          tempObj.GUIDELINES_INTRODUCTION[GuidelineIntroductionType[key]] = tempArr;
        }
        for(let key in TechnicalType)
        {
          const tempArr = data?.filter(item=>item?.section===TechnicalType[key]) || [];
          tempObj.TECHNICAL[TechnicalType[key]] = tempArr;
        }
        for(let key in TheoreticalType)
        {
          const tempArr = data?.filter(item=>item?.section===TheoreticalType[key]) || [];
          tempObj.THEORETICAL[TheoreticalType[key]] = tempArr;
        }
        commentData.data = tempObj;
        setCommentData({...commentData});
  }
  const handleDefaultCss = (section,subSection) => {
    guideSectionCssEffect[section][subSection] = GuideSectionCssClass?.INITIAL;
      setGuideSectionCssEffect({...guideSectionCssEffect}); 
  }
  const handleMouseEnter = (section,subSection,length) => {
    const prevCss =  GuideSectionCssClass?.INITIAL+" "+GuideSectionCssClass.PREV_COMMENT_CLICK;
    if(guideSectionCssEffect[section][subSection]!== prevCss)
    {
      let cssName = GuideSectionCssClass?.INITIAL+" ";
        if(length>0)
        {
          cssName += GuideSectionCssClass?.PREV_COMMENT_HOVER;
        }
        else
          cssName += GuideSectionCssClass?.NEW_COMMENT_HOVER;
        guideSectionCssEffect[section][subSection] = cssName;
        setGuideSectionCssEffect({...guideSectionCssEffect});  
    }
  }
  const handleMouseLeave = (section,subSection) => {
    const prevCss =  GuideSectionCssClass?.INITIAL+" "+GuideSectionCssClass.PREV_COMMENT_CLICK;
    if( guideSectionCssEffect[section][subSection]!== prevCss)
    {
      handleDefaultCss(section,subSection); 
    }
  }
  
  const handleMouseClick = (length,section,subSection) => {
    const prevCss =  GuideSectionCssClass?.INITIAL+" "+GuideSectionCssClass.PREV_COMMENT_CLICK;
    if(guideSectionCssEffect[section][subSection] === prevCss)
    {
      handleDefaultCss(section,subSection); 
      return;
    }
    if(!commentData?.name || commentData?.name?.length===0 || !commentData?.email || commentData?.email?.length===0)
    {
      setIsProfileDialogOpen(true);
    }
    if(length===0)
    {
      showNewCommentBox.status = true;
      
    }
    else
    {
      showNewCommentBox.status = false;
    }
    setShowNewCommentBox({...showNewCommentBox});
    const resetObj = structureCssEffect("reset");
    let cssName = GuideSectionCssClass?.INITIAL+" ";
    cssName += GuideSectionCssClass?.PREV_COMMENT_CLICK;
    resetObj[section][subSection] = cssName;
    setGuideSectionCssEffect(resetObj);  
  }
 
  const getCssClass= (length) =>{
    if(!length || length===0)
      return GuideCommentIconClass?.NEW_COMMENT
    return GuideCommentIconClass?.PREV_COMMENT;
  }
  const getAllGuideComments = () => {
    const token = window?.sessionStorage.getItem('temporary_access_token');
    if(!guide_id || guide_id?.length === 0)
    {
      SwtAlert("There is an error while fetching guide details since guide id is not present",2500,'error',false);
      return;
    }
    const payload = {
      guide_id: guide_id,
    }
    getAllGuideCommentsAPI(payload,token).then(({data})=>{
        if(data)
        {
          structureData(data?.data);
        }
    }).catch(error=>{
        SwtAlert(error?.msg || error?.data || error?.toString(),2000,'error',false);
    })
}

    function handleTechnicalQuestionTopic(technicalArray)  {
      if(Array.isArray(technicalArray) === false){
          let arr = [];
          Object.keys(technicalArray)?.map((data) => {
              arr.push({
                  key : data,
                  value : technicalArray[data],
              })
          })
          setTechnicalQuestionTopics(arr);
      }else{
        setTechnicalQuestionTopics(technicalArray);
      }
    }

    function handleTheoreticalQuestionTopic(theoreticalArray)  {
      if(Array.isArray(theoreticalArray) === false){
          let arr = [];
          Object.keys(theoreticalArray)?.map((data) => {
              arr.push({
                  key : data,
                  value : theoreticalArray[data],
              })
          })
          setTheoreticalQuestionTopics(arr);
      }else{
        setTheoreticalQuestionTopics(theoreticalArray);
      }
    }

  useEffect(() => {
    if (guide_id) getGuidePreviewData();
  }, [dataPreview]);

  useEffect(() => {
    handleTechnicalQuestionTopic(technicalArray);
  },[technicalArray]);

  useEffect(() => {
    handleTheoreticalQuestionTopic(theoreticalArray);
  },[theoreticalArray]);

  useEffect(()=>{
    structureCssEffect();
    getAllGuideComments();
  },[]);

  const filterEvaluation = (mainData) => {
    if (mainData?.values) {
      let obj = {
        high: [],
        medium: [],
        low: [],
      };
      mainData?.values?.forEach((data) => {
        if (data?.value?.toLowerCase() === "high" && data?.selected) {
          obj.high.push(data);
        } else if (data?.value?.toLowerCase() === "medium" && data?.selected) {
          obj.medium.push(data);
        } else if (data?.value?.toLowerCase() === "low" && data?.selected) {
          obj.low.push(data);
        }
      });
      setFilterEvaluationObject(obj);
    }
  };
   
  const handleShareableLink = () => {
    if(previewShareLinkData?.length === 0){
       getGuideShareableLinkData().then((res)=>{
        navigator.clipboard.writeText(res?.data?.client_access_url).then(()=>{      
          setShareLinkCopied({ open: true,  vertical: 'top', horizontal: 'center'});
        })
       })
    }

    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(previewShareLinkData).then(()=>{      
      setShareLinkCopied({ open: true,  vertical: 'top', horizontal: 'center'});
    })

  }

  const totalQuestionasked = (data) => {
      let count = data?.easy + data?.medium + data?.hard;
      return count;
  }

  const topicList = (data) => {

    let tagList = data;
  
    return(
      <div>
        {tagList?.map((data) => {
          return(
            <div className="topicList-Container">
              <div><p className="questionTopicList">{data?.key}</p></div>
              <div style={{marginLeft: 5, marginRight: 5}}><img src={Arrow}/></div>
              <div><p className="questionAskText">Ask <span style={{fontWeight: 600}}>{data?.value}</span>{data?.[1] != 1 ? " questions" : " question"}</p></div>
            
            </div>
          )
        })}
      </div> 
    )
  }


const renderTheoreticalRow = (questionIndex, data, index) => {
  return (
    <>
      <Box display='flex' flexDirection='column' padding={'5px'}>
        <Box display='flex' flexDirection='row' >
          <Box display='flex' className="font-12 fg-black bold-600 width-25">
            Q{questionIndex}.{" "}
          </Box>
          <Box display='flex' className="font-13 bold-500 fg-black white-space pmlr-5">
            {data?.question?.statement}&nbsp;
          </Box>
        </Box>

        <Box display='flex' >
          {data?.difficulty_level && <>
            <span className={`difficulty ${data?.difficulty_level?.toLowerCase() === 'easy' ? 'fg-green' : data?.difficulty_level?.toLowerCase() === 'medium' ? 'fg-gold' : 'fg-red'}`}>{data?.difficulty_level?.toUpperCase()}</span>
            <a className={`bold-400 fg-black td-none pmr-5`}> &#8226; </a>
          </>}
          <CustomizedDialogs
            index={questionIndex}
            mainData={data}
            section={"Theoretical Question"}
          />
        </Box>
      </Box>
    </>
  )
}

  if (dataPreviewList?.length === 0) {
    return <></>;
  }

  return (
    <div className="guide_preview_box">
      <Box display="flex" justifyContent="space-between" className="guide_preview_topBar">
        <img src={logoiv} className="iv-logo"/>
        <div className="navbar-text">INTERVIEW GUIDE</div>
       { copyLinkVisibility ? <div className="share-link" onClick={handleShareableLink}>
          <img src={copy} height="20px" width="20px" /> <p>Copy Link</p>
        </div> : <div></div>}
        {/* ----- Link copied popup ----*/}
       { shareLinkCopied?.open && <Snackbar  anchorOrigin={{ vertical, horizontal }} autoHideDuration={1000}
           open={open} onClose={()=>{setShareLinkCopied({ ...shareLinkCopied, open: false });}} > 
          <SnackbarContent style={{ backgroundColor:'green', marginTop:"30px", fontWeight:'600'}}
              message={<span id="client-snackbar">✅ Link copied to clipboard</span>}/>
        </Snackbar> }

      </Box>
      <div className="guide_preview_main_container">
        <div className="guide_preview_container">
          <div>
            <Box display="flex" gap="15px">
            <p className="guide_preview_role">{guideData?.title}</p>
            { (jobDescriptionLink && Object?.keys(jobDescriptionLink)?.length !== 0) && 
              <Box className="jd-link" onClick={()=> window.open(jobDescriptionLink,"_blank")} >See JD <img src={redirectLink} width="12px"/></Box>}
            </Box>
            <p className="guide_preview_round_name">
              {guideData?.role} <a>- {guideData?.round_category}</a>
            </p>
          </div>
          <hr />
          <div className="guide_preview_details">
            <h2>{dataPreviewList[0]?.name ? dataPreviewList[0]?.name : ""}</h2>
            {commentData?.name &&
            commentData?.email &&
            guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
              dataPreviewList[0]?.sections[0]?.type
            ] === "main-box dark-yellow-box" ? (
              <RightCommentBox
                data={commentData}
                setData={setCommentData}
                comments={
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[0]?.type
                  ]
                }
                guideCommentAPIS={guideCommentAPIS}
                type={dataPreviewList[0]?.type}
                subType={dataPreviewList[0]?.sections[0]?.type}
                showNewCommentBox={showNewCommentBox}
                setShowNewCommentBox={setShowNewCommentBox}
              />
            ) : (
              ""
            )}
            <Box
              onMouseEnter={() =>
                handleMouseEnter(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[0]?.type,
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[0]?.type
                  ]?.length
                )
              }
              onMouseLeave={() =>
                handleMouseLeave(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[0]?.type
                )
              }
              onClick={() =>
                handleMouseClick(
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[0]?.type
                  ]?.length,
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[0]?.type
                )
              }
              className={
                guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
                  dataPreviewList[0]?.sections[0]?.type
                ]
              }
            >
            <Box>
            <p className="guide_preview_heading">1. GENERIC INSTRUCTIONS </p>
            <ul className="guide_preview_box_1">
            { 
              dataPreviewList[0]?.sections[0]?.default?.map((data) => { 
                data?.selected && (data?.is_important ? importantGenericInstructions.push(data?.label) : normalGenericInstructions.push(data?.label)) })
            }
            { dataPreviewList[0]?.sections[0]?.extras?.map((data) => {
              data?.selected && (data?.is_important ? importantGenericInstructions.push(data?.label) : normalGenericInstructions.push(data?.label)) })            
            }
            { importantGenericInstructions.map((item)=> {
                return <li className = 'imp-points'>{item}</li>
              })
            }
            { normalGenericInstructions.map((item)=> {
                return <li>{item}</li>
              })
            }
            </ul>
            </Box>
            <Box className="comment">
            <Comments
                  isDialogOpen={isProfileDialogOpen}
                  setIsDialogOpen={setIsProfileDialogOpen}
                  handleDefaultCss={() =>
                    handleDefaultCss(
                      dataPreviewList[0]?.type,
                      dataPreviewList[0]?.sections[0]?.type
                    )
                  }
                  data={commentData}
                  setData={setCommentData}
                  showNewCommentBox={showNewCommentBox}
                  setShowNewCommentBox={setShowNewCommentBox}
                  getCssClass={() =>
                    getCssClass(
                      commentData?.data?.[dataPreviewList[0]?.type]?.[
                        dataPreviewList[0]?.sections[0]?.type
                      ]?.length
                    )
                  }
                  isRightBoxOpen={
                    guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
                      dataPreviewList[0]?.sections[0]?.type
                    ] === "main-box dark-yellow-box"
                  }
                />
            </Box>
            </Box>
           
            {commentData?.name &&
            commentData?.email &&
            guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
              dataPreviewList[0]?.sections[1]?.type
            ] === "main-box dark-yellow-box" ? (
              <RightCommentBox
                data={commentData}
                setData={setCommentData}
                comments={
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[1]?.type
                  ]
                }
                guideCommentAPIS={guideCommentAPIS}
                type={dataPreviewList[0]?.type}
                subType={dataPreviewList[0]?.sections[1]?.type}
                showNewCommentBox={showNewCommentBox}
                setShowNewCommentBox={setShowNewCommentBox}
              />
            ) : (
              ""
            )}
            <Box
              onMouseEnter={() =>
                handleMouseEnter(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[1]?.type,
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[1]?.type
                  ]?.length
                )
              }
              onMouseLeave={() =>
                handleMouseLeave(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[1]?.type
                )
              }
              onClick={() =>
                handleMouseClick(
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[1]?.type
                  ]?.length,
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[1]?.type
                )
              }
              className={
                guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
                  dataPreviewList[0]?.sections[1]?.type
                ]
              }
            >
            <Box>
              <p className="guide_preview_heading">2. RESULT CLARITY</p>
            
            <div className="guide_preview_content_box  mb-20p pml-20">
             <ResultClarity ratingsList={dataPreviewList[0]?.sections[1]?.values}/>
          </div>
          </Box>
          <Box className="comment">
          <Comments
                  isDialogOpen={isProfileDialogOpen}
                  setIsDialogOpen={setIsProfileDialogOpen}
                  handleDefaultCss={() =>
                    handleDefaultCss(
                      dataPreviewList[0]?.type,
                      dataPreviewList[0]?.sections[1]?.type
                    )
                  }
                  data={commentData}
                  setData={setCommentData}
                  showNewCommentBox={showNewCommentBox}
                  setShowNewCommentBox={setShowNewCommentBox}
                  getCssClass={() =>
                    getCssClass(
                      commentData?.data?.[dataPreviewList[0]?.type]?.[
                        dataPreviewList[0]?.sections[1]?.type
                      ]?.length
                    )
                  }
                  isRightBoxOpen={
                    guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
                      dataPreviewList[0]?.sections[1]?.type
                    ] === "main-box dark-yellow-box"
                  }
                />
          </Box>
</Box>
    

    {commentData?.name &&
            commentData?.email &&
            guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
              dataPreviewList[0]?.sections[2]?.type
            ] === "main-box dark-yellow-box" ? (
              <RightCommentBox
                data={commentData}
                setData={setCommentData}
                comments={
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[2]?.type
                  ]
                }
                guideCommentAPIS={guideCommentAPIS}
                type={dataPreviewList[0]?.type}
                subType={dataPreviewList[0]?.sections[2]?.type}
                showNewCommentBox={showNewCommentBox}
                setShowNewCommentBox={setShowNewCommentBox}
              />
            ) : (
              ""
            )}
            <Box
              onMouseEnter={() =>
                handleMouseEnter(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[2]?.type,
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[2]?.type
                  ]?.length
                )
              }
              onMouseLeave={() =>
                handleMouseLeave(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[2]?.type
                )
              }
              onClick={() =>
                handleMouseClick(
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[2]?.type
                  ]?.length,
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[2]?.type
                )
              }
              className={
                guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
                  dataPreviewList[0]?.sections[2]?.type
                ]
              }
            >
             <Box>
            <p className="guide_preview_heading">3. INTERVIEW SECTIONS</p>
            <ul className="guide_preview_box_1">
              {dataPreviewList[0]?.sections[2]?.values?.map((data) => {
                return (
                  <>
                  {data?.time?.value > 0 && <li>
                    {data?.label}
                    <a className="bold-600">&nbsp;({data?.time?.value}  {data?.time?.unit})</a>
                  </li>}
                  </>
                );
              })}
            </ul>
            </Box>
            <Box className="comment">
            <Comments
                  isDialogOpen={isProfileDialogOpen}
                  setIsDialogOpen={setIsProfileDialogOpen}
                  handleDefaultCss={() =>
                    handleDefaultCss(
                      dataPreviewList[0]?.type,
                      dataPreviewList[0]?.sections[2]?.type
                    )
                  }
                  data={commentData}
                  setData={setCommentData}
                  showNewCommentBox={showNewCommentBox}
                  setShowNewCommentBox={setShowNewCommentBox}
                  getCssClass={() =>
                    getCssClass(
                      commentData?.data?.[dataPreviewList[0]?.type]?.[
                        dataPreviewList[0]?.sections[2]?.type
                      ]?.length
                    )
                  }
                  isRightBoxOpen={
                    guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
                      dataPreviewList[0]?.sections[2]?.type
                    ] === "main-box dark-yellow-box"
                  }
                />
            </Box>
      </Box>     
     

      {commentData?.name &&
            commentData?.email &&
            guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
              dataPreviewList[0]?.sections[3]?.type
            ] === "main-box dark-yellow-box" ? (
              <RightCommentBox
                data={commentData}
                setData={setCommentData}
                comments={
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[3]?.type
                  ]
                }
                guideCommentAPIS={guideCommentAPIS}
                type={dataPreviewList[0]?.type}
                subType={dataPreviewList[0]?.sections[3]?.type}
                showNewCommentBox={showNewCommentBox}
                setShowNewCommentBox={setShowNewCommentBox}
              />
            ) : (
              ""
            )}
            <Box
              onMouseEnter={() =>
                handleMouseEnter(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[3]?.type,
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[3]?.type
                  ]?.length
                )
              }
              onMouseLeave={() =>
                handleMouseLeave(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[3]?.type
                )
              }
              onClick={() =>
                handleMouseClick(
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[3]?.type
                  ]?.length,
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[3]?.type
                )
              }
              className={
                guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
                  dataPreviewList[0]?.sections[3]?.type
                ]
              }
            >
             <Box>
            <p className="guide_preview_heading">
              4. EVALUATION PARAMETERS FOR CANDIDATE PERFORMANCE
            </p>
            <div className="guide_preview_content_box guide_preview_box_2 mb-20p">
              
              {filterEvaluationObject?.high?.length === 0 && filterEvaluationObject?.medium?.length === 0 && filterEvaluationObject?.low?.length === 0 && 
              <div className="no-eval-param"> No evaluation parameters added</div>}
              
              {filterEvaluationObject?.high &&
                filterEvaluationObject?.high?.length > 0 ? (
                <div>
                  <span className="guide_preview_result fg-blue"> High </span>
                  <div className="guide_preview_arrow guide_preview_arrow_5"></div>
                  <div className="guide_preview_arrow_head_right"></div>
                  {filterEvaluationObject?.high.map((data) => {
                    return (
                      <div className="guide_preview_chip bg-blue">
                        {data.label}
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}

              {filterEvaluationObject?.medium &&
                filterEvaluationObject?.medium?.length > 0 ? (
                <div>
                  <span className="guide_preview_result fg-lightblue2">Medium</span>
                  <div className="guide_preview_arrow guide_preview_arrow_6"></div>
                  <div className="guide_preview_arrow_head_right"></div>
                  {filterEvaluationObject?.medium.map((data) => {
                    return (
                      <div className="guide_preview_chip bg-lightblue fg-black">
                        {data.label}
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
              {filterEvaluationObject?.low &&
                filterEvaluationObject?.low?.length > 0 ? (
                <div>
                  <span className="guide_preview_result fg-gray2"> Low </span>
                  <div className="guide_preview_arrow guide_preview_arrow_7"></div>
                  <div className="guide_preview_arrow_head_right"></div>
                  {filterEvaluationObject?.low &&
                    filterEvaluationObject?.low.map((data) => {
                      return (
                        <div className="guide_preview_chip bg-gray fg-lightblack bold-400">
                          {data.label}
                        </div>
                      );
                    })}
                </div>
              ) : (
                ""
              )}
            </div>
            </Box>
            <Box className="comment">
            <Comments
                  isDialogOpen={isProfileDialogOpen}
                  setIsDialogOpen={setIsProfileDialogOpen}
                  handleDefaultCss={() =>
                    handleDefaultCss(
                      dataPreviewList[0]?.type,
                      dataPreviewList[0]?.sections[3]?.type
                    )
                  }
                  data={commentData}
                  setData={setCommentData}
                  showNewCommentBox={showNewCommentBox}
                  setShowNewCommentBox={setShowNewCommentBox}
                  getCssClass={() =>
                    getCssClass(
                      commentData?.data?.[dataPreviewList[0]?.type]?.[
                        dataPreviewList[0]?.sections[3]?.type
                      ]?.length
                    )
                  }
                  isRightBoxOpen={
                    guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
                      dataPreviewList[0]?.sections[3]?.type
                    ] === "main-box dark-yellow-box"
                  }
                />
            </Box>
            </Box>
           

            {commentData?.name &&
            commentData?.email &&
            guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
              dataPreviewList[0]?.sections[4]?.type
            ] === "main-box dark-yellow-box" ? (
              <RightCommentBox
                data={commentData}
                setData={setCommentData}
                comments={
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[4]?.type
                  ]
                }
                guideCommentAPIS={guideCommentAPIS}
                type={dataPreviewList[0]?.type}
                subType={dataPreviewList[0]?.sections[4]?.type}
                showNewCommentBox={showNewCommentBox}
                setShowNewCommentBox={setShowNewCommentBox}
              />
            ) : (
              ""
            )}
            <Box
              onMouseEnter={() =>
                handleMouseEnter(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[4]?.type,
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[4]?.type
                  ]?.length
                )
              }
              onMouseLeave={() =>
                handleMouseLeave(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[4]?.type
                )
              }
              onClick={() =>
                handleMouseClick(
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[4]?.type
                  ]?.length,
                  [dataPreviewList[0]?.type],
                  dataPreviewList[0]?.sections[4]?.type
                )
              }
              className={
                guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
                  dataPreviewList[0]?.sections[4]?.type
                ]
              }
            >
             <Box>
            <p className="guide_preview_heading">5. RESUME DISCUSSION</p>
            <ul className="guide_preview_box_1">
              {dataPreviewList[0]?.sections[4]?.default?.map((data) => {
                return data?.selected ? <li>{data?.label}</li> : "";
              })}
              {dataPreviewList[0]?.sections[4]?.extras.map((data) => {
                return data?.selected ? <li>{data?.label}</li> : "";
              })}
            </ul>
            </Box>
            <Box className="comment">
            <Comments
                  isDialogOpen={isProfileDialogOpen}
                  setIsDialogOpen={setIsProfileDialogOpen}
                  handleDefaultCss={() =>
                    handleDefaultCss(
                      dataPreviewList[0]?.type,
                      dataPreviewList[0]?.sections[4]?.type
                    )
                  }
                  data={commentData}
                  setData={setCommentData}
                  showNewCommentBox={showNewCommentBox}
                  setShowNewCommentBox={setShowNewCommentBox}
                  getCssClass={() =>
                    getCssClass(
                      commentData?.data?.[dataPreviewList[0]?.type]?.[
                        dataPreviewList[0]?.sections[4]?.type
                      ]?.length
                    )
                  }
                  isRightBoxOpen={
                    guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
                      dataPreviewList[0]?.sections[4]?.type
                    ] === "main-box dark-yellow-box"
                  }
                />
            </Box>
      </Box>    
           
          </div>

          {
            dataPreviewList[1] &&

            ((dataPreviewList[1]?.sections[0]?.values &&
              (dataPreviewList[1]?.sections[0]?.values?.easy > 0 ||
                dataPreviewList[1]?.sections[0]?.values?.medium > 0 ||
                dataPreviewList[1]?.sections[0]?.values?.hard > 0))

              ||

              (dataPreviewList[1]?.sections[2]?.default?.length > 0 ||
                dataPreviewList[1]?.sections[2]?.external?.length > 0)
            )
            &&
            <>
              <hr />
              
              <div className="guide_preview_details">
              <Box display="flex" flexDirection="row" alignItems="flex-end" gap="10px">
                <h2>Technical Questions</h2>
              </Box>  
              {commentData?.name &&
                  commentData?.email &&
                  guideSectionCssEffect?.[dataPreviewList[1]?.type]?.[
                    dataPreviewList[1]?.sections[0]?.type
                  ] === "main-box dark-yellow-box" ? (
                    <RightCommentBox
                      data={commentData}
                      setData={setCommentData}
                      comments={
                        commentData?.data?.[dataPreviewList[1]?.type]?.[
                          dataPreviewList[1]?.sections[0]?.type
                        ]
                      }
                      guideCommentAPIS={guideCommentAPIS}
                      type={dataPreviewList[1]?.type}
                      subType={dataPreviewList[1]?.sections[0]?.type}
                      showNewCommentBox={showNewCommentBox}
                      setShowNewCommentBox={setShowNewCommentBox}
                    />
                  ) : (
                    ""
                  )}
                  <Box
                    onMouseEnter={() =>
                      handleMouseEnter(
                        dataPreviewList[1]?.type,
                        dataPreviewList[1]?.sections[0]?.type,
                        commentData?.data?.[dataPreviewList[1]?.type]?.[
                          dataPreviewList[1]?.sections[0]?.type
                        ]?.length
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeave(
                        dataPreviewList[1]?.type,
                        dataPreviewList[1]?.sections[0]?.type
                      )
                    }
                    onClick={() =>
                      handleMouseClick(
                        commentData?.data?.[dataPreviewList[1]?.type]?.[
                          dataPreviewList[1]?.sections[0]?.type
                        ]?.length,
                        dataPreviewList[1]?.type,
                        dataPreviewList[1]?.sections[0]?.type
                      )
                    }
                    className={
                      guideSectionCssEffect?.[dataPreviewList[1]?.type]?.[
                        dataPreviewList[1]?.sections[0]?.type
                      ]
                    }
                  >
             <Box>
                {
                  dataPreviewList[1]?.sections[0]?.values &&
                  (dataPreviewList[1]?.sections[0]?.values?.easy > 0 ||
                    dataPreviewList[1]?.sections[0]?.values?.medium > 0 ||
                    dataPreviewList[1]?.sections[0]?.values?.hard > 0) &&
                   
                  <div className="guide-questions-ask-label mb-10p">
                     <div className="guide-questions-ask-label bold-400 mb-5p">  Please ask {totalQuestionasked(dataPreviewList[1]?.sections[0]?.values)} questions in total.</div>
                  
                    <>
                      {dataPreviewList[1]?.sections[0]?.values &&
                        dataPreviewList[1]?.sections[0]?.values?.easy?.length !== 0 &&
                        dataPreviewList[1]?.sections[0]?.values?.easy !== 0
                        ? (
                          <p className="mb-5p">
                            <span className="bold-700">
                              {dataPreviewList[1]?.sections[0]?.values?.easy}
                            </span>
                            <div className="guide_preview_chip bg-green2">
                              Easy
                            </div>
                          </p>
                        ) : (
                          ""
                        )}
                      {dataPreviewList[1]?.sections[0]?.values &&
                        dataPreviewList[1]?.sections[0]?.values?.medium?.length !== 0 &&
                        dataPreviewList[1]?.sections[0]?.values?.medium !== 0 ? (
                        <p className="mb-5p">
                          <span className="bold-700">
                            {dataPreviewList[1]?.sections[0]?.values?.medium}
                          </span>
                          <div className="guide_preview_chip bg-gold2">
                            Medium
                          </div>
                        </p>
                      ) : (
                        ""
                      )}
                      {dataPreviewList[1]?.sections[0]?.values &&
                        dataPreviewList[1]?.sections[0]?.values?.hard?.length !== 0 &&
                        dataPreviewList[1]?.sections[0]?.values?.hard !== 0 ? (
                        <p>
                          <span className="bold-700">
                            {dataPreviewList[1]?.sections[0]?.values?.hard}
                          </span>
                          <div className="guide_preview_chip bg-red">Hard</div>
                        </p>
                      ) : (
                        ""
                      )}
                    </>
                  </div>
                 
                }
               


                {
                   (dataPreviewList[1]?.sections[0]?.values?.easy > 0 ||
                    dataPreviewList[1]?.sections[0]?.values?.medium > 0 ||
                    dataPreviewList[1]?.sections[0]?.values?.hard > 0) && 
                    (dataPreviewList[1]?.sections[0]?.values?.question_topic) &&
                    Object.keys(dataPreviewList[1]?.sections[0]?.values?.question_topic)?.length > 0 &&

                    <div>
                        <div><p className="questionAskText">From the below topic list:</p></div>
                    <div> {topicList(technicalQuestionTopic)}</div>
                    </div>
                }
                </Box>
              <Box className="comment">
              <Comments
                        isDialogOpen={isProfileDialogOpen}
                        setIsDialogOpen={setIsProfileDialogOpen}
                        handleDefaultCss={() =>
                          handleDefaultCss(
                            dataPreviewList[1]?.type,
                            dataPreviewList[1]?.sections[0]?.type
                          )
                        }
                        data={commentData}
                        setData={setCommentData}
                        showNewCommentBox={showNewCommentBox}
                        setShowNewCommentBox={setShowNewCommentBox}
                        getCssClass={() =>
                          getCssClass(
                            commentData?.data?.[dataPreviewList[1]?.type]?.[
                              dataPreviewList[1]?.sections[0]?.type
                            ]?.length
                          )
                        }
                        isRightBoxOpen={
                          guideSectionCssEffect?.[dataPreviewList[1]?.type]?.[
                            dataPreviewList[1]?.sections[0]?.type
                          ] === "main-box dark-yellow-box"
                        }
                      />
              </Box>
              </Box>
              

              {commentData?.name &&
                  commentData?.email &&
                  guideSectionCssEffect?.[dataPreviewList[1]?.type]?.[
                    dataPreviewList[1]?.sections[2]?.type
                  ] === "main-box dark-yellow-box" ? (
                    <RightCommentBox
                      data={commentData}
                      setData={setCommentData}
                      comments={
                        commentData?.data?.[dataPreviewList[1]?.type]?.[
                          dataPreviewList[1]?.sections[2]?.type
                        ]
                      }
                      guideCommentAPIS={guideCommentAPIS}
                      type={dataPreviewList[1]?.type}
                      subType={dataPreviewList[1]?.sections[2]?.type}
                      showNewCommentBox={showNewCommentBox}
                      setShowNewCommentBox={setShowNewCommentBox}
                    />
                  ) : (
                    ""
                  )}

                  <Box
                    onMouseEnter={() =>
                      handleMouseEnter(
                        dataPreviewList[1]?.type,
                        dataPreviewList[1]?.sections[2]?.type,
                        commentData?.data?.[dataPreviewList[1]?.type]?.[
                          dataPreviewList[1]?.sections[2]?.type
                        ]?.length
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeave(
                        dataPreviewList[1]?.type,
                        dataPreviewList[1]?.sections[2]?.type
                      )
                    }
                    onClick={() =>
                      handleMouseClick(
                        commentData?.data?.[dataPreviewList[1]?.type]?.[
                          dataPreviewList[1]?.sections[2]?.type
                        ]?.length,
                        dataPreviewList[1]?.type,
                        dataPreviewList[1]?.sections[2]?.type
                      )
                    }
                    className={
                      dataPreviewList[1]?.sections[2]?.default.length === 0 &&
                  dataPreviewList[1]?.sections[2]?.external.length === 0?"":
                      guideSectionCssEffect?.[dataPreviewList[1]?.type]?.[
                        dataPreviewList[1]?.sections[2]?.type
                      ]
                    }
                  >
              <Box>
                {dataPreviewList[1]?.sections[2]?.default?.length === 0 &&
                  dataPreviewList[1]?.sections[2]?.external?.length === 0 ? (
                  ""
                ) : (<>
                  <p className="fg-black2 font-12 bold-600 mb-5p">Questions List</p>
                  <p className="font-12 fg-gray3">
                    These are the questions suggested for you to ask.
                  </p>
                  
                </>)}

                {technicalQuestionTopic?.length === 0 &&
                  <>
                    {dataPreviewList[1]?.sections[2]?.default.map((data, index) => {
                      return (
                        <QuestionCard
                          section="Technical Question"
                          index={index + 1}
                          mainData={data}
                        />
                      );
                    })}
                    {dataPreviewList[1]?.sections[2]?.external.map((data, index) => {
                      return (
                        <QuestionCard
                          section="Technical Question"
                          index={dataPreviewList[1]?.sections[2]?.default?.length + index + 1}
                          mainData={data}
                        />
                      );
                    })}
                  </>}
                
                {technicalQuestionTopic?.length > 0 &&
                  <>
                    {
                      technicalQuestionTopic?.map(questionTopic => {
                        let filterArr = dataPreviewList[1]?.sections[2]?.default?.filter(e => { return e.topic === questionTopic?.key }).concat(dataPreviewList[1]?.sections[2]?.external.filter(e => { return e.topic === questionTopic?.key }));
                        return filterArr.length > 0 ? (
                          <Box className='topicContainer'>
                            <Typography className="questionTopic">{questionTopic?.key}</Typography>
                            <Box className="flexBox">
                               <Box className="noteBox">
                                    <Typography className="noteText">Note</Typography>
                                  </Box>
            
                               <Box style={{marginLeft: "5px"}}> 
                                <Typography className="ques-sub-heading">Ask not more than {questionTopic?.value} questions from this topic.</Typography> 
                              </Box>
                            </Box>
                          

                            {dataPreviewList[1]?.sections[2]?.default?.map((data, index) => {
                              if (data.topic === questionTopic?.key)
                                return <QuestionCard
                                  section="Technical Question"
                                  index={index + 1}
                                  mainData={data}
                                />
                            })
                            }
                            {dataPreviewList[1]?.sections[2]?.external?.map((data, index) => {
                              if (data.topic === questionTopic?.key)
                                return <QuestionCard
                                  section="Technical Question"
                                  index={index + 1}
                                  mainData={data}
                                />
                            })
                            }</Box>) : null
                      })
                    }

                    {dataPreviewList[1]?.sections[2]?.default?.filter(e => { return (e.topic === null || e.topic === undefined || !e.topic) }).concat(dataPreviewList[1]?.sections[2]?.external?.filter(e => { return (e.topic === null || e.topic === undefined || !e.topic) })).length > 0 &&
                      <Box className='topicContainer'>
                        <Typography className="questionTopic">General Questions</Typography>
                        {dataPreviewList[1]?.sections[2]?.default?.map((data, index) => {
                          if (data.topic === null || data.topic === undefined || !data.topic)
                            return <QuestionCard
                              section="Technical Question"
                              index={index + 1}
                              mainData={data}
                            />
                        })
                        }
                        {dataPreviewList[1]?.sections[2]?.external?.map((data, index) => {
                          if (data.topic === null || data.topic === undefined || !data.topic)
                            return <QuestionCard
                              section="Technical Question"
                              index={index + 1}
                              mainData={data}
                            />
                        })
                        }</Box>}
                  </>
                }
                </Box>
                <Box className="comment">
                {
                  dataPreviewList[1]?.sections[2]?.default.length === 0 &&
                  dataPreviewList[1]?.sections[2]?.external.length === 0?"":
                  <Comments
                        isDialogOpen={isProfileDialogOpen}
                        setIsDialogOpen={setIsProfileDialogOpen}
                        handleDefaultCss={() =>
                          handleDefaultCss(
                            dataPreviewList[1]?.type,
                            dataPreviewList[1]?.sections[2]?.type
                          )
                        }
                        data={commentData}
                        setData={setCommentData}
                        showNewCommentBox={showNewCommentBox}
                        setShowNewCommentBox={setShowNewCommentBox}
                        getCssClass={() =>
                          getCssClass(
                            commentData?.data?.[dataPreviewList[1]?.type]?.[
                              dataPreviewList[1]?.sections[2]?.type
                            ]?.length
                          )
                        }
                        isRightBoxOpen={
                          guideSectionCssEffect?.[dataPreviewList[1]?.type]?.[
                            dataPreviewList[1]?.sections[2]?.type
                          ] === "main-box dark-yellow-box"
                        }
                      />
                }
                
                </Box>
      </Box>
    
              </div>
            </>}


          {
            dataPreviewList[2] &&

            (dataPreviewList[2]?.sections[0]?.values?.total > 0
              ||
              (dataPreviewList[2]?.sections[1]?.default.length > 0 ||
                dataPreviewList[2]?.sections[1]?.external?.length > 0)
            )
            &&
            <>
              <hr />
              <div className="guide_preview_details">
                <h2>Theoretical Questions</h2>
                {commentData?.name &&
                  commentData?.email &&
                  guideSectionCssEffect?.[dataPreviewList[2]?.type]?.[
                    dataPreviewList[2]?.sections[0]?.type
                  ] === "main-box dark-yellow-box" ? (
                    <RightCommentBox
                      data={commentData}
                      setData={setCommentData}
                      comments={
                        commentData?.data?.[dataPreviewList[2]?.type]?.[
                          dataPreviewList[2]?.sections[0]?.type
                        ]
                      }
                      guideCommentAPIS={guideCommentAPIS}
                      type={dataPreviewList[2]?.type}
                      subType={dataPreviewList[2]?.sections[0]?.type}
                      showNewCommentBox={showNewCommentBox}
                      setShowNewCommentBox={setShowNewCommentBox}
                    />
                  ) : (
                    ""
                  )}
                  <Box
                    onMouseEnter={() =>
                      handleMouseEnter(
                        dataPreviewList[2]?.type,
                        dataPreviewList[2]?.sections[0]?.type,
                        commentData?.data?.[dataPreviewList[2]?.type]?.[
                          dataPreviewList[2]?.sections[0]?.type
                        ]?.length
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeave(
                        dataPreviewList[2]?.type,
                        dataPreviewList[2]?.sections[0]?.type
                      )
                    }
                    onClick={() =>
                      handleMouseClick(
                        commentData?.data?.[dataPreviewList[2]?.type]?.[
                          dataPreviewList[2]?.sections[0]?.type
                        ]?.length,
                        dataPreviewList[2]?.type,
                        dataPreviewList[2]?.sections[0]?.type
                      )
                    }
                    className={
                      guideSectionCssEffect?.[dataPreviewList[2]?.type]?.[
                        dataPreviewList[2]?.sections[0]?.type
                      ]
                    }
                  >

                  <Box>                
                {dataPreviewList[2]?.sections[0]?.values?.total > 0 &&
                  <div className="guide-questions-ask-label mb-10p">
                    <div className="guide-questions-ask-label bold-400 mb-5p">Total Number of questions to ask: {" "}
                    <span className="bold-700" style={{fontSize: 13}}>
                      {dataPreviewList[2]?.sections[0]?.values?.total}
                    </span>
                    </div>
                   </div>       
                }


                {dataPreviewList[2]?.sections[0]?.values?.total > 0 &&  dataPreviewList[2]?.sections[0]?.values?.question_topic && Object.keys(dataPreviewList[2]?.sections[0]?.values?.question_topic)?.length > 0  &&
                   <div style={{marginTop: 20}}>
                      <div><p className="questionAskText">From the below topic list:</p></div>
                      <div> {topicList(theoreticalQuestionTopics)}</div>
                   </div>
                }    
                </Box>
                <Box className="comment">
                <Comments
                        isDialogOpen={isProfileDialogOpen}
                        setIsDialogOpen={setIsProfileDialogOpen}
                        handleDefaultCss={() =>
                          handleDefaultCss(
                            dataPreviewList[2]?.type,
                            dataPreviewList[2]?.sections[0]?.type
                          )
                        }
                        data={commentData}
                        setData={setCommentData}
                        showNewCommentBox={showNewCommentBox}
                        setShowNewCommentBox={setShowNewCommentBox}
                        getCssClass={() =>
                          getCssClass(
                            commentData?.data?.[dataPreviewList[2]?.type]?.[
                              dataPreviewList[2]?.sections[0]?.type
                            ]?.length
                          )
                        }
                        isRightBoxOpen={
                          guideSectionCssEffect?.[dataPreviewList[2]?.type]?.[
                            dataPreviewList[2]?.sections[0]?.type
                          ] === "main-box dark-yellow-box"
                        }
                      />
                </Box>
                </Box> 
            
                {commentData?.name &&
                  commentData?.email &&
                  guideSectionCssEffect?.[dataPreviewList[2]?.type]?.[
                    dataPreviewList[2]?.sections[1]?.type
                  ] === "main-box dark-yellow-box" ? (
                    <RightCommentBox
                      data={commentData}
                      setData={setCommentData}
                      comments={
                        commentData?.data?.[dataPreviewList[2]?.type]?.[
                          dataPreviewList[2]?.sections[1]?.type
                        ]
                      }
                      guideCommentAPIS={guideCommentAPIS}
                      type={dataPreviewList[2]?.type}
                      subType={dataPreviewList[2]?.sections[1]?.type}
                      showNewCommentBox={showNewCommentBox}
                      setShowNewCommentBox={setShowNewCommentBox}
                    />
                  ) : (
                    ""
                  )}
                  <Box
                    onMouseEnter={() =>
                      handleMouseEnter(
                        dataPreviewList[2]?.type,
                        dataPreviewList[2]?.sections[1]?.type,
                        commentData?.data?.[dataPreviewList[2]?.type]?.[
                          dataPreviewList[2]?.sections[1]?.type
                        ]?.length
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeave(
                        dataPreviewList[2]?.type,
                        dataPreviewList[2]?.sections[1]?.type
                      )
                    }
                    onClick={() =>
                      handleMouseClick(
                        commentData?.data?.[dataPreviewList[2]?.type]?.[
                          dataPreviewList[2]?.sections[1]?.type
                        ]?.length,
                        dataPreviewList[2]?.type,
                        dataPreviewList[2]?.sections[1]?.type
                      )
                    }
                    className={dataPreviewList[2]?.sections[1]?.default.length === 0 &&
                  dataPreviewList[2]?.sections[1]?.external.length === 0?"":
                      guideSectionCssEffect?.[dataPreviewList[2]?.type]?.[
                        dataPreviewList[2]?.sections[1]?.type
                      ]
                    }
                  >
                <Box>
                {dataPreviewList[2]?.sections[1]?.default.length === 0 &&
                  dataPreviewList[2]?.sections[1]?.external.length === 0 ? (
                  ""
                ) : (<>
                  <p className="fg-black2 font-12 bold-600">Questions List</p>


                  <p className="font-12 fg-gray3 nmt-10">
                  { theoreticalQuestionTopics?.length === 0  ?
                   <> These are the questions suggested for you to ask. Please ask any <span className="pml-5">{dataPreviewList[2]?.sections[0]?.values?.total}</span> questions from these.</>
                 : <> These are the topic-wise questions suggested for you to ask.</>
                  }
                  </p>

                    {theoreticalQuestionTopics?.length === 0 &&
                      <>
                        {dataPreviewList[2]?.sections[1]?.default.length !== 0
                          ? dataPreviewList[2]?.sections[1]?.default?.map(
                            (data, index) => {
                              return renderTheoreticalRow(++questionIndex, data, index)
                            }
                          )
                          : ""}

                        {dataPreviewList[2]?.sections[1]?.external.length !== 0
                          ? dataPreviewList[2]?.sections[1]?.external?.map(
                            (data, index) => {
                              return renderTheoreticalRow(++questionIndex, data, index)
                            }
                          )
                          : ""}
                      </>}
                </>)}

                {
                  theoreticalQuestionTopics?.length > 0 &&
                <>
                    {
                      theoreticalQuestionTopics?.map(questionTopic => {
                            let filterArr = dataPreviewList[2]?.sections[1]?.default?.filter(e => { return e.topic === questionTopic?.key }).concat(dataPreviewList[2]?.sections[1]?.external.filter(e => { return e.topic === questionTopic?.key }));
                            return filterArr.length > 0 ? (
                                <Box className='topicContainer'>
                                   
                                <Typography className="questionTopic">{questionTopic?.key}</Typography>
                                <Box className="flexBox">
                                   <Box className="noteBox">
                                    <Typography className="noteText">Note</Typography>
                                  </Box>
            
                                    <Box style={{marginLeft: "5px"}}> 
                                       <Typography className="ques-sub-heading">Ask not more than {questionTopic?.value} questions from this topic.</Typography> 
                                   </Box>
                               </Box>
                          
                                
                                {dataPreviewList[2]?.sections[1]?.default?.map((data, index) => {
                                    if (data?.topic === questionTopic?.key)
                                    return renderTheoreticalRow(++questionIndex, data, index)
                                })
                                }
                                {dataPreviewList[2]?.sections[1]?.external?.map((data, index) => {
                                    if (data?.topic === questionTopic?.key)
                                    return renderTheoreticalRow(++questionIndex, data, index)
                                })
                                }</Box>) : null
                        })
                    }
                    
                    {dataPreviewList[2]?.sections[1]?.default?.filter(e => { return (e.topic === null || e.topic === undefined || !e.topic) }).concat(dataPreviewList[2]?.sections[1]?.external?.filter(e => { return (e.topic === null || e.topic === undefined || !e.topic) })).length > 0 &&
                        <Box className='topicContainer'>
                            <Typography className="questionTopic">General Questions</Typography>
                            {dataPreviewList[2]?.sections[1]?.default?.map((data, index) => {
                                if (data.topic === null || data.topic === undefined || !data.topic)
                                return renderTheoreticalRow(++questionIndex, data, index)
                            })
                            }
                            {dataPreviewList[2]?.sections[1]?.external?.map((data, index) => {
                                if (data.topic === null || data.topic === undefined || !data.topic)
                                return renderTheoreticalRow(++questionIndex, data, index)
                            })
                            }</Box>}                    
                </>
            }
            </Box>
            <Box className="comment">
            {dataPreviewList[2]?.sections[1]?.default.length === 0 &&
                  dataPreviewList[2]?.sections[1]?.external.length === 0?"":
                  <Comments
                        isDialogOpen={isProfileDialogOpen}
                        setIsDialogOpen={setIsProfileDialogOpen}
                        handleDefaultCss={() =>
                          handleDefaultCss(
                            dataPreviewList[2]?.type,
                            dataPreviewList[2]?.sections[1]?.type
                          )
                        }
                        data={commentData}
                        setData={setCommentData}
                        showNewCommentBox={showNewCommentBox}
                        setShowNewCommentBox={setShowNewCommentBox}
                        getCssClass={() =>
                          getCssClass(
                            commentData?.data?.[dataPreviewList[2]?.type]?.[
                              dataPreviewList[2]?.sections[1]?.type
                            ]?.length
                          )
                        }
                        isRightBoxOpen={
                          guideSectionCssEffect?.[dataPreviewList[2]?.type]?.[
                            dataPreviewList[2]?.sections[1]?.type
                          ] === "main-box dark-yellow-box"
                        }
                      />
                  }
            
            </Box>
</Box>


              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default GuidePreview;
