import { TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    input: {
        color: "#FFF",
        fontSize: "13px",
        fontWeight: "300",
    },
}));

const useStylesForTheoryPreview = makeStyles((theme) => ({
    input: {
        color: "#3D3D3D",
        fontSize: "13px",
        fontWeight: "300",
    },

}));

const TextEditor = ({ value, setValue, readOnly = false, placeholder, theoryPreview = false }) => {
    const classes = useStyles();
    const classesTheory = useStylesForTheoryPreview();

    const [text, setText] = useState(value);

    useEffect(() => {
        setValue(text)
    }, [text])

    return (
        <div className="w-100">

            <TextField
                inputProps={{ className: `${theoryPreview ? classesTheory.input : classes.input}` }}
                id="outlined-size-small"
                size="small"
                type="textarea"
                variant="outlined"
                className={`${theoryPreview ? 'form-value-codetext-theory' : 'form-value-codetext'}`}

                multiline
                minRows={5}
                maxRows={7}
                disabled={readOnly}
                placeholder={placeholder || ""}
                value={text}
                onChange={(e) => setText(e?.target?.value)}
            />
        </div>
    );
}
export default TextEditor;