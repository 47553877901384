import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

const modules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        [
            'bold', 'italic', 'underline', 'strike',
            // 'blockquote', 'code-block'
        ],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },],
    ],
};

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    // 'blockquote',  'code-block',
    'list', 'bullet',
];


const FeedbackTextEditor = ({ value, onChange, readOnly = false, placeholder, width = 'width-value-field', defaultValue, onBlur,  onKeyUp}) => {
    const [text, setText] = useState(value);

    useEffect(() => {
        onChange(text)
    }, [text])

    return (
        <div className={width}>
            {( onBlur) ?

                <ReactQuill
                    readOnly={readOnly}
                    placeholder={placeholder || ""}
                    theme='snow'
                    modules={modules}
                    formats={formats}
                    
                    value={text}
                    onChange={setText}

                    onBlur={(e) => onBlur({...e, type: 'blur'})}
                    onKeyUp={onKeyUp}
                />

                :

                <ReactQuill
                    readOnly={readOnly}
                    placeholder={placeholder || ""}
                    theme='snow'
                    modules={modules}
                    formats={formats}
                    onChange={setText}
                    value={text}
                />
            }

        </div>
    );
}
export default FeedbackTextEditor;