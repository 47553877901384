import React from "react";
import { CommentConstants, CommentsResolveStatus, DashboardType } from "../../Constants/FeedbackConstants";
import "./Stepper.scss";

import commentsCountSVG from "../../../../../assets/images/feedback/comments-count.svg";
export const stepperStatus = {
  ERROR: "ERROR", //Previous Step - Error
  SUCCESS: "SUCCESS", //Previous Step - Successfully done
  CURRENT: "CURRENT", //Current Step
  UPCOMING: "UPCOMING", //Future Step / Not explored
};

const Stepper = ({ role, steps, onStepperClick, feedbackType, showFeedbackComments, feedbackComments, stepperClickDisable }) => {
  const getUnresolvedCommentCount = (section) => {
    return section === "FEEDBACK_SUMMARY" ? feedbackComments?.filter((data) => ([CommentConstants.FEEDBACK_SUMMARY, CommentConstants.OVERALL_COMMENTS].includes(data?.section)) && data?.status === CommentsResolveStatus.UNRESOLVED)?.length
      : feedbackComments?.filter((data) => data?.section === section && data?.status === CommentsResolveStatus.UNRESOLVED)?.length
  }

  return (
    <div className="stepper-main-bg">
      {steps.map((step, index) => {
        return (
          <div className={`step-wrapper`} key={index}>
            <div
              onClick={() =>
                stepperClickDisable ? null : onStepperClick(step,index)}
              className={`${stepperClickDisable ? "not-allowed" : "pointer"} step-number ${step.status === stepperStatus.ERROR
                ? "step-number-error"
                : step.status === stepperStatus.SUCCESS
                  ? "step-number-success"
                  : step.status === stepperStatus.CURRENT
                    ? "step-number-current"
                    : step.status === stepperStatus.UPCOMING
                      ? "step-number-next"
                      : ""
                }`}
            >
              {index + 1}
            </div>

            <div
              onClick={() => stepperClickDisable ? null : onStepperClick(step, index)}
              className={`${stepperClickDisable ? "not-allowed" : "pointer"} step-description ${step.status === stepperStatus.ERROR
                ? "step-description-error"
                : step.status === stepperStatus.UPCOMING
                  ? "step-description-next"
                  : ""
                }`}
            >
              {step.name}
            </div>

            {showFeedbackComments && role === DashboardType.INTERVIEWER && getUnresolvedCommentCount(step?.code) > 0 &&
              <div style={{ background: `url(${commentsCountSVG})`, marginTop: "-25px", marginRight: "5px", marginLeft: "5px", width: "36px", height: "29px", color: "white", textAlign: "center", backgroundRepeat: "no-repeat" }}>
                {getUnresolvedCommentCount(step?.code)}
              </div>
            }

            {index !== steps.length - 1 && (
              <div
                className={`divider-line ${step.status === stepperStatus.ERROR
                  ? "divider-line-error"
                  : step.status === stepperStatus.UPCOMING
                    ? "divider-line-next"
                    : ""
                  }  
                  ${showFeedbackComments ? 'width-15' : 'width-25'}
                  `}
              ></div>
            )}
            {index === steps.length - 1 && (
              <div style={{ border: "1px solid #FFFFFF" }}
                className={`divider-line ${showFeedbackComments ? 'width-15' : 'width-25'}`}
              ></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;