import Drawer from '@material-ui/core/Drawer';
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { styled } from "@material-ui/core/styles"

// Sidebar drawer
export const DrawerSidebar = styled(Drawer)(({ theme }) => ({
    width: "250px",
    position: "relative"
}))

// Sidebar header
export const SidebarHeader = styled(Box)(({ theme }) => ({
    width: "100%",
    minHeight: theme.spacing(7),
    backgroundColor: "#D5E5FA",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 24px"
}))

// Sidebar header avatar
export const Avatar = styled("img")(({ theme }) => ({
    height: "30px",
    width: "30px",
    borderRadius: "50%",
    objectFit: "cover"
}))

// Sidebar body wrapper
export const SidebarBodyWrapper = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "12px 24px",
    marginTop: theme.spacing(4),
    gap: theme.spacing(4)
}))

// Sidebar body -> Menu items
export const SidebarMenuItem = styled(Box)(({ theme, cursorPointer }) => ({
    display: "flex",
    flexWrap: "nowrap",
    gap: theme.spacing(2),
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer"
}))

// Sidebar menu items text
export const SidebarMenuItemText = styled(Typography)(({ theme, customColor }) => ({
    fontWeight: 500,
    color: customColor || "#3D3D3D",
    fontFamily: "Poppins"
}))

// Sidebar menu items -> Expand icon
export const SidebarMenuItemExpandIcon = styled("img")(({ theme, expanded }) => ({
    transition: theme.transitions.create("all", { duration: theme.transitions.duration.complex }),
    transform: `rotate(${expanded ? "0" : "-90"}deg)`
}))

// Sidebar menu sub items wrapper (Collapsible)
export const SidebarSubMenuCollapsibleWrapper = styled(Collapse)(({ theme, isOpen }) => ({
    marginLeft: "30px",
    marginTop: isOpen ? "-16px" : "-32px",
    "& .MuiCollapse-wrapper .MuiCollapse-wrapperInner": {
        display: "flex",
        flexDirection: "column",
        gap: "16px"
    }
}))

// Sidebar sub-menu items text
export const SidebarSubMenuItemText = styled(Typography)(({ theme, customColor }) => ({
    fontWeight: 400,
    color: customColor || "#3D3D3D",
    fontFamily: "Poppins"
}))