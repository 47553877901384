import { FETCH_SCHEDULE } from "../actions/Schedule";

export const initialState = {
  list: []
}

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SCHEDULE:
      return {
        ...state,
        list: action.payload
      };
    default:
      return state;
  }
}

export default scheduleReducer;