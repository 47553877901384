import React, { useState } from "react";
import Modal from '@material-ui/core/Modal';
import {makeStyles} from "@material-ui/core";
import "./modal.css";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment'
import fileIcon from '../../../images/file-icon.svg';
import {confirmRequest} from '../../../services/requests';
import Swal from 'sweetalert2';
import cogoToast from 'cogo-toast';
import Radio from '@material-ui/core/Radio';


const RequestModal = ({ open, setOpenModal, handleClose,removeRequest,addScheduled, selectedInterview, interviewSlot,setAccepting, setSelected, setRightDrawer }) => {

  const [checked, setChecked] = useState(false);
  const [option,setOption] = useState('');


  const onClose = () => {
    if (handleClose) {
      handleClose();
    }else{
      setSelected(null);
    }
  };

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width: '385px',
      minHeight:'350px',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #f2f2f2',
      borderRadius: '4px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    }
  }));

  const classes = useStyles();

  const acceptInterview = async () => {
    if(setRightDrawer) setRightDrawer(false);
    try{
        setOpenModal(false);
        const response = await Swal.fire({
            title: "Are you sure you want to accept the request?",
            icon: 'info',
            iconColor: '#123FBC',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            customClass:{
                cancelButton: 'btnCancelSwal',
                confirmButton : 'btnConfirmSwal'
            }
        });

        if(response.isConfirmed){
          setAccepting(true);
          const req_body = {
              "interview_id": selectedInterview.interview_details.id,
              "client_id":  selectedInterview.company_name.id,
              "scheduled_start_timestamp": parseInt(interviewSlot) || parseInt(option),
              "scheduled_end_timestamp": parseInt(interviewSlot)+(3600) || parseInt(option)+3600,
              "last_updated_timestamp": selectedInterview.interview_details.last_updated_timestamp,
              "create_calendar_invite": true
          }
          const res = await confirmRequest(req_body)
          cogoToast.success(`Interview has been scheduled for ${selectedInterview.candidate_name.name} at ${moment((interviewSlot===''?option:interviewSlot)*1000).format('MMM DD, YYYY | hh:mm A')}`)
          removeRequest(selectedInterview);
          addScheduled(res);
          setAccepting(false);
        }
    } catch(err){
        setAccepting(false);
    }
  }
  const isRadioButtonDisabled = (dateTime) => {
    let isDisableSlot = false;
    if(dateTime < new Date().setHours(23,59,59)){
        isDisableSlot = true;
    }
    return isDisableSlot;
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      aria-labelledby="download-interview-history-label"
      aria-describedby="download-interview-history-description"
    >
      <div className={classes.paper}>
        <Box display="flex" flexDirection="column" justifyContent="space-between" className="modal-content">
          <Typography d="modal-title" className="modal-title">Please go through the interview guide before accepting the interview.</Typography>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <img className="guide-img" src={fileIcon} alt="file icon" />
            {
              selectedInterview?.round?.guide_id 
              ?  <a href={`/interview-guides/preview?guide_id=${selectedInterview?.round?.guide_id}`}  target="_blank" className="guide-link" rel="noreferrer">View Guide</a> 
              :  <a href={selectedInterview?.round?.interview_guide_link}  target="_blank" className="guide-link" rel="noreferrer">View Guide</a>
            }

          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Box display="flex" justifyContent="center" alignItems="center">
            <Checkbox color="primary" checked={checked} onChange={()=>setChecked(!checked)}/>
            <Typography className="check-text">I have read the interview guideline</Typography>
          </Box>
          <Box>
          {interviewSlot === '' || !moment(interviewSlot*1000).isValid()?
            <>
              <Typography class = 'error-text'>Please select a slot before confirming:</Typography>
              {
                selectedInterview?.interview_details?.preferred_slots_json?.map((item)=>{
                  const value=item?.start;
                  return <Typography 
                  className={`slot-text ${isRadioButtonDisabled(value*1000) ? "disable-text" : ""}`}>
                    <Radio color="primary" 
                    onClick={(e)=>setOption(e.target.value)} 
                    id={value} value={value} 
                    disabled={isRadioButtonDisabled(value*1000)} 
                    name="date" 
                    checked={parseInt(option)===value}
                    />
                    {moment(value*1000).format('MMM DD, YYYY | hh:mm A')}
                    </Typography>
                })
              }
            </>:
            <Typography class = 'slot-text'>Selected Slot: {moment(interviewSlot*1000).format('MMM DD, YYYY | hh:mm A')}</Typography>
          }
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              variant="outlined"
              className="btn btnPrimary modal-btn"
              color="primary"
              onClick={onClose}
            >
            Cancel
            </Button>
            <Button
              variant="contained"
              onClick={acceptInterview}
              disabled={!checked || (interviewSlot === '' && option==='')}
              className={`modal-btn btn ${(checked && (interviewSlot !== '' || option!=='')) ?"modal-btn--enabled":"modal-btn--disabled"}`}
            >
              Accept
              </Button>
          </Box>
          </Box>
        </Box>        
      </div>
    </Modal>
  );
};

export default RequestModal;
