import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Box";
import moment from "moment-timezone";
import fileIcon from "../../images/file-icon.svg";
import "./SlotModal.scss";

const SlotModal = ({ openModal, closeModal, date , data , onConfirm}) => {
  const [checked, setChecked] = useState(true); 
  return ( <Modal open={openModal} onClose={closeModal}>
      <Box className="slot-modal-wrapper">
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography className="heading-text">
            Please go through the interview guide before accepting the interview
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img className="guide-img" src={fileIcon} alt="file icon" />
          <a target="_blank" className="guide-link" href="#a">
            View Guide
          </a>
        </Box>
        <Box
          className="check-guideline"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Checkbox
            color="primary"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <Typography className="check-text">
            I have read the interview guideline
          </Typography>
        </Box>
        {/* For Single Slot */}
        <Box>
          <Typography className="time-label">Selected Time Slot</Typography>
          <Typography className="date-time">{moment(date * 1000).format(
                            "MMM DD, YYYY | hh:mm A"
                          )}</Typography>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button
            variant="outlined"
            className="btn outlined bg-white clr-blue"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            className="btn bg-grey clr-white"
            variant="contained"
            onClick={onConfirm}
          >
            Accept
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SlotModal;
