import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Modal from '@material-ui/core/Modal';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import cogoToast from "cogo-toast";

import warning from "../../../../../assets/images/feedback/warning.svg";
import highlight from "../../../../../assets/images/feedback/highlight.svg"

import { renderToolTip } from "../../Components/RenderToolTip";

import { InterviewFeedbackStatus } from "../../Constants/FeedbackConstants";
import { evaluationRatingMessageForInfoIcon } from "../../Constants/ComonStrings";

import "./../../feedback.scss";
import "./form.scss";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  modal: {
    overflow: 'scroll',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: '80%',
    maxHeight: '80%',
    position: 'relative',
    overflow: 'scroll',
    backgroundColor: '#ffffff',
    border: '1px solid #f2f2f2',
    borderRadius: '4px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  }
}));

const feedbackExampleData = [
  {
    name: "highlights",
    heading: "How to add good Highlights:",
    exampleData:
      "Just add 3-4 lines about the overall things which stood out during the interview and you would like the hiring manager to know. On the basis of this, you are advocating that we should hire this candidate.",
    goodExampleData: [
      "1. Solid Grasp on Problem Solving Skills and Data Structures, Was able to solve Binary Search Problems with little hints / no hints.",
      "2. Was able to solve 3 problems with optimised solutions and working code in 45 minutes",
    ],
    BadExampleData: "Good in DSA",
  },
  {
    name: "lowlights",
    heading: "How to add good Lowlights:",
    exampleData:
      "Just add 3-4 lines about the overall things which stood out during the interview and you would like the hiring manager to know. On the basis of this, you are advocating that we should not hire this candidate.",
    goodExampleData: [
      "1. Below Average in problem solving. Even after multiple hints, the candidate was not able to solve a simple binary search problem.",
      "2. Code was like spaghetti. Not at all organized. And used random naming convention",
    ],
    BadExampleData: "Bad in DSA",
  },
  {
    name: "scopeOfImprovement",
    heading: "How to add good Scope of Improvements:",
    exampleData:
      "Now who doesn’t like feedback? Write feedback in an actionable way which can be directly shared with candidates",
    goodExampleData: [
      "1. Need to revise Java Concepts like Garbage Collector and need to deep dive internal implementation as well.",
      "2. Need to practice Data Structure and Algorithms",
    ],
    BadExampleData: "Weak Java Concepts",
  },
];

const coreCompetenciesSkillsEval = [
  { type: "Requirement Gathering", checked: "", rating: "" },
  { type: "Problem Solving", checked: "", rating: "" },
  { type: "Working Code", checked: "", rating: "" },
  { type: "Development Language", checked: "", rating: "" },
  { type: "In-Depth Knowledge", checked: "", rating: "" },
  { type: "Code Quality", checked: "", rating: "" },
  { type: "Backend Concepts", checked: "", rating: "" },
  { type: "Debugging", checked: "", rating: "" },
  { type: "API Contract", checked: "", rating: "" },
  { type: "DB Schema", checked: "", rating: "" },
  { type: "Classes & Methods", checked: "", rating: "" },
  { type: "Entities and Relationships", checked: "", rating: "" },
  { type: "OO Concepts", checked: "", rating: "" },
  { type: "Design Approach & Choices", checked: "", rating: "" },
  { type: "High-Level Design", checked: "", rating: "" },
  { type: "Storage/Bandwidth Estimation", checked: "", rating: "" },
  { type: "System APIs", checked: "", rating: "" },
  { type: "Sharding", checked: "", rating: "" },
  { type: "Caching", checked: "", rating: "" },
  { type: "Scalability/Fault Tolerance", checked: "", rating: "" },
  { type: "ReactJS", checked: "", rating: "" },
  { type: "HTML/CSS", checked: "", rating: "" },
  { type: "AngularJS", checked: "", rating: "" },
  { type: "Architecture Knowledge", checked: "", rating: "" },
  { type: "Flutter Concepts", checked: "", rating: "" },
  { type: "React Native Concepts", checked: "", rating: "" },
  { type: "Infrastructure Knowledge", checked: "", rating: "" },
  { type: "Linux Fundamental", checked: "", rating: "" },
  { type: "Code Hands-On", checked: "", rating: "" },
  { type: "AWS", checked: "", rating: "" },
  { type: "Manual Testing", checked: "", rating: "" },
  { type: "REST API", checked: "", rating: "" },
  { type: "Appium Automation", checked: "", rating: "" },
  { type: "Selenium Automation", checked: "", rating: "" },
  { type: "Backend/API Automation", checked: "", rating: "" },
  { type: "NLP", checked: "", rating: "" },
  { type: "ML", checked: "", rating: "" },
  { type: "MATHS", checked: "", rating: "" },
  { type: "STATISTICS", checked: "", rating: "" },
  { type: "Database (SQL / NoSQL)", checked: "", rating: "" },
  { type: "Data Warehouse & Data Lake", checked: "", rating: "" },
  { type: "Data Architecture & Design", checked: "", rating: "" },
  { type: "Apache Kafka", checked: "", rating: "" },
]

const behavioralCompetenciesSkillsEval = [
  { type: "Representation Skills", checked: "", rating: "" },
  { type: "Communication", checked: "", rating: "" },
  { type: "Collaborative Approach", checked: "", rating: "" },
  { type: "Tools Understanding", checked: "", rating: "" },
  { type: "Structured Thought Process", checked: "", rating: "" },
  { type: "Data Visualization", checked: "", rating: "" },
  { type: "Product Thinking", checked: "", rating: "" },
]

const othersSkillsEval = [
  { type: "Business Understanding", checked: "", rating: "" },
  { type: "Project Ownership", checked: "", rating: "" },
  { type: "User Research", checked: "", rating: "" },
  { type: "UX - Flows, IA, Affinity Maps", checked: "", rating: "" },
  { type: "UI & Visual Design", checked: "", rating: "" },
  { type: "Prototyping", checked: "", rating: "" },
  { type: "Usability Testing", checked: "", rating: "" },
  { type: "Design Methodologies", checked: "", rating: "" },
  { type: "Design Components", checked: "", rating: "" },
]

const ratingOptions = [
  { value: "", label: "NA" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];

const StronglyProceedRadio = withStyles({
  root: {
    color: "#C4C4C4",
    "&$checked": {
      color: "#0F6908",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const ProceedRadio = withStyles({
  root: {
    color: "#C4C4C4",
    "&$checked": {
      color: "#71A41F",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RejectRadio = withStyles({
  root: {
    color: "#C4C4C4",
    "&$checked": {
      color: "#DA6C1D",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const StronglyRejectRadio = withStyles({
  root: {
    color: "#C4C4C4",
    "&$checked": {
      color: "#E23939",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
const addSkils = {
  fontFamily: 'Poppins',
  fontWeight: "400",
  fontSize: "13px",
  letterSpacing: "0.025em",
  color: "#3D3D3D",
  marginTop: "10px"
}
const FeedBackSummary = ({ error, feedbackSummaryData, setFeedbackSummaryData, status, previewResult }) => {
  const classes = useStyles();
  const [inputVerifier, setInputVerifier] = useState("");
  const [openSkillEvalModal, setSkillEvalModalVisibility] = useState(false);
  const [overallRatingValue, setOverallRatingValue] = useState(-1);
  const [genericSkilsEvalList, setGenericSkilsEvalList] = useState(
    {
      ["coreCompetencies"]: { header: "CORE COMPETENCIES", list: coreCompetenciesSkillsEval },
      ["behavioralCompetencies"]: { header: "BEHAVIORAL COMPETENCIES", list: behavioralCompetenciesSkillsEval },
      ["others"]: { header: "OTHERS", list: othersSkillsEval },
    }
  );
  const [ratingData, setRatingData] = useState();
  const [newSkillsEvalList, setNewSkillsEvalList] = useState([]);
  const [newSkill, setNewSkill] = useState("");


  const setRating = (rating) => {
    rating = parseInt(rating);
    let feedback = feedbackSummaryData;
    feedback.rating = rating;
    let color, title;
    if (rating <= 3) {
      color = "#B60000";
      title = "Strong Reject";
    }
    else if (rating <= 5) {
      color = "#DE9811";
      title = "Reject";
    }
    else if (rating <= 8) {
      color = "#84C51B";
      title = "Proceed";
    }
    else if (rating >= 9 && rating <= 10) {
      color = "#0B8701";
      title = "Strong Proceed";
    }
    const recomand = {
      fontFamily: 'Poppins',
      fontWeight: "500",
      fontSize: "12px",
      letterSpacing: "0.005em",
      color: "#757575",
    }
    if (color === undefined)
      setRatingData('');
    else {
      let RatingData = (
        <Box sx={{ mt: 2 }}>
          <p style={{ color: color }} className="rating-summary" >{title}</p>
          <p style={recomand}>(Overall<br /> Recommendation)</p>
        </Box>
      )
      feedback.recommendation = title;
      setFeedbackSummaryData(feedback);
      setRatingData(RatingData);
    }
  }
  const feedBackDetail = [
    {
      color: "#0B8701",
      title: "Strong Proceed",
      rating: "9 & 10",
      details: "Solved all problems correctly & optimally without (or very little) help",
    },
    {
      color: "#84C51B",
      title: "Proceed",
      rating: "6,7 & 8",
      details: "Solved all problems correctly with hints",
    },
    {
      color: "#DE9811",
      title: "Reject",
      rating: "4 & 5",
      details: "Not able to justify his resume or not able to solve problems even with hints or needed hand holding",
    },
    {
      color: "#B60000",
      title: "Strong Reject",
      rating: "<=3",
      details: "Not able to solve or even approach the problem in right way",
    },
  ];
  useEffect(() => {
    let copyGenericSkilsEvalData = { ...genericSkilsEvalList };
    let copySkillEvalList = [...feedbackSummaryData?.skillEvaluation];
    let newSkillsList = [];

    feedbackSummaryData?.skillEvaluation?.find((findData) => {
      let dataChecked = false;

      Object.keys(copyGenericSkilsEvalData).map((data) => {
        copyGenericSkilsEvalData[data]?.list?.map((listData, index) => {
          if (findData?.type === listData?.type) {
            copyGenericSkilsEvalData[data].list[index].checked = true;
            dataChecked = true;
            return true;
          }

        })
      })

      if (!dataChecked) {
        newSkillsList.push({ type: findData?.type, checked: "true", rating: findData?.rating })
      }
    })

    setGenericSkilsEvalList(copyGenericSkilsEvalData);
    setNewSkillsEvalList(feedbackSummaryData?.newSkillsEvalList || newSkillsList)
  }, [])

  const handleAddSkillButton = (e) => {
    setSkillEvalModalVisibility(true);
  };

  const closeAddSkillDialog = (e) => {
    setSkillEvalModalVisibility(false);
    setNewSkill("")
  };

  const handleNewSkillsSelect = (e, index) => {
    let copyData = [...newSkillsEvalList];
    copyData[index].checked = e?.target?.checked;
    setNewSkillsEvalList(copyData);
    setSkillEvaluationList(copyData[index], 'new')
  }

  const addNewSkill = (e) => {
    let error = false;

    feedbackSummaryData?.skillEvaluation?.some((data) => {
      if (data?.type?.toLocaleLowerCase() === newSkill?.toLocaleLowerCase()) {
        cogoToast.error("This skill is already added. Please add another one.")
        error = true;
      }
      if (error) return;
    })

    newSkillsEvalList?.some((data) => {
      if (data?.type?.toLocaleLowerCase() === newSkill?.toLocaleLowerCase()) {
        cogoToast.error("This skill is already added. Please add another one.")
        error = true;
      }
      if (error) return;
    })

    Object.keys(genericSkilsEvalList).some((data) => {
      genericSkilsEvalList[data]?.list?.some((listData) => {
        if (listData?.type?.toLocaleLowerCase() === newSkill?.toLocaleLowerCase()) {
          cogoToast.error("This skill is already added. Please add another one.")
          error = true;
        }
        if (error) return;
      })
    })

    if (!error) {
      let copyData = [...newSkillsEvalList];
      copyData.push({ type: newSkill, checked: "true", rating: "" })
      setSkillEvaluationList({ type: newSkill, checked: "true", rating: "" }, 'new')
      setNewSkillsEvalList(copyData);
      setNewSkill("")
    }
  }

  const setSkillEvaluationList = (newData, skillType) => {
    let copyData = [...feedbackSummaryData?.skillEvaluation];
    let addedDataIndex = -1;
    let addedData = copyData?.find((data, index) => {
      if (data?.type === newData?.type) {
        addedDataIndex = index;
        return true;
      }
    });

    if (newData?.checked && !addedData) {
      copyData.push(newData)
    } else if (!newData?.checked && addedData) {
      copyData.splice(addedDataIndex, 1)
    }

    if (skillType === 'new') {
      setFeedbackSummaryData({ ...feedbackSummaryData, skillEvaluation: copyData, newSkillsEvalList: newSkillsEvalList })
    } else {
      setFeedbackSummaryData({ ...feedbackSummaryData, skillEvaluation: copyData })
    }
  }

  const OverallRatingCalculation = (ratings) => {
    let rating = [];
    ratings.forEach((item) => {
      if (item?.rating?.length > 0)
        rating.push(item?.rating);
    })
    rating.sort();
    let ratingLength = rating.length;

    if (ratingLength === 1) {
      return rating[0];
    }

    let firstHalfLength = 0, secondHalfLength = 0;
    if (ratingLength % 2 === 0) {
      firstHalfLength = secondHalfLength = parseInt(ratingLength / 2);
    }
    else {
      firstHalfLength = parseInt(ratingLength / 2);
      secondHalfLength = firstHalfLength + 1;
    }
    let firstHalf = 0, secondHalf = 0;
    for (let i = 0; i < firstHalfLength; i++) {
      firstHalf += parseInt(rating[i]);
    }
    for (let i = firstHalfLength; i < ratingLength; i++) {
      secondHalf += parseInt(rating[i]);
    }
    return parseInt((firstHalf / firstHalfLength + secondHalf / secondHalfLength) / 2);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedbackSummaryData({ ...feedbackSummaryData, [name]: value });
    setRating(e?.target?.value)
    let overallRating = OverallRatingCalculation(feedbackSummaryData?.skillEvaluation);
    setOverallRatingValue(overallRating);
  };

  const handleEvaluationChange = (e, data) => {
    const { name, value } = e.target;
    let index = feedbackSummaryData?.skillEvaluation?.findIndex((findData, index) => {
      if (findData?.type === data?.type) {
        return true;
      }
    })

    if (index >= 0) {
      const feedbackSumData = { ...feedbackSummaryData };
      feedbackSumData.skillEvaluation[index] = {
        ...feedbackSumData.skillEvaluation[index],
        rating: value,
      };
      setFeedbackSummaryData(feedbackSumData);
      let overallRating = OverallRatingCalculation(feedbackSumData?.skillEvaluation);
      setOverallRatingValue(overallRating);
    }
  };

  const renderGenericEvalList = (keyData, listData, indexList) => {
    const handleSkillsEvalSelect = (e) => {
      let copyData = { ...genericSkilsEvalList }
      copyData[keyData].list[indexList].checked = e?.target?.checked
      setGenericSkilsEvalList(copyData);
      setSkillEvaluationList(copyData[keyData].list[indexList], 'generic')
    }

    return (
      <Grid item sm={3} md={3} style={{ padding: "0px", width: "25%" }}>
        <FormGroup>
          <FormControlLabel className="add-skill-checkbox-label"
            control={<Checkbox name={listData?.type} checked={listData?.checked} color="primary" onChange={(value) => handleSkillsEvalSelect(value)} style={{ padding: "0px 5px" }} />}
            label={<div className='add-skill-checkbox-label'> {listData?.type} </div>} />
        </FormGroup>
      </Grid>
    )
  }

  const renderArrayFields = (fieldName, labelName, placeholder) => {

    const handleChange = (e, index) => {
      setInputVerifier(fieldName);
      const { name, value } = e.target;
      let copyData = [...feedbackSummaryData[name]];
      copyData[index] = value;
      setFeedbackSummaryData({ ...feedbackSummaryData, [name]: copyData });
    };

    const handleDelete = (name, index) => {
      let copyData = [...feedbackSummaryData[name]];
      copyData.splice(index, 1);
      setFeedbackSummaryData({ ...feedbackSummaryData, [name]: copyData });
    };

    const handleKeypress = (e, index) => {
      //it triggers by pressing the enter key
      if (e?.key === 'Enter') {
        handleAddMore(e?.target?.name);
      }
    };

    const handleAddMore = (name) => {
      let copyData = [...feedbackSummaryData[name]];
      copyData.push("");
      setFeedbackSummaryData({ ...feedbackSummaryData, [name]: copyData });
    };

    const showError = (name) => {
      let isError = false;
      for (let i = 0; i < feedbackSummaryData[name]?.length; i++) {
        const data = feedbackSummaryData[name][i];
        if (data?.trim() === "") {
          isError = true;
        }
      }
      return isError;
    }

    const renderExampleSection = (value) => {
      return (
        <>
          {feedbackExampleData.map((item) => {
            return (
              <>
                {(item.name === value) && (
                  <Box className={`example-section ${value}`}>
                    <p className={`good-exp-${value}`}>{item?.heading}</p>
                    <p className="common-txt">{item?.exampleData}</p>
                    <p className="common-exp-heading">Good Examples</p>
                    <p className="common-txt">
                      {Object.values(item?.goodExampleData).map((item) => {
                        return <p className="common-txt">{item}</p>;
                      })}
                    </p>
                    <p className="common-exp-heading">Bad Examples</p>
                    <p className="common-txt">{item?.BadExampleData}</p>
                  </Box>
                )}
              </>
            );
          })}
        </>
      );
    };

    //Capture the input value on change and store it in state
    const handleHover = (e) => {
      setInputVerifier(e.target.name);
    };

    const checkFiveWords = (str) => {
      let feedback = str.trim();
      if (feedback.length === 0)
        return false;
      let flag = true;
      let i = 0, cnt = 0;
      for (i = 1; i < feedback.length; i++) {
        if (str[i] !== ' ' && str[i - 1] === ' ') {
          cnt++;
        }
      }
      if (cnt >= 4)
        flag = false;

      return flag;
    }
    return (
      <div className="form-row-style">
        <div className={`label ${error && showError(fieldName) ? "error-color" : ""} `}>{labelName}<span className="asterisk">*</span> {renderToolTip()}:</div>
        <Box display="flex" flexDirection="column" className="width-value-field">
          {feedbackSummaryData[fieldName].map((data, index) => {
            return (
              <>
                <Box display="flex" style={{ marginBottom: "5px" }}>
                  <TextField
                    autoFocus
                    key={`${fieldName}-${index}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    placeholder={placeholder}
                    className={(status !== InterviewFeedbackStatus?.CHANGES_REQUESTED && status !== InterviewFeedbackStatus?.SUBMITTED) ? "form-value-feedback " : "form-value"}
                    value={data}
                    name={fieldName}
                    onMouseOver={handleHover}
                    onChange={(value) => handleChange(value, index)}
                    onKeyPress={(value) => handleKeypress(value, index)}
                  />

                  {index !== 0 && (
                    <button
                      className="delete-bg"
                      onClick={(e) => handleDelete(fieldName, index)}
                    >
                      <DeleteOutlinedIcon />
                    </button>
                  )}

                  {index === feedbackSummaryData[fieldName]?.length - 1 && (
                    <button
                      className="add-more"
                      onClick={(e) => handleAddMore(fieldName)}
                    >
                      +
                    </button>
                  )}
                </Box>
                {
                  checkFiveWords(data) && <p style={{ fontSize: '12px', color: 'red' }}>Please add atleast 5 words in each feedback point.</p>
                }
                {/* Example Section not visible in Edit Mode */}
                {(status !== InterviewFeedbackStatus?.CHANGES_REQUESTED && status !== InterviewFeedbackStatus?.SUBMITTED) && <div>
                  {fieldName === inputVerifier && renderExampleSection(fieldName)}
                </div>}
              </>
            );
          })}
        </Box>
      </div>
    )
  }

  const groupEvaluationSkills = (list, key) => {
    return list.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  return (
    <div className="feedback-form-bg">

      <Modal
        open={openSkillEvalModal}
        disableBackdropClick
        onClose={closeAddSkillDialog}
        className={classes.modal}
        aria-labelledby="add-skill-label"
        aria-describedby="add-skill-description">

        <div className={classes.paper}>

          <Box display='flex' justifyContent='space-between'>
            <div className="add-skill-modal-header">Select skills </div>
            <IconButton aria-label="close" onClick={closeAddSkillDialog}> <CloseIcon /> </IconButton>
          </Box>
          <div className="add-skill-subtitle">Please select among these skills to rate upon</div>
          <hr />


          <Box display='flex' flexDirection='column'>
            <Box display='flex' flexDirection='column'>

              {
                Object.keys(genericSkilsEvalList)?.map((keyData, index) => {
                  return (
                    <>
                      <div className="add-skill-checkbox-header">{genericSkilsEvalList[keyData]?.header}</div>

                      <Grid container>
                        {genericSkilsEvalList[keyData]?.list?.map((listData, indexList) => {
                          return renderGenericEvalList(keyData, listData, indexList)
                        })}
                      </Grid>
                    </>)
                })
              }
            </Box>

            <Box display='flex' mt={"20px"}>
              <Grid container >
                {
                  newSkillsEvalList?.map((data, index) => {
                    return (
                      <Grid item sm={3} md={3} style={{ padding: "0px", width: "25%" }}>
                        <FormGroup>
                          <FormControlLabel className="add-skill-checkbox-label"
                            control={<Checkbox name={data?.type} checked={data?.checked} color="primary" onChange={(e) => handleNewSkillsSelect(e, index)} style={{ padding: "0px 5px" }} />}
                            label={<div className='add-skill-checkbox-label'> {data?.type} </div>} />
                        </FormGroup>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Box>
          </Box>


          <div className="add-skill-subtitle mt-10"> <span style={{ fontWeight: "bold" }}>NEW SKILL?</span> You can also add new skills from here.</div>
          <Box display='flex' className="mt-20">
            <TextField
              id="outlined-size-small"
              size="small"
              variant="outlined"
              placeholder="Enter Skill..."
              name="newSkill"
              value={newSkill}
              onChange={(e) => setNewSkill(e?.target?.value)}
            />
            <button className={`add-skills ml-10 ${newSkill?.trim() === "" ? "blur-8" : ""}`} disabled={newSkill?.trim() === ""} onClick={addNewSkill}>  + Add Skills</button>
          </Box>

          <hr />
          <Box display='flex' justifyContent='flex-end'>
            <button className="next-button" onClick={closeAddSkillDialog}> Confirm </button>
          </Box>
        </div>
      </Modal>


      <div className="form-row-style">
        <div className="heading">Feedback Summary</div>
      </div>
      {renderArrayFields("highlights", "Highlights", "Enter the highlights here..")}
      {renderArrayFields("lowlights", "Lowlights", "Enter the lowlights here..")}
      {renderArrayFields("scopeOfImprovement", "Scope of improvement", "Enter the scope of improvement here..")}
      {renderArrayFields("extraComments", "Extra Comments", "Enter the extra comments here..")}

      <div className="feedback-subheading">
        SKILL EVALUATION - RATING{" "}<span className="label"><i>[The ratings are out of 10]</i></span> {" "}{renderToolTip(evaluationRatingMessageForInfoIcon)}
      </div>

      <Box display='flex' flexDirection='column'>
        {
          Object.keys(groupEvaluationSkills(feedbackSummaryData?.skillEvaluation, 'group'))?.map((data, index) => {
            return (
              <Box display='flex' flexDirection='column'>
                {Object.keys(groupEvaluationSkills(feedbackSummaryData?.skillEvaluation, 'group'))?.length === 1 && data !== 'undefined' && <div className="field-header">{data}</div>}
                {Object.keys(groupEvaluationSkills(feedbackSummaryData?.skillEvaluation, 'group'))?.length > 1 && <div className="field-header">{data !== 'undefined' ? data : "OTHERS"}</div>}

                <div className="form-row-style-10">
                  {
                    groupEvaluationSkills(feedbackSummaryData?.skillEvaluation, 'group')[data]?.map((data, index2) => {
                      return (
                        <div className="form-row-style-10 width-50">
                          <div className="label width-40">{data.type} :</div>

                          <FormControl
                            variant="outlined"
                            className={`${classes.formControl}`}
                          >
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              name={data.type}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              value={data.rating}
                              placeholder="Out of 10"
                              onChange={(value) => handleEvaluationChange(value, data)}
                            >
                              {/* <MenuItem value="" hidden>
                              Out of 10
                            </MenuItem> */}

                              {ratingOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      );
                    })}
                </div>
              </Box>
            )
          })}
      </Box>

      <button className="add-skills" onClick={handleAddSkillButton}> + Edit Skills </button>
      <Typography style={addSkils}>(Please select more relevant skills corresponding to the role-category to rate the candidate)</Typography>


      <Box className="outer-box">
        <Box>
          <p className="heading">
            OVERALL rating and RECOMMENDATION
            <HelpOutlineIcon style={{ height: "15px", position: 'relative', top: '2px' }} />
          </p>
          <Box className="feedback-detail-box">
            {
              feedBackDetail?.map((item) => (
                <Box key={item?.details} className="feedback-row">
                  <Box className="rating-section">
                    <div className="circle" style={{ backgroundColor: item?.color }} ></div>
                    <span className="feedback-title-heading"> {item?.title} </span>
                    <span className="feedback-title-rating">
                      ( {item?.rating} )
                    </span>
                  </Box>
                  <Box className="detail-section">
                    <p>
                      [ {item?.details} ]
                    </p>
                  </Box>
                </Box>
              ))
            }
          </Box>
        </Box>
        <Box className="feedback-summary-warning-box">
          <div className="form-row-style-10">
            <div className={`label ${error && feedbackSummaryData?.rating === "" ? "error-color" : ""}`}>Rating:<span className="asterisk">*</span></div>
            <Box className="rating-dropdown">
              <FormControl variant="outlined" className={`${classes.formControl}`}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="rating"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  value={feedbackSummaryData.rating}
                  placeholder="Out of 10"
                  onChange={(value) => handleChange(value)}
                  style={{ height: "40px" }}
                >
                  {ratingOptions.map((option) => (
                    <MenuItem
                      key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {ratingData}
            </Box>
          </div>

          <Box>
            {previewResult && feedbackSummaryData?.recommendation !== "" && feedbackSummaryData?.recommendation?.replace("_", " ")?.toLocaleLowerCase() !== previewResult?.replace("_", " ")?.toLocaleLowerCase() ?
              <Box className="previewWarning mb-10">
                <img src={warning} alt="" className="previewImg" />
                <Typography className="previewText"> Overall recommendation doesn’t match with your initial Result Preview (
                  <span className={previewResult === "STRONG_PROCEED" ? "dgreen" : previewResult === "PROCEED" ? "green" : previewResult === "REJECT" ? "yellow" : previewResult === "STRONG_REJECT" ? "dred" : ""}>
                    {previewResult === "STRONG_PROCEED" ? "Strong Proceed" : previewResult === "PROCEED" ? "Proceed" : previewResult === "REJECT" ? "Reject" : previewResult === "STRONG_REJECT" ? "Strong Reject" : ""}</span> )
                </Typography>
              </Box>
              : ""}
            {
              isNaN(overallRatingValue) ? (
                <Box className="warning-box" style={{ backgroundColor: '#B20600', color: 'white' }}>
                  <img src={highlight} alt="highlight" style={{ color: "white", marginRight: "10px" }} />
                  <Box>
                    <p className='warning-second-details'>Please select atleast one skill from the skill evaluation. </p>
                  </Box>
                </Box>
              ) :
                overallRatingValue !== -1 ? (
                  <Box className="warning-box">
                    <img src={highlight} alt="highlight" style={{ color: "#B60000", marginRight: "10px" }} />
                    <Box>
                      <p className='warning-second-details'>Based on the skill evaluation, the overall rating should be {overallRatingValue} </p>
                    </Box>
                  </Box>
                ) : ''
            }
          </Box>

        </Box>
      </Box>
    </div>
  );
};

export default FeedBackSummary;