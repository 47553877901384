import React from "react";
import { Box } from "@mui/material";
import "./SideComponent.scss";
import moment from "moment-timezone";

import upstock from "../images/upstock.svg";
import { Typography } from "@material-ui/core";

const SideComponent = (props) => {
  const { details } = props;

  const interviewTimestamp = details?.interview_details?.scheduled_start_timestamp;

  return (
    <Box className="sidecomponent_parent">
      <Box>
        <img className='client-logo' src={details?.client_details?.logo_url} />
      </Box>

      <Box className="sidecomponent_body">
        <Box className="body_content">
          <Box>
            <Typography className="sidecomp_headings">
              CANDIDATE NAME
            </Typography>
          </Box>
          <Box>
            <Typography className="sidecomp_name">
              {details?.candidate_details?.name}
            </Typography>
          </Box>
        </Box>
        <Box className="body_content">
          <Box>
            <Typography className="sidecomp_headings">ROLE</Typography>
          </Box>
          <Box>
            <Typography className="sidecomp_role">
              {details?.position_details?.role}
            </Typography>
          </Box>
          <Box>
            <Typography className="sidecomp_position">
              {details?.position_details?.position_name}
            </Typography>
          </Box>
        </Box>
        <Box className="body_content">
          <Box>
            <Typography className="sidecomp_headings">CATEGORY</Typography>
          </Box>
          <Box>
            <Typography className="sidecomp_dsa">
              {details?.round_details?.round_category}
            </Typography>
          </Box>
        </Box>
        <Box className="body_content">
          <Box>
            <Typography className="sidecomp_headings">
              INTERVIEW TIME
            </Typography>
          </Box>
          <Box>
            <Typography className="sidecomp_time">{moment(interviewTimestamp* 1000).format('hh:mm A')}</Typography>
          </Box>
          <Box>
            <Typography className="sidecomp_date">{ moment(interviewTimestamp * 1000).format("MMM DD,YYYY")}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SideComponent;
