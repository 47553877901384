import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import './ResultClarity.scss'

const ResultClarity = ({ ratingsList }) => {

    const groupRatingsByType = (list, key) => {
        return list.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    const getOverallRatingRange = (ratingData) => {
       let from = 10;
       let to = 0;
       
        ratingData?.map((data) => {
            if( (data?.range?.from || 0) < from){
                from = data?.range?.from
            }
            if((data?.range?.to || 10) > to){
                to = data?.range?.to
            }
        })

        return getRatingRange({'to': to, 'from': from})
    }

    return (
        <>
            {Object.values(groupRatingsByType(ratingsList, "type"))?.map((resultData) => {
                return (
                    <Box display='flex' alignItems={'center'} mb={'5px'} className='result-clarity-container' flexDirection={resultData?.length === 1 ? 'row': 'column'}>
                        {
                            <Box display='flex'  sx={{ width: '100%' }}>
                                <Box display='flex' sx={{ width: '30%' }}>

                                    <div className="indicators" id={resultData[0]?.label?.toLowerCase() === 'strong proceed' ? 'greenIndicator'
                                        : resultData[0]?.label?.toLowerCase() === 'proceed' ? 'limeIndicator'
                                            : resultData[0]?.label?.toLowerCase() === 'reject' ? 'yellowIndicator'
                                                : resultData[0]?.label?.toLowerCase() === 'strong reject' ? 'redIndicator' : 'blackIndicator'} />

                                    <Typography className="rating-label">{`${resultData[0]?.label}`}
                                        <span className="recommendation"> ({getOverallRatingRange(resultData)})</span>
                                    </Typography>

                                   { resultData?.length === 1 && <Box className="arrow">
                                        <hr className='arrow-seperator' />
                                        <ArrowForwardIcon fontSize='small' />
                                    </Box>}
                                </Box>

                                { resultData?.length === 1 && <Typography className="description-label" sx={{ width: '70%' }}>{`[${resultData[0]?.hint}]`}</Typography>}
                            </Box>

                        }

                        {resultData?.length > 1 && resultData?.map((data) => {
                          return  <Box display='flex'  sx={{ width: '100%', marginBottom:"10px" }}>
                                <Box display='flex' sx={{ width: '30%' }}>

                                    <Typography className="rating-sub-label">{`${data.label}`}
                                        <span className="recommendation"> ({getRatingRange(data?.range)})</span>
                                    </Typography>

                                    <Box className="arrow">
                                        <hr className='arrow-seperator' />
                                        <ArrowForwardIcon fontSize='small' />
                                    </Box>
                                </Box>

                                 <Typography className="description-label" sx={{ width: '70%' }}>{`[${data?.hint}]`}</Typography>
                            </Box>
                            })}


                    </Box>
                )
            })}
        </>
    );
}
export default ResultClarity;

export const getRatingRange = (range) => {
    let copyRange = { ...range };
    if (!copyRange.from) {
        copyRange.from = 1
        if (copyRange.to) {
            return ("<=" + copyRange.to)
        }
    }
    if (!copyRange.to) {
        copyRange.to = 10
    }
    let rangeArray = [];
    for (let i = copyRange?.from; i <= copyRange?.to; i++) {
        // converting array to readable form 
        if ((copyRange?.from + 1 === copyRange?.to) || (i !== copyRange?.from && i === (copyRange?.to - 1))) {
            rangeArray.push(`${i} & ${i + 1}`);
            i++;
        }
        else rangeArray.push(i)
    }
    return rangeArray.toString();
}
