import axios from "./axios";

export const loginUser = async (user) => {
    try{
        return axios.post('/login', user)
        .then(response => {
            return response.data;
        }).catch(function (err) {
            return err.response;
        });
    } catch(err){
        throw err;
    }
};

export const refreshAuth = (refresh_token) => {
    return axios.post('/auth/refresh', {}, {
            headers: {
                'Authorization': `Bearer ${refresh_token}`
            }
        })
        .then(response => {
            return response.data;
        })
};

export const logoutUser = async () => {
    try{
        const resp = await axios.get('/logout');
        return resp.data;
    } catch(err){
        throw err
    }
};

export const requestAccess = (authToken) => {
    return axios.get('/admin/request_access', {
        headers: {
            'X-Admin-Request-Access-Token': authToken,
            'Authorization': null
        }
    })
    .then(({ data }) => data);
};

export const resetPassword = async (data) => {
    try{
        const resp = await axios.post('/reset-password', data);
        if(resp.data === "Error 'NoneType' object has no attribute 'get'") throw resp.data
        return resp.data;

    } catch(err){
        throw err
    }
};


export const forgotPassword = async (data) => {
    try{
        const resp = await axios.post('/forgot-password', data);
        if(resp.data === "Error 'NoneType' object has no attribute 'get'") throw resp.data
        return resp.data;
    } catch(err){
        throw err;
    }
};

export const verifyFeedbackAccess = async (token) => {
    try {
        const result = await axios.get('/interview/feedback/verify_access', {
            headers: {
                'X-Interviewer-Request-Access-Token': token,
                'Authorization': null
            }
        });

        return result.data;
    } catch (err){
        throw err;
    }
};

export const verifyGuideAccess = async (token) => {
    try {
        const result = await axios.get('/guides/verify_access', {
            headers: {
                'X-Interviewer-Request-Access-Token': token,
                'Authorization': null
            }
        });
        console.log(result)
        return result.data;
    } catch (err){
        throw err;
    }
};

export const verifyReportAccess = async (token) => {
    try {
        const result = await axios.get('/audit_reports/verify_access', {
            headers: {
                'X-Interviewer-Request-Access-Token': token,
                'Authorization': null
            }
        });

        if(!result?.data?.audit_id && result?.data?.report_id){
          const data = await getAuditIdFromReportId(result?.data?.report_id, token)
          return data;
        }else{
            return result.data;
        }
    } catch (err){
        throw err;
    }
};

export const getAuditIdFromReportId = async (reportId, token) => {
    try {
        const result = await axios.get(`/audit_reports/${reportId}`, {
            headers: {
                'X-Interviewer-Request-Access-Token': token,
                'Authorization': null
            }
        });
        return result.data;
    } catch (err){
        throw err;
    }
};