import React from "react";
import { nanoid } from 'nanoid'
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { IconButton } from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import "./../../feedback.scss";
import "./form.scss";

import TextEditor from "../../Components/TextEditor";
import FeedbackTextEditor from "../../Components/FeedbackTextEditor";

import { isTheoryQuestionFilled } from '../../Constants/FeedbackConstants'

const TheoryQuestions = ({ error, theoryQuestionsData, setTheoryQuestionsData }) => {
  let isTheoryQuesFilled = isTheoryQuestionFilled(theoryQuestionsData);

  const handleTimeSpent = (e) => {
    const re = /^[0-9\b]+$/;

    const { name, value } = e.target;
    if (value === "" || re?.test(value)) {
      setTheoryQuestionsData({ ...theoryQuestionsData, [name]: value });
    }
  };

  const handleChange = (e, questionId) => {
    const { name, value } = e.target;
    const updatedQuestions = theoryQuestionsData.questions.map(question => {
      if (question.questionId === questionId) {
        question[name] = value
      }
      return question
    });

    setTheoryQuestionsData({ ...theoryQuestionsData, questions: updatedQuestions });
  };
  const setCodeTextValue = (value, questionId) => {
    handleChange({ target: { name: "codeText", value: value } }, questionId)
  }

  const handleDelete = (name, questionId) => {
    const updatedQuestions = theoryQuestionsData["questions"].filter(question => question.questionId !== questionId)
    console.log('handle delete after', updatedQuestions);
    setTheoryQuestionsData({ ...theoryQuestionsData, [name]: updatedQuestions });
  };

  const handleAddMore = () => {
    let copyData = [...theoryQuestionsData.questions];
    copyData.push({
      questionId: nanoid(),
      newQuestion: true,
      questionAsked: "",
      candidateResponse: "",
      codeText: "",
    });
    setTheoryQuestionsData({ ...theoryQuestionsData, questions: copyData });
  };

  return (
    <div className="feedback-form-bg">

      <div className="form-row-style optional-text mb-2">
        <InfoOutlinedIcon className="info-button" /> This section is optional. If there are no theory questions asked, leave this section completely.
      </div>

      <div className="heading">Theory Questions</div>

      <div className="form-row-style">

        <div className={`label ${error && isTheoryQuesFilled && theoryQuestionsData?.timeSpent === "" ? "error-color" : ""}`}
          style={{
            width: "fit-content",
            marginRight: "10px",
          }}
        >
          Total Time Spent :
        </div>
        <TextField
          id="outlined-size-small"
          size="small"
          variant="outlined"
          placeholder="Enter numbers"
          value={theoryQuestionsData.timeSpent}
          name="timeSpent"
          onChange={handleTimeSpent}
        />
        <div style={{ margin: "10px" }}>in minutes</div>

      </div>

      {
        theoryQuestionsData.questions.map((data, index) => {
          return (
            <Box display="flex" flexDirection="column" key={data.questionId}>
              <div
                className="form-row-style"
                style={{ width: "100%", alignItems: "center" }}
              >
                Question {index + 1}.
                {index !== 0 && (
                  <IconButton
                    style={{ marginLeft: "auto" }}
                    onClick={(e) => handleDelete("questions", data.questionId)}
                  >
                    {" "}
                    <DeleteOutlinedIcon />{" "}
                  </IconButton>
                )}
              </div>

              <Box display="flex" flexDirection="column" className="questions-bg">
                <div className="form-row-style">
                  <div className={`label ${error && isTheoryQuesFilled && data.questionAsked.trim() === "" ? "error-color" : ""}`}> Question Asked :</div>

                  <Box display="flex" flexDirection="column" className="width-value-field">

                    <FeedbackTextEditor
                      id="outlined-size-small"
                      value={data.questionAsked}
                      onChange={(value) => handleChange({ target: { name: "questionAsked", value: value } }, data.questionId)}
                      placeholder="Enter the question asked here...
(Use line break to add multiple points)"
                      name="questionAsked"
                      width='w-100'
                    />

                    {/* <TextField
                  id="outlined-size-small"
                  size="small"
                  type="textarea"
                  variant="outlined"
                  placeholder="Enter the question asked here...
(Use line break to add multiple points)"
                  className="form-value w-100 mb-10"
                  value={data.questionAsked}
                  name="questionAsked"
                  multiline
                  rows={2}
                  rowsMax={5}
                  onChange={(value) => handleChange(value, index)}
                /> */}

                    <TextEditor
                      value={data?.codeText || ""}
                      setValue={(value) => setCodeTextValue(value, data.questionId)}
                      placeholder="Code part of the question goes here...
(Use line break to add multiple points)"
                      className="form-value"
                      name="codeText"
                    />

                  </Box>
                </div>


                <div className="form-row-style">
                  <div className={`label ${error && isTheoryQuesFilled && data.candidateResponse.trim() === "" ? "error-color" : ""}`}>Candidate Response :</div>

                  <FeedbackTextEditor
                    value={data?.candidateResponse || ""}
                    onChange={(value) => handleChange({ target: { name: "candidateResponse", value: value } }, data.questionId)}
                    placeholder="Enter candidate’s response here...
(Use line break to add multiple points)"
                    className="form-value"
                    width='width-value-field'
                    name="candidateResponse"
                  />
                </div>



              </Box>
            </Box>
          );
        })}

      <button className="add-more-text-button" style={{ margin: "20px 0px" }} onClick={(e) => handleAddMore()}>
        Add more Questions
      </button>
    </div>
  );
};

export default TheoryQuestions;
