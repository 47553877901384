import React from "react";
import { Text, View } from '@react-pdf/renderer';

import { stylesPDF, styleQuestions, stylesFeedbackSummary } from "./Style";

export const ResumeEntry = ({ resumeIntroData }) => {
    return (
        <View style={[{ margin: "15", border: "1 solid #cdcdcd", backgroundColor: "#fff" }]}>

            <View style={[stylesPDF.rowstyle, { width: "100%", backgroundColor: "#C5D4FF", minHeight: 42, padding: "15" }]}>
                <Text style={[stylesFeedbackSummary.previewSubheaderTitle]}> Resume - Intro</Text>
                <Text style={[stylesFeedbackSummary.previewSubheaderSubtitle, { margin: '0 15' }]}>  Time Spent: {resumeIntroData.timeSpent} Mins  </Text>
            </View>

            <View style={[stylesPDF.columnstyle, { width: "100%", padding: 20 }]}>

                <View style={[styleQuestions.tableContainer]}>
                    <View style={[styleQuestions.tableHeader, { height: 35 }]}>
                        <Text style={[styleQuestions.theoryTableHeaderComponent]}>Experience</Text>
                        <Text style={[styleQuestions.theoryTableHeaderComponent]}>Projects</Text>
                        <Text style={[styleQuestions.theoryTableHeaderComponent, styleQuestions.theoryTableHeaderComponentLastChild]}> Tech Stack Knowledge  </Text>
                    </View>
                    <View style={[stylesPDF.rowstyle]}>

                        <View style={[styleQuestions.tableRow, styleQuestions.tableRowContainer]}>
                            {resumeIntroData?.experience.map((data) => {
                                return <Text style={[styleQuestions.tableData]}>{"- " + data + "\n"}</Text>
                            })}
                        </View>
                        <View style={[styleQuestions.tableRow, styleQuestions.tableRowContainer]}>
                            {resumeIntroData?.projects.map((data) => {
                                return <Text style={[styleQuestions.tableData]}>{"- " + data + "\n"}</Text>
                            })}
                        </View>
                        <View style={[styleQuestions.tableRow, styleQuestions.tableRowContainer]}>
                            {typeof (resumeIntroData?.techStack) !== "string" && resumeIntroData?.techStack?.map((data) => {
                                return <Text style={[styleQuestions.tableData]}>{"- " + data + "\n"}</Text>
                            })}

                            {typeof (resumeIntroData?.techStack) === "string" && resumeIntroData?.techStack?.split(",").map((data) => {
                                return <Text style={[styleQuestions.tableData]}>{"- " + data + "\n"}</Text>
                            })}
                        </View>
                    </View>
                </View>

                <View style={[stylesPDF.rowstyle, { width: "100%", marginTop: 20 }]}>
                    <Text style={[styleQuestions.extraCommentsText]}>Extra Comments:</Text>

                    <View style={[{ marginLeft: "10", verticalAlign: "top" }]}>
                        {resumeIntroData.extraComments.map((data, index) => {
                            return <Text style={[styleQuestions.tableData]}> {index + 1 + ". " + data + "\n"} </Text>
                        })}
                    </View>
                </View>
            </View>
        </View>
    );
};
