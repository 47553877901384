import { Box, Typography } from '@material-ui/core';

import './HistoryCard.scss';

const HistoryCard = ({headers, data, handleRowClick, index}) => {
  console.log(headers, data, index);
  return (
    <Box
      display="flex"
      gridGap="10px"
      onClick={() => handleRowClick(data.id)}
      className={`history-card-wrapper ${index%2 === 0 ? '' : 'card-odd'}`}
    >
      <Box
        display="flex"
        flexDirection="column"
        gridGap="5px"
      >
        {headers.map((header) => (
          <Typography className="header-label">{header?.label}:</Typography>
        ))}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gridGap="5px"
      >
        {headers.map((header) => (
          <Typography className="header-value">
            {data[header.value] ? (data[header.value].label ? data[header.value].label : data[header.value]) : '-'}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default HistoryCard;