import React from "react";
import { Modal } from "react-bootstrap";
import './RightSide.scss';

const RightSideModal = (props) => {
  return (
    <Modal
      className="modal fade fixed-right"
      dialogClassName="modal-dialog modal-dialog-vertical RightSide"
      show={props.show}
      onHide={() => props.setShow(false)}
    >
     {props.children}
    </Modal>
  );
};

export default RightSideModal;

