/* eslint-disable import/no-anonymous-default-export */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from 'query-string';
import axios from "../../services/axios";
import { setUser } from '../../store/actions/User';
import { requestAccess } from '../../services/auth';
import { loginSuccess } from "../../store/actions/Auth";

export default ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const { authorization_token: authToken } = queryString.parse(location.search);
    requestAccess(authToken)
      .then(({user, access_token, refresh_token}) => {
        if (user) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
          dispatch(setUser(user));
          dispatch(loginSuccess(access_token, refresh_token));
          history.replace('/home');
        }
      })
      .catch((error) => {
        history.replace('/login');
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
