import React from "react";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./../../feedback.scss";
import "./../../SectionsV1/Preview/preview.scss";

import feedbackHighlights from "../../../../../assets/images/feedback/feedback-highlights.svg"
import feedbackLowlights from "../../../../../assets/images/feedback/feedback-lowlights.svg"
import feedbackScopeImprovement from "../../../../../assets/images/feedback/feedback-scope-improvement.svg"

import { ratingToSVG } from '../../Components/Preview'
import { AccordionSummary, AccordionDetails } from '../../Components/Accordion'

import {
  InterviewFeedbackStatus, CommentConstants, isV2SummaryProcessedResponse,
} from "../../Constants/FeedbackConstants";

const FeedbackSummary = ({ interviewDetails, feedbackStatus, feedbackSummaryData, showFeedbackComments, renderAddCommentSection, renderViewUnresolvedCommentSection }) => {
  const highlightsArray = feedbackSummaryData?.highlights;
  const lowlightsArray = feedbackSummaryData?.lowlights;
  const scopeOfImprovementArray = feedbackSummaryData?.scopeOfImprovement;
  const extraCommentsArray = isV2SummaryProcessedResponse(interviewDetails) ?  interviewDetails?.feedback_pages?.processed_response?.summary?.summary?.extra_comments : feedbackSummaryData?.extraComments ;
  const skillEvaluationsArray = feedbackSummaryData?.skillEvaluation;

  const [expanded, setExpanded] = React.useState(true);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded);
  };

  const groupEvaluationSkills = (list, key) => {
    return list?.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  // id="add-comment-parent" is the hover trigger for adding comments

  return (
    <Accordion id="add-comment-parent" expanded={expanded} className="mtb-20" onChange={handleChange('panel1')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" style={{ width: "100%", alignItems: "center" }}>
          <div className="preview-subheader-title"> Feedback Summary</div>
        </Box>
        {showFeedbackComments && renderViewUnresolvedCommentSection(CommentConstants.FEEDBACK_SUMMARY)}
        {showFeedbackComments && renderAddCommentSection(CommentConstants.FEEDBACK_SUMMARY)}
      </AccordionSummary>

      <AccordionDetails>
        <Box
          display="flex"
          flexDirection="row"
          style={{ width: "100%" }}
        >
          <Box display="flex" flexDirection="column" style={{ width: "50%", paddingRight: '20px' }}>


            {isV2SummaryProcessedResponse(interviewDetails) && interviewDetails?.feedback_pages?.processed_response?.['summary']?.summary?.suspicion_of_cheating && <div className="suspicion-cheating">Suspicion of Cheating</div>}

            <div className="feedback-summary-subheading" style={{ marginTop: "0px", color: "#209E14" }}>HIGHLIGHTS</div>

            {highlightsArray?.map((data) => {
              return (
                <div className="feedback-summary-value" style={{ textIndent: "-26px", paddingLeft: "28px" }}>
                  <img
                    src={feedbackHighlights}
                    style={{ marginRight: "10px" }}
                  />
                  {data}
                </div>
              );
            })}

            <div className="feedback-summary-subheading" style={{ color: "#984141" }}>LOWLIGHTS</div>
            {lowlightsArray?.map((data) => {
              return (
                <div className="feedback-summary-value" style={{ textIndent: "-28px", paddingLeft: "24px" }}>
                  <img
                    src={feedbackLowlights}
                    style={{ marginRight: "10px" }}
                  />
                  {data}
                </div>
              );
            })}

            <div className="feedback-summary-subheading" style={{ color: "#011C67" }}>
              SCOPE OF IMPROVEMENT
            </div>
            {scopeOfImprovementArray?.map((data) => {
              return (
                <div className="feedback-summary-value" style={{ textIndent: "-28px", paddingLeft: "24px" }}>
                  <img
                    src={feedbackScopeImprovement}
                    style={{ marginRight: "10px" }}
                  />
                  {data}
                </div>
              );
            })}

           { extraCommentsArray?.length > 0 && <div className="feedback-summary-subheading">EXTRA COMMENTS</div>}
            {extraCommentsArray?.map((data, index) => {
              return (
                <div className="feedback-summary-value" style={{ textIndent: "-13px", paddingLeft: "15px" }}>
                  {index + 1}. {"  "}
                  {data || 'NA'}
                </div>
              );
            })}
          </Box>

          <Box display="flex" flexDirection="column" style={{ width: "50%", paddingLeft: '20px' }}>
            <div className="feedback-summary-subheading" style={{ marginTop: "0px", marginLeft: "20px" }}>
              SKILL EVALUATION - RATING
            </div>

            <Box display='flex' flexDirection='column'>
              { Object.keys(groupEvaluationSkills(skillEvaluationsArray, 'group'))?.map((data, index) => {
                return (
                  <Box display='flex' flexDirection='column'>
                    {Object.keys(groupEvaluationSkills(skillEvaluationsArray, 'group'))?.length === 1 && data !== 'undefined' && <div className="field-header ml-20">{data}</div>}
                    {Object.keys(groupEvaluationSkills(skillEvaluationsArray, 'group'))?.length > 1 && <div className="field-header ml-20">{data !== 'undefined' ? data : "OTHERS"}</div>}

                    <div className="form-row-style-10">
                      {groupEvaluationSkills(skillEvaluationsArray, 'group')[data]?.map((data) => {
                        if (!(feedbackStatus === InterviewFeedbackStatus.APPROVED && !data?.rating)) {
                          return (
                            <Box
                              display="flex"
                              flexDirection="column"
                              style={{
                                width: "25%",
                                alignItems: "center",
                                marginTop: "20px",
                              }}
                              justifyContent="top"
                            >
                              <img src={ratingToSVG[`${data?.rating ? data?.rating : 0}`]} />

                              <div className="feedback-summary-rating-text">
                                {data?.type}
                              </div>
                            </Box>
                          )
                        }
                      })}
                    </div>
                    {index < Object.keys(groupEvaluationSkills(skillEvaluationsArray, 'group'))?.length - 1
                      && <hr className="feedback-eval-separator" />}
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default FeedbackSummary;