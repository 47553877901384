const config = {
    local: {
        api: {
            baseUrl: "http://localhost:5000/api",
        }
    },
    dev: {
        api: {
            baseUrl: "https://dev.api.interviewvector.com/api",
        }
    },
    test: {
        api: {
            baseUrl: "https://test.api.interviewvector.com/api",
        }
    },
    mirror: {
        api: {
            baseUrl: "https://mirror.api.interviewvector.com/api",
        }
    },
    stage: {
        api: {
            baseUrl: "https://stage.api.interviewvector.com/api",
        }
    },
    test2: {
        api: {
            baseUrl: "https://test2.api.interviewvector.com/api",
        }
    },
    prod: {
        api: {
            baseUrl: "https://api.interviewvector.com/api",
        }
    },
};

const env = process.env.REACT_APP_STAGE || "local";

export default config[env];
