import { FETCH_PROFILE } from "../actions/Profile";

export const initialState = {

}

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROFILE:
      return action.payload;
    default:
      return state;
  }
}

export default profileReducer;