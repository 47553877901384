export const FETCH_HISTORY = 'FETCH_HISTORY';
export const CANCELLED_INTERVIEWS = 'CANCELLED_INTERVIEWS';

export const histroyAction = (history) => ({
  type: FETCH_HISTORY,
  payload: history
});


export const cancelledInteviewsAction = (data) => ({
  type: CANCELLED_INTERVIEWS,
  payload: data
});

