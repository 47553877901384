import "./RequestDetail.scss"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Slide from "@material-ui/core/Slide"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ResumeIcon from "./images/resume-icon.svg"
import { useCallback, useEffect, useState } from "react"
import axios from "../../../services/axios"
import moment from "moment"
import RequestModal from "./RequestModal"
import IframeOverlay from "../../../components/IframeOverlay"

// Function to get number suffix
const getNumSuffix = (num) => {
    const lastDigit = parseInt(num) % 10
    if (lastDigit === 1) return "st";
    else if (lastDigit === 2) return "nd";
    else if (lastDigit === 3) return "rd";
    else return "th";
}

// Function to capitalize a word
const capitalize = (word) => {
    return `${word[0].toUpperCase()}${word.slice(1)}`
}

// Component for showing Request detail in a full screen drawer
export default function RequestDetail({ request, show, setShow, removeRequestFromUi }) {

    const [roundsHistory, setRoundsHistory] = useState(null) // State to store round history
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null) // State to store chosen time slot
    const [roundsHistoryLoading, setRoundsHistoryLoading] = useState(false) // State to keep track if Rounds history is loading
    const [requestModalShow, setRequestModalShow] = useState(false) // State to track if confirm modal is shown
    const [docIframeUrl, setDocIframeUrl] = useState(null); // State to track URL of the page shown in iframe (for embedding external docs)

    // Function to show resume
    const showResume = useCallback(() => {
        setDocIframeUrl(request?.candidate_name?.resume_url);
    }, [request])

    // Function to show guide
    const showRoundGuide = useCallback(() => {
        setDocIframeUrl(request?.round?.interview_guide_link);
    }, [request])

    // Function to close iframe
    const closeDoc = useCallback(() => {
        setDocIframeUrl(null);
    }, [])

    // Keeps round history updated
    useEffect(() => {
        (async () => {
            if (!!request) {
                setRoundsHistoryLoading(true)
                const payload = {
                    "candidate_id": request.candidate_name.id,
                    "position_id": request.position.id,
                    "client_id": request.company_name.id
                }
                const { data } = await axios.post(`/interview/history`, payload);
                setRoundsHistory(data.map(({ interview_details }) => (interview_details)));
                setRoundsHistoryLoading(false);
            }
        })()
    }, [request])

    return (
        <Slide direction="up" in={show}>
            {/* Parent */}
            <Box className="parent">
                {/* Close button */}
                <Box display="flex" justifyContent="flex-end">
                    <IconButton onClick={() => { setShow(false) }}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Render sections only if request is non-null */}
                {!!request &&
                    <>
                        {/* First section - Company, position, round*/}
                        <Box className="section section-1">
                            <Typography variant="body1" className="company-name">{request?.companyName || "Loading"}</Typography>
                            <Typography variant="body1" className="position-name">{request?.positionName || "Loading"}</Typography>
                            <Typography variant="body1" className="round-name">{request?.roundName || "Loading"}</Typography>
                            <Typography variant="body1" className="resume" onClick={showResume}>Resume<img src={ResumeIcon} alt="Resume icon" /></Typography>
                        </Box>

                        {/* Second section - Round details, Round name, Round guide */}
                        <Box className="section section-2">
                            <Typography variant="body1" className="section-title">Round Details</Typography>

                            <Box className="detail-grid">
                                {/* Round name */}
                                <Typography variant="body1" className="detail-key">Round name:</Typography>
                                <Typography variant="body1" className="detail-value ">{request?.roundName || "Loading"}</Typography>

                                {/* Round guide */}
                                <Typography variant="body1" className="detail-key">Round guide:</Typography>
                                <Typography variant="body1" className="detail-value link" onClick={showRoundGuide}>guide.pdf</Typography>
                            </Box>
                        </Box>

                        {/* Third section - Round history */}
                        {(!!roundsHistory && !roundsHistoryLoading) &&
                            <Box className="section">
                                <Typography variant="body1" className="section-title">Round History</Typography>

                                {roundsHistory.map((roundHistory, index) => (
                                    <Box className="detail-grid" key={index}>
                                        <Typography variant="body1" className="detail-key">{roundHistory?.round_name}</Typography>
                                        <Typography variant="body1" className="detail-value green">{capitalize(roundHistory?.interview_status?.toLowerCase())}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        }

                        {/* Fourth section */}
                        {!!request?.dateTime?.label &&
                            <Box className="section">
                                <Typography variant="body1" className="section-title">Preferred Slots</Typography>

                                <RadioGroup name="preferred-timeslots" value={selectedTimeSlot} onChange={(e) => { setSelectedTimeSlot(parseInt(e.target.value)) }}>
                                    {request?.dateTime?.label?.map((preferredUnixSecTime, index) => (
                                        <Box className="timeslot-box" key={index}>
                                            <FormControlLabel value={preferredUnixSecTime} control={<Radio color="primary" size="small" />} label={`${index + 1}${getNumSuffix(index + 1)}`} />
                                            <Typography variant="body1" className="detail-value">{moment.unix(preferredUnixSecTime).format("DD-MM-YYYY | hh:mm A")}</Typography>
                                        </Box>
                                    ))}
                                </RadioGroup>
                            </Box>
                        }
                    </>
                }

                {/* Bottom menu - Cancel and Accept */}
                <Box className="bottom-menu">
                    {/* Cancel button */}
                    <Button className="cancel-button" variant='text' onClick={() => { setShow(false) }}>
                        Cancel
                    </Button>
                    {/* Accept button */}
                    <Button disabled={!selectedTimeSlot} className="accept-button" variant='text' onClick={() => { setRequestModalShow(true) }}>
                        Accept
                    </Button>
                </Box>

                {/* Confirm modal */}
                <RequestModal request={request} selectedTimeSlot={selectedTimeSlot} show={requestModalShow} setShow={setRequestModalShow} setRequestDetailModalShow={setShow} removeRequestFromUi={removeRequestFromUi} />

                {/* Document (iframe) */}
                <IframeOverlay url={docIframeUrl || ""} onClose={closeDoc} show={!!docIframeUrl}/>

            </Box>

        </Slide>
    )
}