export const GetAPIData = (
  dataResponse,
  feedbackSummaryData,
  setFeedbackSummaryData,
  resumeIntroData,
  setResumeIntroData,
  codingQuestionData,
  setCodingQuestionData,
  theoryQuestionsData,
  setTheoryQuestionsData
) => {
  const feedbackSummary = { ...feedbackSummaryData };
  feedbackSummary.id = dataResponse?.id;
  if (typeof (dataResponse?.feedback_summary?.highlights) === 'string') {
    feedbackSummary.highlights = dataResponse?.feedback_summary?.highlights ? dataResponse?.feedback_summary?.highlights.split("\n") : [""];
  } else {
    feedbackSummary.highlights = dataResponse?.feedback_summary?.highlights || [""];
  }

  if (typeof (dataResponse?.feedback_summary?.lowlights) === 'string') {
    feedbackSummary.lowlights = dataResponse?.feedback_summary?.lowlights ? dataResponse?.feedback_summary?.lowlights.split("\n") : [""];
  } else {
    feedbackSummary.lowlights = dataResponse?.feedback_summary?.lowlights || [""];
  }


  if (typeof (dataResponse?.feedback_improvements) === 'string') {
    feedbackSummary.scopeOfImprovement = dataResponse?.feedback_improvements ? dataResponse?.feedback_improvements.split("\n") : [""];
  } else {
    feedbackSummary.scopeOfImprovement =  dataResponse?.feedback_improvements?.length > 0 ? dataResponse?.feedback_improvements : [''];
  }

  if (typeof (dataResponse?.feedback_comments) === 'string') {
    feedbackSummary.extraComments = dataResponse?.feedback_comments ? dataResponse?.feedback_comments.split("\n") : [""];
  } else {
    feedbackSummary.extraComments = dataResponse?.feedback_comments || [""];
  }

  feedbackSummary.skillEvaluation = dataResponse?.evaluation_ratings || [];
  feedbackSummary.rating = dataResponse?.overall_rating || "";
  feedbackSummary.recommendation = dataResponse?.overall_recommendation || "";
  setFeedbackSummaryData(feedbackSummary);


  for (let i = 0; i < dataResponse?.feedback_question_group?.length; i++) {
    const data = dataResponse?.feedback_question_group[i];

    if (data?.group_type === "RESUME") {
      const resumeData = { ...resumeIntroData };
      resumeData.id = data?.id;
      resumeData.timeSpent = data?.time_spent || "";

      for (let j = 0; j < data?.questions[0]?.feedback?.length; j++) {
        resumeData.questionId = data?.questions[0]?.id;

        const feedbackResp = data?.questions[0]?.feedback[j];

        resumeData[feedbackResp?.question] = feedbackResp?.response;

        // if (feedbackResp?.question === "experience") {
        //   resumeData.experience = feedbackResp?.response;
        // }
        // if (feedbackResp?.question === "projects") {
        //   resumeData.projects = feedbackResp?.response;
        // }
        // if (feedbackResp?.question === "techStack") {
        //   resumeData.techStack = feedbackResp?.response;
        // }
        // if (feedbackResp?.question === "extraComments") {
        //   resumeData.extraComments = feedbackResp?.response;
        // }
      }

      setResumeIntroData(resumeData);

    } else if (data?.group_type === "CODING" || data?.group_type === 'DESIGN' || data?.group_type === 'PROBLEM_SOLVING_CODING' || data?.group_type === 'PROBLEM_SOLVING' || data?.group_type === 'GENERIC') {
      const codingData = { ...codingQuestionData };
      codingData.id = data?.id;
      codingData.language = dataResponse?.language_used || "";
      codingData.questions = [];

      for (let j = 0; j < data?.questions?.length; j++) {
        const questions = {
          timeSpent: data?.questions[j]?.time_spent || "",
        };

        questions.questionId = data?.questions[j]?.id;
        questions.questionType = data?.questions[j]?.question_type;

        for (let k = 0; k < data?.questions[j]?.feedback?.length; k++) {
          const feedbackResp = data?.questions[j]?.feedback[k];
          questions[feedbackResp?.question] = feedbackResp?.response;

          if (feedbackResp?.question === "positives" || feedbackResp?.question === "neutrals" || feedbackResp?.question === "negatives" || feedbackResp?.question === "approach") {
            if (typeof (feedbackResp?.response) === 'string') {
              questions[feedbackResp?.question] = feedbackResp?.response ? feedbackResp?.response.split("\n") : [""];
            } else {
              questions[feedbackResp?.question] = feedbackResp?.response || [""];
            }
          }
        }

        codingData.questions.push(questions);
        codingData.questionType = data?.questions[j]?.question_type;
      }

      setCodingQuestionData(codingData);
    } else if (data?.group_type === "THEORY") {
      const theoryData = { ...theoryQuestionsData };
      theoryData.id = data?.id;
      theoryData.timeSpent = data?.time_spent || "";
      theoryData.questions = [];

      for (let j = 0; j < data?.questions?.length; j++) {
        const questions = {};
        for (let k = 0; k < data?.questions[j]?.feedback?.length; k++) {
          questions.questionId = data?.questions[j].id;
          const feedbackResp = data?.questions[j]?.feedback[k];
          questions[feedbackResp?.question] = feedbackResp?.response;
        }
        theoryData.questions.push(questions);
      }

      setTheoryQuestionsData(theoryData);
    }
  }
};
