import { styled } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { KeyboardDatePicker } from '@material-ui/pickers';

// Below styles for profile picture

export const StyledProfilePicContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
        justifyContent: "flex-start"
    }
}))

export const StyledProfilePicWrapper = styled(Box)(({ theme }) => ({
    height: "105px",
    width: "105px",
    borderRadius: "50%",
    backgroundColor: "#C4C4C4",
    position: "relative"
}))

export const StyledProfilePic = styled('img')(({ theme }) => ({
    position: "relative",
    height: "100%",
    width: "100%",
    borderRadius: "inherit",
    objectFit: "cover"
}))

export const StyledProfilePicUploadButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: "#D5E5FA",
    position: "absolute",
    bottom: theme.spacing(-1),
    right: theme.spacing(-1),
    width: theme.spacing(5),
    height: theme.spacing(5),
    zIndex: 2,
    "&:hover": {
        backgroundColor: "#D5E5FA",
    }
}))

// Below styles for form

export const StyledPersonalInfoFormParentGrid = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(2)
}))

export const StyledPersonalInfoFormChildGrid = styled(Grid)(({ theme }) => ({

}))

export const StyledPersonalInfoFormTextField = styled(TextField)(({ theme }) => ({

}))

export const StyledPersonalInfoFormDatePicker = styled(KeyboardDatePicker)(({ theme }) => ({
    marginTop: "0px !important"
}))

export const StyledPersonalInfoGendersSelect = styled(Select)(({ theme }) => ({
    marginTop: theme.spacing(2)
}))

export const StyledPersonalInfoGendersMenuItem = styled(MenuItem)(({ theme }) => ({
    
}))

export const StyledPersonalInfoFormSubmitButton = styled(Button)(({ theme }) => ({
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textTransform: "capitalize",
    fontSize: theme.typography.body1.fontSize,
    [theme.breakpoints.up("sm")]: {
        width: "fit-content",
        float: "right",
        marginRight: theme.spacing(2)
    }
}))