import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import emptyBox from '../../../images/empty-box.svg';

import './style.css';

import TableRow  from './TableRow';
import RequestModal from './RequestModal';

const TableActionCard = ({id, title, tableHeaders, requests,removeRequest,addScheduled, accepting, setAccepting}) => {

    const [selected, setSelected] = useState(null)
    const [openRequestModal, setOpenRequestModal] = useState(false);
    const [interviewSlot,setInterviewSlot] = useState('');
    
    return (
        <div className = 'card flex-grow-1 requests-container'>
            <div className = 'card-body'>
                <div className = 'row align-items-center mb-4'>
                <div className = 'col h4 mb-0 table-title'>{title}</div>
                    { requests.length>0?
                        <Box display="flex" justifyContent="flex-start" className="see-all-link">
                            <Link to = {`/${id}`} className = 'col-auto small pointer btn-link text-decoration-underline'>See All</Link>
                            <span className="small">{">"}</span>
                        </Box>
                        :<span></span>
                    }
                </div>
                {requests.length > 0 ? <div className = 'table-responsive requests-table rounded table-xm scrollable-table-wrapper' >
                    <table className = "table card-table ">
                        <thead className = ''>
                            <tr >
                                {tableHeaders.map(item => (<th key = {item.value} className = 'text-dark'>{item.label}</th>))}
                                <th className = 'text-dark text-center'>Action</th>
                            </tr>
                        </thead>
                        <tbody >
                            {requests.map((item,index)=> (
                                <TableRow 
                                    item={item} 
                                    index={index} 
                                    tableHeaders={tableHeaders} 
                                    selectedId={selected?.id}
                                    setSelected={setSelected} 
                                    setViewSideModal={setOpenRequestModal}
                                    multiValue="dateTime"
                                    setInterviewSlot={setInterviewSlot}  
                                    accepting={accepting}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>: <Box class = 'py-6 my-4 h2 mb-0 text-muted  text-center'>
                    <img src={emptyBox} alt="empty box img" />
                    <Typography className="no-data-text">No interview requests available.</Typography>
                </Box>}
            </div>
            <RequestModal accepting={accepting} setAccepting={setAccepting} removeRequest={removeRequest} addScheduled={addScheduled} selectedInterview={selected} interviewSlot={interviewSlot} open = {openRequestModal} setOpenModal={setOpenRequestModal} handleClose = {()=>{setOpenRequestModal(false); setInterviewSlot(null)}} />
        </div>
    )
}


export default TableActionCard;