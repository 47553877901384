import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { getPassPercentage } from '../../../services/history';
import Box from '@material-ui/core/Box';
import percentageIndicatorUp from '../../../images/percentage-indicator-up.svg';
import percentageIndicatorDown from '../../../images/percentage-indicator-down.svg';
import Typography from '@material-ui/core/Typography';
import classnames from "classnames";
import moment from 'moment-timezone';

const PassPercentIndicator = ({ passPercentageData, setPassPercentageData, selectedDate }) => {
    const user = useSelector(state => state.user)
    const [colors,setColors] = useState({
        red1 : [0,0],
        yellow1 : [0,0],
        green : [0,0],
        yellow2 : [0,0],
        red2 : [0,0]
    });

    useEffect(()=>{
        fetchPassPercentage();
    },[selectedDate]);
    
    function getDate(day, month, year) {
        return moment(`${day} ${month} ${year}`, 'DD MM YYYY').format("DD-MM-YYYY");
    }

    const fetchPassPercentage = async() => {
        //Fetching pass percetange for current month only
        const payload = {
            start_time : selectedDate?.from_date,
            end_time: selectedDate?.to_date,
            interviewer_id: user?.id
        }

        const newCardsData = {...passPercentageData};
        const passPercentage = await getPassPercentage(payload);
        const interviewerPassPercentage = passPercentage?.interviewer_pass_percent || 0;
        newCardsData.interviewerPassPercentage = Math.round(interviewerPassPercentage);
        const overallPassPercentage = passPercentage?.overall_pass_percent || 0;
        newCardsData.overallPassPercentage = Math.round(overallPassPercentage);
        newCardsData.passPercentageCompared = Math.round(interviewerPassPercentage - overallPassPercentage);
        let updatedColors={
            green: [Math.max(newCardsData.overallPassPercentage-10,0),Math.min(newCardsData.overallPassPercentage+10,100)],
        }
        updatedColors.yellow2 = [updatedColors.green[1],Math.min(updatedColors.green[1]+20,100)];
        updatedColors.red2 = [updatedColors.yellow2[1],100];
        updatedColors.yellow1 = [Math.max(updatedColors.green[0]-20,0),updatedColors.green[0]];
        updatedColors.red1 = [0,updatedColors.yellow1[0]];

        setColors(updatedColors);
        setPassPercentageData(newCardsData);
    }
    
    const absPassPercentageCompared = Math.abs(passPercentageData?.passPercentageCompared) || 0;
    const passPercentageStyles={
        background :`linear-gradient(
            to right, 
            #E00C0C ${colors.red1[0]}% ${colors.red1[1]}%, 
            #FFB800 ${colors.yellow1[0]}% ${colors.yellow1[1]}%,
            #11DC62 ${colors.green[0]}% ${colors.green[1]}%,
            #FFB800 ${colors.yellow2[0]}% ${colors.yellow2[1]}%,
            #E00C0C ${colors.red2[0]}% ${colors.red2[1]}%
        )`
    }
    const averageTrackerStyles={
        left : `calc(${passPercentageData.overallPassPercentage}% - 5px)`
    }
    const passPercentTrackerStyles={
        left : `calc(${passPercentageData.interviewerPassPercentage}% - 5px)`
    }
    return(
        <Box 
            className="passPercent-container"
            style={passPercentageStyles}
        >
            <Box className="average-tracker" style={averageTrackerStyles}>
                <img src={percentageIndicatorDown} className="indicator-icon" alt="percentage img" />
                <Typography 
                    className={classnames("average-text",{
                        "transform-left" : passPercentageData?.overallPassPercentage >= 50
                    })}
                >
                    IV Average: <span>{passPercentageData?.overallPassPercentage}%</span>
                </Typography>
            </Box>
            <Box className="passPercent-tracker" style={passPercentTrackerStyles}>
                <Typography 
                    className={classnames("passPercent-text",{
                        "transform-left" : passPercentageData?.interviewerPassPercentage >= 50
                    })}
                >
                    You:
                    <span 
                        className={classnames("passPercent-text",{
                            "color-red" : absPassPercentageCompared > 30,
                            "color-yellow" : absPassPercentageCompared > 10 && absPassPercentageCompared <= 30,
                            "color-green" : absPassPercentageCompared <= 10
                        })}
                    >
                        {" "}{passPercentageData?.interviewerPassPercentage}%
                    </span>
                </Typography>
                <img src={percentageIndicatorUp} className="indicator-icon" alt="percentage up img" />
            </Box>                
        </Box>
    )
}

export default PassPercentIndicator;