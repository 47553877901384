import React from "react";
import { Text, View, Image } from '@react-pdf/renderer';

import { stylesPDF, stylesFeedbackSummary } from "../../SectionsV1/Download/Style";
import { ratingToImage } from '../../Components/Download';

import { InterviewFeedbackStatus, isV2SummaryProcessedResponse } from "../../Constants/FeedbackConstants";

// images......
import highlightsImg from "../../../../../assets/images/download-feedback/feedback-highlights.png";
import lowLightsImg from "../../../../../assets/images/download-feedback/feedback-lowlights.png";
import feedbackScopeImprovementImg from "../../../../../assets/images/download-feedback/feedback-scope-improvement.png";


export const FeedbackSummary = ({ interviewDetails, feedbackStatus, feedbackSummaryData }) => {
    const highlightsArray = feedbackSummaryData?.highlights;
    const lowlightsArray = feedbackSummaryData?.lowlights;
    const scopeOfImprovementArray = feedbackSummaryData?.scopeOfImprovement;
    const extraCommentsArray = isV2SummaryProcessedResponse(interviewDetails) ?  interviewDetails?.feedback_pages?.processed_response?.summary?.summary?.extra_comments : feedbackSummaryData?.extraComments ;
    const skillEvaluationsArray = feedbackSummaryData?.skillEvaluation;

    const groupEvaluationSkills = (list, key) => {
        return list?.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    return (
        <View style={[{ margin: "15", border: "1 solid #cdcdcd", backgroundColor: "#fff" }]}>

            <View style={[stylesPDF.rowstyle, { width: "100%", backgroundColor: "#C5D4FF", minHeight: 42, padding: "15" }]}>
                <Text style={[stylesFeedbackSummary.previewSubheaderTitle]}> Feedback Summary</Text>
            </View>

            <View style={[stylesPDF.rowstyle, { width: "100%", padding: 20 }]}>
                <View style={[stylesPDF.columnstyle, { width: "50%", paddingRight: 15 }]}>
                   
                {isV2SummaryProcessedResponse(interviewDetails) && interviewDetails?.feedback_pages?.processed_response?.['summary']?.summary?.suspicion_of_cheating
                && <Text style={[stylesFeedbackSummary.suspicionCheating]}>Suspicion of Cheating</Text>}

                   
                    <Text style={[stylesFeedbackSummary.feedbackSummarySubheading, { marginTop: 0, color: "#209E14" }]}>HIGHLIGHTS</Text>

                    {highlightsArray?.map((data) => {
                        return (
                            <View style={[stylesPDF.rowstyle]}>
                                <Image
                                    src={highlightsImg}
                                    style={[{ marginRight: 8, width: 12, height: 12 }]}
                                />
                                <Text style={[stylesFeedbackSummary.feedbackSummaryValue]}>{data}</Text>
                            </View>
                        );
                    })}

                    <Text style={[stylesFeedbackSummary.feedbackSummarySubheading, { color: "#984141" }]}>LOWLIGHTS</Text>

                    {lowlightsArray?.map((data) => {
                        return (
                            <View style={[stylesPDF.rowstyle]}>
                                <Image
                                    src={lowLightsImg}
                                    style={[{ marginRight: 8, width: 12, height: 12 }]}
                                />
                                <Text style={[stylesFeedbackSummary.feedbackSummaryValue]}>{data}</Text>
                            </View>
                        );
                    })}

                    <Text style={[stylesFeedbackSummary.feedbackSummarySubheading, { color: "#011C67" }]}>SCOPE OF IMPROVEMENT</Text>

                    {scopeOfImprovementArray?.map((data) => {
                        return (
                            <View style={[stylesPDF.rowstyle]}>
                                <Image
                                    src={feedbackScopeImprovementImg}
                                    style={[{ marginRight: 8, width: 12, height: 12 }]}
                                />
                                <Text style={[stylesFeedbackSummary.feedbackSummaryValue]}>{data}</Text>
                            </View>
                        );
                    })}


                    {extraCommentsArray?.length > 0 && <Text style={[stylesFeedbackSummary.feedbackSummarySubheading]}>EXTRA COMMENTS</Text>}

                    {extraCommentsArray?.map((data, index) => {
                        return (
                            <View style={[stylesPDF.rowstyle]}>
                                <Text style={[stylesFeedbackSummary.feedbackSummaryValue]}>
                                    {index + 1}. {data || 'NA'}</Text>
                            </View>
                        );
                    })}
                </View>

                <View style={[stylesPDF.columnstyle, { width: "50%", paddingLeft: 15 }]}>
                    <Text style={[stylesFeedbackSummary.feedbackSummarySubheading, { marginTop: 0, marginLeft: 15 }]}>SKILL EVALUATION - RATING</Text>

                    <View style={[stylesPDF.columnstyle]}>
                       
                       
                    { Object.keys(groupEvaluationSkills(skillEvaluationsArray, 'group'))?.map((data, index) => {
                            return (
                                <View style={[stylesPDF.columnstyle]}>
                                    {data !== 'undefined' && <Text style={[stylesFeedbackSummary.fieldHeader, { marginLeft: 16 }]}>{data}</Text>}

                                    <View style={[stylesPDF.rowstyleWrap, { margin: '4 0' }]}>
                                        {groupEvaluationSkills(skillEvaluationsArray, 'group')[data]?.map((data) => {
                                            if (!(feedbackStatus === InterviewFeedbackStatus.APPROVED && !data?.rating)) {
                                                return (
                                                    <View style={[stylesPDF.columnstyle, {
                                                        width: "25%",
                                                        alignItems: "center",
                                                        marginTop: 16,
                                                        justifyContent: "top"
                                                    }]}>
                                                        <Image style={[{ width: "75%" }]} src={ratingToImage[`${data?.rating ? data?.rating?.toString() : "0"}`]} />
                                                        <Text style={[stylesFeedbackSummary.feedbackSummaryRatingText]}>
                                                            {data?.type}
                                                        </Text>
                                                    </View>
                                                )
                                            }
                                        })}
                                    </View>

                                    {index < Object.keys(groupEvaluationSkills(skillEvaluationsArray, 'group'))?.length - 1
                                        && <View style={[stylesFeedbackSummary.feedbackEvalSeparator, stylesFeedbackSummary.horizontalDivider]} />}
                                </View>
                            )
                        })}
                    </View>
                </View>
            </View>

        </View>
    );
};