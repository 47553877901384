import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import "./AuditFeedback.scss";
import AuditSidebar from "./AuditSidebar.jsx";
import {Submitted} from "./Submitted.jsx";
import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'red !important'
  },
  cssLabel: {
    color : 'red'
  },

}));

const numbers = ['0','1','2','3','4','5','6','7','8','9','10'];
const AuditFeedbackShared = ({ confirmSubmit, handleAuditForm, newPerformance, setNewPerformance,
  sidebarData, setSideBarData, auditReport, setAuditReport, open, setOpen, successful, setSuccessful, goToAuditList, downloadFile, apiInProgress
}) => {
  const classes = useStyles();

  const [summary, setSummary] = React.useState({
      highCommentbox:"false", highlights:"", highComment:"",
     lowCommentbox:"false", lowlights:"", lowComment:"", isError:false
  });
  const [missingInfo, setMissingInfo] =  React.useState([
    {comment:'',isError:false}
  ]);
  const [improvement, setImprovement] =  React.useState([
    {comment:'',isError:false}
  ]);
  const [extraComments,setExtraComments]=React.useState([
    {comment:'',isError:false}
  ])

  const [performance, setPerformance] =  React.useState({ overall:'',isError:false})
  const [ratings, setRatings] = React.useState({ interview:'' , feedback: '',isError:false})
  const [count, setCount] =  React.useState(0);

  const [questions, setQuestions] = React.useState([
    {qus: 'Did interviewer follow the guide?', selected:'', isSelect:false, expectedAnswer: 'Yes',isCommentboxShown:false},
    {qus: 'Was the introduction done by interviewer appropriate?',selected:'', isSelect:false, expectedAnswer: 'Yes',isCommentboxShown:false},
    {qus: 'Did interviewer build rapport at the start?',selected:'', isSelect:false, expectedAnswer: 'Yes',isCommentboxShown:false},
    {qus: 'Did resume discussion happen for 5-10 mins?', selected:'', isSelect:false, expectedAnswer: 'Yes',isCommentboxShown:false},
    {qus: 'Did interviewer ask candidate to share his screen?',selected:'', isSelect:false, expectedAnswer: 'Yes',isCommentboxShown:false},
    {qus: 'Did interviewer ask candidate to switch on his camera?',selected:'', isSelect:false, expectedAnswer: 'Yes',isCommentboxShown:false},
    {qus: 'Did interviewer ask candidate to fill candidate’s feedback form?',selected:'', isSelect:false, expectedAnswer: 'Yes',isCommentboxShown:false},
    {qus: 'Did interviewer ask questions about past experiences of candidate?',selected:'', isSelect:false, expectedAnswer: 'Yes',isCommentboxShown:false},
    // {qus: 'Did candidate take appropriate time as required to answer the questions?',selected:'', isSelect:false, expectedAnswer: 'Yes',isCommentboxShown:false},
    {qus: 'Did interviewer ask cross-questions on answers given by candidate?',selected:'', isSelect:false, expectedAnswer: 'Yes',isCommentboxShown:false},
    {qus: 'Was interviewer giving too many hints?', selected:'', isSelect:false, expectedAnswer: 'No',isCommentboxShown:false},
    {qus: 'Was the interviewer interactive?',selected:'' , isSelect:false, expectedAnswer: 'Yes',isCommentboxShown:false},
    {qus: 'Was interviewer too lenient?',text:'Your comments on where interviewer was too lenient..' , commentbox: false, comment:'', selected:'', isSelect:false, expectedAnswer: 'No',isCommentboxShown:true},
    {qus: 'Was the interviewer’s behaviour in-appropriate? (rude, disrespectful, pushy, sarcastic, etc)',text:'Your comments on how the interviewer’s behaviour was in-appropriate..', commentbox: false, comment:'', selected:'' , isSelect:false, expectedAnswer: 'No',isCommentboxShown:true},
    {qus: 'Was the candidate’s code quality judged correctly by the interviewer?', selected:'', isSelect:false, expectedAnswer: 'Yes',isCommentboxShown:false},
    {qus: 'Was any question too easy?',text:'Which questions were too easy and how to correct it..', commentbox: false, comment:'', selected:'', isSelect:false, expectedAnswer: 'No',isCommentboxShown:true},
    {qus: 'Was any question too hard?',text:'Which questions were too hard and how to correct it..',commentbox: false, comment:'', selected:'', isSelect:false, expectedAnswer: 'No',isCommentboxShown:true},
    {qus: 'Was any question judged incorrectly in the feedback?',text:'Which questions were judged incorrectly & what should be the correct judgement..', commentbox: false, comment:'', selected:'', isSelect:false, expectedAnswer: 'No',isCommentboxShown:true}
   ]);
  
  const handleClose = () => setOpen(false);
  
  const handleSubmitClose = () =>{
    setSuccessful(false);
    goToAuditList();
  };
  
  const handleAddMoreComments=()=>{
    setExtraComments([...extraComments,{comment:'',isError:false}])
  }
  const handleAddMoreImprovement=()=>{
    setImprovement([...improvement,{comment:'',isError:false}])
  }
  const handleAddMoreMissingInfo=()=>{
    setMissingInfo([...missingInfo,{comment:'',isError:false}])
  }

  const handleDeleteComments=(index)=>{
    const list=[...extraComments]
    list.splice(index,1)
    setExtraComments(list)
  }
  const handleDeleteImprovement=(index)=>{
    const list=[...improvement]
    list.splice(index,1)
    setImprovement(list)
  }
  const handleDeleteMissingInfo=(index)=>{
    const list=[...missingInfo]
    list.splice(index,1)
    setMissingInfo(list)
  }
  
  const handleChange = (data, index) => {
    let quest=questions;
    quest[index].selected = data;
    if(quest[index].isCommentboxShown){
      if(data === 'Yes'){
        quest[index].commentbox = true;
      }
      else if(data === 'No'|| data === 'NA'){
        quest[index].commentbox = false;
      }
    }
    quest[index].isSelect = false;
    quest[index].comment = '';
    setQuestions([...quest]);
  };

  const getcolor = (index) => {
    if(index%2 === 0){
      return {backgroundColor: '#F5F8FF'}
    }
    else{
      return {backgroundColor: '#FFFF'}
    }
  }

  const handlecomment= (data,index) => {
    let quest=questions;
    quest[index].comment= data;
    setQuestions([...quest]);
  }

  const giveQuestionIndex = (data)=>{
    let ind = -1;
    for (let i=0;i<questions.length;i++){
      if (data?.question === questions[i]?.qus){
        ind = i;
        break;
      }
    }
    return ind;
  }

  const give_index = (arr, data)=>{
    let ind = 0;
    for (let i=0;i<arr?.length;i++){
      if (arr[i]?.type === data)
      return i;
    }

    return ind;
  }

  const handlePreFill = ()=>{
    if (auditReport?.audit_id === undefined)
    return ;

    // First Check the radio button
    let obj = summary;
    obj.highlights = auditReport?.feedback_assessment?.highlights?.rating;
    obj.highCommentbox = auditReport?.feedback_assessment?.highlights?.comments===undefined ? false:true;
    obj.highComment = auditReport?.feedback_assessment?.highlights?.comments === undefined ? '':auditReport?.feedback_assessment?.highlights?.comments;

    obj.lowlights = auditReport?.feedback_assessment?.lowlights?.rating;
    obj.lowCommentbox = auditReport?.feedback_assessment?.lowlights?.comments===undefined ? false:true;
    obj.lowComment = auditReport?.feedback_assessment?.lowlights?.comments === undefined ? '':auditReport?.feedback_assessment?.lowlights?.comments;
    setSummary(obj);

    // Fill the data of questions

    let questionList = questions;
    auditReport?.interview_assessment?.forEach((item)=>{
      let index = giveQuestionIndex(item);
      if (index > -1) {
      questionList[index].selected = item?.answer;
      if (item?.comments){
        questionList[index].commentbox = true;
        questionList[index].comment = item?.comments;
      }
    }
    })
    setQuestions([...questionList]);

    // handle Data for rating section
    handlePerformance(auditReport?.overall_rating, 'overall');

    if (newPerformance && newPerformance?.length > 0){
      let tempPerformance = newPerformance;
      for (let i=0;i<auditReport?.skill_ratings?.length;i++){
        let ind = give_index(tempPerformance, auditReport?.skill_ratings[i]?.name);
        tempPerformance[ind].auditRating = auditReport?.skill_ratings[i]?.value;
      }
      setNewPerformance([...tempPerformance]);
    }

    // handleData for emoji rating
    handleRatings(auditReport?.interview_rating,'interview');
    handleRatings(auditReport?.feedback_rating, 'feedback');

    // for feedback missing and interviewer improvement
    handleMissingInfoFromBE(auditReport?.feedback_missing_info);
    handleImprovementFromBE(auditReport?.scope_of_improvement);
    handleExtraCommentsFromBE(auditReport?.extra_comments && auditReport?.extra_comments.length > 0 ? auditReport?.extra_comments : [""]);

    let quesError = questions;
    for (let i=0;i<questions.length;i++){
      if (quesError[i]?.selected?.length === 0){
        quesError[i].isSelect = true;
      }
    }
    setQuestions([...quesError]);
  }

  useEffect(()=>{
    if (Object.keys(auditReport)?.length > 0)
    handlePreFill();
  },[auditReport])

  const handleSummary = (data,k) => {
    let summ = summary;
    if (k === 'highlights'){
      summ.highComment = '';
      if(data === 'Appropriate')
        summ.highCommentbox = false;
    }
    if (k === 'lowlights'){
      summ.lowComment = '';
      if (data === 'Appropriate')
        summ.lowCommentbox = false;
    }
    if(k === 'highlights'){
       summ.highlights = data;
       if(data  ===  'OverRated' || data  ===  'UnderRated'){
        summ.highCommentbox = true;
       }
       else if(data  ===  'Appropriate'){
         summ.highCommentbox = false;
       }
    }
    else if(k === 'lowlights'){
      summ.lowlights = data;
      if(data  ===  'OverRated' || data  ===  'UnderRated'){
        summ.lowCommentbox = true;
       }
       else if(data  ===  'Appropriate'){
        summ.lowCommentbox = false;
       }
    }
    else if(k === 'highComment'){
      summ.highComment = data;
    }
    else if(k === 'lowComment'){
      summ.lowComment = data;
    }
    summ.isError = false;
    setSummary({...summ});
  }

  const handlePerformance = (data,k) => {
    let pref = performance;
    if(k === 'overall'){
      pref.overall = data;
    }
    else if(k === 'communication'){
      pref.communication = data;
    }
    else if(k === 'coding'){
      pref.coding = data;
    }
    else if(k === 'probSolving'){
      pref.probSolving = data;
    }
    else if(k === 'reqGathering'){
      pref.reqGathering = data;
    }
    pref.isError = false;
    setPerformance({...pref});
  }

  const handleRatings = (data,k) =>{
    let rating = ratings;
    if(k == 'interview'){
      rating.interview = data;
    }
    else if(k == 'feedback'){
      rating.feedback = data;
    }
    rating.isError =false;
    setRatings({...rating});
  }

  const handleMissingInfo = (e,index) =>{
    const {name,value}=e?.target
    const list=[...missingInfo]
    list[index][name]=value
    setMissingInfo(list)
  }
  const handleMissingInfoFromBE=(data)=>{
    const array = [];
    data?.map((comm)=>{
      array.push({comment:comm,isError:false})
    })
    setMissingInfo(array)
  }

  const handleImprovementFromBE=(data)=>{
    const array = [];
    data?.map((comm)=>{
      array.push({comment:comm,isError:false})
    })
    setImprovement(array)
  }
  const handleImprovement = (e,index) =>{
    const {name,value}=e?.target
    const list=[...improvement]
    list[index][name]=value
    setImprovement(list)
  }
  
  const handleExtraCommentsFromBE=(data)=>{
    const array = [];
    data?.map((comm)=>{
      array.push({comment:comm,isError:false})
    })
    setExtraComments(array)
  }
  const handleExtraComments=(e,index)=>{
    const {name,value}=e?.target
    const list=[...extraComments]
    list[index][name]=value
    setExtraComments(list)
  }


  const handleQuestion = ()=>{
    let assess =[];
    for(let i=0; i<questions.length; i++){
        let obj={
          question : questions[i].qus,
          answer : questions[i]?.selected,
          expected_answer: questions[i].expectedAnswer,
        }
        if (questions[i]?.comment){
          obj.comments = questions[i]?.comment;
        }
        assess.push(obj);
    }
    return assess;
  }

  const giveRecommend = (data)=>{
    if (data<=3){
      return "STRONG_REJECT";
    }
    else if (data<=5)
    return 'REJECT';
    else if (data<=8)
    return 'PROCEED';
    else
    return 'STRONG_PROCEED';
  }

  const give_object = (arr)=>{
    let temp=[];
    for (let i=0;i<arr?.length;i++){
      if (arr[i]?.rating === '')
      continue;
      temp.push({name:arr[i]?.type, value:parseInt(arr[i]?.auditRating)})
    }
    return temp;
  }
  const giveMissingInfo=()=>{
    const data = []
    for(let i=0;i<missingInfo.length;i++){
      if(missingInfo[i].comment){
        data.push(missingInfo[i].comment)
      }
    }
    return data
  }
  const giveExtraComments=()=>{
    const data = []
    for(let i=0;i<extraComments.length;i++){
     if(extraComments[i].comment){
       data.push(extraComments[i].comment)
     }
    }
    return data
  }
  const giveImprovement=()=>{
    const data = []
    for(let i=0;i<improvement.length;i++){
      if(improvement[i].comment){
        data.push(improvement[i].comment)
      }
    }
    return data
  }

  const onConfirm=()=>{
      confirmSubmit(createSubmission) 
  }

  const createSubmission=()=>{

    let sub = {
      interview_assessment:[],
        highlights:{
          rating:'',
          comments:''
        },
        lowlights:{
          rating:'',
          comments:''
        },
      overall_rating:'',
      skill_ratings:give_object(newPerformance),
      interview_rating:'',
      feedback_rating:'',
      feedback_missing_info:[],
      scope_of_improvement:[],
      extra_comments:[]
  };
    let assess = handleQuestion();
    sub.interview_assessment = assess;

    sub.highlights.rating = summary.highlights;
    sub.highlights.comments = summary.highComment;
    
    sub.lowlights.rating = summary.lowlights;
    sub.lowlights.comments = summary.lowComment;

    sub.overall_rating = parseInt(performance.overall);

    sub.interview_rating = ratings.interview;
    sub.feedback_rating = ratings.feedback;
    sub.scope_of_improvement = giveImprovement();
    sub.extra_comments = giveExtraComments()
    sub.feedback_missing_info=giveMissingInfo()
    sub.overall_recommendation = giveRecommend(parseInt(performance.overall));
    return sub;
  }


  const handleSubmit = () => {
    let count = 0;
    // Question Validation done
    let ques = questions;
    for (let i=0;i<questions.length;i++){
      if (ques[i]?.selected?.length === 0){
        ques[i].isSelect = true;
        count ++ ;
        setQuestions([...ques]);
        // return ;
      }
    }

    // Feedback Summary validation
    let summ = summary;
    if (summ?.highlights?.length === 0 || summ?.lowlights?.length === 0){
      summ.isError = true;
      count ++ ;
    }
    if (summ?.highCommentbox){
      summ.isError = summ?.highComment?.length === 0?true:false;
    }
    if (summ?.lowCommentbox){
      summ.isError = summ?.lowComment?.length === 0?true:false;
    }
    if (summ?.isError){
      setSummary({...summ});
      // return ;
    }
    // Performance Validation
    let per = performance;
    if (per?.overall?.length === 0){
      per.isError = true;
      count ++ ;
      setPerformance({...per});
      // return ;
    }

    let newRating = newPerformance;
    for (let i=0;i<newRating?.length;i++){
      if (newRating[i]?.rating === '')
      continue;
      if (newRating[i]?.auditRating?.length === 0){
        per.isError = true;
        count ++ ;
        setPerformance({...per});
      }
    }

    // Emoji Ratings
    let rating = ratings;
    if (rating?.feedback?.length === 0 || rating?.interview?.length === 0){
      rating.isError = true;
      count ++ ;
      setRatings({...rating});
    }


    let miss = missingInfo;
    for(let i=0;i<miss.length;i++){
      if(miss[i].comment===''){
        miss[i].isError=true
        count++
      }
      else{
        miss[i].isError=false
      }
    }
    setMissingInfo(miss)
// Missing infor and Improvement
    let impro= improvement
    for(let i=0;i<impro.length;i++){
      if(impro[i].comment===''){
        impro[i].isError=true
        count++
      }
      else{
        impro[i].isError=false
      }
    }
    setImprovement(impro)

    // let extraComm = extraComments
    // for(let i=0;i<extraComm.length;i++){
    //   if(extraComm[i].comment===''){
    //     extraComm[i].isError=true
    //     count++
    //   }
    //   else{
    //     extraComm[i].isError=false
    //   }
    // }
    // setExtraComments(extraComm)

    if (count>0){
      setCount(count);
      return ;
    }
    setCount(0);
    setOpen(true);
  }

  const setRating = (rating)=>{
    rating = parseInt(rating);
    let color, title;
    if (rating<=3){
      color = "#B60000";
      title = "Strong Reject";
    }
    else if (rating<=5){
      color="#DE9811";
      title="Reject";
    }
    else if (rating<=8){
      color="#84C51B";
      title="Proceed";
    }
    else if (rating>=9 && rating<=10){
      color="#0B8701";
      title="Strong Proceed";
    }
    const recomand = {
      fontFamily: 'Poppins',
      fontWeight: "500",
      fontSize: "12px",
      letterSpacing: "0.005em",
      color: "#757575",
    }

    let RatingData =  (<span style={{color:color}} className="rating-summary">{title}</span>);
    return RatingData;
  }
  const handleNewPerformance = (value, index)=>{
    let temp = newPerformance;
    temp[index].auditRating = value;
    let pref = performance;
    pref.isError = false;
    setPerformance(pref);
    setNewPerformance([...temp]);
  }

    return(
    <>
    <Box className="feedback_nav">
        INTERVIEW AUDIT FORM
    </Box>
    { successful === false?
    <Box className="feedback_body">

      <AuditSidebar sidebarData={sidebarData} downloadFile={downloadFile}/>
        <Box>

        <Modal
          style={{marginTop:'20px'}}
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
         >
         <Box className="modal_box">
           <Typography className="modal_head">
           Submit Audit Report <span onClick={handleClose}> <CloseOutlinedIcon className='crossbutton'/> </span>
           </Typography>
           <Typography sx={{ mt: 2 }}>
             Are you sure that you want to submit the audit report now?
           </Typography>
           <Box className='modal_end'>
            <Button variant="outlined" className="cancelButton" onClick={handleClose}>Cancel</Button>
            <Button variant="contained" className="submitButton" onClick={onConfirm} disabled={apiInProgress}>Confirm</Button>
           </Box>
         </Box>
        </Modal>

          <Box className="tablebox">

            <TableContainer component={Paper} className='assessment_box'>
              <Table sx={{ minWidth: 950 }} aria-label="simple table">

                <TableHead>
                  <TableRow>
                    <TableCell align="left" className="table_heading">
                      Please fill the response closest to your assessment of the Interview <span className='star'>*</span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {questions.map((row,index) => ( <>
                    <Box style={getcolor(index)} >
                    <TableRow
                      key={row.qus}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, display:'flex' }}
                    >

                      <TableCell component="th" scope="row" className='ass_qus' sx={{color: row?.isSelect== true? "red":"black"}}>
                        <span className='bold'>{index+1} </span>
                        {`${row.qus}`}
                      </TableCell>

                      <TableCell className='ass_ans'>
                          <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={questions[index]?.selected}
                                  onChange={(e)=>{handleChange(e?.target?.value,index)}}
                                >
                                  <FormControlLabel value='Yes' control={<Radio />} label="Yes" />
                                  <FormControlLabel value='No' control={<Radio />} label="No" />
                                  <FormControlLabel value='NA' control={<Radio />} label="NA" />
                                </RadioGroup>
                          </FormControl>
                      </TableCell>
                      

                    </TableRow>
                    {
                       row?.commentbox && <TableRow>
                          <TextareaAutosize
                                className="textarea-form text_area"
                                aria-label="minimum height"
                                minRows={2}
                                placeholder={row.text}
                                value={row?.comment}
                                onChange={(e)=>handlecomment(e?.target?.value,index)}
                              />
                            </TableRow>
                    }

                    </Box>
                  </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>


            <TableContainer component={Paper} className="summary_box">
              <Table sx={{ minWidth: 950 }} aria-label="simple table">

                <TableHead>
                  <TableRow>
                    <TableCell align="left" className="table_heading" sx={summary?.isError == true? {color:'red'} : {color:'black'}}>
                    Did the interviewer fill Feedback Summary appropriately? <span className='star'>*</span>

                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>

                   <Box className="blues">
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } , display:"flex"}}>

                      <TableCell component="th" scope="row" className='summ_first'>
                        1. Highlights
                      </TableCell>
                      <TableCell className='summ_second'>
                          <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={summary?.highlights}
                                  onChange={(e)=>handleSummary(e?.target?.value,'highlights')}
                                >
                                  <FormControlLabel value='Appropriate' control={<Radio />} label="Appropriate" />
                                  <FormControlLabel value='OverRated' control={<Radio />} label="Over-Rated" />
                                  <FormControlLabel value='UnderRated' control={<Radio />} label="Under-Rated" />
                                </RadioGroup>
                          </FormControl>
                      </TableCell>
                    </TableRow>
                    {
                       summary?.highCommentbox == true && summary?.highlights !== 'Appropriate' ? <TableRow>
                          <TextareaAutosize
                          className="textarea-form text_area"
                                aria-label="minimum height"
                                minRows={2}
                                placeholder="Please share the correct Highlights according to you"
                                value={summary?.highComment}
                                onChange={(e)=>handleSummary(e?.target?.value,'highComment')}
                              />
                            </TableRow> : ''
                    }
                    </Box>

                    <Box >
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, display:"flex"}}
                    >

                      <TableCell component="th" scope="row" className='summ_first'>
                        2. Lowlights
                      </TableCell>

                      <TableCell className='summ_second'>

                          <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={summary?.lowlights}
                                  onChange={(e)=>handleSummary(e?.target?.value,'lowlights')}
                                >
                                  <FormControlLabel value='Appropriate' control={<Radio />} label="Appropriate" />
                                  <FormControlLabel value='OverRated' control={<Radio />} label="Over-Rated" />
                                  <FormControlLabel value='UnderRated' control={<Radio />} label="Under-Rated" />
                                </RadioGroup>
                          </FormControl>

                      </TableCell>

                    </TableRow>
                    {
                       summary?.lowCommentbox == true && summary?.lowlights !== 'Appropriate'? <TableRow>
                          <TextareaAutosize
                          className="textarea-form text_area"
                                aria-label="minimum height"
                                minRows={2}
                                placeholder="Please share the correct Lowlights according to you"
                                value={summary?.lowComment}
                                onChange={(e)=>handleSummary(e?.target?.value,'lowComment')}
                              />
                            </TableRow> : ''
                    }
                    </Box>
                </TableBody>
              </Table>
            </TableContainer>


            <Box className="performance_box">
              <Typography className="text_text" style={performance?.isError == true? {color:'red'} : {color:'black'}} >
                Were the Interview Ratings given appropriately based on candidate performance? <span className='star'>*</span>
              </Typography>

              <Box className="performance1">
                <Typography>1. Overall Rating (<span className='semibold'> {sidebarData?.interviewer_feedback?.overall_rating} </span> - {setRating(sidebarData?.interviewer_feedback?.overall_rating)} )</Typography>
                <Box className="chooseRating overall">Choose your rating
                  <FormControl sx={{ m: 1, minWidth: 50 }}>
                    <Select
                      value={performance?.overall}
                      className='choose1'
                      onChange={(e)=>handlePerformance(e?.target?.value,'overall')}
                      label="."
                    >
                    {numbers.map((value) => (
                      <MenuItem value={value} key={value}>{value}</MenuItem>
                    ))}
                    </Select>

                  </FormControl>
                  <Typography className='greens'>
                    {
                      setRating(performance?.overall)
                    }
                  </Typography>
                </Box>
              </Box>

              <Box className="performance2">
                <Typography>2. Skill Ratings</Typography>
                  <Box className="performance_table">
                    {
                      newPerformance?.map((item,index)=>(
                        <>
                            {
                              item?.rating !== '' &&  <Box className={index%2 == true?"blues skillrow":"skillrow"} >
                                <Typography className="performance_cell">- {`${item?.type} ( ${item?.rating} )`} </Typography>
                                <Box className="chooseRating">Choose your rating
                                  <FormControl sx={{ m: 1, minWidth: 50 }}>
                                    <Select
                                      value={item?.auditRating}
                                      className='choose2'
                                      onChange={(e)=>handleNewPerformance(e?.target?.value,index)}
                                      label="."
                                    >
                                    {numbers.map((item) => (
                                      <MenuItem value={item} key={item}>{item}</MenuItem>
                                    ))}
                                    </Select>
    
                                  </FormControl>
                                </Box>
                              </Box>
                            }
                        </>
                      )
                      )
                    }
                  </Box>
              </Box>
            </Box>

            <Box className="rating_box">

              <Typography className="text_text" style={ratings?.isError == true? {color:'red'} : {color:'black'}}>Your overall rating of the interview? <span className='star'>*</span>
              
              </Typography>
              <Box className="rating_div">
                  <img src="https://w7.pngwing.com/pngs/666/250/png-transparent-angry-emoji-illustration-emoji-anger-emoticon-iphone-angry-emoji-orange-computer-wallpaper-smiley-thumbnail.png" alt="angry" className="emojiIcon" />
                  <Box className="ratings">
                        <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={ratings?.interview}
                                    onChange={(e)=>handleRatings(e?.target.value,'interview')}
                                  >
                                    <FormControlLabel value='WORST' control={<Radio />} label="Worst" />
                                    <FormControlLabel value='BAD' control={<Radio />} label="Bad" className='moveleft'/>
                                    <FormControlLabel value='OKAY' control={<Radio />} label="Okay" className='moveleft'/>
                                    <FormControlLabel value='GOOD' control={<Radio />} label="Good" className='moveleft'/>
                                    <FormControlLabel value='BEST' control={<Radio />} label="Best" className='moveleft'/>
                                  </RadioGroup>
                        </FormControl>
                  </Box>
                  <img src="http://cdn.shopify.com/s/files/1/1061/1924/products/Heart_Eyes_Emoji_grande.png?v=1571606037" alt="happy" className="emojiIcon2" />
              </Box>

              <br />

              <Typography className="text_text" style={ratings.isError == true? {color:'red'} : {color:'black'}}>Your overall rating of the feedback? <span className='star'>*</span>
              </Typography>
              <Box className="rating_div">
                  <img src="https://w7.pngwing.com/pngs/666/250/png-transparent-angry-emoji-illustration-emoji-anger-emoticon-iphone-angry-emoji-orange-computer-wallpaper-smiley-thumbnail.png" alt="angry" className="emojiIcon" />
                  <Box className="ratings">
                        <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={ratings?.feedback}
                                    onChange={(e)=>handleRatings(e?.target?.value,'feedback')}
                                  >
                                    <FormControlLabel value='WORST' control={<Radio />} label="Worst" />
                                    <FormControlLabel value='BAD' control={<Radio />} label="Bad" className='moveleft'/>
                                    <FormControlLabel value='OKAY' control={<Radio />} label="Okay" className='moveleft'/>
                                    <FormControlLabel value='GOOD' control={<Radio />} label="Good" className='moveleft'/>
                                    <FormControlLabel value='BEST' control={<Radio />} label="Best" className='moveleft'/>
                                  </RadioGroup>
                        </FormControl>
                  </Box>
                  <img src="http://cdn.shopify.com/s/files/1/1061/1924/products/Heart_Eyes_Emoji_grande.png?v=1571606037" alt="happy" className="emojiIcon2" />
              </Box>

            </Box>


                <Box className="text_box">
                  <Typography className="text_text" style={{ color: missingInfo[0]?.isError == true ? "red" : "black" }}>
                    Any missing information in the feedback? <span className='star'>*</span>
                  </Typography>
                  {missingInfo?.map((data, index) => {
                    return (
                      <Box className="text-area-comment"  alignItems={'flex-start'}>
                        <TextField
                        InputProps={{
                        classes: data?.isError ? {
                          notchedOutline: classes.notchedOutline,
                        }: {},
                      }}
                      inputProps={{
                        style: {
                          padding: '10px 10px'
                        }
                      }}
                          className="textarea-form mb-10"
                          placeholder="Type here.."
                          aria-label="minimum height"
                          name="comment"
                          fullWidth
                          sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                      }}
                          value={data?.comment}
                          onChange={(e) => handleMissingInfo(e, index)} 
                          onKeyPress={(e) => {if (e?.key === 'Enter') {
                            handleAddMoreMissingInfo();}}}
                          />
                        <Box className="field-button">
                          {index !== 0 && <button className="delete-bg" onClick={() => { handleDeleteMissingInfo(index) }} > - </button>}
                          {missingInfo.length - 1 === index && <button className="add-more" onClick={handleAddMoreMissingInfo}> + </button>}
                        </Box>
                      </Box>
                    )
                  })}
                </Box>

            <Box className="text_box">
              <Typography className="text_text" style={{color:improvement[0]?.isError == true? "red" : "black"}} >
                Scope of improvement for the interviewer? <span className='star'>*</span>
              </Typography>
              {improvement.map((impro,index)=>{
                return(
                  <Box className={`text-area-comment`} alignItems={'flex-start'}>
                    <TextField
                      InputProps={{
                        classes: impro?.isError ? {
                          notchedOutline: classes.notchedOutline,
                        }: {},
                      }}
                      inputProps={{
                        style: {
                          padding: '10px 10px'
                        }
                      }}
                      className="textarea-form mb-10 "
                      placeholder="Type here.."
                      aria-label="minimum height"
                      fullWidth
                      sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                      }}
                      name="comment"
                      value={impro?.comment}
                      onChange={(e) => handleImprovement(e, index)}
                      onKeyPress={(e) => {
                        if (e?.key === 'Enter') {
                          handleAddMoreImprovement();
                        }
                      }}
                    />
                    <Box className="field-button">
                      {index !== 0 && <button className="delete-bg" onClick={() => { handleDeleteImprovement(index) }} > - </button>}
                      {improvement.length - 1 === index && <button className="add-more" onClick={handleAddMoreImprovement}> + </button>}
                    </Box>
                  </Box>
                )
              })}
            </Box>

            <Box className="text_box">
              <Typography className="text_text">
                Extra Comments 
              </Typography>
              {extraComments.map((extraComment,index)=>{
                return(<Box className="text-area-comment"  alignItems={'flex-start'}>
                <TextField 
                    key={index}
                    inputProps={{
                        style: {
                          padding: '10px 10px'
                        }
                      }}
                    className="textarea-form mb-10"
                    placeholder="Type here.."
                    aria-label="minimum height"
                    fullWidth
                    sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                      }}
                    name="comment"
                    value={extraComment?.comment}
                    onChange={(e)=>handleExtraComments(e,index)} 
                    onKeyPress={(e) => {if (e?.key === 'Enter') {
                      handleAddMoreComments();}}}
                    />
                    <Box className="field-button">
                    {index!==0 && <button className="delete-bg" onClick={()=>{handleDeleteComments(index)}} > - </button>}
                    {extraComments.length-1===index && <button className="add-more" onClick={handleAddMoreComments}> + </button>}
                    </Box>
                
                </Box>)
              })}
              
            </Box>
          </Box>
         <Box className="lastbutton">
          <Button variant="contained" className="submitButton" onClick={handleSubmit}>Submit</Button>
         </Box>
         <Box className="warning-box">{
           count > 0 ? <Typography className="last_warning">There are <span className="bold">{count} missing</span> input fields. Please fill the remaining fields and try again.</Typography> : ''
         }</Box>
        </Box>
    </Box>
     :
       <Submitted handleSubmitClose={handleSubmitClose} />
    }
    </>)
}
export default AuditFeedbackShared;