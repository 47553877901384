import axios from "./axios";
import moment from 'moment'
import { getFileURL } from "./file";

export const fetchHistory = async () => {
    try{
        const resp = await axios.get('/interviewer/history');
        return resp.data.interview_list.map(item => ({
            ...item,
            candidateName: item.candidate_details.name,
            companyName: item.client_details.client_name,
            position: item.position_details.position_name,
            round: item.round_details.name,
            date: moment(new Date(item.interview_details.scheduled_start_timestamp * 1000)).format('DD-MM-YY'),
            time: moment(new Date(item.interview_details.scheduled_start_timestamp * 1000)).format('hh:mm a'),
            dateTime: {value: item.interview_details.scheduled_start_timestamp  || '', label: item.interview_details.scheduled_start_timestamp ? moment(new Date(item.interview_details.scheduled_start_timestamp)).format('MMM DD, YYYY | hh:mm a') : ''},
            id: item.interview_details.id,
            isReviewd: true,
            feedback: {value: item.interview_details.feedback_link || '', label: item.interview_details.feedback_link ? <a target = "_blank" rel = "noreferrer" href = {item.interview_details.feedback_link}>View</a> : <div></div> }
        })).sort((a, b) => b.interview_details.scheduled_start_timestamp - a.interview_details.scheduled_start_timestamp);
    } catch(err){
        throw(err)
    }
};

export const fetchHistoryRange = async (from_date,to_date) => {
    try{
        const resp = await axios.post('/interviewer/history', { from_date,to_date});
        return resp?.data?.interview_list?.map(item => ({
            ...item,
            candidateName: item?.candidate_details?.name,
            companyName: item?.client_details?.client_name,
            position: item?.position_details?.position_name,
            round: item?.round_details?.name,
            date: moment(new Date(item?.interview_details?.scheduled_start_timestamp * 1000)).format('DD-MM-YY'),
            time: moment(new Date(item?.interview_details?.scheduled_start_timestamp * 1000)).format('hh:mm a'),
            dateTime: {value: item?.interview_details?.scheduled_start_timestamp  || '', label: item?.interview_details?.scheduled_start_timestamp ? moment(new Date(item?.interview_details?.scheduled_start_timestamp)).format('MMM DD, YYYY | hh:mm a') : ''},
            id: item.interview_details.id,
            isReviewd: true,
            feedback: {value: item?.interview_details?.feedback_link || '', label: item?.interview_details?.feedback_link ? <a target = "_blank" rel = "noreferrer" href = {item?.interview_details?.feedback_link}>View</a> : <div></div> }
        })).sort((a, b) => b?.interview_details?.scheduled_start_timestamp - a?.interview_details?.scheduled_start_timestamp);
    } catch(err){
        throw(err)
    }
};


export const getInterviewerStatsRange = async (from_date,to_date) => {
    try{
       const response = await axios.post('/interviewer/user_interviewer_stats',{from_date,to_date});
       return response?.data?.data;
    } catch(err){
        throw(err)
    }
}


export const getCandidateFeedbackIndex = async (payload) => {
    try{
        const response = await axios.post('/candidate/feedback-index', payload);
        return response;
     } catch(err){
         throw(err)
     }

}
const handleUrl = async (e, url) => {
    let data = await getFileURL(url);
    window.open(data, "_blank");
    e.preventDefault();
};

export const fetchHistoryWithPagination = async (payload) => {
    try{
        const resp = await axios.post('/interviewer/history', payload);
        return resp?.data;
    } catch(err){
        throw(err)
    }
};


function statusMapper(interview_details){
    if(interview_details.interview_status == 'COMPLETED'){
       return {value: 'Completed', label: <div class = "badge badge-success">Completed</div>}
    }
    else if(interview_details.interview_status == 'CANCELLED'){
        return {value: 'Cancelled', label: <div class = "badge badge-info">Cancelled (Chargeable)</div>}
    }
    else if(interview_details.interview_status == 'DELETED'){
        return {value: 'Deleted', label: <div class = "badge badge-danger">Cancelled (Non-Chargeable)</div>}
    }else{
        return {value: '--', label: <div class = "badge">--</div>}
    }
}

export const saveFeedback = async (data) => {
    try{
        const resp = await axios.post(`/interview/edit/${data.interview_id}`, data);
        return resp.data
    } catch(err){
        throw(err)
    }
}



export const getCancelledInterviews = async () => {
    try{
        const resp = await axios.get(`/interviewer/cancelled-interview`);
        return resp.data.cancelled_interview_count
    } catch(err){
        console.log(err)
    }
}

export const getClientsAndPositions = async () => {
    try{
        const resp = await axios.get(`/interviewer/client_and_position`);
        return resp.data
    } catch(err){
        console.log(err)
    }
}

export const getCancelledInterviewsRange = async (from_date,to_date) => {
    try{
        const resp = await axios.post(`/interviewer/cancelled-interview`, { from_date,to_date });
        return resp?.data?.cancelled_interview_count
    } catch(err){
        console.log(err)
    }
}

export const getPassPercentage = async (payload) => {
    try{
        const resp = await axios.post(`/percentage_pass/`, payload);
        return resp?.data
    } catch(err){
        console.log(err)
    }
}

export const getTatFeedback = async (payload) => {
    try {
        const resp = await axios.post(`/interviewer/tat_feedback`, payload);
        return resp;
    } catch(err) {
        console.log(err)
    }
}