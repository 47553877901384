import React, { useMemo, useEffect, useState } from "react";
import './styles.scss';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import axios from "../../services/axios"

/*
'url' is the url of the page to show; can be absolute (public), or in format "bucket_name=XXXX&s3_key=XXXXXX"
'show' is boolean for showing/hiding iframe
'onClose' function to close iframe
*/

const IframeOverlay = ({ url, show, onClose }) => {
  const isS3Url = useMemo(() => (url?.startsWith("bucket_name")), [url]); // Boolean; tracks if url is a protected S3 bucket url or absolute url (public)
  const [s3GeneratedUrl, setS3GeneratedUrl] = useState(null); // Url genrated from blob, only for S3 urls
  const [s3UrlError, setS3UrlError] = useState(false); // State to track if error ocurred on downloading from s3 bucket
  const [s3UrlLoading, setS3UrlLoading] = useState(true); // State to track if doc is downloading from S3 bucket

  // Gets public PDf url for the s3 bucket
  useEffect(() => {
    (async () => {
      if (isS3Url) {
        setS3UrlLoading(true); // Set loading to true
        setS3UrlError(false); // Set error to false
        const res = await axios.get(`/utils/file/download?${url}`)
        if (res.status === 200) { // If succesful
          setS3GeneratedUrl(res.data);
        } else { // If error
          setS3UrlError(true);
        }
        setS3UrlLoading(false); // Set loading to false
      }
    })()
  }, [isS3Url, url])

  return (!show ? <></> :
    <Box className="iframe-parent-box">
      {/* Close button */}
      <Box className="close-button-container">
        <IconButton onClick={onClose} className="close-icon-button">
          <CloseIcon className="close-icon"/>
        </IconButton>
      </Box>

      {/* FOR S3 BUCKET URL */}
      {isS3Url && (s3UrlLoading ?
        <Loader error={s3UrlError} /> :
        (s3UrlError ?
          <Error/> :
          <iframe src={`${s3GeneratedUrl}`} title="Iframe overlay" />
        )
      )}

      {/* FOR ABSOLUTE URL */}
      {!isS3Url &&
        <iframe src={url} title="Iframe overlay" />
      }
    </Box>
  );
};

export default IframeOverlay;


// Loader component
const Loader = ({ error }) => {
  return (
    <Box className="loader-wrapper-box">
      <Typography className="status-message" variant="body1">Loading...</Typography>
    </Box>
  )
}

// Error component
const Error = ({ error }) => {
  return (
    <Box className="error-wrapper-box">
      <Typography className="status-message" variant="body1">
        Error, please try again!
      </Typography>
    </Box>
  )
}