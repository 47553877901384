import "./JobDescription.scss";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState } from "react";

function JobDescription({setViewDescription,demoData}) {
  
  const handleJobDescription =() =>{  console.log("cross click")
    setViewDescription(false)
  } 

  return (
    <Box id="popUpContainer" >
      <Box id="headerContainer">
        <Typography
          variant="h6"
          component="div"
          className="jobDescriptionHeader"
          gutterBottom
        >
          Job Description
        </Typography>

        <CloseIcon
          className="jobDescriptionHeader"
          onClick={handleJobDescription}
        />
      </Box>

      <Box id="jobDescriptionContentBox">
        {demoData?.audit_details?.position_details?.position_description}
      </Box>
    </Box>
  );
}

export default JobDescription;
/* <Box className="jobDescrtiptionContent">About InterviewVector:</Box>
        <Box className="jobDescrtiptionContent">
          We at InterviewVector are solving the toughest problem of all -
          tech-hiring. We are currently being used by the likes of Flipkart,
          InMobi, Dunzo, MindTickle, BharatPe and several other unicorns.
        </Box>
        <Box className="jobDescrtiptionContent">
          Founded by the computer science duo brother - Sachin Mittal (Tower
          Research Capital, EightFold, IITG) and Aashish Mittal (Tower Research
          Capital, IITD Silver Medalist), we have been on a hockeystick growth
          journey.
        </Box>
        <Box className="jobDescrtiptionContent">
          We have already grown to 2+ million ARR in less than 1.5 years of
          incorporation. And expanding the platform to US and South East Asian
          companies.
        </Box>
        <Box className="jobDescrtiptionContent">Requirements:</Box>

        <Box className="jobDescrtiptionContent">
          <Typography variant="span" className="jobDescrtiptionContentDetails">
            2-5 Years of experience in Backend Development.
          </Typography>
          <br />
          <Typography variant="span" className="jobDescrtiptionContentDetails">
            2-5 Years of experience in Backend Development.
          </Typography>
          <br />
          <Typography variant="span" className="jobDescrtiptionContentDetails">
            2-5 Years of experience in Backend Development.
          </Typography>
          <br />
        </Box> */