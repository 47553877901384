import { createContext, useContext, useState } from 'react';

const AvailabilityContext = createContext(); 
const AvailabilityContextUpdate = createContext(); 

export const useAvailabilityContext = () => {
    return useContext(AvailabilityContext)
}

export const useAvailabilityContextUpdate = () => {
    return useContext(AvailabilityContextUpdate)
}

export const AvailabilityProvider = ({ children }) => {

    const [isEditing,setIsEditing] = useState(false);
    const [link,setLink] = useState('#');

    const changeIsEditing = (state) => {
        setIsEditing(state);
    }

    const changeLink = (state) => {
        setLink(state);
    }

    return(
        <AvailabilityContext.Provider value={{ isEditing, link }}>
            <AvailabilityContextUpdate.Provider value={{ changeIsEditing, changeLink }}>
                {children}
            </AvailabilityContextUpdate.Provider>
        </AvailabilityContext.Provider>
    )
}
