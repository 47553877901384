import { SET_USER, USER_TIMEZONE_UPDATE } from "../actions/User";

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_USER:
      return action.payload;
    case USER_TIMEZONE_UPDATE:
      return {
        ...state,
        user_config:{
          ...state.user_config,
          timezone : action.payload
        }
      };
    default:
      return state;
  }
};

export default userReducer;
