import { StyleSheet } from '@react-pdf/renderer';

export const stylesPDF = StyleSheet.create({
    page: {
        backgroundColor: '#F0F0F0',
        padding: '32 16',
    },
    section: {
        margin: 10,
        padding: 10,
    },
    rowstyle: {
        display: 'flex',
        flexDirection: 'row',
    },
    rowstyleWrap: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    columnstyle: {
        display: 'flex',
        flexDirection: 'column',
    },
    detailsBoxStyle: {
        border: '1 solid #aeb6cd',
        boxSizing: 'border-box',
        filter: 'drop-shadow(1 2 25 rgba(18, 63, 188, 0.07))',
        justifyContent: 'center',
        padding: 15,
    },
    detailsBoxTitle: {
        fontFamily: "Poppins",
        fontWeight: 'bold',
        fontSize: '10.5',
        marginBottom: '11',
        letterSpacing: '0.65',
        textTransform: 'uppercase',
        textAlign: 'start',
        color: '#011c67',
    },
    detailsBoxLabelValue: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: '10',
        letterSpacing: '0.3',
        color: '#3d3d3d',
        width: '50%',
        marginBottom: '4',
    },
    detailsValue: {
        fontWeight: 400,
    },

    strongProceedBg: {
        border: '1 solid #0F6908',
        boxShadow: '1 2 20 -2 rgba(15, 105, 8, 0.3)',
    },
    proceedBg: {
        border: '1 solid #71A41F',
        boxShadow: '1 2 20 -2 rgba(113, 164, 31, 0.3)',
    },
    rejectBg: {
        border: '1 solid #DA6C1D',
        boxShadow: '1 2 20 -2 rgba(218, 108, 29, 0.3)',
    },
    strongRejectBg: {
        border: '1 solid #E23939',
        boxShadow: '1 2 20 -2 rgba(226, 57, 57, 0.3)',
    },
    strongProceedColor: {
        color: '#0F6908',
    },
    proceedColor: {
        color: '#71A41F',
    },
    rejectColor: {
        color: '#DA6C1D',
    },
    strongRejectColor: {
        color: '#E23939',
    },
    overallRecommendationText: {
        fontFamily: "Poppins",
        fontWeight: 'bold',
        fontSize: '18',
        lineHeight: '130%',
        letterSpacing: '0.5',
        textTransform: 'uppercase',
        color: '#3d3d3d',
    },
    overallRecommendationHeading: {
        fontFamily: "Poppins",
        fontWeight: '600',
        fontSize: '12',
        lineHeight: '130%',
        letterSpacing: '0.5',
        color: '#3d3d3d',
    },
    vertical: {
        border: '1 solid #cdcdcd',
    },
    horizontalDivider: {
        border: '1 solid #cdcdcd',
        width: "100%",
        margin: "10 0",
        display: 'block',
        content: " "
    },
});

export const stylesFeedbackSummary = StyleSheet.create({
    previewSubheaderTitle: {
        fontFamily: "Poppins",
        fontWeight: 'bold',
        fontSize: 13.5,
        letterSpacing: 1.1,
        textTransform: 'uppercase',
        color: '#0d3270',
    },
    previewSubheaderSubtitle: {
        fontFamily: "Poppins",
        fontWeight: 'bold',
        fontSize: 11,
        lineHeight: '117.4%',
        letterSpacing: 0.3,
        color: '#374671',
    },
    feedbackSummarySubheading: {
        fontFamily: "Poppins",
        fontWeight: 'bold',
        fontSize: 12,
        letterSpacing: 0.65,
        textTransform: 'uppercase',
        color: '#494949',
        marginTop: 22,
        marginBottom: 8,
    },
    feedbackSummaryValue: {
        fontFamily: "Poppins",
        fontWeight: 'medium',
        fontSize: 11,
        letterSpacing: 0.3,
        color: '#3d3d3d',
        whiteSpace: 'pre-line',
        marginBottom: 8,
        wordBreak: 'break-word',
        textOverflow: 'ellipsis'
    },
    fieldHeader: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: 11,
        lineHeight: '117%',
        letterSpacing: 0.3,
        textTransform: 'uppercase',
        color: '#0D3270',
        marginBottom: 8,
        marginTop: 16,
    },
    feedbackSummaryRatingText: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: 11,
        lineHeight: '130%',
        textAlign: 'center',
        letterSpacing: 0.3,
        color: '#3d3d3d',
        marginTop: 12,
        width: '100%',
    },
    feedbackEvalSeparator: {
        border: '1 solid #CDCDCD',
        marginLeft: 16,
        marginRight: 16,
    },
    suspicionCheating: {
        backgroundColor: '#FDB7B7',
        borderRadius: '16px',
        padding: '5px',
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: 11,
        lineHeight: '130%',
        textAlign: 'center',
        letterSpacing: 0.3,
        color: '#B22B2B',
        marginTop: 12,
        marginBottom: 12,
        width:'180px',
    }
})

export const styleQuestions = StyleSheet.create({
    extraCommentsText: {
        fontFamily: "Poppins",
        fontStyle: 'italic',
        fontWeight: 700,
        fontSize: 11,
        lineHeight: '117.4%',
        letterSpacing: 0.3,
        color: '#374671',
    },
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10,
    },
    tableHeader: {
        flexDirection: 'row',
        backgroundColor: '#f2f2f2',
        height: 24,
        flexGrow: 1,
        textAlign: 'center',
        alignItems: 'center',
    },
    theoryTableHeaderComponent: {
        textAlign: 'center',
        fontFamily: "Poppins",
        fontWeight: 'medium',
        width: '33%',
        borderRightColor: '#bcc7e6',
        borderRightWidth: 1,
        fontSize: 11,
        lineHeight: '117.4%',
        letterSpacing: 0.3,
        color: '#374671',
    },
    theoryTableHeaderComponentLastChild: {
        borderRightWidth: 0,
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'column',
    },
    tableRowContainer: {
        width: '33%',
        verticalAlign: 'top',
        padding: 8,
    },
    codingTableRowContainer: {
        width: '25%',
        verticalAlign: 'top',
        padding: 8,
    },
    tableData: {
        fontFamily: "Poppins",
        fontWeight: 'medium',
        fontSize: 11,
        lineHeight: '117%',
        letterSpacing: 0.3,
        color: '#3d3d3d',
        whiteSpace: 'pre-line',
        paddingLeft: 16,
        textIndent: -11,
        marginBottom: 5,
        wordBreak: 'break-word',
    },
    codingQuestionsQuestions: {
        fontFamily: "Poppins",
        fontWeight: 'bold',
        fontSize: 11,
        lineHeight: '117.4%',
        letterSpacing: 0.3,
        color: '#374671',
        padding: 16,
    },
    theoryQuestionsUnformatted: {
        lineBreak: 'anywhere',
        fontFamily: "Poppins",
        fontSize: 11,
        letterSpacing: 0.3,
        color: '#3d3d3d',
    },
    codingSolution: {
        fontFamily: "Poppins",
        fontWeight: 'bold',
        fontSize: 10,
        lineHeight: '117.4%',
        letterSpacing: 0.3,
        opacity: 0.78,
        whiteSpace: 'nowrap',
        alignItems: 'center',
        marginRight: 16,
    },
    codingSolutionTrue: {
        color: '#59A41F',
    },
    codingSolutionFalse: {
        color: '#B44545',
    },
    codingHldLldLabel: {
        fontFamily: "Poppins",
        fontWeight: 'bold',
        fontSize: 10,
        letterSpacing: 0.3,
        color: '#374671',
        width: '15%'
    },
    flipkartPreviewHeading: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: 11,
        lineHeight: '117.4%',
        letterSpacing: 0.3,
        textTransform: 'uppercase',
        color: '#0D3270',
        margin: '16 0 8 0',
    },
    theoryAnswers: {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: 11,
        lineHeight: '132%',
        letterSpacing: 0.3,
        color: '#3d3d3d',
        margin: 16,
    },
    codingPart: {
        fontFamily: "Poppins",
        fontStyle: 'italic',
        backgroundColor: '#000',
        fontSize: 10,
        lineHeight: '132%',
        letterSpacing: 0.3,
        color: '#fff',
        padding: 16,
        margin: '8 0'
    },
})
