import React from 'react'
import emptyBoxImg from '../../images/empty-box.svg';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
const EmptyBox = ({text}) => {
    return (
        <Box className='mb-40' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
            <img src={emptyBoxImg} alt="empty box img" />
            <Typography>{text || 'Something Went Wrong..!!'}</Typography>
        </Box>
    )
}

export default EmptyBox
