import React, { useState, useEffect } from "react";
import Modal from '@material-ui/core/Modal';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import cogoToast from "cogo-toast";

import './SkillEvaluationPopup.scss';
import { CommonFieldsType } from "../../Constants/FeedbackConstants";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    modal: {
        overflow: 'scroll',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '80%',
        maxHeight: '80%',
        position: 'relative',
        overflow: 'scroll',
        backgroundColor: '#ffffff',
        border: '1px solid #f2f2f2',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }
}));

const coreCompetenciesSkillsEval = [
    { type: "Requirement Gathering", checked: "", disabled: false },
    { type: "Problem Solving", checked: "", disabled: false },
    { type: "Working Code", checked: "", disabled: false },
    { type: "Development Language", checked: "", disabled: false },
    { type: "In-Depth Knowledge", checked: "", disabled: false },
    { type: "Code Quality", checked: "", disabled: false },
    { type: "Backend Concepts", checked: "", disabled: false },
    { type: "Debugging", checked: "", disabled: false },
    { type: "API Contract", checked: "", disabled: false },
    { type: "DB Schema", checked: "", disabled: false },
    { type: "Classes & Methods", checked: "", disabled: false },
    { type: "Entities and Relationships", checked: "", disabled: false },
    { type: "OO Concepts", checked: "", disabled: false },
    { type: "Design Approach & Choices", checked: "", disabled: false },
    { type: "High-Level Design", checked: "", disabled: false },
    { type: "Storage/Bandwidth Estimation", checked: "", disabled: false },
    { type: "System APIs", checked: "", disabled: false },
    { type: "Sharding", checked: "", disabled: false },
    { type: "Caching", checked: "", disabled: false },
    { type: "Scalability/Fault Tolerance", checked: "", disabled: false },
    { type: "ReactJS", checked: "", disabled: false },
    { type: "HTML/CSS", checked: "", disabled: false },
    { type: "AngularJS", checked: "", disabled: false },
    { type: "Architecture Knowledge", checked: "", disabled: false },
    { type: "Flutter Concepts", checked: "", disabled: false },
    { type: "React Native Concepts", checked: "", disabled: false },
    { type: "Infrastructure Knowledge", checked: "", disabled: false },
    { type: "Linux Fundamental", checked: "", disabled: false },
    { type: "Code Hands-On", checked: "", disabled: false },
    { type: "AWS", checked: "", disabled: false },
    { type: "Manual Testing", checked: "", disabled: false },
    { type: "REST API", checked: "", disabled: false },
    { type: "Appium Automation", checked: "", disabled: false },
    { type: "Selenium Automation", checked: "", disabled: false },
    { type: "Backend/API Automation", checked: "", disabled: false },
    { type: "NLP", checked: "", disabled: false },
    { type: "ML", checked: "", disabled: false },
    { type: "MATHS", checked: "", disabled: false },
    { type: "STATISTICS", checked: "", disabled: false },
    { type: "Database (SQL / NoSQL)", checked: "", disabled: false },
    { type: "Data Warehouse & Data Lake", checked: "", disabled: false },
    { type: "Data Architecture & Design", checked: "", disabled: false },
    { type: "Apache Kafka", checked: "", disabled: false },
]

const behavioralCompetenciesSkillsEval = [
    { type: "Representation Skills", checked: "", disabled: false },
    { type: "Communication", checked: "", disabled: false },
    { type: "Collaborative Approach", checked: "", disabled: false },
    { type: "Tools Understanding", checked: "", disabled: false },
    { type: "Structured Thought Process", checked: "", disabled: false },
    { type: "Data Visualization", checked: "", disabled: false },
    { type: "Product Thinking", checked: "", disabled: false },
]

const othersSkillsEval = [
    { type: "Business Understanding", checked: "", disabled: false },
    { type: "Project Ownership", checked: "", disabled: false },
    { type: "User Research", checked: "", disabled: false },
    { type: "UX - Flows, IA, Affinity Maps", checked: "", disabled: false },
    { type: "UI & Visual Design", checked: "", disabled: false },
    { type: "Prototyping", checked: "", disabled: false },
    { type: "Usability Testing", checked: "", disabled: false },
    { type: "Design Methodologies", checked: "", disabled: false },
    { type: "Design Components", checked: "", disabled: false },
]

const SkillEvaluationPopup = ({ openSkillEvalModal, setSkillEvalModalVisibility, evaluationSkillsNestedFields = {}, addSkillsList = {}, handleChangeEvalSkills }) => {
    const classes = useStyles();

    const [newSkill, setNewSkill] = useState("");
    const [newSkillsEvalList, setNewSkillsEvalList] = useState([]);

    const [genericSkilsEvalList, setGenericSkilsEvalList] = useState(
        {
            ["coreCompetencies"]: { header: "CORE COMPETENCIES", list: coreCompetenciesSkillsEval },
            ["behavioralCompetencies"]: { header: "BEHAVIORAL COMPETENCIES", list: behavioralCompetenciesSkillsEval },
            ["others"]: { header: "OTHERS", list: othersSkillsEval },
        }
    );

    const closeAddSkillDialog = (e) => {
        setSkillEvalModalVisibility(false);
        setNewSkill("")
    };

    const confirmAddSkillDialog = (e) => {
        setSkillEvalModalVisibility(false);
        setNewSkill("")

        //To handle what will happen when clicked on confirm....
    };

    useEffect(() => {
        let copyGenericSkilsEvalData = { ...genericSkilsEvalList };
        let newSkillsList = [];

        Object.keys(evaluationSkillsNestedFields)?.find((findData) => {
            let dataChecked = false;

            Object.keys(copyGenericSkilsEvalData).map((data) => {
                copyGenericSkilsEvalData[data]?.list?.map((listData, index) => {

                    if (evaluationSkillsNestedFields[findData]?.question?.toLocaleLowerCase() === listData?.type?.toLocaleLowerCase()) {
                        copyGenericSkilsEvalData[data].list[index].checked = true;
                        copyGenericSkilsEvalData[data].list[index].disabled = true;
                        dataChecked = true;
                        return true;
                    }
                })
            })

            //The button check is to ensure that add skill button will not copy into new skill data.
            if (!dataChecked && evaluationSkillsNestedFields[findData]?.field_type !== CommonFieldsType.BUTTON.label) {
                newSkillsList.push({ type: evaluationSkillsNestedFields[findData]?.question, checked: true, disabled: true })
            }
        })


        Object.keys(addSkillsList)?.find((findData) => {
            let dataChecked = false;

            Object.keys(copyGenericSkilsEvalData).map((data) => {
                copyGenericSkilsEvalData[data]?.list?.map((listData, index) => {

                    if (findData?.toLocaleLowerCase() === listData?.type?.toLocaleLowerCase()) {
                        copyGenericSkilsEvalData[data].list[index].checked = true;
                        copyGenericSkilsEvalData[data].list[index].disabled = false;
                        dataChecked = true;
                        return true;
                    }
                })
            })

            //The button check is to ensure that add skill button will not copy into new skill data.
            if (!dataChecked && findData?.field_type !== CommonFieldsType.BUTTON.label) {
                newSkillsList.push({ type: findData, checked: true, disabled: false })
            }
        })

        setGenericSkilsEvalList(copyGenericSkilsEvalData);
        setNewSkillsEvalList(newSkillsList)  // setNewSkillsEvalList(feedbackSummaryData?.newSkillsEvalList || newSkillsList)
    }, [])


    const addNewSkill = (e) => {
        let error = false;

        Object.keys(evaluationSkillsNestedFields)?.some((data) => {
            if (evaluationSkillsNestedFields[data]?.question?.toLocaleLowerCase() === newSkill?.toLocaleLowerCase()) {
                cogoToast.error("This skill is already added. Please add another one.")
                error = true;
            }
            if (error) return;
        })

        Object.keys(addSkillsList)?.some((data) => {
            if (data?.toLocaleLowerCase() === newSkill?.toLocaleLowerCase()) {
                cogoToast.error("This skill is already added. Please add another one.")
                error = true;
            }
            if (error) return;
        })

        Object.keys(genericSkilsEvalList).some((data) => {
            genericSkilsEvalList[data]?.list?.some((listData) => {
                if (listData?.type?.toLocaleLowerCase() === newSkill?.toLocaleLowerCase()) {
                    cogoToast.error("This skill is already added. Please add another one.")
                    error = true;
                }
                if (error) return;
            })
        })

        if (!error) {
            let copyData = [...newSkillsEvalList];
            copyData.push({ type: newSkill, checked: true, disabled: false })
            setNewSkillsEvalList(copyData);
            setSkillEvaluationList({ type: newSkill, checked: true, disabled: false }, 'new')
            setNewSkill("")
        }
    }

    const handleNewSkillsSelect = (e, index) => {
        let copyData = [...newSkillsEvalList];
        copyData[index].checked = e?.target?.checked;
        setNewSkillsEvalList(copyData);
        setSkillEvaluationList(copyData[index], 'new')
    }

    const renderGenericEvalList = (keyData, listData, indexList) => {
        const handleSkillsEvalSelect = (e) => {
            let copyData = { ...genericSkilsEvalList }
            copyData[keyData].list[indexList].checked = e?.target?.checked
            setGenericSkilsEvalList(copyData);
            setSkillEvaluationList(copyData[keyData].list[indexList], 'generic')
        }

        return (
            <Grid item sm={3} md={3} style={{ padding: "0px", width: "25%" }}>
                <FormGroup>
                    <FormControlLabel className="add-skill-checkbox-label"
                        control={<Checkbox name={listData?.type} checked={listData?.checked} color="primary" disabled={listData?.disabled} onChange={(value) => handleSkillsEvalSelect(value)} style={{ padding: "0px 5px" }} />}
                        label={<div className='add-skill-checkbox-label'> {listData?.type} </div>} />
                </FormGroup>
            </Grid>
        )
    }

    const setSkillEvaluationList = (newData, skillType) => {
        //Need to handle only add new skills since rest eval list checkbox is disabled...

        let copyData = { ...addSkillsList };
        let addedData = Object.keys(copyData)?.find((data) => {
            if (data === newData?.type) {
                return true;
            }
        });

        if (newData?.checked && !addedData) {
            copyData[newData?.type] = null;
        } else if (!newData?.checked && addedData) {
            delete copyData?.[newData?.type]
        }

        handleChangeEvalSkills(copyData);
    }

    return (
        <Modal
            open={openSkillEvalModal}
            disableBackdropClick
            onClose={closeAddSkillDialog}
            className={classes.modal}
            aria-labelledby="add-skill-label"
            aria-describedby="add-skill-description">

            <div className={classes.paper}>

                <Box display='flex' justifyContent='space-between'>
                    <div className="add-skill-modal-header">Select skills </div>
                    <IconButton aria-label="close" onClick={closeAddSkillDialog}> <CloseIcon /> </IconButton>
                </Box>
                <div className="add-skill-subtitle">Please select among these skills to rate upon</div>
                <hr />


                <Box display='flex' flexDirection='column'>
                    <Box display='flex' flexDirection='column'>

                        {
                            Object.keys(genericSkilsEvalList)?.map((keyData, index) => {
                                return (
                                    <>
                                        <div className="add-skill-checkbox-header">{genericSkilsEvalList[keyData]?.header}</div>

                                        <Grid container>
                                            {genericSkilsEvalList[keyData]?.list?.map((listData, indexList) => {
                                                return renderGenericEvalList(keyData, listData, indexList)
                                            })}
                                        </Grid>
                                    </>)
                            })
                        }
                    </Box>

                    <Box display='flex' mt={"20px"}>
                        <Grid container >
                            {
                                newSkillsEvalList?.map((data, index) => {
                                    return (
                                        <Grid item sm={3} md={3} style={{ padding: "0px", width: "25%" }}>
                                            <FormGroup>
                                                <FormControlLabel className="add-skill-checkbox-label"
                                                    control={<Checkbox name={data?.type} checked={data?.checked} color="primary" disabled={data?.disabled} onChange={(e) => handleNewSkillsSelect(e, index)} style={{ padding: "0px 5px" }} />}
                                                    label={<div className='add-skill-checkbox-label'> {data?.type} </div>} />
                                            </FormGroup>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                </Box>


                <div className="add-skill-subtitle mt-10"> <span style={{ fontWeight: "bold" }}>NEW SKILL?</span> You can also add new skills from here.</div>
                <Box display='flex' className="mt-20">
                    <TextField
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        placeholder="Enter Skill..."
                        name="newSkill"
                        value={newSkill}
                        onChange={(e) => setNewSkill(e?.target?.value)}
                    />
                    <button className={`add-skills ml-10 ${newSkill?.trim() === "" ? "blur-8" : ""}`} disabled={newSkill?.trim() === ""} onClick={addNewSkill}>  + Add Skills</button>
                </Box>

                <hr />
                <Box display='flex' justifyContent='flex-end'>
                    <button className="next-button" onClick={confirmAddSkillDialog}> Confirm </button>
                </Box>
            </div>
        </Modal>
    )


}

export default SkillEvaluationPopup;