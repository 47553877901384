import React, {useState, useEffect} from 'react'
import moment from 'moment'
import {Modal} from 'react-bootstrap'
import {GrFormClose} from 'react-icons/gr'
import { useDispatch, useSelector } from 'react-redux';
import { requestsAction } from '../../store/actions/Requests'
import { openResume,getFileURL } from '../../services/file'
import axios from '../../services/axios'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import './DetailedRequests.scss';
import RequestModal from '../Dashboard/Components/RequestModal';

const DetailedRequests = ({fetchData, data, setShow, selected, setSelected, removeRequest, accepting, setAccepting}) => {

    const dispatch = useDispatch();
    const requests = useSelector((state) => state && state.requests && state.requests && state.requests.list && state.requests.list);
    const loading=false;
    const [selectedSlot, setSelectedSlot] = useState('');
    const [error, setError]= useState(false)
    const [openRequestModal, setOpenRequestModal] = useState(false);
    const [interviewSlot,setInterviewSlot] = useState('');

    
    useEffect(() => {
        if(data.all_round_details === undefined) getRoundHistroy();
        // eslint-disable-next-line
    }, [])

    const getRoundHistroy = async () => {
        try{
            const req_body = {
                "candidate_id": data.candidate_name.id,
                "position_id": data.position.id,
                "client_id": data.company_name.id
            }
            const res = await axios.post('/interview/history', req_body)
            const newRequests = [...requests];
            const index = newRequests.findIndex(item => item.id === data.id);
            newRequests[index].all_round_details = res.data.map(item => item.interview_details);
            dispatch(requestsAction(newRequests))
        } catch(err){
        }
    }

    const handleAccept = () => {
        setOpenRequestModal(true);
    }

    const handleUrl = async (e, url) => {
        let data = await getFileURL(url);
        window.open(data, "_blank");
        e.preventDefault();
      };
      const rejectRequest = async ()=> {
            setShow(false)
      }

    return (
        <div className="modal-content DetailedRequests">
        <Modal.Body className="modal-body">
            <div className="close pointer">
                <span onClick={() => setShow(false)}><GrFormClose /></span>
            </div>
            <div className = 'w-100 mt-3'>
                <div className = '' id = 'details'>
                    <div className = 'h3 mb-2'>{data.company_name.client_name}</div>
                    <div className = 'mt-3 mb-3'>{data.position.position_name}</div>
                    <div className = 'mt-3 mb-3'>{data?.round?.name}</div>
                    {data.candidate_name.resume_url 
                    ? <div className = 'my-3 btn-link pointer' onClick = {() => openResume(data.candidate_name.resume_url)}> <span>Resume</span> <DescriptionOutlinedIcon className="m-b-10"/></div>
                    : <div className='resume-optional-warning'>*The resume has not been uploaded for this candidate.</div>
                    }
                </div>

                <div className = 'border-top mt-4 pt-4 mb-4' id = 'round-details'>
                    <div className = 'mb-4 h4'>Round Details</div>
                    <div className = 'row mb-3'>
                        <div className = 'small col-6'>Round Name:</div>
                        <div className = 'small col txt-align-right'>{data.round.name}</div>
                    </div>
                        {
                            <div className='row'>
                                <div className='small col-6'>Round Guide:</div>
                                {
                                        data?.round?.guide_id
                                            ? <a href={`/interview-guides/preview?guide_id=${data?.round?.guide_id}`} target="_blank" rel="noreferrer" className='small col btn-link txt-align-right'> View Guide</a>
                                            : data?.round?.interview_guide_link ?
                                                <a href={data?.round?.interview_guide_link} target="_blank" rel="noreferrer" className='small col btn-link txt-align-right' >Guide Link  </a> : "N/A"
                                 }

                            </div>
                        }
                </div>

                <div className = 'border-top mt-4 pt-4 mb-4' id = 'round-details'>
                    <div className = 'mb-3 h4'>Comments</div>
                    {
                            data?.interview_details?.comments &&
                            data?.interview_details?.comments?.length>0 &&
                            data?.interview_details?.comments?.map((comment,index)=>{
                                const date = typeof (comment?.timestamp) === 'string' ? moment(comment?.timestamp, "DD/MM/YYYY hh:mm:ss") : moment.unix(comment?.timestamp);
                                return (
                                    <>
                                        <div className='row mb-2'>
                                            <span className='small col-5'> <b>{comment?.user?.name}</b></span>
                                            <text className='small col align-right'> {date.format('lll')} </text>
                                        </div>
                                        <div className='row mb-2'>
                                            <span className='small col'>{date.fromNow()}</span>
                                        </div>
                                        <div className='row mb-2'>
                                        <div className='small col'>{typeof (comment?.content) === "string" ? comment?.content : JSON.stringify(comment?.content)}</div>
                                        </div>
                                        {
                                            index < data?.interview_details?.comments?.length-1 &&
                                            <hr/>
                                        }
                                    </>
                                )
                            })
                    }
                    {
                            (!data?.interview_details || !data?.interview_details?.comments ||
                            data?.interview_details?.comments?.length === 0) &&
                            <div>No comments available</div>
                    }
                </div>

                <div className = 'border-top mt-4 pt-4 mb-4' id = 'round-details'>
                    <div className = 'mb-4 h4'>Round History</div>
                    <table className = 'w-100'>
                        {data.all_round_details ? data.all_round_details.map(item => <tr className = ''>
                            <td className = 'small py-2 '>{item.round_name}</td>
                            <td className = 'small txt-align-right'>{item?.scheduled_start_timestamp ? moment(item?.scheduled_start_timestamp*1000).format('MMM DD, YYYY') : "-"}</td>
                            <td className = 'small'>
                                {item?.feedback_report_link && <div className = 'my-3 btn-link pointer' onClick = {(e) => handleUrl(e,item?.feedback_report_link)}>Feedback</div>}
                                {!item?.feedback_report_link && item?.feedback_status &&
                                <a className='mt-4 mr-3 ml-3' href={`/interview-feedback?i_id=${item?.id}`}>
                                    Feedback
                                </a>}
                            </td>
                            <td className = 'small text-success txt-capitalize txt-align-right'>{item.interview_status.toLowerCase()}</td>
                        </tr>) : 'Loading...'}
                    </table>
                </div>

                <div className = 'mt-4 pt-4 h4 border-top'>Preferred Slots</div>
                {data.interview_details.preferred_slots_json.map((item, index) => <div className = 'row align-items-center my-2'>
                    <div className = 'small col-1 pt-2'>
                        <input className = 'mb-0 ' type = 'radio' checked = {selectedSlot === item.start} disabled={isDisabled(item.start*1000)} onClick = {() => {setSelectedSlot(item.start); setInterviewSlot(item.start); setError(false)}}/>
                    </div>
                    <div className = {`small col-3 ${isDisabled(item.start*1000) ? "disable-text" : ""}`}>{ordinal_suffix_of(index+1)} Slot</div>
                    <div className = {`small col text-right ${isDisabled(item.start*1000) ? "disable-text" : ""}`}>{moment(item.start*1000).format('MMM DD, YYYY | hh:mm A')}</div>
                </div>)}
            </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer border-0">
            {error && <div class = 'invalid-feedback d-block'>Please select a slot before confirming</div>}
            <div className = 'row w-100 no-gutters'>
                <div className = 'col'><div className = 'btn btn-block btn-outline-danger mr-2' onClick = {() => rejectRequest()}>Cancel</div></div>
                <div className = 'col'>
                    <div className = {`btn btn-block ml-2 ${accepting ? "btn-disabled" : "btn-primary"}`} onClick = {() => handleAccept()}>
                        {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                        Accept
                    </div>
                </div>
            </div>
        </Modal.Footer>
        <RequestModal fetchData={fetchData} setRightDrawer={setShow} accepting={accepting} setAccepting={setAccepting} removeRequest={removeRequest} selectedInterview={requests.filter(item => item.id === selected)[0]} interviewSlot={interviewSlot} open = {openRequestModal} setOpenModal={setOpenRequestModal} handleClose = {()=>{setOpenRequestModal(false); setInterviewSlot(null)}} />
        </div>
    )
}

export default DetailedRequests;

function isDisabled(dateTime) {
  let isDisableSlot = false;

  if(dateTime < new Date().setHours(23,59,59)){
      isDisableSlot = true;
  }

  return isDisableSlot;
}

function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}
