import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {Modal} from 'react-bootstrap'
import {GrFormClose} from 'react-icons/gr'
import { openResume, getFileURL } from '../../services/file'
import axios from '../../services/axios'
import { InterviewFeedbackStatus } from '@iv/shared/components/pages/Feedback/Constants/FeedbackConstants'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import './DetailedHistory.scss';
import getWindowDimensions from "../../utilis/windowDimension";

const DetailedHistory = ({data, setShow}) => {
   const {width} = getWindowDimensions(); 
   const [roundDetails, setRoundDetails] = useState([])

    useEffect(() => {
        getRoundHistroy();
        // eslint-disable-next-line
    }, [])

    const getRoundHistroy = async () => {
        try{
            const data1 = {
                "candidate_id": data.candidate_details.id,
                "position_id": data.position_details.id,
                "client_id": data.client_details.id,
            }
            const res = await axios.post('/interview/history', data1)

            let details = res.data.map(item => item.interview_details);
            setRoundDetails(details);
            let duplicateData = {...data};
            duplicateData.all_round_details = res.data.map(item => item.interview_details);

        } catch(err){
            console.log(err)
        }
    }

    const handleUrl = async (e, url) => {
        let data = await getFileURL(url);
        window.open(data, "_blank");
        e.preventDefault();
      };

    return (
        <div className={width > 786 ?"modal-content DetailedHistory" : "modal-content DetailedHistory mt-50"}>
        <Modal.Body className="modal-body">
        <div className="close pointer">
            <span onClick={() => setShow(false)}><GrFormClose /></span>
        </div>
        <div className = 'w-100 mt-3'>
            <div className = '' id = 'details'>
                <div className = 'h3 mb-2'>{data?.client_details?.client_name}</div>
                <div className="row mb-3 mr-0 ml-0 justify-content-between">
                    <div>
                        <div className = 'small '>{data?.position_details?.position_name}</div>
                        <div className = ''>{data.round_details.name}</div>
                    </div>
                </div>
                <div className="fl-row">
                    {data?.candidate_details?.resume_url && <div className = 'my-3 btn-link pointer' onClick = {() => openResume(data.candidate_details.resume_url)}> <span>Resume</span> <DescriptionOutlinedIcon className="m-b-10"/></div>}
                    <div class="col"></div>
                    {data?.interview_details?.interview_status === 'DELETED' || data?.interview_details?.interview_status === 'CANCELLED'?
                        <div className = {`small ${data?.interview_details?.interview_status === 'DELETED' ? 'text-danger' : 'text-info'}`}>
                            <div className="cancelHeading">CANCELLED</div>
                            <div className="cancelSubHeading">{data?.interview_details?.interview_status === 'DELETED' ? '(Non-Chargeable)' : '(Chargeable)' }</div>
                        </div>
                    : null }
                </div>
                {data?.interview_details?.feedback_report_link && <div className = 'row'>
                    <div className = 'small col-6'>Feedback:</div>
                    <div className = 'small col btn-link pointer' onClick = {(e) => handleUrl(e, data?.interview_details?.feedback_report_link)}>View</div>
                </div>}
                {
                   !data?.interview_details?.feedback_report_link && data?.interview_details?.feedback_status === InterviewFeedbackStatus.APPROVED &&
                  <div className = 'row mb-3 mt-3'>
                    <div className = 'small col-6'>Feedback Status:</div>
                    <div className = 'small col tc-approved txt-align-right'>Approved</div>
                    <a className='btn btn-outline-dark btn-block mt-4 mr-3 ml-3' href={`/interview-feedback?i_id=${data?.interview_details?.id}`}>
                     View Feedback
                    </a>
                </div>
                }

            </div>

            <div className = 'border-top mt-4 pt-4 mb-4' id = 'round-details'>
                    <div className = 'mb-3 h4'>Comments</div>
                    {
                            data?.interview_details?.comments &&
                            data?.interview_details?.comments?.length>0 &&
                            data?.interview_details?.comments?.map((comment,index)=>{
                                const date = typeof (comment?.timestamp) === 'string' ? moment(comment?.timestamp, "DD/MM/YYYY hh:mm:ss") : moment.unix(comment?.timestamp);
                                return (
                                    <>
                                        <div className='row mb-2'>
                                            <span className='small col-5'> <b>{comment?.user?.name}</b></span>
                                            <text className='small col align-right'> {date.format('lll')} </text>
                                        </div>
                                        <div className='row mb-2'>
                                            <span className='small col'>{date.fromNow()}</span>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='small col'>{typeof (comment?.content) === "string" ? comment?.content : JSON.stringify(comment?.content)}</div>
                                        </div>
                                        {
                                            index < data?.interview_details?.comments?.length-1 &&
                                            <hr/>
                                        }
                                    </>
                                )
                            })
                    }
                    {
                            (!data?.interview_details || !data?.interview_details?.comments ||
                            data?.interview_details?.comments?.length === 0) &&
                            <div>No comments available</div>
                    }
                </div>

            <div className = 'border-top mt-4 pt-4 mb-4' id = 'round-details'>
                <div className = 'mb-4 h4'>Round Details</div>
                <div className = 'row mb-3'>
                    <div className = 'small col-6'>Round Name:</div>
                    <div className = 'small col txt-align-right'>{data?.round_details?.name}</div>
                </div>
                        {
                            data?.round_details?.interview_guide_link &&
                            <div className='row'>
                                <div className='small col-6'>Round Guide:</div>
                                    {
                                        data?.round_details?.guide_id
                                            ? <a href={`/interview-guides/preview?guide_id=${data?.round_details?.guide_id}`} target="_blank" without rel="noreferrer" className='small col btn-link txt-align-right'> View Guide</a>
                                            : data?.round_details?.interview_guide_link ?
                                                <a href={data?.round_details?.interview_guide_link} target="_blank" without rel="noreferrer" className='small col btn-link txt-align-right' >Guide Link  </a> : "N/A"
                                    }

                            </div>
                        }
            </div>



            <div className = 'border-top mt-4 pt-4 mb-4' id = 'round-details'>
                <div className = 'mb-4 h4'>Round History</div>
                <table className = 'w-100'>
                    {roundDetails ? roundDetails.map(item => <tr className = ''>
                        <td className = 'small py-2 '>{item.round_name}</td>
                        <td className = 'small'>{item?.scheduled_start_timestamp ? moment(item?.scheduled_start_timestamp*1000).format('DD/MM/YYYY') : "-"}</td>
                        <td className = 'small'>
                            {item?.feedback_report_link && <div className = 'my-3 btn-link pointer' onClick = {(e) => handleUrl(e,item?.feedback_report_link)}>Feedback</div>}
                            {!item?.feedback_report_link && item?.feedback_status &&
                                <a className='mt-4 mr-3 ml-3' href={`/interview-feedback?i_id=${item?.id}`}>
                                    Feedback
                                </a>}
                        </td>
                        <td className = 'small text-success txt-capitalize'>{item.interview_status.toLowerCase()}</td>
                    </tr>) : 'Loading...'}
                </table>
            </div>


        </div>

        </Modal.Body>
        </div>
    )
}

export default DetailedHistory;



