import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import GradingIcon from '@mui/icons-material/Grading';
import CodeSharpIcon from '@mui/icons-material/CodeSharp';
import {useHistory} from 'react-router'
//import CodeIcon from '@mui/icons-material/Code';
import "./AuditSidebar.scss";

const AuditSidebar = ({sidebarData, downloadFile}) =>{
     const data = sidebarData;
      const history = useHistory();
     const handleAuditReportLink = (idValue) => {
      history.push(`/interviews/audit-feedback-report?id=${idValue}`);
    }

    return(
    <Box className="sidebar">
    <Box className='sidebar_top'>
      <img src={data?.client_details?.logo_url} alt="client-logo" className='top_img'/>
      <Box className="sidebar_box" >
          <Typography  className="sidebar_heading">
            CANDIDATE NAME
          </Typography>
          <Typography  className="sidebar_info" >
            {data?.candidate_details?.name}
          </Typography>
          <Typography  className="sidebar_heading">
            ROLE
          </Typography>
          <Typography className="sidebar_info" >
            {data?.position_details?.role}
          </Typography>

          <Typography className="sidebar_heading">
            CATEGORY
          </Typography>
          <Typography className="sidebar_info" >
            {data?.round_details?.round_category}
          </Typography>
          <Typography  className="sidebar_heading">
            FEEDBACK RESULT
          </Typography>
          <Typography className="sidebar_info" >
            <span className="fontbold">{data?.interviewer_feedback?.overall_rating}</span> (Overall Rating)
          </Typography>
          <Box className="sidebar_result">
            {data?.interviewer_feedback?.overall_rating>=9? "STRONG PROCEED" : data?.interviewer_feedback?.overall_rating>=6? "PROCEED" : "REJECT"}
          </Box>
      </Box>

    </Box>
    <Box className="sidebar_bottom">
      <Typography className="headlink">
        Audit Essentials <span className="fontnormal">(Links)</span>
      </Typography>
      <Typography className="headlink2">
        Click to open resources in new tab.
      </Typography>

      <Box className="link_box">
          <Box className="audit_links">
            <PostAddOutlinedIcon />
            {
              data?.candidate_details?.resume_url === (undefined || null)? <p className="curdisable" style={{color:"grey", cursor:'not-allowed'}}>Resume</p> :
              <a href="javascript:void(0);" onClick={() => downloadFile(data?.candidate_details?.resume_url)}> Resume</a>
            }
          </Box>
          <Box className="audit_links">
           <ReportGmailerrorredIcon />
           {
             (!data?.round_details?.guide_id && data?.round_details?.interview_guide_link === (undefined || null || '')) ?
             <p className="curdisable">Round Guide</p> :
              <a href={data?.round_details?.guide_id ?  `#/interview-guides/preview?guide_id=${data?.round_details?.guide_id}` : data?.round_details?.interview_guide_link} target="__blank">Round Guide</a>
            }
          </Box>
          <Box className="audit_links">
            <PlayArrowIcon />
            {data?.interview_details?.feedback_video_link === (undefined || null)? <p className="curdisable">Recording</p> :
            <a  onClick={() => downloadFile(data?.interview_details?.feedback_video_link)}>Recording</a>
            }
          </Box>
          <Box className="audit_links">
            <GradingIcon />
            {data?.interview_details?.feedback_status !== 'APPROVED'? <p className="curdisable">Feedback</p> :
            <a href={`#/interview-feedback/?c_id=${data?.client_details?.id}&i_id=${data?.interview_details?.id}`} className="audit-sidebar-link" target="__blank">
             Feedback</a>
            }
          </Box>
          <Box className="audit_links">
            <CodeSharpIcon />
            {data?.interview_details?.extra_info?.coderpad_link === (null || undefined)? <p style={{cursor:'not-allowed'}} >Coderpad</p> :
            <a href={data?.interview_details?.extra_info?.coderpad_link}  target="__blank">Coderpad</a>
             }
          </Box>
      </Box>

      {
        !data?.candidate_details?.resume_url &&
          <div className='resume-optional-warning'>*The resume has not been uploaded for this candidate.</div>
      }
    </Box>
</Box>)
}
export default AuditSidebar;
