import { React, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import Modal from '@mui/material/Modal';
import './style.scss'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    p: 4,
};

export default function CodeBlockPopup({ response, handleChange, setModalVisibility }) {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        setModalVisibility(false);
    }

    const handleAddCode = () => {
        handleChange(code?.trim())
        handleClose();
    }
    const [code, setCode] = useState(response);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='code-block-container'>
                    <Box display='flex' justifyContent={'space-between'}>
                        <Typography className='heading'>SOLUTION CODE</Typography>
                        <ClearIcon onClick={handleClose} style={{ cursor: "pointer" }} />
                    </Box>

                    <Typography className='description'>Paste the pseudo code done by the candidate. (if any)</Typography>

                    <TextField
                        size="small"
                        className="w-100"
                        minRows={4}
                        maxRows={15}
                        multiline
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        inputProps={{ style: { fontSize: 12 } }}
                        InputLabelProps={{ style: { fontSize: 12, color: "GrayText" } }}
                        sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }}
                    />

                    <button className="modal-ok-button" onClick={handleAddCode} >
                        ADD CODE
                    </button>
                </Box>
            </Modal>
        </div>
    );
}