import React, {useState, useEffect} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from "@mui/material/Box";
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
// import imageCompression from 'browser-image-compression';
import { uploadFileV2 } from '../../../services/file';
import { StyledProfilePicContainer, StyledProfilePicWrapper, StyledProfilePicUploadButton, StyledProfilePic, StyledPersonalInfoFormParentGrid, StyledPersonalInfoFormChildGrid, StyledPersonalInfoFormTextField, StyledPersonalInfoFormSubmitButton, StyledPersonalInfoFormDatePicker } from "./styles"
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import TimezoneSelect from 'react-timezone-select';
import { useSelector } from 'react-redux';

const PersonalInfo = ({profile, setProfile, loading, saveProfile, setLoading, setPageName}) => {

    const profilePicInputRef = React.useRef(null)
    const [avatar, setAvatar] = useState(profile.avatar)
    const [errors] = useState(Array(7).fill(false))
    const userData = useSelector((state) => state && state.user && state.user);
    // const userProfile = useSelector((state) => state && state.user && state.user);
    const [timeZone , setTimeZone] = useState("");
    const [gender, setGender] = useState('');

    // Updates list of supported gender
    useEffect(() => {
        // console.warn("userData", userData);
        setGender(profile?.gender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile])

    // Update timezone when user data is received
    useEffect(()=>{
        if(userData?.user_config?.timezone){
            console.log('timezone',userData?.user_config?.timezone)
            setTimeZone(userData?.user_config?.timezone)
        }
    },[userData])


    const validate = async (e) => {
        e.preventDefault();
        if(profilePicInputRef.current.files && profilePicInputRef.current.files.length > 0){
            setLoading(true);
            const res = await uploadFileV2(profilePicInputRef.current.files[0])
            const avatar_link = (res.data.fields['x-amz-meta-internal-key'])
            setProfile(prev => ({...prev, profile_image: avatar_link, avatar: avatar}))
            const newProfile = {...profile, profile_image: avatar_link, avatar: avatar};
            await saveProfile( {...newProfile , user_config :{
                timezone : timeZone
            }});
        } else await saveProfile({...profile , user_config :{
            timezone : timeZone
        }});
    }


    const updateAvatar = async () => {
        if(profilePicInputRef.current.files && profilePicInputRef.current.files.length > 0) {
            setAvatar(URL.createObjectURL(profilePicInputRef.current.files[0]));
        }
    }

    // Sets page title
    useEffect(() => {
        setPageName("Personal Information")
    }, [setPageName])
    
    return (
    <>
  
        {/* Profile picture */}
        <StyledProfilePicContainer>
            <StyledProfilePicWrapper>
                {/* Display pic */}
                <StyledProfilePic src={avatar || "#"} alt="Your profile picture"/>

                {/* Hidden file input that actually uploads the profile pic */}
                <input type="file" name="avatar" accept="image/png, image/jpeg" hidden ref={profilePicInputRef} onChange={e => {updateAvatar()}} />

                {/* Upload button */}
                <StyledProfilePicUploadButton onClick = {()=> { profilePicInputRef.current?.click(); }}>
                    <CameraAltOutlinedIcon />
                </StyledProfilePicUploadButton>
            </StyledProfilePicWrapper>
        </StyledProfilePicContainer>

        {/* Personal info form */}
        <form>
            <StyledPersonalInfoFormParentGrid container spacing={3}>

                {/* First name */}
                <StyledPersonalInfoFormChildGrid item xs={12} md={6}>
                    <StyledPersonalInfoFormTextField 
                    label="First Name" 
                    color="primary" 
                    fullWidth value={profile.name?.split(" ")[0] || ""} 
                    onChange={(e) => {setProfile(prev => ({...prev, name: `${e.target.value} ${prev.name.split(" ")[1]}`}))}} 
                    error={errors[0]} 
                    helperText={errors[0] && "Please enter a valid first name"}
                    disabled={true}
                    />
                </StyledPersonalInfoFormChildGrid>

                {/* last name */}
                <StyledPersonalInfoFormChildGrid item xs={12} md={6}>
                    <StyledPersonalInfoFormTextField 
                    label="Last Name" 
                    color="primary" 
                    fullWidth value={profile.name?.split(" ")[1] || ""} 
                    onChange={(e) => {setProfile(prev => ({...prev, name: `${prev.name.split(" ")[0]} ${e.target.value}`}))}} 
                    error={errors[0]} 
                    helperText={errors[0] && "Please enter a valid last name"}
                    disabled={true}
                    />
                </StyledPersonalInfoFormChildGrid>

                {/* EmailID */}
                <StyledPersonalInfoFormChildGrid item xs={12} md={6}>
                    <StyledPersonalInfoFormTextField 
                    label="Email ID" 
                    color="primary" 
                    fullWidth value={profile.email || ""} 
                    onChange={(e) => {setProfile(prev => ({...prev, email: e.target.value}))}} 
                    error={errors[2]} 
                    helperText={errors[2] && "Please enter a valid email"}
                    disabled={true}
                    />
                </StyledPersonalInfoFormChildGrid>

                {/* DOB */}
                <StyledPersonalInfoFormChildGrid item xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <StyledPersonalInfoFormDatePicker
                            disableToolbar
                            variant="inline"
                            format="DD/MM/yyyy"
                            margin="normal"
                            id="dob-picker"
                            label="Date of Birth"
                            value={!!profile.dob ? new Date(profile.dob*1000) : null}
                            onChange={(selectedDate) => { setProfile(prev => ({...prev, dob: selectedDate.unix() })) }}
                            fullWidth color="primary"
                            KeyboardButtonProps={{
                              'aria-label': 'Change date of birth',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </StyledPersonalInfoFormChildGrid>

                {/* Location */}
                <StyledPersonalInfoFormChildGrid item xs={12} md={6}>
                    <StyledPersonalInfoFormTextField label="Location" color="primary" fullWidth value={profile.location || ""} onChange={(e) => {setProfile(prev => ({...prev, location: e.target.value}))}} error={errors[6]} helperText={errors[6] && "Please enter a valid location"}/>
                </StyledPersonalInfoFormChildGrid>

                {/* Gender */}
                <StyledPersonalInfoFormChildGrid item xs={12} md={6}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={gender}
                    label="Gender"
                    onChange={(e) => {setProfile(prev => ({...prev, gender: e.target.value}))}}
                    >
                    <MenuItem value={'MALE'}>MALE</MenuItem>
                    <MenuItem value={'FEMALE'}>FEMALE</MenuItem>
                    <MenuItem value={'OTHERS'}>OTHERS</MenuItem>
                    </Select>
                </FormControl>
                </StyledPersonalInfoFormChildGrid>

                {/* Timezone */}
                <StyledPersonalInfoFormChildGrid item xs={12} md={6}>
                    <h3>Time Zone</h3>
                    <TimezoneSelect value={timeZone} onChange={(obj)=>{setTimeZone(obj.value)}} />
                </StyledPersonalInfoFormChildGrid>

            </StyledPersonalInfoFormParentGrid>

            {/* Submit button */}
            <StyledPersonalInfoFormSubmitButton onClick = {(e) => { e.preventDefault(); validate(e) }} disabled={loading} color="primary" variant="contained" type="submit">
                {loading ? "Saving" : "Save Changes"}
            </StyledPersonalInfoFormSubmitButton>
        </form>
    </>
    )
}

export default PersonalInfo