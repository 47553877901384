import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
export const ConfirmationDialog = ({isOpen,setIsOpen,setDisplayPreview}) => {
    const handleOpen = () => {
        setIsOpen(true);
    }
    const handleClose = () => {
        setIsOpen(false)
    }
    const handleAgreeClick = () => {
        setDisplayPreview(false);
    }
    return <Modal
            open={isOpen}
            onClose={handleClose}
    >
        <Box className="shared-feedback-edit-confirm-dialog">
            <Box display="flex" flexDirection="row" gap="10px" alignContent="center" padding="14px">
            <Box>
                <ReportProblemIcon className="danger-icon"/>
            </Box>
           <Typography className="title">Feedback already approved</Typography>
            </Box>
            <Divider/>
            <Box padding="22px 16px">
                <Typography className="text">This feedback is already approved. Are you sure you want to edit?</Typography>
                <Box width="100%" display="flex" justifyContent="flex-end" gap="14px" marginTop="22px">
                    <Button className="btn cancel-btn" onClick={handleClose}>Cancel</Button>
                    <Button className="btn edit-btn" onClick={handleAgreeClick}>Edit</Button>
                </Box>
            </Box>
        </Box>
    </Modal>
}