import React, { Fragment } from 'react'
import boxImg from '../../components/Table/empty-box.svg';
import HistoryCard from './HistoryCard';
import Box from '@material-ui/core/Box';
import './HistoryList.scss';

const HistoryList = ({
        data, 
        totalDataCount,
        headers,  
        handleRowClick
    }) => {

    return(
        <>
            { totalDataCount !== 0 &&  
                data.map((row, index) => (
                    <HistoryCard headers={headers} data={row} index={index} handleRowClick={handleRowClick}/>
            ))}
            { totalDataCount === 0 && 
                <Box display="flex" flexDirection="column" justifyContent="Center" alignItems="center" className='empty-table-wrapper'>
                    <img className="empty-box" src={boxImg} alt="box-img" />
                    <p className="empty-box-text">You don’t have any interviews scheduled</p>
                </Box>
            }
        </>
    )
}

export default HistoryList;