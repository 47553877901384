import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css'
import { AvailabilityProvider } from './availabilityContext';



ReactDOM.render(
    <Suspense fallback={(<div>...Loading!</div>)}>
      <AvailabilityProvider>
        <App />
      </AvailabilityProvider>
    </Suspense>,
  document.getElementById('root')
);


