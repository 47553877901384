import React from "react";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./../../feedback.scss";
import "./../../SectionsV1/Preview/preview.scss";
import TextEditor from "../../Components/TextEditor";

import { AccordionSummary, AccordionDetails } from './../../Components/Accordion'

import { CommentConstants } from "../../Constants/FeedbackConstants";

const TheoryQuestions = ({ theoryQuestionsData, showFeedbackComments, renderAddCommentSection, renderViewUnresolvedCommentSection }) => {

  const setCodeTextValue = (value) => {
  }

  const [expanded, setExpanded] = React.useState(true);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded);
  };
  return (
    <Accordion className="mtb-20" id="add-comment-parent" expanded={expanded} onChange={handleChange('panel1')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" style={{ width: "100%", alignItems: "center" }}>
          <div className="preview-subheader-title"> Theory Questions</div>

          <div className="preview-subheader-subtitle mrl-20">
            {" "}
            Time Spent: {theoryQuestionsData?.timeSpent} Mins
          </div>
        </Box>
        {showFeedbackComments && renderViewUnresolvedCommentSection(CommentConstants.THEORY_QUESTIONS)}
        {showFeedbackComments && renderAddCommentSection(CommentConstants.THEORY_QUESTIONS)}
      </AccordionSummary>

      <AccordionDetails>
        <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
          {theoryQuestionsData?.questions?.map((data, index) => {
            return (
              <Box
                display="flex"
                flexDirection="column"
                style={{ width: "100%" }}
                key={`theory-preview-${index + 1}`}
              >
                <div className="theory-questions-unformatted" dangerouslySetInnerHTML={{ __html: index + 1 + ". " + data?.questionAsked }}>
                  {/* {index + 1}. {data?.questionAsked} */}
                </div>

                {data?.codeText &&

                  <TextEditor
                    theoryPreview
                    readOnly
                    value={data?.codeText || ""}
                    setValue={setCodeTextValue}
                  />
                }

                <div className="theory-answers" dangerouslySetInnerHTML={{ __html: data?.candidateResponse }}>
                  {/* {data?.candidateResponse} */}
                </div>

                {index + 1 !== theoryQuestionsData?.questions?.length && (
                  <div
                    style={{
                      width: "100%",
                      border: "1px solid #CDCDCD",
                      margin: "20px",
                    }}
                  ></div>
                )}
              </Box>
            );
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default TheoryQuestions;