import React, { useState, useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import "./../../feedback.scss";
import "./form.scss";

import { commonMessageForInfoIcon } from "../../Constants/ComonStrings";
import { showExampleText } from "../../Constants/FeedbackConstants";
import { renderToolTip } from "../../Components/RenderToolTip";

const ResumeIntro = ({ status, error, resumeIntroData, setResumeIntroData }) => {
  const widthByStatus = useRef("preApprove");
  widthByStatus.current = (showExampleText(status)) ? "preApprove" : "postApprove";
  const resumeIntroExampleData =
  {
    name: "resumeIntention",
    heading: "Resume Intention",
    exampleData:
      "Intention - The intention of this part is not to rewrite the resume of a candidate. Rather, the purpose of this is to capture if the candidate was able to explain any one of his projects in detail or not. And how much did he know about the project. Was he able to explain to you.. Did he know about the design choices. Was he involved in making those design choices? If he was not, still did he know why those choices were taken? Is he a team player? Or an IC? Any ideas that he came up with by himself?",
  };
  const handleTimeSpent = (e) => {
    const re = /^[0-9\b]+$/;

    const { name, value } = e.target;
    if (value === "" || re?.test(value)) {
      setResumeIntroData({ ...resumeIntroData, [name]: value });
    }
  };

  const renderExampleSection = () => {
    return (
      <>
        <Box className={`example-section resumeIntention`}>
          <p className={`good-exp-resumeIntention`}>{resumeIntroExampleData?.heading}</p>
          <p className="common-txt">{resumeIntroExampleData?.exampleData}</p>
        </Box>
      </>
    );
  };

  const handleTechStack = (e) => {
    const { name, value } = e.target;
    let copyData = [...resumeIntroData[name]];
    copyData = value;
    setResumeIntroData({ ...resumeIntroData, [name]: copyData });
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    let copyData = [...resumeIntroData[name]];
    copyData[index] = value;
    setResumeIntroData({ ...resumeIntroData, [name]: copyData });
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e?.key === 'Enter') {
      handleAddMore(e?.target?.name);
    }
  };

  const handleAddMore = (name) => {
    let copyData = [...resumeIntroData[name]];
    copyData.push("");
    setResumeIntroData({ ...resumeIntroData, [name]: copyData });
  };

  const handleDelete = (name, index) => {
    let copyData = [...resumeIntroData[name]];
    copyData.splice(index, 1);
    setResumeIntroData({ ...resumeIntroData, [name]: copyData });
  };

  const showError = (name) => {
    let isError = false;
    for (let i = 0; i < resumeIntroData[name]?.length; i++) {
      const data = resumeIntroData[name][i];
      if (data?.trim() === "") {
        isError = true;
      }
    }
    return isError;
  }

  return (
    <div className="feedback-form-bg">
      <div className="heading">Resume-Intro {renderToolTip(commonMessageForInfoIcon)}</div>

      <div className="form-row-style">
        <div className={`label ${error && resumeIntroData?.timeSpent === "" ? "error-color" : ""}`}>Time Spent<span className="asterisk">*</span> :</div>

        <TextField
          id="outlined-size-small"
          size="small"
          variant="outlined"
          className={`${widthByStatus.current}`}
          placeholder="Enter Numbers"
          value={resumeIntroData.timeSpent}
          name="timeSpent"
          onChange={handleTimeSpent}
        />
        <div id="inMin">in minutes</div>
      </div>

      <div className="form-row-style">
        <div className={`label ${error && showError("experience") ? "error-color" : ""} `}>Experience<span className="asterisk">*</span> :</div>
        <Box display="flex" flexDirection="column" className="width-value-field">
          {resumeIntroData.experience.map((data, index) => {
            return (
              <Box display="flex" id="experience">
                <TextField
                  autoFocus
                  key={`experience-${index}`}
                  id={`outlined-size-small`}
                  size="small"
                  variant="outlined"
                  placeholder="Enter the candidate’s career experience here.."
                  className={`form-value ${widthByStatus.current}`}
                  value={data}
                  name="experience"
                  onChange={(value) => handleChange(value, index)}
                  onKeyPress={handleKeypress}
                />

                {index !== 0 && (
                  <button
                    className="delete-bg"
                    onClick={(e) => handleDelete("experience", index)}
                  >
                    <DeleteOutlinedIcon />
                  </button>
                )}

                {index === resumeIntroData.experience.length - 1 && (
                  <button
                    className="add-more"
                    onClick={(e) => handleAddMore("experience")}
                  >
                    +
                  </button>
                )}
              </Box>
            );
          })}
        </Box>
      </div>

      <div className="form-row-style">
        <div className={`label ${error && showError("projects") ? "error-color" : ""} `}>Projects<span className="asterisk">*</span> :</div>
        <Box display="flex" flexDirection="column" className="width-value-field">
          {resumeIntroData.projects.map((data, index) => {
            return (
              <Box display="flex" style={{ marginBottom: "5px" }}>
                <TextField
                  autoFocus
                  key={`projects-${index}`}
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  placeholder="Enter the candidate’s projects here.."
                  className={`form-value ${widthByStatus.current}`}
                  value={data}
                  name="projects"
                  rows={2}
                  type="textarea"
                  multiline
                  rowsMax={5}
                  onChange={(value) => handleChange(value, index)}
                />

                {index !== 0 && (
                  <button
                    className="delete-bg"
                    style={{ alignSelf: "start", marginTop: "2px" }}
                    onClick={(e) => handleDelete("projects", index)}
                  >
                    <DeleteOutlinedIcon />
                  </button>
                )}

                {index === resumeIntroData.projects.length - 1 && (
                  <button
                    className="add-more"
                    style={{ alignSelf: "start", marginTop: "2px" }}
                    onClick={(e) => handleAddMore("projects")}
                  >
                    +
                  </button>
                )}
              </Box>
            );
          })}
        </Box>
      </div>

      <div className="form-row-style">
        <div className={`label ${error && resumeIntroData?.techStack === "" ? "error-color" : ""}`}>Tech-Stack<span className="asterisk">*</span> :</div>

        {/* <Box display="flex" flexDirection="column"> */}
        {/* {resumeIntroData.techStack.map((data, index) => {
            return ( */}
        <Box display="flex" style={{ marginBottom: "5px" }} className="width-value-field">
          <TextField
            key={`techStack-${0}`}
            id="outlined-size-small"
            size="small"
            variant="outlined"
            placeholder="Enter the candidate’s tech stack here.."
            className={`form-value ${widthByStatus.current}`}
            value={resumeIntroData?.techStack}
            name="techStack"
            onChange={(value) => handleTechStack(value)}
          />

          {/* {index !== 0 && (
                  <button
                    className="delete-bg"
                    onClick={(e) => handleDelete("techStack", index)}
                  >
                    <DeleteOutlinedIcon />
                  </button>
                )}

                {index === resumeIntroData.techStack.length - 1 && (
                  <button
                    className="add-more"
                    onClick={(e) => handleAddMore("techStack")}
                  >
                    +
                  </button>
                )} */}
        </Box>
        {/* ); */}
        {/* })} */}
        {/* </Box> */}
      </div>

      <div className="form-row-style">
        <div className={`label ${error && showError("extraComments") ? "error-color" : ""} `}>Extra Comments<span className="asterisk">*</span>  {renderToolTip()}:</div>
        <Box display="flex" flexDirection="column" className="width-value-field">
          {resumeIntroData.extraComments.map((data, index) => {
            return (
              <Box display="flex" style={{ marginBottom: "5px" }}>
                <TextField
                  autoFocus
                  key={`extraComments-${index}`}
                  id={`outlined-size-small`}
                  size="small"
                  variant="outlined"
                  placeholder="Enter extra comments here.."
                  className={`form-value ${widthByStatus.current}`}
                  value={data}
                  name="extraComments"
                  onChange={(value) => handleChange(value, index)}
                  onKeyPress={handleKeypress}
                />

                {index !== 0 && (
                  <button
                    className="delete-bg"
                    onClick={(e) => handleDelete("extraComments", index)}
                  >
                    <DeleteOutlinedIcon />
                  </button>
                )}

                {index === resumeIntroData.extraComments.length - 1 && (
                  <button
                    className="add-more"
                    onClick={(e) => handleAddMore("extraComments")}
                  >
                    +
                  </button>
                )}
              </Box>
            );
          })}
        </Box>
      </div>
      {showExampleText(status) && renderExampleSection()}
    </div>
  );
};

export default ResumeIntro;
