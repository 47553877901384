import React from "react";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./../../feedback.scss";
import "./../../SectionsV1/Preview/preview.scss";

import { AccordionSummary, AccordionDetails } from './../../Components/Accordion'

import { CommentConstants } from "../../Constants/FeedbackConstants";

import { downloadFile2 } from "../../../../../Services/file";

const ResumeEntry = ({ interviewDetails, resumeIntroData, showFeedbackComments, renderAddCommentSection, renderViewUnresolvedCommentSection, role,
  user,
  auth, }) => {
  
  const [expanded, setExpanded] = React.useState(true);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded);
  };

  return (
    <Accordion className="mtb-20" id="add-comment-parent" expanded={expanded} onChange={handleChange('panel1')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" style={{ width: "100%", alignItems: "center" }}>
          <div className="preview-subheader-title"> Resume - Intro</div>

          <div className="preview-subheader-subtitle mrl-20">
            {" "}
            Time Spent: {resumeIntroData.timeSpent} Mins
          </div>
        </Box>
        {/* {showFeedbackComments && <p>HELLO</p>} */}
        {showFeedbackComments && renderViewUnresolvedCommentSection(CommentConstants.RESUME_INTRO)}
        {showFeedbackComments && renderAddCommentSection(CommentConstants.RESUME_INTRO)}
      </AccordionSummary>

      <AccordionDetails>
        <Box
          display="flex"
          flexDirection="column"
          style={{ width: "100%" }}
        >
          <table>
            <tr style={{ background: "#F2F2F2", height: "41px" }}>
              <th className="center-align table-header">Experience</th>
              <th className="center-align table-header">Projects</th>
              <th className="center-align table-header">
                Tech Stack Knowledge
              </th>
            </tr>
            <tr>
              <td className="width-25 p-10 vertical-align">
                {resumeIntroData?.experience.map((data) => {
                  return <div className="table-data">{"- " + data + "\n"}</div>
                })}
              </td>
              <td className="width-25 p-10 vertical-align">
                {resumeIntroData?.projects.map((data) => {
                  return <div className="table-data">{"- " + data + "\n"}</div>
                })}
              </td>
              <td className="width-25 p-10 vertical-align">
                {typeof (resumeIntroData?.techStack) !== "string" && resumeIntroData?.techStack?.map((data) => {
                  return <div className="table-data">{"- " + data + "\n"}</div>
                })}

                {typeof (resumeIntroData?.techStack) === "string" && resumeIntroData?.techStack?.split(",").map((data) => {
                  return <div className="table-data">{"- " + data + "\n"}</div>
                })}
              </td>
            </tr>
          </table>

          <Box display="flex" style={{ width: "100%", marginTop: "25px" }}>
            <div className="extra-comments-text">Extra Comments:</div>

            <div className="vertical-align" style={{ marginLeft: "10px" }}>
              {resumeIntroData.extraComments.map((data, index) => {
                return <div className="table-data"> {index + 1 + ". " + data + "\n"} </div>
              })}
            </div>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ResumeEntry