import React from "react";

import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { CommentConstants } from "../../Constants/FeedbackConstants";

import './previewSection.scss';

import {AccordionSummary, AccordionDetails} from './../../Components/Accordion'
import { CommonTable } from "./CommonData";
import starIcon from "../../../../../assets/images/feedback/star-icon.svg";
import fadeStarIcon from "../../../../../assets/images/feedback/fade-star-icon.svg";
import { CodeBox } from "./CodeBox";
import { codeBoxHeading, codingTable } from "./Constant";

const CodingSection = ({ data, showFeedbackComments, renderAddCommentSection, renderViewUnresolvedCommentSection }) => {
  const star = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
  const [isCodingOpen,setIsCodingOpen] = React.useState(false);
  const [codeSolution,setCodeSolution] = React.useState('');
  const [readMore, setReadMore] = React.useState({});

  const [totalTimeSpent,setTotalTimeSpent] = React.useState(0);

  React.useEffect(()=>{
    if(data?.questions && data?.questions?.length>0)
    {
      let count = 0;
      data?.questions?.forEach(question=>{
          if(question && question?.time_spent)
          {
            count += +question?.time_spent;
          }
      });
      setTotalTimeSpent(count);
    }
  },[data?.questions]);

  const renderRatings = (rating)=>{
     return <Box display="flex" flexDirection="row" alignItems="center">
          {
            star?.map((item,index)=>{
                if((index+1) <=rating)
                {
                  return <Box><img src={starIcon}/></Box>
                }
                else
                {
                  return <Box><img src={fadeStarIcon}/></Box>
                }
            })
          }
     </Box>
  }
  const renderAllQuestionBox = (questionList)=>{
    return (
              <Box display="flex" flexDirection="column" gap="14px">
                  {
                    questionList && questionList?.map((question,index)=>{
                        return <>{renderQuestionBox(question,index)}</>
                    })
                  }
              </Box>
    )
  }
  const renderQuestionBox = (question,index)=>{
    const copyData = JSON.parse(JSON.stringify(codingTable));
    for(let key in copyData)
    {
      copyData[key].data = question[copyData[key]?.apiKey];
    }

    return (
              <Box className="box-container" display="flex" flexDirection="column" gap="10px">
                  <Box className="box-header" display="flex" flexDirection="row" justifyContent='space-between' alignItems="center">
                    <Box paddingTop="14px" width="74%">
                    <Box display="flex" flexDirection="row">
                      <Box>{index+1}.&nbsp;</Box> 
                        <Box>
                        <Box display="flex" flexDirection="row" gap="0px">
                          <a className={`${question?.question_link? "link" : ''}`} href={question?.question_link?question?.question_link:null} target="_blank" rel="noreferrer">
                          { question?.question_asked !== "None (If your question is not available in the Guide)" && 
                          <Box display="inline-block" className={( !readMore[index])?"question-statement":""}  style={{lineBreak:'anywhere'}} dangerouslySetInnerHTML={{ __html: (question?.question_asked) }}></Box>}</a>
                          { question?.question_asked?.length >= 380 ? readMore[index]?<Box onClick={(e)=>{setReadMore({ ...readMore, [index]:false})}} className="read-more">Read less</Box>:
                            <Box onClick={()=>setReadMore({ ...readMore, [index]: true})} className="read-more">Read more</Box> 
                            : ''}
                          </Box>
                          <Box display="flex" flexDirection="row" gap="0px">
                            <a className={`${question?.question_link? "link" : ''}`} href={question?.question_link?question?.question_link:null} target="_blank" rel="noreferrer">
                            <Box display="inline-block" className={( !readMore[index])?"question-statement":""} style={{lineBreak:'anywhere'}} dangerouslySetInnerHTML={{ __html: (question?.question_statement) }}></Box></a>
                            { question?.question_statement?.length >= 380 ? readMore[index]?<Box onClick={(e)=>{setReadMore({ ...readMore, [index]:false})}} className="read-more">Read less</Box>:
                            <Box onClick={()=>setReadMore({ ...readMore, [index]: true})} className="read-more">Read more</Box> 
                            : ''}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" gap="16px">
                      <Box>
                      Time Spent : <span>{question?.time_spent} Mins</span>
                      </Box>
                      <Box>
                        <Button className="view-code"  onClick={()=>{setIsCodingOpen(!isCodingOpen); setCodeSolution(question?.solution_code);}}>View Code</Button>
                      
                      </Box>
                    </Box>
                  </Box>
                  <Box className="box-body" display="flex" flexDirection="column" justifyContent="space-evenly" gap="20px">
                      <Box display="flex" flexDirection="row" justifyContent="space-between">
                          <Box className={question?.difficulty?.tag?.toLowerCase()==="easy"?"chip green":question?.difficulty?.tag?.toLowerCase()==="medium"?"chip black":"chip red"}>{question?.difficulty?.text}</Box>
                          <Box display="flex" flexDirection="row" alignItems='center' gap='5px'>
                           
                            { renderRatings(question?.rating) }
                           
                            <Box>({question?.rating}/10)</Box>
                          </Box>
                          <Box className="text-1 bold-700">Time Complexity : <span className="bold-500">{question?.time_complexity}</span></Box>
                          <Box className="text-1 bold-700">Space Complexity : <span className="bold-500">{question?.space_complexity}</span></Box>
                          <Box className="text-1 bold-700">Language Used : <span className="bold-500">{question?.language_used}</span></Box>
                      </Box>
                      <CommonTable tableData={copyData}/>
                    { question?.comment?.length>0 && <Box display="flex" flexDirection="row" gap="10px"  className="extra-comment">
                          <Box>Extra Comments :</Box>
                          <Box display="flex" flexDirection="column">
                          {
                            question?.comment?.map(comment=>{
                              return <Box>&#8226; {comment}</Box>
                            })
                          }
                          </Box>
                      </Box>}
                  </Box>
              </Box>
    )
  }

  const [expanded, setExpanded] = React.useState(true);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded);
  };

  return (
    <>
      <CodeBox
        isDialogOpen={isCodingOpen}
        handleClose={() => setIsCodingOpen(false)}
        solutionCode={codeSolution}
        type="coding"
        heading={codeBoxHeading?.CODING}
      />

       <Accordion id="add-comment-parent" className="question-container" style={{ marginBottom: '20px' }} expanded={expanded} onChange={handleChange('panel1')}>

      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box className="theory-question-header" style={{ width: "100%", alignItems: "center" }}>
          <Box className="theory-question-header-text">
            <Typography className="theory-question-title">CODING QUESTIONS</Typography>
            <Typography className="theory-question-time">Time Spent: {totalTimeSpent} Mins</Typography>
          </Box>
        </Box>
        {showFeedbackComments && renderViewUnresolvedCommentSection(CommentConstants.CODING_QUESTIONS)}
        {showFeedbackComments && renderAddCommentSection(CommentConstants.CODING_QUESTIONS)}
      </AccordionSummary>

      <AccordionDetails>
        <Box className="coding-question-body" display="flex" flexDirection="column" style={{ width: "100%" }}>
            {renderAllQuestionBox(data?.questions)}
        </Box>
      </AccordionDetails>
    </Accordion>
    </>
   
  )
}


export default CodingSection;