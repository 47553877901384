import React, { useState, useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { nanoid } from 'nanoid';
import html2canvas from 'html2canvas';
import snakeCase from 'lodash/snakeCase';
import jsPDF from "jspdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { PDFDownloadLink } from '@react-pdf/renderer';
import Modal from '@material-ui/core/Modal';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import Popup from 'reactjs-popup';
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';

import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import cx from "classnames";
import debounce from "lodash/debounce";
import ShareIcon from '@material-ui/icons/Share';
import { CircularProgress } from "@material-ui/core";

import { getS3Url } from "../../../Utils/image";
import SwtAlert from "../../../Utils/SwtAlert";

import "./feedback.scss";

import Download from "./Components/Download";
import { CommentsSideDrawer } from "./Components/CommentsSideDrawer";
import Stepper, { stepperStatus } from "./Components/Stepper";
import Preview from "./Components/Preview/index";

import ResumeIntro from "./SectionsV1/Section/ResumeIntro";
import CodingQuestions from "./SectionsV1/Section/CodingQuestions";
import TheoryQuestions from "./SectionsV1/Section/TheoryQuestions";
import FeedbackSummary from "./SectionsV1/Section/FeedbackSummary";


import { InterviewFeedbackStatus, FeedbackType, showDesignQuestions, CommentConstants, CommentsResolveStatus, StepperSteps, DashboardType, isV2Version, isTheoryV2Version, isCodingV2Version, isResumeV2Version, isSummaryV2Versions, isHLDDesignV2Versions, isLLDDesignV2Versions, StepperKeyName } from "./Constants/FeedbackConstants";
import { Validation, validateResumeIntro, validateCodingQuestion, validateTheoryQuestion, validateFeedbackSummary } from "./BackendData/Validation";
import { GetAPIData } from "./BackendData/GetAPIData";
import { getResumeDataForAPI, getCodingDataForAPI, getTheoryDataForAPI, getSectionV2DataForAPI } from "./BackendData/SetDataForAPI";
import BasicModal from "./Popups/FeedbackSummaryValidationModal/modal";

import addCommentReplySVG from "../../../assets/images/feedback/add-comment-reply.svg";
import markAsResolved from "../../../assets/images/feedback/mark-as-resolved-tick.svg"
import markAsUnresolved from "../../../assets/images/feedback/mark-as-unresolved-sign.svg"
import logoFeedback from "../../../assets/images/feedback/logo-feedback.svg";

import CommonSectionV2 from "./SectionsV2/Section/Section.js";
import BackendValidationPopup from "./Popups/BackendValidationPopup/index";


const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #e2e2e2',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Feedback = ({state, role, addFeedback, editFeedback, getFeedback, editFeedbackStatus, addFeedbackComment, editFeedbackComment, getFeedbackComments, addFeedbackCommentReply, getShareableFeedbackLink, rateFeedback }) => {
  const [backendValidationV2, setBackendValidationV2] = useState({type: '' , data: null});
  console.log("ratefeedback",rateFeedback);
  const [showBackendValidationModal, setBackendValidationModal] = useState({state: false, data: null});

  const [user, setUser] = useState(state?.user);
  const [auth, setAuth] = useState(state?.auth);
  
  const { search } = useLocation();
  const parsedQueryString = queryString.parse(search)
  const interviewId = parsedQueryString?.i_id;
  const urlToken = parsedQueryString?.token;
  // const interviewId =`${window.location.href}`.split("i_id=")[1] || undefined;

  const classes = useStyles();
  const history = useHistory();
  const [loaderPDF, setLoaderPDF] = useState(false);
  const [showCommentsSideDrawer, setCommentsSideDrawer] = useState(false);
  const [error, setError] = useState({ resumeIntroError: false, codingQuestionsError: false, theoryQuestionsError: false, feedbackSummaryError: false });
  const [displayPreview, setDisplayPreview] = useState(false);
  const [interviewDetails, setInterviewDetails] = useState({});
  const [clientLogo, setClientLogo] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [shareLink, setShareLink] = useState(null);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const ref = useRef();
  const [reply, setReply] = useState("");
  const [showReply, setReplyVisibility] = useState([]);

  const [feedbackStatus, setFeedbackStatus] = useState("");

  const [feedbackType, setFeedbackType] = useState(FeedbackType.PROBLEM_SOLVING_CODING);
  const [showFeedbackComments, setFeedbackCommentsVisibility] = useState(false)
  const [feedbackComments, setFeedbackComments] = useState(null);

  const [disableSubmitButtons, setSubmitButtonDisable] = useState(false);
  const [clickSubmitButton, setSubmitButtonClick] = useState({ click: false, isDraft: false, closeFeedbackScreen: true });
  const [displayApproveButton, setApproveButtonDisplay] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState([
    { section: 'resume' , name: "Resume - Intro", status: stepperStatus.CURRENT, code: "RESUME_INTRO", version: 'v1' },
    { section: 'coding' , name: "Coding Questions", status: stepperStatus.UPCOMING, code: "CODING_QUESTIONS", version: 'v1' },
    { section: 'theory' , name: "Theory Questions", status: stepperStatus.UPCOMING, code: "THEORY_QUESTIONS", version: 'v1'},
    { section: 'summary' , name: "Feedback Summary", status: stepperStatus.UPCOMING, code: "FEEDBACK_SUMMARY", version: 'v1' },
  ]);

  const [resumeIntroData, setResumeIntroData] = useState({
    id: "",
    questionId: "",
    timeSpent: "",
    experience: [""],
    projects: [""],
    techStack: "",
    extraComments: [""],
  });

  const [codingQuestionData, setCodingQuestionData] = useState({
    id: "",
    language: "",
    questionType: "",
    questions: [
      {
        questionId: "",
        questionType: "",
        questionAsked: "",
        questionLink: "",
        timeSpent: "",
        isQuestionCompleted: "",
        isSolutionOptimal: "",
        //Problem Solving Coding //Problem solving
        languageUsed: "",
        solutionCode: "",
        spaceComplexity: "",
        timeComplexity: "",
        approach: [""],
        positives: [""],
        neutrals: [""],
        negatives: [""],
        //HLD, LLD
        designProcess: [""],
        requirementGathering: [""],
        highLevelDesign: [""],
        lowLevelDesign: [""],
        scalability: [""],
        dbchoices: [""],
        caching: [""],
        extraComments: [""],

        //Flipkart Feedback
        assumptions: "",
        heuristics: "",
        entityModel: "",
        apiDesign: "",
        concurrency: "",
        subcomponentBreakdown: "",
        asyncSyncInteraction: "",
        designTradeoffs: "",
        familiarity: "",
        correctness: "",
      },
    ],
  });

  const [theoryQuestionsData, setTheoryQuestionsData] = useState({
    id: "",
    timeSpent: "",
    questions: [
      { questionId: nanoid(), newQuestion: true, questionAsked: "", candidateResponse: "", codeText: "" },
      { questionId: nanoid(), newQuestion: true, questionAsked: "", candidateResponse: "", codeText: "" },
    ],
  });

  const [feedbackSummaryData, setFeedbackSummaryData] = useState({
    id: "",
    highlights: [""],
    lowlights: [""],
    scopeOfImprovement: [""],
    extraComments: [""],
    skillEvaluation: [],
    rating: "",
    recommendation: "",
  });

  useEffect(async () => {
    if(role === DashboardType.CLIENT)
    {
          let sessionToken = sessionStorage.getItem("temporary_access_token");
          if(!urlToken && sessionToken)
          {
            let url = `${window.location.href}&token=${sessionToken}`;
            window.location.href = url;
            return;
          }
          else if(urlToken && !sessionToken)
          {
              history.push(`/feedback/authorize?token=${urlToken}`);
              return;
          }
    }
    await getInterviewDetails();
  }, []);

  useEffect(() => {
    setUser(state?.user)
    setAuth(state?.auth)
  }, [state])

  useEffect(async () => {
    if (clickSubmitButton?.click) handleSubmitClick({e: clickSubmitButton, isDraft: clickSubmitButton?.isDraft, closeFeedbackScreen: clickSubmitButton?.closeFeedbackScreen, displayPreview: false, ignoreWarning: false});
  }, [clickSubmitButton]);

  const getInterviewDetails = async () => {
    try {
      console.log(interviewId, "IV");

      let dataResponse = await getFeedback(interviewId);
      if (dataResponse?.client_details?.logo) {
        const logoURL = getS3Url(dataResponse?.client_details?.logo);
        if (logoURL) {
          setClientLogo(logoURL)
        }
      }
      setInterviewDetails(dataResponse);
      setFeedbackStatus(dataResponse?.status);
      setFeedbackType(dataResponse?.feedback_type);

      if (showDesignQuestions(dataResponse?.feedback_type)) {
        let stepData = [...steps];
        stepData[1].name = 'Design Questions';
        setSteps(stepData);
      }

      if (dataResponse?.feedback_pages?.ordering && dataResponse?.feedback_pages?.ordering?.length > 0) {
        const stepsArray = [];
        dataResponse?.feedback_pages?.ordering.forEach((data, index) => {
          const stepObj = { ...data };

          if (index === 0) {
            stepObj.status = stepperStatus.CURRENT;
          } else {
            stepObj.status = stepperStatus.UPCOMING;
          }

          if (data?.section === 'resume') {
            stepObj.code = 'RESUME_INTRO';
          } else if (data?.section === 'theory') {
            stepObj.code = 'THEORY_QUESTIONS';
          } else if (data?.section === 'summary') {
            stepObj.code = 'FEEDBACK_SUMMARY';
          } else {
            stepObj.code = 'CODING_QUESTIONS';
          }

          stepsArray.push(stepObj)
        })
        setSteps(stepsArray)
      }

      setFeedbackCommentsVisibility(dataResponse?.status === InterviewFeedbackStatus?.SUBMITTED || dataResponse?.status === InterviewFeedbackStatus.CHANGES_REQUESTED)
      setDisplayPreview(
        ((dataResponse?.status === InterviewFeedbackStatus.SUBMITTED ||
          dataResponse?.status === InterviewFeedbackStatus.APPROVED) && role === DashboardType.INTERVIEWER) ||
        (
          (dataResponse?.status === InterviewFeedbackStatus.SUBMITTED ||
            dataResponse?.status === InterviewFeedbackStatus.CHANGES_REQUESTED ||
            dataResponse?.status === InterviewFeedbackStatus.APPROVED) && role === DashboardType.OPS
        ) || ((dataResponse?.status === InterviewFeedbackStatus.APPROVED) && role === DashboardType.CLIENT)
      );

      if (dataResponse?.status === InterviewFeedbackStatus.PENDING) {

        let data = dataResponse?.evaluation_skills || [];
        let arr = [];
        for (let i = 0; i < data?.length; i++) {
          let obj;
          if (typeof (data[i]) === 'string') {
            obj = { type: data[i], rating: "" };
          } else {
            obj = { ...data[i], type: data[i]?.type, rating: "" };
          }
          arr.push(obj);
        }

        const feedbackSumData = { ...feedbackSummaryData };
        feedbackSumData.skillEvaluation = arr;
        setFeedbackSummaryData(feedbackSumData);

        // Nothing to get from API.
      } else {
        GetAPIData(
          dataResponse,
          feedbackSummaryData,
          setFeedbackSummaryData,
          resumeIntroData,
          setResumeIntroData,
          codingQuestionData,
          setCodingQuestionData,
          theoryQuestionsData,
          setTheoryQuestionsData
        );
      }

      getComments(dataResponse?.id);
    } catch (err) {
      console.log(err, "error");
      SwtAlert("There is some issue in opening the feedback form. Please try to refresh again or contact InterviewVector. ", null, null, [
        "Go Back",
        "Refresh",
      ]).then((willRefresh) => {
        if (willRefresh) {
          getInterviewDetails();
        } else {
          history.goBack();
        }
      })
    }
  };

  const getComments = async (id) => {
    if (role !== DashboardType.CLIENT) {
      try {
        let dataResponse = await getFeedbackComments(id);
        setFeedbackComments(dataResponse);
      }
      catch (err) { }
    }
  };

  const editCommentStatus = async (id, status) => {
    const payload = {
      feedback_comment_id: id,
      resolved_status: status === CommentsResolveStatus.UNRESOLVED ? CommentsResolveStatus.RESOLVED : CommentsResolveStatus.UNRESOLVED,
    }
    try {
      let dataResponse = await editFeedbackComment(payload, id);
      SwtAlert(dataResponse, 1500, null, null);
      getComments(interviewDetails?.id)
    } catch (err) { }
  };

  const addCommentReply = async (payload) => {
    try {
      let dataResponse = await addFeedbackCommentReply(payload);
      SwtAlert(dataResponse, 1500, null, null);
      getComments(interviewDetails?.id)
    } catch (err) { }
  };

  const handleSaveAsDraftOnNextClick = (e) => {
    debounceHandleSubmit({ click: true, isDraft: true, closeFeedbackScreen: false });
  };

  const handleSaveAsDraft = (e) => {
    debounceHandleSubmit({ click: true, isDraft: true, closeFeedbackScreen: false });
  };

  const setFormError = () => {
    let errorMessage = "";

    switch (steps[currentStep].section) {
      case StepperKeyName.RESUME_INTRO:
        errorMessage = validateResumeIntro(interviewDetails, resumeIntroData, steps, currentStep, setSteps);
        setError({ ...error, resumeIntroError: errorMessage !== "" ? true : false });
        break;

      case StepperKeyName.CODING_QUESTIONS:
        errorMessage = validateCodingQuestion(interviewDetails, codingQuestionData, steps, currentStep, setSteps, feedbackType, steps[currentStep].section);
        setError({ ...error, codingQuestionsError: errorMessage !== "" ? true : false });
        break;

      case StepperKeyName.DESIGN_QUESTIONS:
        case StepperKeyName.HLD_QUESTIONS:
          case StepperKeyName.LLD_QUESTIONS:
            errorMessage = validateCodingQuestion(interviewDetails, codingQuestionData, steps, currentStep, setSteps, feedbackType, steps[currentStep].section);
        setError({ ...error, codingQuestionsError: errorMessage !== "" ? true : false });
        break;

      case StepperKeyName.THEORY_QUESTIONS:
        errorMessage = validateTheoryQuestion(interviewDetails, theoryQuestionsData, steps, currentStep, setSteps);
        setError({ ...error, theoryQuestionsError: errorMessage !== "" ? true : false });
        break;

      case StepperKeyName.FEEDBACK_SUMMARY:
        errorMessage = validateFeedbackSummary(interviewDetails, feedbackSummaryData, steps, currentStep, setSteps);
        setError({ ...error, feedbackSummaryError: errorMessage !== "" ? true : false });
        break;
    }

    return errorMessage;
  }

  const handleStepperWithValidations = (index) => {
    let errorMessage = setFormError();

    if (errorMessage) {
      SwtAlert("Following fields are mandatory. Please fill them before final submission\n" + errorMessage, 3000, null, [null, "OK"]).then((willDelete) => {
        setCurrentStep(index);
        let stepData = [...steps];
        stepData[currentStep].status = stepperStatus.ERROR;
        stepData[index].status = stepperStatus.CURRENT;
        setSteps(stepData);
      });
    } else {
      setCurrentStep(index);
      let stepData = [...steps];
      stepData[currentStep].status = stepperStatus.SUCCESS;
      stepData[index].status = stepperStatus.CURRENT;
      setSteps(stepData);
    }
  }

  const onStepperClick = (step, index) => {
    if (feedbackStatus === InterviewFeedbackStatus.PREVIEW || feedbackStatus === InterviewFeedbackStatus.PENDING || feedbackStatus === InterviewFeedbackStatus.DRAFT) {
      handleSaveAsDraftOnNextClick()
    }
    handleStepperWithValidations(index);
  }

  const handlePrevClick = () => {
    let errorMessage = setFormError();

    setCurrentStep(currentStep - 1);
    let stepData = [...steps];
    stepData[currentStep].status = errorMessage ? stepperStatus.ERROR : stepperStatus.SUCCESS;
    stepData[currentStep - 1].status = stepperStatus.CURRENT;
    setSteps(stepData);
  };


  const [modalError, setModalError] = useState({
    lowLight: '',
    hightLight: '',
    lowCnt: '',
    highCnt: '',
    rating: '',
  });

  const handleErrorModal = () => {
    let lowLight = false, highLight = false;
    let error = {
      lowLight: '',
      highLight: '',
      lowCnt: '',
      highCnt: '',
      rating: '',
    }

    switch (true) {
      case feedbackSummaryData?.rating <= 3:
        highLight = (feedbackSummaryData?.highlights?.length < 1) ? true : false;
        lowLight = (feedbackSummaryData?.lowlights?.length < 4) ? true : false;
        error.lowLight = lowLight;
        error.highLight = highLight;
        error.highCnt = 1;
        error.lowCnt = 4;
        error.rating = feedbackSummaryData?.rating;
        if (steps[currentStep].section === StepperKeyName.FEEDBACK_SUMMARY && (lowLight || highLight)) {
          setModalError(error);
          setShowErrorModal(true);
          return true;
        }
        break;
      case parseInt(feedbackSummaryData?.rating) <= 5:
        highLight = (feedbackSummaryData?.highlights?.length < 2) ? true : false;
        lowLight = (feedbackSummaryData?.lowlights?.length < 3) ? true : false;
        error.lowLight = lowLight;
        error.highLight = highLight;
        error.highCnt = 2;
        error.lowCnt = 3;
        error.rating = feedbackSummaryData?.rating;
        if (steps[currentStep].section === StepperKeyName.FEEDBACK_SUMMARY && (lowLight || highLight)) {
          setModalError(error);
          setShowErrorModal(true);
          return true;
        }
        break;
      case parseInt(feedbackSummaryData?.rating) <= 8:
        highLight = (feedbackSummaryData?.highlights?.length < 3) ? true : false;
        lowLight = (feedbackSummaryData?.lowlights?.length < 2) ? true : false;
        error.lowLight = lowLight;
        error.highLight = highLight;
        error.highCnt = 3;
        error.lowCnt = 2;
        error.rating = feedbackSummaryData?.rating;
        if (steps[currentStep].section === StepperKeyName.FEEDBACK_SUMMARY && (lowLight || highLight)) {
          setModalError(error);
          setShowErrorModal(true);
          return true;
        }
        break;
      default:
        highLight = (feedbackSummaryData?.highlights?.length < 4) ? true : false;
        lowLight = (feedbackSummaryData?.lowlights?.length < 1) ? true : false;
        error.lowLight = lowLight;
        error.highLight = highLight;
        error.highCnt = 4;
        error.lowCnt = 1;
        error.rating = feedbackSummaryData?.rating;
        if (steps[currentStep].section === StepperKeyName.FEEDBACK_SUMMARY && (lowLight || highLight)) {
          setModalError(error);
          setShowErrorModal(true);
          return true;
        }
    }
    return false;
  }

  const handleNextClick = (e) => {
    if (currentStep < steps.length - 1) {
      if (feedbackStatus === InterviewFeedbackStatus.PREVIEW || feedbackStatus === InterviewFeedbackStatus.PENDING || feedbackStatus === InterviewFeedbackStatus.DRAFT) {
        handleSaveAsDraftOnNextClick(e)
      }

      handleStepperWithValidations(currentStep + 1);
    } else {
          setFormError();
          let errorMessage = Validation(
            resumeIntroData,
            codingQuestionData,
            theoryQuestionsData,
            feedbackSummaryData,
            steps,
            setSteps,
            feedbackType, 
            interviewDetails
            );

           console.log('errorMessage', errorMessage)

          if ( !errorMessage) {
            if (role===DashboardType.INTERVIEWER &&  !isSummaryV2Versions(interviewDetails)  && handleErrorModal()){
              return ;
            }
            let stepData = [...steps];
            stepData[currentStep].status = stepperStatus.CURRENT;
            setSteps(stepData);
            // let totalTimeSpent = 0;

            // totalTimeSpent += resumeIntroData?.timeSpent ? parseInt(resumeIntroData?.timeSpent) : 0;
            // totalTimeSpent += theoryQuestionsData?.timeSpent ? parseInt(theoryQuestionsData?.timeSpent) : 0;

            // codingQuestionData?.questions?.map((data) => {
            //   totalTimeSpent += data?.timeSpent ? parseInt(data?.timeSpent) : 0;
            // });

            const warningMessage = role===DashboardType.INTERVIEWER && feedbackComments?.filter((data => data?.status === CommentsResolveStatus.UNRESOLVED))?.length > 0 
            ? "You have unresolved comments. This feedback won't be marked completed without resolving all comments."
            : '';

            // : (totalTimeSpent < 40 ?  "The total time spent is less than 40 mins. Please make sure that you have filled in all the questions asked and time spent correctly."
            // : "")

            if( warningMessage ){       
              SwtAlert( warningMessage, null, null, [true, "Confirm"]).then((confirm) => {
                if (confirm) {
                 handleSubmitClick({e: e, isDraft: false, closeFeedbackScreen: false, displayPreview: true, ignoreWarning: false});
                }
              });
            } else {
              handleSubmitClick({e: e, isDraft: false, closeFeedbackScreen: false, displayPreview: true, ignoreWarning: false});
            }

      } else {
        // handleSubmitClick(e, false, false, true);
        return SwtAlert(errorMessage, null, null, [null, "OK"]);

      }
    }
  };

  const handleSubmitClick = async ({e, isDraft = false, closeFeedbackScreen = true, displayPreview = false, ignoreWarning = false}) => {

    setSubmitButtonDisable(true);

    try {
      const payload = {
        last_updated_timestamp: interviewDetails?.last_updated_timestamp,

        feedback_type: feedbackType,
        overall_rating: feedbackSummaryData?.rating ? feedbackSummaryData?.rating : null,
        overall_recommendation: feedbackSummaryData?.recommendation !== "" ? feedbackSummaryData?.recommendation
          .toUpperCase()
          .replace(" ", "_") : null,
        evaluation_ratings: feedbackSummaryData?.skillEvaluation?.length !== 0 ? feedbackSummaryData?.skillEvaluation : null,
        feedback_summary: {
          highlights: feedbackSummaryData?.highlights ? feedbackSummaryData?.highlights : null,
          lowlights: feedbackSummaryData?.lowlights ? feedbackSummaryData?.lowlights : null,
        },
        feedback_improvements: feedbackSummaryData?.scopeOfImprovement ? feedbackSummaryData?.scopeOfImprovement : null,
        feedback_comments: feedbackSummaryData?.extraComments ? feedbackSummaryData?.extraComments : null,

        language_used: codingQuestionData?.language?.trim() !== "" ? codingQuestionData?.language?.trim() : null,
        
      };

      const feedback_groups = []
      if(!isResumeV2Version(interviewDetails)){ //Need to check if there is a chance where resume will come in feedback pages but v1 will be there
       feedback_groups.push( getResumeDataForAPI(feedbackStatus, resumeIntroData))
      }

      if (getCodingDataForAPI(feedbackStatus, codingQuestionData, feedbackType, interviewDetails)) {
       feedback_groups.push(getCodingDataForAPI(feedbackStatus, codingQuestionData, feedbackType, interviewDetails))
      }

      if (getTheoryDataForAPI(feedbackStatus, theoryQuestionsData)) {
       feedback_groups.push(getTheoryDataForAPI(feedbackStatus, theoryQuestionsData),)
      }

      if(feedback_groups?.length > 0) {
        payload.feedback_groups = feedback_groups;
      }
    
      payload.feedback_pages = getSectionV2DataForAPI(interviewDetails);


      if (feedbackStatus !== InterviewFeedbackStatus.PENDING) {
        payload.id = feedbackSummaryData?.id;
      }

      if (isDraft) {
        payload.is_draft = isDraft;
      }

      if (ignoreWarning) {
        payload.ignore_warnings = ignoreWarning;
      }

      if (role === DashboardType.INTERVIEWER) {
        let dataResponse;
        if (feedbackStatus === InterviewFeedbackStatus.PENDING) {
          dataResponse = await addFeedback(interviewId, payload);
        } else {
          dataResponse = await editFeedback(interviewId, payload);
        }

        if (dataResponse?.status === 200) {
          if (closeFeedbackScreen) {
            SwtAlert(isDraft ? "Your feedback has been saved as a draft" : "Your feedback has been successfully submitted", 2000, null, null);
            setTimeout(() => {
              role === DashboardType.OPS ? history.push('/interviews/all') : history.push('/scheduled');
            }, 1600);
          } else {
            setFeedbackStatus(dataResponse?.data?.status);
            const interviewDetailsData = { ...interviewDetails };
            setInterviewDetails({ ...dataResponse?.data, feedback_steps: interviewDetailsData?.feedback_steps });
            if (dataResponse?.data?.status !== InterviewFeedbackStatus.PENDING) {
              GetAPIData(
                dataResponse?.data,
                feedbackSummaryData,
                setFeedbackSummaryData,
                resumeIntroData,
                setResumeIntroData,
                codingQuestionData,
                setCodingQuestionData,
                theoryQuestionsData,
                setTheoryQuestionsData
              );
            }
          }

         setBackendValidationV2({type: '' , data: null})
          if (displayPreview) setDisplayPreview(true);
      } else {
        if (dataResponse?.status === 400 && dataResponse?.data?.msg === 'Error in the raw response') {
          setBackendValidationV2({type: 'error' , data: dataResponse?.data?.data});
          setBackendValidationModal({state: true, data: {e, isDraft, closeFeedbackScreen, displayPreview}});
         } else if (dataResponse?.status === 422 && dataResponse?.data?.msg === "Warning in the raw response") {
          setBackendValidationV2({type: 'warning' , data: dataResponse?.data?.data});
          setBackendValidationModal({state: true, data: {e, isDraft, closeFeedbackScreen, displayPreview}});
        }else{
          cogoToast.error(dataResponse?.data?.msg)
        }
      }
      setSubmitButtonDisable(false);
    } else{
      if (feedbackStatus === InterviewFeedbackStatus.PENDING) {
        let dataResponse;
        dataResponse =  await addFeedback(interviewId, payload);
        if(dataResponse?.status === 200){
          setInterviewDetails(dataResponse?.data)
          alert("Your feedback has been successfully submitted");
          setTimeout(() => {
            history.push("/interviews/all");
          }, 1600);
          if (displayPreview) setDisplayPreview(true);
          setBackendValidationV2({type: '' , data: null})
          if (displayPreview) setDisplayPreview(true);
        } else {
          if (dataResponse?.status === 400 && dataResponse?.data?.msg === 'Error in the raw response') {
            setBackendValidationV2({type:'error',data: dataResponse?.data?.data});
            setBackendValidationModal({state: true, data: {e, isDraft, closeFeedbackScreen, displayPreview}});
          }else if (dataResponse?.status === 422 && dataResponse?.data?.msg === "Warning in the raw response") {
            setBackendValidationV2({type: 'warning' , data: dataResponse?.data?.data});
            setBackendValidationModal({state: true, data: {e, isDraft, closeFeedbackScreen, displayPreview}});
          }else{
            cogoToast.error(dataResponse?.data?.msg)
          }
        }
        setSubmitButtonDisable(false);
      } else {         
          if (displayApproveButton && !displayPreview) {
            let data = await editFeedbackStatus(interviewId, {
              status: InterviewFeedbackStatus.APPROVED,
            });
            alert("You have successfully approved this interview.");
            setTimeout(() => {
              history.push("/interviews/all");
            }, 1600);
            if (displayPreview) setDisplayPreview(true);
          } else {
            let dataResponse;
            dataResponse = await editFeedback(interviewId, payload);
            if (dataResponse?.status === 200) {
              if (closeFeedbackScreen) {
                SwtAlert(isDraft ? "Your feedback has been saved as a draft" : "Your feedback has been successfully submitted", 2000, null, null);
                setTimeout(() => {
                  role === DashboardType.OPS ? history.push('/interviews/all') : history.push('/scheduled');
                }, 1600);
              } else {
                setFeedbackStatus(dataResponse?.data?.status);
                const interviewDetailsData = { ...interviewDetails };
                setInterviewDetails({ ...dataResponse?.data, feedback_steps: interviewDetailsData?.feedback_steps });
                if (dataResponse?.data?.status !== InterviewFeedbackStatus.PENDING) {
                  GetAPIData(
                    dataResponse?.data,
                    feedbackSummaryData,
                    setFeedbackSummaryData,
                    resumeIntroData,
                    setResumeIntroData,
                    codingQuestionData,
                    setCodingQuestionData,
                    theoryQuestionsData,
                    setTheoryQuestionsData
                  );
                }
              }
              setBackendValidationV2({type: '' , data: null})
              if (displayPreview) setDisplayPreview(true);
            } else {
              if (dataResponse?.status === 400 && dataResponse?.data?.msg === 'Error in the raw response') {
                setBackendValidationV2({type: 'error' , data: dataResponse?.data?.data});
                setBackendValidationModal({state: true, data: {e, isDraft, closeFeedbackScreen, displayPreview}});
              }else if (dataResponse?.status === 422 && dataResponse?.data?.msg === "Warning in the raw response") {
                setBackendValidationV2({type: 'warning' , data: dataResponse?.data?.data});
                setBackendValidationModal({state: true, data: {e, isDraft, closeFeedbackScreen, displayPreview}});
              }
            }
          }
        }
        setSubmitButtonDisable(false);
        setApproveButtonDisplay(true);
      }
    } catch (err) {
      setSubmitButtonDisable(false);
      //Error handled in common file
      SwtAlert(error?.data?.msg || error?.data?.toString() || error?.toString,2000,"error",false);

    }
  };

  const debounceHandleSubmit = useCallback(debounce(setSubmitButtonClick, 500), []);

  const renderAddReplySection = (parentCommentData) => {
    const closeTooltip = () => ref.current.close();

    const addSectionCommentReply = () => {
      if (reply !== "") {
        const payload = {
          feedback_comment_id: parentCommentData?.id,
          feedback_reply: reply,
        }
        addCommentReply(payload);
      }
      closeTooltip();
      setReply("")
    }

    return (
      <Popup ref={ref} trigger={open => (
        <div className="comments-button-background pointer">
          <img src={addCommentReplySVG} className="add-comment-reply" />
        </div>)}
        position="bottom right"
      >
        <div className="p-15">
          <Typography className="suggest-edit-dialog-title">Add Reply</Typography>
          <TextField
            key={`add-reply`}
            id="outlined-size-small"
            size="small"
            variant="outlined"
            placeholder="Type here.."
            name="reply"
            rows={2}
            value={reply}
            onChange={(e) => { setReply(e.target.value) }}
            type="textarea"
            multiline
            rowsMax={5}
            style={{ width: "100%", marginTop: "10px" }}
          />

          <Box display='flex' justifyContent='end' style={{ width: "100%", marginTop: "10px" }}>
            <button onClick={closeTooltip} className='white-bg-button'>
              Cancel
            </button>
            <button onClick={addSectionCommentReply} className='blue-bg-button ml-10'>
              Enter
            </button>
          </Box>
        </div>
      </Popup>
    )
  }

  const renderCommentListData = (list, index) => {
    const date = moment.unix(list?.created_at).format("MMM DD, hh:mm a");

    return (
      <>
        <Box display="flex" flexDirection="column" className={`padding-comment-box ${list?.status === CommentsResolveStatus.UNRESOLVED ? "unresolved-bg" : "resolved-bg"}`}>
          <div className='side-drawer-date'>{date}</div>
          <div className="side-drawer-name">{list.created_by_name} <span className='side-drawer-profile'>{list?.created_by_profile ? `(${list?.created_by_profile.replace("_", " ")})` : ""}</span></div>
          <div className="side-drawer-comments mt-2"> {list?.comment}  </div>
          <Box display="flex" className="mt-10" justifyContent="space-between" alignContent="center">
            <Box display="flex">
              <Tooltip title={list?.status === CommentsResolveStatus.UNRESOLVED ? "Mark as Resolved" : "Mark as Unresolved"}>
                <div className="comments-button-background mr-1 pointer" onClick={(e) => editCommentStatus(list?.id, list?.status)}>
                  <img src={list?.status === CommentsResolveStatus.UNRESOLVED ? markAsResolved : markAsUnresolved} className="add-comment-reply" />
                </div>
              </Tooltip>
              {list?.status === CommentsResolveStatus.UNRESOLVED && renderAddReplySection(list)}
            </Box>
            {list?.feedback_thread?.length > 0 &&
              <div style={{ alignSelf: "center" }} className="side-drawer-show-hide-replies">
                <span className={cx('pointer', { 'mark-as-style-resolved': true })}
                  onClick={(e) => setRepliesVisibility(index)}>
                  {showReply[index] ? "Hide" : "Show"}{" Replies ("}{list?.feedback_thread?.length}{")"}
                </span>
              </div>
            }
          </Box>
        </Box>
        {showReply[index] && list?.feedback_thread?.map((replyList) => {
          const date = moment.unix(replyList?.created_timestamp).format("MMM DD, hh:mm a");;
          return (
            <Box display="flex">
              <div className="vertical-reply-divider vertical-line-comment-margins" />
              <Box display="flex" flexDirection="column" style={{ margin: "5px 10px" }} className={`m-10 padding-comment-box comment-reply-bg w-100`}>
                <div className='side-drawer-date'>{date}</div>
                <div className="side-drawer-name">{replyList?.created_by_name} <span className='side-drawer-profile'>{replyList?.created_by_profile ? `(${replyList?.created_by_profile.replace("_", " ")})` : ""}</span></div>
                <div className="side-drawer-comments mt-10"> {replyList?.text}  </div>
              </Box>
            </Box>
          )
        })}
      </>

    )
  }

  const setRepliesVisibility = (index) => {
    const showReplyData = [...showReply];
    showReplyData[index] = !showReply[index];
    setReplyVisibility(showReplyData);
  }


  const handleBack = (e) => {
    history.goBack();
    // history.push("/interviews/all");
  };

  // OPS PDF Downloader
  const downloadPDF = () => {
    let element = document.getElementById('preview-element');
    setLoaderPDF(true);
    html2canvas(element, { scale: 2 }).then(function (canvas) {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF("p", "px", [Math.ceil(canvas.width / 2) + 60, Math.ceil(canvas.height / 2) + 60]);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'PNG', 30, 30, pdfWidth - 60, pdfHeight - 60, 'someAlias', 'FAST');

      const candidateName = snakeCase(interviewDetails?.candidate_details?.name);
      const clientName = snakeCase(interviewDetails?.client_details?.name);
      const positionName = snakeCase(interviewDetails?.interview_details?.position_name);
      const roundName = snakeCase(interviewDetails?.interview_details?.round_name);
      const pdfName = `${candidateName}_${clientName}_${positionName}_${roundName}`;
      pdf.save(`${pdfName}.pdf`);
      setLoaderPDF(false);
    });
  };

  // Creates Shareable Feedback Link
  const shareFeedback = async () => {
    setShareLink(null);
    const link = await getShareableFeedbackLink(interviewId);
    setShareLink(link);
    setShareModalOpen(true);
  };

  const copyToClipboard = () => {
    if (!navigator.clipboard) {
      return;
    }

    navigator.clipboard.writeText(shareLink);
  };

  const viewAllComments = () => {
    setCommentsSideDrawer(true);
  };

  if (!interviewDetails) {
    return null;
  }

  const renderSwitchSection = (data) => {
    switch (steps[currentStep].section) {
      case StepperKeyName.RESUME_INTRO:
        return data?.section === CommentConstants.RESUME_INTRO;

      case StepperKeyName.CODING_QUESTIONS:
      case StepperKeyName.DESIGN_QUESTIONS:
        case StepperKeyName.HLD_QUESTIONS:
          case StepperKeyName.LLD_QUESTIONS:
            return data?.section === CommentConstants.CODING_QUESTIONS;

      case StepperKeyName.THEORY_QUESTIONS:
        return data?.section === CommentConstants.THEORY_QUESTIONS;

      case StepperKeyName.FEEDBACK_SUMMARY:
        return data?.section === CommentConstants.FEEDBACK_SUMMARY || data?.section === CommentConstants.OVERALL_COMMENTS;

      default:
        return true;
    }
  }
  const setTheoryRawResponse = (e) => {
    const dupResponse = { ...interviewDetails }
    dupResponse.feedback_pages.raw_response.theory = e;
    setInterviewDetails(dupResponse)
  }

  const setCodingRawResponse = (e) => {
    const dupResponse = { ...interviewDetails }
    dupResponse.feedback_pages.raw_response.coding = e;
    setInterviewDetails(dupResponse)
  }

  const setHLDDesignRawResponse = (e) => {
    const dupResponse = { ...interviewDetails }
    dupResponse.feedback_pages.raw_response.hld_design = e;
    setInterviewDetails(dupResponse)
  }

  const setLLDDesignRawResponse = (e) => {
    const dupResponse = { ...interviewDetails }
    dupResponse.feedback_pages.raw_response.lld_design = e;
    setInterviewDetails(dupResponse)
  }

  const setResumeRawResponse = (e) => {
    const dupResponse = { ...interviewDetails }
    dupResponse.feedback_pages.raw_response.resume = e;
    setInterviewDetails(dupResponse)
  }

  const setSummaryRawResponse = (e) => {
    const dupResponse = { ...interviewDetails }
    dupResponse.feedback_pages.raw_response.summary = e;
    setInterviewDetails(dupResponse)
  }
  
  return (
    <>
      {role === DashboardType.OPS &&
        <Box display="flex" style={{
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "10px"
        }}>
          <div className="main_title" style={{
            display: 'flex',
            alignItems: "center"
          }}>
            <FontAwesomeIcon
              style={{
                height: "20px",
                width: "15px",
                cursor: "pointer",
                margin: "10px",
              }}
              icon={faArrowLeft}
              onClick={handleBack}
            />
            AUDIT - Interview Feedback
          </div>
          <Box display="flex" className="main_right" >
            {displayPreview &&
              <PDFDownloadLink
                document={<Download
                  feedbackStatus={feedbackStatus}
                  feedbackType={feedbackType}
                  interviewDetails={interviewDetails}
                  resumeIntroData={resumeIntroData}
                  codingQuestionData={codingQuestionData}
                  theoryQuestionsData={theoryQuestionsData}
                  feedbackSummaryData={feedbackSummaryData}

                />} fileName={`${snakeCase(interviewDetails?.candidate_details?.name)}_${snakeCase(interviewDetails?.client_details?.name)}_${snakeCase(interviewDetails?.interview_details?.position_name)}_${snakeCase(interviewDetails?.interview_details?.round_name)}`}>
                {({ blob, url, loading, error }) =>
                  loading ? 'Loading Document...' : 'Download PDF'
                }
              </PDFDownloadLink>
            }
            {/* <Box display="flex">
   
           { loaderPDF &&
             <CircularProgress style={{
                         margin: "0  0 0 15px",
                         width: "25px", height: "25px"
                       }}/>
             }
             <button
               style={{ background: "#fff", border: '1px solid #999', cursor: 'pointer', marginLeft: "10px" }}
               onClick={downloadPDF}
             >
               Download PDF
             </button>
           </Box> */}
            {displayPreview && showFeedbackComments && feedbackComments?.length > 0 &&
              <Box display="flex">
                <button
                  style={{ background: "#fff", border: '1px solid #999', cursor: 'pointer', marginLeft: "10px" }}
                  onClick={viewAllComments}>
                  View All Comments
                </button>
              </Box>}
          </Box>
        </Box>}
      <div className="main-bg">
        <CommentsSideDrawer state={state} showDrawer={showCommentsSideDrawer} setDrawerVisibility={setCommentsSideDrawer} allComments={feedbackComments} editCommentStatus={editCommentStatus} addCommentReply={addCommentReply} />
        {role === DashboardType.INTERVIEWER && <Header role={role} displayPreview={displayPreview} feedbackStatus={feedbackStatus} />}
        {role === DashboardType.CLIENT && <Header role={role}
          displayPreview={displayPreview}
          feedbackStatus={feedbackStatus}
          feedbackType={feedbackType}
          downloadPDF={downloadPDF}
          loaderPDF={loaderPDF}
          shareFeedback={shareFeedback}
          interviewDetails={interviewDetails}
          resumeIntroData={resumeIntroData}
          codingQuestionData={codingQuestionData}
          theoryQuestionsData={theoryQuestionsData}
          feedbackSummaryData={feedbackSummaryData}
          />}
      {displayPreview && (
        <div className="feedback-background">
          {role === DashboardType.OPS?
          <Preview
          user={user}
          role={role}
          auth={auth}
          setDisplayPreview={setDisplayPreview}
          interviewDetails={interviewDetails}
          resumeIntroData={resumeIntroData}
          codingQuestionData={codingQuestionData}
          theoryQuestionsData={theoryQuestionsData}
          feedbackSummaryData={feedbackSummaryData}
          handleSubmitClick={handleSubmitClick}
          feedbackStatus={feedbackStatus}
          displayApproveButton={displayApproveButton}
          setApproveButtonDisplay={setApproveButtonDisplay}
          feedbackType={feedbackType}
          showFeedbackComments={showFeedbackComments}
          feedbackComments={feedbackComments}
          setFeedbackComments={setFeedbackComments}
          addCommentReply={addCommentReply}
          addFeedbackComment = {addFeedbackComment}
          editFeedbackComment = {editFeedbackComment}
          getFeedbackComments = {getFeedbackComments} 
          rateFeedback = {rateFeedback}
        />:
          <Preview
            user={user}
            role={role}
            auth={auth}
            setDisplayPreview={setDisplayPreview}
            interviewDetails={interviewDetails}
            resumeIntroData={resumeIntroData}
            codingQuestionData={codingQuestionData}
            theoryQuestionsData={theoryQuestionsData}
            feedbackSummaryData={feedbackSummaryData}
            handleSubmitClick={handleSubmitClick}
            feedbackStatus={feedbackStatus}
            feedbackType={feedbackType}
            addFeedbackComment = {addFeedbackComment}
            editFeedbackComment = {editFeedbackComment}
            getFeedbackComments = {getFeedbackComments} 
            rateFeedback = {rateFeedback}
          />}
        </div>
      )}

      {!displayPreview && (
        <div className="feedback-background">
        <Details interviewDetails={interviewDetails} clientLogo={clientLogo}/>

        <Box display='flex' flexDirection='row' className={`width-100' ${showFeedbackComments  && role===DashboardType.INTERVIEWER ? "overflow-hidden" : "margin-top-50"}`}>

         <Box display='flex' flexDirection='column' className={`${(showFeedbackComments && role===DashboardType.INTERVIEWER) ? "width-75 overflow-scroll" : 'width-100'}`}>
          <div className="stepper-margin">
            {(error?.resumeIntroError || error?.codingQuestionsError || error?.theoryQuestionsError || error?.feedbackSummaryError) && (
              <div className="warning-error">
                ** Some mandatory fields left out from the sections of the
                feedback which appear red. Do fill them up to
                <i>
                  <b> PREVIEW & SUBMIT </b>
                </i>
                your feedback.
              </div>
            )}

            <Stepper role={role} steps={steps} onStepperClick={onStepperClick} feedbackType = {feedbackType} showFeedbackComments={showFeedbackComments} feedbackComments={feedbackComments} stepperClickDisable={disableSubmitButtons}/>

                </div>
                {steps[currentStep].section === StepperKeyName.RESUME_INTRO && (
                  isResumeV2Version(interviewDetails) && isV2Version(steps[currentStep])
                    ?
                    <CommonSectionV2
                      section={'resume'}
                      interviewDetails={interviewDetails}
                      rawForm={interviewDetails?.feedback_pages?.raw_form?.resume}
                      rawResponse={interviewDetails?.feedback_pages?.raw_response?.resume}
                      setRawResponse={setResumeRawResponse}
                      showError={error?.resumeIntroError}
                    />
                    :
                  <ResumeIntro
                    error={error?.resumeIntroError}
                    resumeIntroData={resumeIntroData}
                    setResumeIntroData={setResumeIntroData}
                    status={feedbackStatus}
                  />
                )}

                {(steps[currentStep].section === StepperKeyName.CODING_QUESTIONS) && (
                  (isCodingV2Version(interviewDetails) && isV2Version(steps[currentStep]))
                    ? <CommonSectionV2
                      section={'coding'}
                      interviewDetails={interviewDetails}
                      rawForm={interviewDetails?.feedback_pages?.raw_form?.coding}
                      rawResponse={interviewDetails?.feedback_pages?.raw_response?.coding}
                      setRawResponse={setCodingRawResponse}
                      showError={error?.codingQuestionsError}
                    />
                    : <CodingQuestions
                        error={error?.codingQuestionsError}
                        codingQuestionData={codingQuestionData}
                        setCodingQuestionData={setCodingQuestionData}
                        feedbackType={feedbackType}
                        interviewDetails={interviewDetails}
                      />
                )}

                {(steps[currentStep].section === StepperKeyName.DESIGN_QUESTIONS 
                || steps[currentStep].section === StepperKeyName.HLD_QUESTIONS 
                || steps[currentStep].section === StepperKeyName.LLD_QUESTIONS ) && (
                  (isHLDDesignV2Versions(interviewDetails) && isV2Version(steps[currentStep]))
                      ? <CommonSectionV2
                        section={'hld_design'}
                        interviewDetails={interviewDetails}
                        rawForm={interviewDetails?.feedback_pages?.raw_form?.hld_design}
                        rawResponse={interviewDetails?.feedback_pages?.raw_response?.hld_design}
                        setRawResponse={setHLDDesignRawResponse}
                        showError={error?.codingQuestionsError}
                      />
                      : (isLLDDesignV2Versions(interviewDetails) && isV2Version(steps[currentStep]))
                      ? <CommonSectionV2
                        section={'lld_design'}
                        interviewDetails={interviewDetails}
                        rawForm={interviewDetails?.feedback_pages?.raw_form?.lld_design}
                        rawResponse={interviewDetails?.feedback_pages?.raw_response?.lld_design}
                        setRawResponse={setLLDDesignRawResponse}
                        showError={error?.codingQuestionsError}
                      />
                      : <CodingQuestions
                        error={error?.codingQuestionsError}
                        codingQuestionData={codingQuestionData}
                        setCodingQuestionData={setCodingQuestionData}
                        feedbackType={feedbackType}
                        interviewDetails={interviewDetails}
                      />
                )}

                {steps[currentStep].section === StepperKeyName.THEORY_QUESTIONS && (

                  (isTheoryV2Version(interviewDetails) && isV2Version(steps[currentStep]))
                    ?
                    <CommonSectionV2
                      section={'theory'}
                      interviewDetails={interviewDetails}
                      rawForm={interviewDetails?.feedback_pages?.raw_form?.theory}
                      rawResponse={interviewDetails?.feedback_pages?.raw_response?.theory}
                      setRawResponse={setTheoryRawResponse}
                      showError={error?.theoryQuestionsError}
                    />
                    :
                    <TheoryQuestions
                      error={error?.theoryQuestionsError}
                      theoryQuestionsData={theoryQuestionsData}
                      setTheoryQuestionsData={setTheoryQuestionsData}
                    />
                )}
                {steps[currentStep].section === StepperKeyName.FEEDBACK_SUMMARY && (
                  isSummaryV2Versions(interviewDetails)  && isV2Version(steps[currentStep])
                    ?
                    <CommonSectionV2
                      section={'summary'}
                      interviewDetails={interviewDetails}
                      rawForm={interviewDetails?.feedback_pages?.raw_form?.summary}
                      rawResponse={interviewDetails?.feedback_pages?.raw_response?.summary}
                      setRawResponse={setSummaryRawResponse}
                      showError={error?.feedbackSummaryError}
                    />
                    :
                  <FeedbackSummary
                    error={error?.feedbackSummaryError}
                    feedbackSummaryData={feedbackSummaryData}
                    setFeedbackSummaryData={setFeedbackSummaryData}
                    status={feedbackStatus}
                    previewResult={interviewDetails?.feedback_preview}
                  />
                )}

                <Box display="flex">
                  {
                    // currentStep === steps.length - 1 &&
                    (feedbackStatus === InterviewFeedbackStatus.PREVIEW ||
                      feedbackStatus === InterviewFeedbackStatus.PENDING ||
                      feedbackStatus === InterviewFeedbackStatus.DRAFT) &&

                    <Box display="flex" flexDirection="column">
                      <div
                        disabled={disableSubmitButtons}
                        className={`previous-button`}
                        onClick={handleSaveAsDraft}
                      >
                        Save as draft
                      </div>

                      <Box display="flex" style={{ marginTop: "20px" }}>

                        {interviewDetails?.last_updated_timestamp && <>Feedback Data saved {moment.unix(interviewDetails?.last_updated_timestamp).fromNow()}.</>}

                        {disableSubmitButtons && <CircularProgress style={{ width: "30px", marginLeft: "20px", padding: '0px' }}></CircularProgress>}
                      </Box>

                    </Box>

                  }

                  <div
                    className={`previous-button mtb-20 mrl-20 ${currentStep === 0 ? "blur" : ""
                      }`}
                    style={{ marginLeft: "auto" }}
                    disabled={currentStep === 0 ? true : false}
                    onClick={handlePrevClick}
                  >
                    Previous Section
                  </div>
                  <div className="next-button" onClick={handleNextClick} disabled={disableSubmitButtons}>
                    {currentStep === steps.length - 1
                      ? "Preview & Submit"
                      : "Next Section"}
                  </div>
                </Box>
              </Box>

              {role === DashboardType.INTERVIEWER && showFeedbackComments &&
                <Box display='flex' flexDirection='row' className='width-25 overflow-scroll'>

                  <div className="vertical-line" />

                  <Box display='flex' flexDirection='column' className='suggested-comments-section' >

                    <div className='suggested-changes-text'>Suggested Changes</div>
                    <div className='suggested-changes-section'> {steps[currentStep].section === StepperKeyName.RESUME_INTRO ? "Resume-Intro" 
                    : (steps[currentStep].section === StepperKeyName.CODING_QUESTIONS || steps[currentStep].section === StepperKeyName.DESIGN_QUESTIONS || steps[currentStep].section === StepperKeyName.HLD_QUESTIONS || steps[currentStep].section === StepperKeyName.LLD_QUESTIONS) ? "Coding-Questions" 
                    : steps[currentStep].section === StepperKeyName.THEORY_QUESTIONS ? "Theory-Questions" 
                    : steps[currentStep].section === StepperKeyName.FEEDBACK_SUMMARY ? "Feedback-Summary" : "Feedback"}</div>

                    {
                      feedbackComments?.filter((data) => renderSwitchSection(data))?.map((list, index) => {
                        return (
                          renderCommentListData(list, index)
                        );
                      })}

                    {feedbackComments?.filter((data) => renderSwitchSection(data))?.length === 0 &&
                      <div className="no-comments-available"> No Comments Available </div>
                    }

                  </Box>
                </Box>
              }
            </Box>
          </div>
        )}
        {showErrorModal && <BasicModal modalError={modalError} setShowErrorModal={setShowErrorModal} />}
        {showBackendValidationModal?.state && <BackendValidationPopup backendValidation={backendValidationV2} setBackendValidationModal={setBackendValidationModal} canIgnoreWarning={ (canIgnore) => handleSubmitClick({...showBackendValidationModal?.data, ignoreWarning: canIgnore})}/>}

        <Modal
          open={shareModalOpen}
          onClose={() => setShareModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }} className={classes.paper}>
            <h5 id="simple-modal-title" style={{ fontSize: '16px' }}>Share Feedback</h5>
            <p id="simple-modal-description" style={{ fontSize: '14px' }}>
              Please copy the following link to share this feedback for 7 days:
            </p>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Generating shareable link..."
                inputProps={{ 'aria-label': 'feedback shareable link' }}
                value={shareLink}
              />
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton
                color="default"
                className={classes.iconButton}
                aria-label="copy to clipboard"
                onClick={copyToClipboard}
              >
                <FileCopyIcon />
              </IconButton>
            </Paper>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Feedback;

const Details = ({ clientLogo, interviewDetails }) => {
  return (
    <div className="width-100">
      <div className="interviewer-response-title mtb-20">

        INTERVIEWER RESPONSE
      </div>

      {/* <hr className="interviewer-response-hr" /> */}

      <Box
        display="flex"
        justifyContent="space-around"
        className="details-box-style"
      >
        <img className="mrl-20" style={{ maxWidth: "100px", maxHeight: "100px", alignSelf: "center" }}
          src={
            interviewDetails?.client_details?.logo
              ? clientLogo
              : logoFeedback
          }
          alt="feedback-logo"
        />

        <Box display="flex" flexDirection="row">
          <div className="vertical" />
          <Box display="flex" flexDirection="column" className="mrl-30" >

            <div className="details-box-title">COMPANY - CANDIDATE DETAILS</div>

            <div className="row-style">
              <div className="details-box-label-value">Company:</div>
              <div className="details-box-label-value details-value">
                {interviewDetails?.client_details?.name}
              </div>
            </div>
            <div className="row-style">
              <div className="details-box-label-value">Candidate:</div>
              <div className="details-box-label-value details-value">
                {interviewDetails?.candidate_details?.name}
              </div>
            </div>
            <div className="row-style">
              <div className="details-box-label-value">Position:</div>
              <div className="details-box-label-value details-value">
                {interviewDetails?.interview_details?.position_name}
              </div>
            </div>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row">
          <div className="vertical" />
          <Box display="flex" flexDirection="column" className="mrl-30" >
            <div className="details-box-title">Interview DETAILS</div>

            <div className="row-style">
              <div className="details-box-label-value">Position Role:</div>
              <div className="details-box-label-value details-value">
                {interviewDetails?.interview_details?.position_role}
              </div>
            </div>
            <div className="row-style">
              <div className="details-box-label-value">Round Category:</div>
              <div className="details-box-label-value details-value">
                {interviewDetails?.interview_details?.round_category}
              </div>
            </div>
            <div className="row-style">
              <div className="details-box-label-value">Interview Date:</div>
              <div className="details-box-label-value details-value">
                {interviewDetails?.interview_details?.scheduled_at
                  ? moment(
                    interviewDetails?.interview_details?.scheduled_at * 1000
                  ).format("MMM DD, YYYY | hh:mm a")
                  : "NA"}
              </div>
            </div>
          </Box>
        </Box>

        {interviewDetails?.feedback_preview ?
          <Box display="flex" flexDirection="row">
            <div className="vertical" />
            <Box display="flex" flexDirection="column" className="mrl-30" >
              <div className="details-box-title">PREVIEW RESULT</div>
              <div className="previewBox">
                <div className={interviewDetails?.feedback_preview == "STRONG_PROCEED" ? "previewResult dgreen" : interviewDetails?.feedback_preview == "PROCEED" ? "previewResult green"
                  : interviewDetails?.feedback_preview == "REJECT" ? "previewResult red" : interviewDetails?.feedback_preview == "STRONG_REJECT" ? "previewResult dred" : "previewResult"}>
                  {interviewDetails?.feedback_preview?.replace("_", " ")}
                </div>
              </div>
            </Box>
          </Box> : ""
        }
      </Box>
    </div>
  );
};

const Header = (props) => {
  const isDialogOpenByDefault = (!props.displayPreview && (props.feedbackStatus === InterviewFeedbackStatus.PREVIEW || props.feedbackStatus === InterviewFeedbackStatus.PENDING)) ? true : false;

  const [openInstructionDialog, setInstructionDialogOpen] = useState(isDialogOpenByDefault);

  const handleInstructionClick = (e) => {
    setInstructionDialogOpen(!openInstructionDialog);
  };

  return (
    <>
      <CustomizedDialogs
        isDialogOpen={openInstructionDialog}
        handleClose={handleInstructionClick}
      />

      <Box
        display="flex"
        justifyContent="space-between"
        className="header-style"
      >
        <img src={logoFeedback} style={{ width: "125px" }} />

        <Box display="flex" sx={{ marginLeft: 'auto' }} justifyContent="center">
          <div className="header-name"> INTERVIEW FEEDBACK </div>

          {props.role === DashboardType.INTERVIEWER && props.displayPreview &&
            props.feedbackStatus !== InterviewFeedbackStatus.APPROVED && (
              <div className="preview-text-header">PREVIEW</div>
            )}
        </Box>

        {!props.displayPreview && (
          <div style={{ width: "125px" }}
            className={`instructions-box ${openInstructionDialog
                ? "instructions-box-clicked"
                : "instructions-box-unclicked"
              }`}
            onClick={handleInstructionClick}
          >
            <div
              className={`instruction-i-style ${openInstructionDialog
                  ? "instruction-text-clicked"
                  : "instruction-text-unclicked"
                }`}
            >
              i
            </div>
            <div
              className={`instruction-text-style ${openInstructionDialog
                  ? "instruction-text-clicked"
                  : "instruction-text-unclicked"
                }`}
            >
              Instructions
            </div>
          </div>
        )}
        <div className="clientButtons">
          {props.role === DashboardType.CLIENT && props.displayPreview &&
            <div className={`instructions-box instructions-box-unclicked download-pdf-text-style`}>
              <PDFDownloadLink
                document={<Download
                  feedbackStatus={props.feedbackStatus}
                  feedbackType={props.feedbackType}
                  interviewDetails={props.interviewDetails}
                  resumeIntroData={props.resumeIntroData}
                  codingQuestionData={props.codingQuestionData}
                  theoryQuestionsData={props.theoryQuestionsData}
                  feedbackSummaryData={props.feedbackSummaryData}

                />} fileName={`${snakeCase(props.interviewDetails?.candidate_details?.name)}_${snakeCase(props.interviewDetails?.interview_details?.position_name)}_${snakeCase(props.interviewDetails?.interview_details?.round_name)}`}>
                {({ blob, url, loading, error }) =>
                  loading
                    ? 'Loading Document...'
                    : <div className={`download-pdf-text-style`}> <img src="/images/download_icon_white.svg" style={{ height: "12px", width: "12px", marginRight: "8px" }} /> Download PDF</div>
                }
              </PDFDownloadLink>
            </div>

          }
          {props.role === DashboardType.CLIENT &&
            <div>
              <IconButton
                aria-label="close"
                className=""
                onClick={() => props.shareFeedback(true)}
                style={{ color: '#fff', margin: "0 15px 0 0" }}
              >
                <ShareIcon style={{ width: "20px", height: "20px", margin: "4px" }} />
              </IconButton>
            </div>
          }
        </div>
        {props.role !== DashboardType.CLIENT && props.displayPreview && <div style={{ width: "125px" }}> </div>}
      </Box>
    </>
  );
};

export function CustomizedDialogs({ isDialogOpen, handleClose }) {
  return (
    <div>
      <Dialog
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={isDialogOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Box display="flex">
            <div className="instruction-i-bg-dialog">i</div>
            <div className="dialog-title">INSTRUCTIONS</div>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <ul>

            <Typography gutterBottom>
              <li className="dialog-content">
                All fields on all the sections of this form are mandatory. If
                there is any field where there isn’t any suitable answer, type
                <b> N/A.</b>
              </li>
            </Typography>
            <Typography gutterBottom>
              <li className="dialog-content">

                You can toggle upon other multiple sections simultaneously
                without mandatorily completing one section, but before doing
                <b> PREVIEW and SUBMIT</b> for the complete form, you must fill
                out all the fields from all the sections.
              </li>
            </Typography>
            <Typography gutterBottom>
              <li className="dialog-content">

                Further instructional informations for sections and sub-sections
                of the complete form are mentioned with an
                <span className="instruction-i-dialog-bg"> i </span>
                <b> icon</b> beside the field/subject headers. You can see the
                info on hovering over the
                <span className="instruction-i-dialog-bg"> i </span>
                <b> icons.</b>
              </li>
            </Typography>
            <Typography gutterBottom>
              <li className="dialog-content">

                To add an extra check for mistakes and their identification, you
                are requested to go-through the <b> Preview</b> of your feedback
                properly, before final submission to us.
              </li>
            </Typography>
          </ul>
        </DialogContent>
      </Dialog>
    </div>
  );
}
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
