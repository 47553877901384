import React from "react";
import { Document, Page, Font } from '@react-pdf/renderer';

import { isCodingQuestionsFilled, isTheoryQuestionFilled, isV2Version, isTheoryV2Version,  isV2TheoryProcessedResponse, isCodingV2Version, isV2CodingProcessedResponse, isResumeV2Version, isV2ResumeProcessedResponse, isHLDDesignV2Versions, isV2HldDesignProcessedResponse, isLLDDesignV2Versions, isV2LldDesignProcessedResponse } from "../../Constants/FeedbackConstants";

import { stylesPDF } from "../../SectionsV1/Download/Style";

import { Details } from "../../SectionsV0/Download/Details";
import { FeedbackSummary } from "../../SectionsV0/Download/FeedbackSummary";

import { CodingQuestions } from "../../SectionsV1/Download/CodingSection";
import { TheoryQuestions } from "../../SectionsV1/Download/TheorySection";
import { ResumeEntry } from "../../SectionsV1/Download/ResumeSection";

import TheorySection from '../../SectionsV2/Download/TheorySection';
import CodingSectionV2 from '../../SectionsV2/Download/CodingSection';
import ResumeSectionV2 from '../../SectionsV2/Download/ResumeSection';
import DesignSectionV2 from '../../SectionsV2/Download/DesignSection';

// images......
import rating0Img from "../../../../../assets/images/download-feedback/rating_0.png";
import rating1Img from "../../../../../assets/images/download-feedback/rating_1.png";
import rating2Img from "../../../../../assets/images/download-feedback/rating_2.png";
import rating3Img from "../../../../../assets/images/download-feedback/rating_3.png";
import rating4Img from "../../../../../assets/images/download-feedback/rating_4.png";
import rating5Img from "../../../../../assets/images/download-feedback/rating_5.png";
import rating6Img from "../../../../../assets/images/download-feedback/rating_6.png";
import rating7Img from "../../../../../assets/images/download-feedback/rating_7.png";
import rating8Img from "../../../../../assets/images/download-feedback/rating_8.png";
import rating9Img from "../../../../../assets/images/download-feedback/rating_9.png";
import rating10Img from "../../../../../assets/images/download-feedback/rating_10.png";

import PoppinsRegular from "../../../../../assets/fonts/poppins-fonts/Poppins-Regular.ttf";
import PoppinsThin from "../../../../../assets/fonts/poppins-fonts/Poppins-Thin.ttf";
import PoppinsMedium from "../../../../../assets/fonts/poppins-fonts/Poppins-Medium.ttf";
import PoppinsSemiBold from "../../../../../assets/fonts/poppins-fonts/Poppins-SemiBold.ttf";
import PoppinsItalic from "../../../../../assets/fonts/poppins-fonts/Poppins-Italic.ttf";
import PoppinsBoldItalic from "../../../../../assets/fonts/poppins-fonts/Poppins-BoldItalic.ttf";
import PoppinsBold from "../../../../../assets/fonts/poppins-fonts/Poppins-Bold.ttf";

export const ratingToImage = {
    "0": rating0Img,
    "1": rating1Img,
    "2": rating2Img,
    "3": rating3Img,
    "4": rating4Img,
    "5": rating5Img,
    "6": rating6Img,
    "7": rating7Img,
    "8": rating8Img,
    "9": rating9Img,
    "10": rating10Img,
}

Font.register({
    family: 'Poppins',
    src: { PoppinsRegular },
    fonts: [
        {
            src: { PoppinsRegular }
        },
        {
            src: { PoppinsThin },
            fontWeight: 'thin',
        },
        {
            src: { PoppinsMedium },
            fontWeight: 'medium'
        },
        {
            src: { PoppinsSemiBold },
            fontWeight: 'semibold',
        },
        {
            src: { PoppinsItalic },
            fontStyle: 'italic',
        },
        {
            src: { PoppinsBoldItalic },
            fontWeight: 'bold',
            fontStyle: 'italic',
        },
        {
            src: { PoppinsBold },
            fontWeight: 'bold',
        },
    ]

});

Font.registerHyphenationCallback(word => [word]);

const Download = ({
    interviewDetails,
    resumeIntroData,
    codingQuestionData,
    theoryQuestionsData,
    feedbackSummaryData,
    feedbackStatus,
    feedbackType,
}) => {
    return (
        <Document style={[{ minWidth: '920', margin: 'auto', marginBottom: '20' }]}>
            <Page size="A2" style={[stylesPDF.page]}  wrap={false}>
                <Details
                    interviewDetails={interviewDetails}
                    feedbackSummaryData={feedbackSummaryData}
                />
                <FeedbackSummary
                interviewDetails={interviewDetails}
                    feedbackStatus={feedbackStatus}
                    feedbackSummaryData={feedbackSummaryData}
                />
               { isResumeV2Version(interviewDetails) && isV2ResumeProcessedResponse(interviewDetails) 
               ? <ResumeSectionV2 resumeData={interviewDetails?.feedback_pages?.processed_response?.resume}/>
               : <ResumeEntry
                    interviewDetails={interviewDetails}
                    resumeIntroData={resumeIntroData}
                />}
                {
                    (isCodingV2Version(interviewDetails) && isV2CodingProcessedResponse(interviewDetails))
                    && <CodingSectionV2 codingData={interviewDetails?.feedback_pages?.processed_response?.coding} />
                }
                {
                    isHLDDesignV2Versions(interviewDetails) && isV2HldDesignProcessedResponse(interviewDetails)
                    && <DesignSectionV2 interviewDetails={interviewDetails} codingData={interviewDetails?.feedback_pages?.processed_response?.hld_design} />
                }
                {
                    isLLDDesignV2Versions(interviewDetails) && isV2LldDesignProcessedResponse(interviewDetails)
                    && <DesignSectionV2 interviewDetails={interviewDetails} codingData={interviewDetails?.feedback_pages?.processed_response?.lld_design} />
                }
                {
                    isCodingQuestionsFilled(codingQuestionData, feedbackType, interviewDetails) &&
                    <CodingQuestions
                        interviewDetails={interviewDetails}
                        codingQuestionData={codingQuestionData}
                        feedbackType={feedbackType}
                    />
                }
                {
                    (isTheoryV2Version(interviewDetails) && isV2TheoryProcessedResponse(interviewDetails))
                        ? <TheorySection theoryData={interviewDetails?.feedback_pages?.processed_response?.theory} />

                        : isTheoryQuestionFilled(theoryQuestionsData) &&
                        <TheoryQuestions
                            interviewDetails={interviewDetails}
                            theoryQuestionsData={theoryQuestionsData}
                        />}
            </Page>
        </Document>
    );
};

export default Download;