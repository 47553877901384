import React, { useCallback, useState } from 'react'
import boxImg from './images/empty-box.svg';
import RequestCard from './RequestCard';
import Box from '@material-ui/core/Box';
import './RequestList.scss';
import RequestDetail from './RequestDetail';

const RequestList = ({ requests, setRequests }) => {
    const [showDetail, setShowDetail] = useState(false) // Tracks show/hide state of the detail drawer
    const [selectedRequest, setSelectedRequest] = useState(null) // State to track selected request

    // Function to handle RequestCard click
    const handleCardClick = useCallback((index) => {
        setSelectedRequest(requests[index]);
        setShowDetail(true);
    }, [requests])

    // Function to remove request (ONLY FROM UI)
    const removeRequestFromUi = useCallback((requestToRemovedId) => {
        setRequests((prevRequests) => ([...(prevRequests.filter((request) => (request.id !== requestToRemovedId)))]))
    }, [setRequests])

    return (
        <>
            {/* If there is data */}
            <>
                <Box className="requests-container">
                    {requests?.length !== 0 &&
                        requests.map((request, index) => (
                            <RequestCard key={index} request={request} index={index} setShow={setShowDetail} handleCardClick={() => { handleCardClick(index) }} />
                        ))}
                </Box>
                {/* Detail request modal */}
                <RequestDetail request={selectedRequest} show={showDetail} setShow={setShowDetail} removeRequestFromUi={() => { removeRequestFromUi(selectedRequest.id) }} />
            </>

            {/* If there is no data */}
            {requests?.length === 0 &&
                <Box display="flex" flexDirection="column" justifyContent="Center" alignItems="center" className='empty-table-wrapper'>
                    <img className="empty-box" src={boxImg} alt="No data" />
                    <p className="empty-box-text">No interview requests available!</p>
                </Box>
            }
        </>
    )
}

export default RequestList;