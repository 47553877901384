import { FETCH_REQUESTS } from "../actions/Requests";

export const initialState = {
  list: []
}

const requestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REQUESTS:
      return {
        ...state,
        list: action.payload
      };
    default:
      return state;
  }
}

export default requestsReducer;