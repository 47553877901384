import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

Font.register({
  family: "Poppins",
  src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
  fonts: [
    {
      src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
    },
    {
      src: `/assets/poppins-fonts/Poppins-Thin.ttf`,
      fontWeight: "thin",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Medium.ttf`,
      fontWeight: "medium",
    },
    {
      src: `/assets/poppins-fonts/Poppins-SemiBold.ttf`,
      fontWeight: "semibold",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Italic.ttf`,
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-BoldItalic.ttf`,
      fontWeight: "bold",
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Bold.ttf`,
      fontWeight: "bold",
    },
  ],
});

Font.registerHyphenationCallback((word) => [word]);

const stylesNavbar = StyleSheet.create({
  navbar: {
    height: 61,
    backgroundColor: "#1844ad",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  headingNav: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: 18,
    textAlign: "center",
    letterSpacing: "0.095",
    textTransform: "uppercase",
    color: "#ffffff",
  },
});

const Navbar = () => {
  return (
    <View style={[stylesNavbar.navbar]} fixed>
      <Text style={[stylesNavbar.headingNav]}>INTERVIEW AUDIT REPORT</Text>
    </View>
  );
};

export default Navbar;
