import swal from "sweetalert";
import "./SwtAlert.css";

const SwtAlert = (message, time, type ,buttonEnable) => {
  return swal({
    icon: type,
    text: message,
    buttons: buttonEnable,
    timer: time,
  });
};

export default SwtAlert;
