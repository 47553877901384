import { DashboardType } from "@iv/shared/constants";
import { useSelector } from 'react-redux';
import Feedback from '@iv/shared/components/pages/Feedback';
import { getFeedback, addFeedback, editFeedback, editFeedbackComment, getFeedbackComments, addFeedbackCommentReply } from '../../services/feedback';

const FeedbackC = () => {
    const state = useSelector((state) => state);
    return (<Feedback state={state} role={DashboardType.INTERVIEWER}
        getFeedback={getFeedback}
        addFeedback={addFeedback}
        editFeedback={editFeedback}
        editFeedbackStatus={null}
        addFeedbackComment={null}
        editFeedbackComment={editFeedbackComment}
        getFeedbackComments={getFeedbackComments}
        addFeedbackCommentReply={addFeedbackCommentReply}
        getShareableFeedbackLink={null}
    />);
}

export default FeedbackC;