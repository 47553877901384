import React from "react";
// Material UI imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import videoImg from "../../images/video-img.svg";
import closeImg from "../../images/close-white.svg";
import archiveImg from "../../images/archive-folder.svg";
import "./VideoModal.scss";

const VideoModal = ({open , isArchive , videoUrl , onCloseModal}) => {

  const renderArchiveModalView = () => {
    return(
      <Box className='archive-video-wrapper' display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
        <img className='archive-img' src={archiveImg} alt="archive img" />
        <Typography className='archive-heading'>This video has been archived.</Typography>
        <Typography className='archive-subtext'>All the video content gets archived after 30 days from the date it was added.</Typography>
        <Typography className='support-text'>For queries or requests, please contact our customer support or write us</Typography>
        <Typography className='support-text'>at <span className='contact-us'>contact@interviewvector.com</span></Typography>
      </Box>
    )
  }
  return (
    <>
      <Modal open={open}>
        <Box className="feedback-video-modal">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="video-link-nav"
          >
            <Box display="flex">
              <img src={videoImg} alt="video img" />
              <Typography className="recording-label">
                Recording
              </Typography>
            </Box>
            <img
              onClick={onCloseModal}
              className="close-icon"
              src={closeImg}
              alt="close img"
            />
          </Box>
          {isArchive ? (
            <> {renderArchiveModalView()}</>
          ) : (
            <>
              {" "}
              {videoUrl && (
                <iframe
                  src={videoUrl}
                  width="900"
                  height="500"
                  frameBorder="0"
                  title="video url"
                />
              )}
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default VideoModal;
