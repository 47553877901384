import React, {useState, useEffect, Fragment} from 'react'
import {Link} from 'react-router-dom'
import { logoutUser } from '../../services/auth';
import { resetUser } from '../../store/actions/User';
import { logoutSuccess } from '../../store/actions/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile } from '../../services/profile'
import { profileAction } from '../../store/actions/Profile'
import axios from "../../services/axios";
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useAvailabilityContext, useAvailabilityContextUpdate } from '../../availabilityContext';
import './styles.scss';
import { Avatar, DrawerSidebar, SidebarBodyWrapper, SidebarHeader, SidebarMenuItem, SidebarMenuItemText, SidebarMenuItemExpandIcon, SidebarSubMenuCollapsibleWrapper, SidebarSubMenuItemText } from './styles_sidebar'
import { AppbarWrapper, AppbarPageName, AugmentElementWrapper } from "./styles_appbar"
import useWindowDimensions from '../../utilis/windowDimension'
import DashboardIcon from "../../images/sidebar/dashboard.svg"
import ScheduledIcon from "../../images/sidebar/scheduled.svg"
import RequestsIcon from "../../images/sidebar/requests.svg"
import AuditIcon from "../../images/sidebar/audit.svg"
import AvailabilityIcon from "../../images/sidebar/availability.svg"
import HistoryIcon from "../../images/sidebar/history.svg"
import MyProfileIcon from "../../images/sidebar/my-profile.svg"
import LogoutIcon from "../../images/sidebar/logout.svg"
import ExpandIcon from "../../images/sidebar/expand.svg"
import { Box } from '@mui/system';

/*
Reusable component that has Sidebar and Appbar combined.

The Appbar only appears on mobile screens, whereas the Sidebar appears permanently on widescreens and openable Drawer on mobile screens. {For displaying header (with Title and an augment element) on widescreens, use component `HeaderView` from "src/components/Pages/Views"}

Props:
1. pageTitle (optional): A string that shows the page title, shown ONLY on Appbar. Default: "InterviewVector"
2. augmentElement (optional): An element that would be rendered ONLY on the Appbar on the right hand side of page title. Use this to pass contextual elements for each page.
*/
const NavBar = ({ location, pageTitle = "InterviewVector", augmentElement }) => {
  const { isEditing } = useAvailabilityContext();
  const { changeLink } = useAvailabilityContextUpdate();
  const dispatch = useDispatch();
  const fetchedProfile = useSelector((state) => state && state.profile && state.profile);
  const [activeLink, setActiveLink] = useState('')
  const { width } = useWindowDimensions()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [profileMenuOpen, setProfileMenuOpen] = useState(false) // State to track open/close state of profile menu

  useEffect(() => {
    if(!fetchedProfile || !fetchedProfile.name) fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try{
        const res = await fetchProfile()
        dispatch(profileAction(res));
    } catch(err){
        console.log(err)
    }
  };

  useEffect(() => {
    if(activeLink !== location.pathname) {
      setActiveLink(location.pathname + location.hash)
    }
    // eslint-disable-next-line
  }, [location]);


  const handleLogout = async () => {
    try {
      const { status } = await logoutUser();
      dispatch(resetUser(status));
      dispatch(logoutSuccess());
      axios.defaults.headers.common['Authorization'] = null;
    } catch (e) {
      // console.error(e);
    }
  };


  return (
    <>
      {/* Appbar (only for mobile) */}
      <AppbarWrapper>
        {/* Hamburger menu icon */}
        <IconButton onClick={() => { setSidebarOpen(true) }}>
          <MenuIcon />
        </IconButton>

        {/* Page name */}
        <AppbarPageName variant="body1">{pageTitle}</AppbarPageName>

        {/* Augment element (Each page using this Navbar has the prop to render an additional contextual element here on navbar) */}
        {!!augmentElement &&
          <AugmentElementWrapper>
            {augmentElement}
          </AugmentElementWrapper>
        }
      </AppbarWrapper>

      {/* Sidebar */}
      <DrawerSidebar className="drawer-sidebar" open={sidebarOpen} variant={width <= 768 ? "temporary"   : "permanent"} onClose={() => { setSidebarOpen(false) }}>
        {/* Header - DP and close button only for mobile */}
        {/* <SidebarHeader>
          <Avatar src={fetchedProfile.avatar} alt="User avatar" />
          {width <= 768 &&
            <IconButton onClick={() => { setSidebarOpen(false) }}>
              <CloseIcon />
            </IconButton>
          }
        </SidebarHeader> */}

        <img src="https://uploads-ssl.webflow.com/60733d2745729b18912a257d/6073439ed222cb39f8564bf9_brand.svg"
         alt="InterviewVector" style={{width:"200px", height:"55px", marginLeft:"20px"}} />

        {/* Sidebar body wrapper */}
        <Box className="sidebody-wrapper">

          {/* Dashboard */}
          <Link to={isEditing ? "#" : "/home" } onClick={() => changeLink('/home')}>
            <SidebarMenuItem>
              <img src={DashboardIcon} alt="Dashboard icon" />
              <SidebarMenuItemText variant="body1">Dashboard</SidebarMenuItemText>
            </SidebarMenuItem>
          </Link>

          {/* Scheduled */}
          <Link to={isEditing ? "#" : "/scheduled" } onClick={() => changeLink('/scheduled')}>
            <SidebarMenuItem>
              <img src={ScheduledIcon} alt="Scheduled icon" />
              <SidebarMenuItemText variant="body1">Scheduled</SidebarMenuItemText>
            </SidebarMenuItem>
          </Link>

          {/* Requests */}
          <Link to={isEditing ? "#" : "/requests" } onClick={() => changeLink('/requests')}>
            <SidebarMenuItem>
              <img src={RequestsIcon} alt="Requests icon" />
              <SidebarMenuItemText variant="body1">Requests</SidebarMenuItemText>
            </SidebarMenuItem>
          </Link>

          {/* Audit */}
          <Link to={isEditing ? "#" : "/audit" } onClick={() => changeLink('/audit')}>
            <SidebarMenuItem>
              <img src={AuditIcon} alt="Audit icon" />
              <SidebarMenuItemText variant="body1">Audit</SidebarMenuItemText>
            </SidebarMenuItem>
          </Link>
        

          {/* Availability */}
          <Link to={isEditing ? "#" : "/availability" } onClick={() => changeLink('/availability')}>
            <SidebarMenuItem>
              <img src={AvailabilityIcon} alt="Availability icon" />
              <SidebarMenuItemText variant="body1">Availability</SidebarMenuItemText>
            </SidebarMenuItem>
          </Link>

          {/* History */}
          <Link to={isEditing ? "#" : "/history" } onClick={() => changeLink('/history')}>
            <SidebarMenuItem>
              <img src={HistoryIcon} alt="History icon" />
              <SidebarMenuItemText variant="body1">History</SidebarMenuItemText>
            </SidebarMenuItem>
          </Link>

          <Typography variant="h6" component="h6" style={{fontSize:"13px",fontWeight:"700", color:"gray", marginBottom:"-20px"}}>
            SETTINGS
          </Typography>

          {/* My profile */}
          <>
            <SidebarMenuItem onClick={(e) => { setProfileMenuOpen(prev => !prev) }}>
              <img src={MyProfileIcon} alt="My Profile icon" />
              <SidebarMenuItemText variant="body1">My Profile</SidebarMenuItemText>
              <SidebarMenuItemExpandIcon expanded={profileMenuOpen} alt="Expand icon" src=  {ExpandIcon} /  >
            </SidebarMenuItem>
            {/* My profile - Sub menu */}
            <SidebarSubMenuCollapsibleWrapper in={profileMenuOpen} isOpen={profileMenuOpen}>
              <Link to={isEditing ? "#" : "/profile/personal"} onClick={() => {
                changeLink('/profile/ personal');
                setSidebarOpen(false);
              }}>
                <SidebarSubMenuItemText style={{fontSize:"14px"}}>Personal Info</SidebarSubMenuItemText>
              </Link>
              <Link to={isEditing ? "#" : "/profile/work-education"} onClick={() => {
                changeLink('/ profile/work-education');
                setSidebarOpen(false);
              }}>
                <SidebarSubMenuItemText style={{fontSize:"14px"}}>Work & Education</SidebarSubMenuItemText>
              </Link>
              <Link to={isEditing ? "#" : "/profile/account"} onClick={() => {
                changeLink('/profile/  account');
                setSidebarOpen(false);
              }}>
                <SidebarSubMenuItemText style={{fontSize:"14px"}}>Account Settings</SidebarSubMenuItemText>
              </Link>
              <Link to={isEditing ? "#" : "/profile/bank-details"} onClick={() => {
                changeLink('/ profile/bank-details');
                setSidebarOpen(false);
              }}>
                <SidebarSubMenuItemText style={{fontSize:"14px"}}>Bank Details</SidebarSubMenuItemText>
              </Link>
            </SidebarSubMenuCollapsibleWrapper>
          </>

          {/* Logout */}
          <Link to="#" onClick={handleLogout}>
            <SidebarMenuItem>
              <img src={LogoutIcon} alt="Logout icon" />
              <SidebarMenuItemText variant="body1" customColor="#E80512">Logout</SidebarMenuItemText>
            </SidebarMenuItem>
          </Link>

           {/*  DP  */}
          <Box>
          <Avatar src={fetchedProfile.avatar} alt="User avatar" style={{marginLeft:"60px"}}/>
          </Box>
          

        </Box>

      </DrawerSidebar>
    </>  
  )
}

export default NavBar;