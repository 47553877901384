import React from "react";
import Modal from "@material-ui/core/Modal";


const CustomModal = ({ open, onClose, children}) => {
  return (
    <Modal
        open={open}
        onClose={onClose}
    >
        {children}
    </Modal>
  );
};

export default CustomModal;

