import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { styled } from "@material-ui/core/styles"

export const AppbarWrapper = styled(Box)(({ theme }) => ({
    minHeight: theme.spacing(9),
    padding: "0 8px",
    marginBottom: "24px",
    zIndex:'999',
    backgroundColor:'#ffffff',
    width: "100%",
    position: "sticky",
    top: 0,
    left: 0,
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "8px",
    borderBottom: "1px solid #D5E5FA",
    "@media (min-width: 769px)": {
        display: "none"
    }
}))

export const AppbarPageName = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    color: "#3D3D3D",
    fontFamily: "Poppins"
}))

export const AugmentElementWrapper = styled(Box)(({ theme }) => ({
    flexBasis: 0,
    flexGrow: 1,
    height: "100%",
    position: "relative",
    "& *": {
        fontFamily: "Poppins",
        textTransform: "none"
    }
}))