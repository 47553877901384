import * as React from "react";
import { useState } from "react";
import Drawer from '@mui/material/Drawer';
import AcccessibleTable from "./DetailedAudit";
import "./Mydrawer.scss";

const Mydrawer = ({demoData, setViewDescription, setViewSideModal,currentTimezone}) => {
    const [openDrawer, setOpenDrawer] = useState(true);
    const [closeicon, setCloseicon] = useState(false);
    console.log("demoData", demoData)
    return(
      <div id="drawerBox">
        <div>
          <Drawer anchor='right'  
            open={openDrawer} 
            onOpen={()=> setOpenDrawer(true)}
            onClose={()=> { setOpenDrawer(false)
                            setCloseicon(true)
                            setViewSideModal(false);
                          }}
          >
            <AcccessibleTable 
              setOpenDrawer={setOpenDrawer} 
              demoData={demoData} 
              setViewDescription={setViewDescription} 
              setViewSideModal={setViewSideModal}
              currentTimezone={currentTimezone} 
            />
        </Drawer>
        </div>
      </div>
    )
}

export default Mydrawer;