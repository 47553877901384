// const config = {
//     local: {
//         api: {
//             baseUrl: "http://localhost:5000/api",
//         }
//     },
//     dev: {
//         api: {
//             baseUrl: "https://dev.api.interviewvector.com/api",
//         }
//     },
//     test: {
//         api: {
//             baseUrl: "https://test.api.interviewvector.com/api",
//         }
//     },
//     mirror: {
//         api: {
//             baseUrl: "https://mirror.api.interviewvector.com/api",
//         }
//     },
//     stage: {
//         api: {
//             baseUrl: "https://stage.api.interviewvector.com/api",
//         }
//     },
//     test2: {
//         api: {
//             baseUrl: "https://test2.api.interviewvector.com/api",
//         }
//     },
//     prod: {
//         api: {
//             baseUrl: "https://api.interviewvector.com/api",
//         }
//     },
// };

// const env = process.env.REACT_APP_STAGE || "local";

// export default config[env];
const config = {
    local: {
      api: {
        baseUrl: "http://localhost:5000/api",
      },
      apps: {
        client: {
          baseUrl: "http://localhost:3000"
        },
        interviewer: {
          baseUrl: 'http://localhost:3000'
        }
      }
    },
    test: {
      api: {
        baseUrl: "https://test.ops.interviewvector.com/api",
      },
      apps: {
        client: {
          baseUrl: "https://test.app.interviewvector.com"
        },
        interviewer:{
          baseUrl: 'https://test.iv.interviewvector.com'
        }
      }
    },
    stage: {
      api: {
        baseUrl: "https://stage.api.interviewvector.com/api",
      },
      apps: {
        client: {
          baseUrl: "https://stage.app.interviewvector.com"
        },
        interviewer:{
          baseUrl: 'https://stage.iv.interviewvector.com'
        }
      }
    },
    dev: {
      api: {
        baseUrl: "https://dev.api.interviewvector.com/api",
      },
      apps: {
        client: {
          baseUrl: "http://dev.app.interviewvector.com"
        },
        interviewer: {
          baseUrl: 'https://dev.iv.interviewvector.com'
        }
      }
    },
    mirror: {
      api: {
        baseUrl: "https://mirror.api.interviewvector.com/api",
      },
      apps: {
        client: {
          baseUrl: "http://mirror.app.interviewvector.com"
        },
        interviewer: {
          baseUrl: 'https://mirror.iv.interviewvector.com'
        }
      }
    },
    test: {
      api: {
        baseUrl: "https://test.api.interviewvector.com/api",
      },
      apps: {
        client: {
          baseUrl: "http://test.app.interviewvector.com"
        },
        interviewer: {
          baseUrl: 'https://test.iv.interviewvector.com'
        }
      }
    },
    prod: {
      api: {
        baseUrl: "https://api.interviewvector.com/api",
      },
      apps: {
        client: {
          baseUrl: "https://app.interviewvector.com"
        },
        interviewer: {
          baseUrl: 'https://iv.interviewvector.com'
        }
      }
    }
  };
  
  const env = process.env.REACT_APP_STAGE || "local";
  
  export default config[env];
  