export const FormAction = {
    ADD: "ADD",
    EDIT: "EDIT",
    DUPLICATE : "DUPLICATE"
};

export const StepperSteps = {
    GUIDELINES_INTRO: { key: 'GUIDELINES_INTRO', label: 'Guidelines and Intro' },
    TECHNICAL: { key: 'TECHNICAL', label: 'Technical' },
    THEORETICAL: { key: 'THEORETICAL', label: 'Theoretical' }
}

export const StagesType = {
    GUIDELINES_INTRODUCTION : "GUIDELINES_INTRODUCTION",
    TECHNICAL : "TECHNICAL",
    THEORETICAL : "THEORETICAL",
}

export const GuidelineIntroductionType = {
    GENERIC_INSTRUCTIONS : "GENERIC_INSTRUCTIONS",
    RATINGS : "RATINGS",
    TIME_DISTRIBUTION : "TIME_DISTRIBUTION",
    SKILL_EVALUATION : "SKILL_EVALUATION",
    RESUME : "RESUME",
}

export const TechnicalType = {
    TECHNICAL_QUESTIONS_DISTRIBUTION : "TECHNICAL_QUESTIONS_DISTRIBUTION",
    TECH_STACK : "TECH_STACK",
    TECHNICAL_QUESTIONS : "TECHNICAL_QUESTIONS",
    SECTION_FEEDBACK : "SECTION_FEEDBACK",
}

export const TheoreticalType = {
    THEORETICAL_QUESTIONS_DISTRIBUTION : "THEORETICAL_QUESTIONS_DISTRIBUTION",
    THEORETICAL_QUESTIONS : "THEORETICAL_QUESTIONS",
}

export const CommentStatus = {
    UNRESOLVED:"UNRESOLVED",
    RESOLVED:"RESOLVED",
    DELETED:"DELETED",
}

export const GuideSectionCssClass = {
    INITIAL : "main-box",
    NEW_COMMENT_HOVER : 'blue-box',
    PREV_COMMENT_HOVER: "light-yellow-box",
    PREV_COMMENT_CLICK : "dark-yellow-box",
}
export const GuideCommentIconClass = {
    NEW_COMMENT : "blue-icon",
    PREV_COMMENT : "yellow-icon",
}