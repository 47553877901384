import React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import './HighlighterStrap.scss';

const HighlighterStrap = ({time, position,clientName}) => {
    return (
        <Box display='flex' flexDirection='column' justifyContent='flex-start' className='strap-wrapper'>
            <Typography className='time'>{time}</Typography>
            <Typography className='position'>{position} </Typography>
            <Typography className='client-name'>{clientName}</Typography>
        </Box>
    )
}

export default HighlighterStrap;
