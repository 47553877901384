import CustomizedDialogs from "./ViewQuestion";
import Box from '@mui/material/Box';
import "./QuestionCard.scss";
import quickLinkIcon from "../../../../assets/images/quick-link.svg";

export const QuestionCard = ({ index, mainData }) => {
  return (
    <Box display='flex' flexDirection="column" className="question_card_preview">
      <Box display="flex" flexDirection='row'>
      <Box display='flex' className="font-13 fg-black bold-600 mb-2p mr-5p width-15">
        Q.
      </Box>
       <Box display='flex' className="font-13 fg-black bold-500 mb-2p white-space">
       {
         mainData?.question?.name && mainData?.question?.name?.length > 0?mainData?.question?.name:""
        }
       </Box>
      </Box>

      <Box marginLeft="20px">
       <p className="font-11">
        <span
          className={
            mainData?.difficulty_level?.toLowerCase() === "easy"
              ? "bold-600 fg-green td-none"
              : mainData?.difficulty_level?.toLowerCase() === "medium"
              ? "bold-600 fg-gold td-none"
              : "bold-600 fg-red td-none"
          }
        >
          {mainData?.difficulty_level?.toUpperCase()}
        </span>
        <a className="bold-900 fg-black td-none"> &#8226; </a>{" "}
        <a className="bold-400 fg-gray4 td-none">{mainData?.avg_time_taken?mainData?.avg_time_taken+" mins":""}</a>
        <a className="bold-900 fg-black td-none"> &#8226; </a>{" "}
        <CustomizedDialogs
          index={index}
          mainData={mainData}
          section={"Technical Question"}
        />
        {
          mainData?.question?.link && mainData?.question?.link?.length>0?(<>
            <a className="bold-900 fg-black td-none"> &#8226; </a>
        <a href={mainData?.question?.link} target="_blank" className='font-style question-link'>Question Link <img src={quickLinkIcon}/></a>
        </>
          ):""
        }
       
      </p>

      <p className="font-11 fg-gray4 tag">{mainData?.tags?.join(", ")}</p>


      <p className="font-13 fg-black bold-400 mb-2p white-space" style={{marginTop: -13}}>
      {
            mainData?.question?.statement &&
            mainData?.question?.statement?.length > 0
            ? mainData?.question?.statement
            : ""
      }
      </p>
  
     
      </Box>
    </Box>
  );
};
