import axios from "./axios";

const getTemporaryFeedbackAccessToken = () => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const accessToken = url.searchParams.get("token");

    if (accessToken) {
        return {
            'X-Interviewer-Request-Access-Token': accessToken,
            Authorization:null,
        };
    }

    return {};
};

export const addFeedback = async (interview_id, payload) => {
    try{
        const resp = await axios.post(`/interview/${interview_id}/feedback`, payload,  {
            headers: { ...getTemporaryFeedbackAccessToken() }
        });
        return resp;
    } catch(err){
        return err?.response;
    }
};


export const editFeedback = async (interview_id, payload) => {
    try{
        const resp = await axios.put(`/interview/${interview_id}/feedback`, payload,  {
            headers: { ...getTemporaryFeedbackAccessToken() }
        });
        return resp;
    } catch(err){
        return err?.response;
    }
};


export const getFeedback = async (interview_id) => {
    try{
        const resp = await axios.get(`/interview/${interview_id}/feedback`,  {
            headers: { ...getTemporaryFeedbackAccessToken() }
        });
        return resp?.data;
    } catch(err){
        throw(err)
    }
};

export const getFeedbackComments = async (feedback_id) => {
    try{
        const resp = await axios.get(`/feedback_comment/${feedback_id}`,  {
            headers: { ...getTemporaryFeedbackAccessToken() }
        });
        return resp?.data;
    } catch(err){
        throw(err)
    }
};

export const editFeedbackComment = async (payload, comment_id) => {
    try {
        const resp = await axios.post(`/feedback_comment/${comment_id}/edit`, payload,  {
            headers: { ...getTemporaryFeedbackAccessToken()}
        });
        return resp?.data;
    } catch(err){
        throw(err)
    }
};


export const addFeedbackCommentReply = async (payload) => {
    try {
        const resp = await axios.post(`/feedback_comment/thread/add`, payload,  {
            headers: { ...getTemporaryFeedbackAccessToken()}
        });
        return resp?.data;
    } catch(err){
        throw(err)
    }
};