import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

import AppropriateBig from "./Images/Appropriate-Big.png";
import OverratedBig from "./Images/overrated-big.png";
import UnderratedBig from "./Images/underrated-big.png";

Font.register({
  family: "Poppins",
  src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
  fonts: [
    {
      src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
    },
    {
      src: `/assets/poppins-fonts/Poppins-Thin.ttf`,
      fontWeight: "thin",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Medium.ttf`,
      fontWeight: "medium",
    },
    {
      src: `/assets/poppins-fonts/Poppins-SemiBold.ttf`,
      fontWeight: "semibold",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Italic.ttf`,
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-BoldItalic.ttf`,
      fontWeight: "bold",
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Bold.ttf`,
      fontWeight: "bold",
    },
  ],
});

Font.registerHyphenationCallback((word) => [word]);

const stylesFeedbackBox = StyleSheet.create({
  FeedbackBoxmainwrapper: {
    height: 156,
    width: 850,
    backgroundColor: "#ffffff",
    borderRadius: 14,
    marginTop: 20,
    marginLeft: 20,
    marginBottom: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  FeedbackBoxChildWrapper: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#f5f8ff",
    width: 826,
    height: 130,
    borderRadius: 6,
  },

  auditFeedbackHeading: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: 0.005,
    color: "#123fbc",
    marginTop: 15,
  },

  auditFeedbackNumber: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 30,
    letterSpacing: 0.025,
    color: "#303E61",
  },

  auditFeedbackOverallRating: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.025,
    color: "#52659B",
    marginTop: 12,
    marginLeft: 4,
  },

  initialFeedbackRating: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: 0.005,
    color: "#3D3D3D",
    marginTop: 15,
  },

  auditFeedbackSuggestion: {
    backgroundColor: "#0B8701",
    width: 159,
    height: 26,
    borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 30,
    marginTop: 3,
  },

  auditFeedbackSuggestionText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 0.035,
    color: "#FFFFFF",
  },

  initialFeedbackRatingNumber: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 30,
    letterSpacing: 0.025,
    color: "#3D3D3D",
  },

  initialFeedbackOverallRating: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 15,
    letterSpacing: 0.025,
    color: "#545455",
    marginTop: 12,
    marginLeft: 4,
  },

  initialFeedbackSuggestion: {
    backgroundColor: "#A3A3A3",
    width: 159,
    height: 26,
    borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 15,
    marginTop: 3,
  },

  initialFeedbackSuggestionText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 0.035,
    color: "#FFFFFF",
  },

  postAuditHeading: {
    marginTop: 15,
    marginLeft: 230,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 13,
    letterSpacing: 0.005,
    color: "#3D3D3D",
  },

  postAuditText: {
    marginLeft: 105,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    letterSpacing: 0.005,
    color: "#797979",
    marginTop: 6,
  },
});

const FeedbackBox = ({ data }) => {
  return (
    <View style={[stylesFeedbackBox.FeedbackBoxmainwrapper]}>
      <View style={[stylesFeedbackBox.FeedbackBoxChildWrapper]}>
        {/* Audit feedback suggestion Box */}
        <View style={[{ marginLeft: 20 }]}>
          <View>
            <Text style={[stylesFeedbackBox.auditFeedbackHeading]}>
              Audit Report Suggestion
            </Text>
          </View>
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginLeft: 25,
                marginTop: 6,
              },
            ]}
          >
            <Text style={[stylesFeedbackBox.auditFeedbackNumber]}>
              {data?.audit_report?.overall_rating}
            </Text>
            <Text style={[stylesFeedbackBox.auditFeedbackOverallRating]}>
              (Overall Rating)
            </Text>
          </View>
          <View style={[stylesFeedbackBox.auditFeedbackSuggestion]}>
            <Text style={[stylesFeedbackBox.auditFeedbackSuggestionText]}>
              {data?.audit_report?.overall_recommendation == "STRONG_REJECT"
                ? "STRONG REJECT"
                : data?.audit_report?.overall_recommendation == "STRONG_PROCEED"
                ? "STRONG PROCEED"
                : data?.audit_report?.overall_recommendation == "REJECT"
                ? "REJECT"
                : data?.audit_report?.overall_recommendation == "PROCEED"
                ? "PROCEED"
                : ""}
            </Text>
          </View>
        </View>

        {/* Initial feedback rating  Box*/}
        <View style={[{ marginLeft: 130 }]}>
          <View>
            <Text style={[stylesFeedbackBox.initialFeedbackRating]}>
              {" "}
              Initial Feedback Rating
            </Text>
          </View>
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginLeft: 10,
                marginTop: 6,
              },
            ]}
          >
            <Text style={[stylesFeedbackBox.initialFeedbackRatingNumber]}>
              {data?.audit_details?.interviewer_feedback?.overall_rating}
            </Text>
            <Text style={[stylesFeedbackBox.initialFeedbackOverallRating]}>
              (Overall Rating)
            </Text>
          </View>
          <View style={[stylesFeedbackBox.initialFeedbackSuggestion]}>
            <Text style={[stylesFeedbackBox.initialFeedbackSuggestionText]}>
              {data?.audit_details?.interviewer_feedback
                ?.overall_recommendation == "STRONG_REJECT"
                ? "STRONG REJECT"
                : data?.audit_details?.interviewer_feedback
                    ?.overall_recommendation == "STRONG_PROCEED"
                ? "STRONG PROCEED"
                : data?.audit_details?.interviewer_feedback
                    ?.overall_recommendation == "REJECT"
                ? "REJECT"
                : data?.audit_details?.interviewer_feedback
                    ?.overall_recommendation == "PROCEED"
                ? "PROCEED"
                : ""}
            </Text>
          </View>
        </View>

        {/* Post Audit Box */}
        <View>
          <View>
            <Text style={[stylesFeedbackBox.postAuditHeading]}>Post Audit</Text>
          </View>
          <View>
            <Text style={[stylesFeedbackBox.postAuditText]}>
              Initial feedback was found to be
            </Text>
          </View>
          <View>
            <Image
              src={
                data?.audit_details?.result == "APPROPRIATE"
                  ? AppropriateBig
                  : data?.audit_details?.result == "OVER_RATED"
                  ? OverratedBig
                  : UnderratedBig
              }
              style={[
                { width: 205, height: 27, marginLeft: 105, marginTop: 15 },
              ]}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default FeedbackBox;
